export default function getControlBodyDictionary(options) {
  const controlBodies = {
    "GB-ORG-02": {
      code: "GB-ORG-02",
      name: "Organic Farmers and Growers CIC",
      label: "Organic Farmers and Growers CIC (GB-ORG-02)",
      website: "www.ofgorganic.org",
    },
    "GB-ORG-04": {
      code: "GB-ORG-04",
      name: "Organic Food Federation",
      label: "Organic Food Federation (GB-ORG-04)",
      website: "www.orgfoodfed.com",
    },
    "GB-ORG-05": {
      code: "GB-ORG-05",
      name: "Soil Association Certification Ltd",
      label: "Soil Association Certification Ltd (GB-ORG-05)",
      website: "www.soilassociation.org/certification/",
    },
    "GB-ORG-06": {
      code: "GB-ORG-06",
      name: "Biodynamic Association Certification",
      label: "Biodynamic Association Certification (GB-ORG-06)",
      website: "www.bdcertification.org.uk",
    },
    "GB-ORG-13": {
      code: "GB-ORG-13",
      name: "Quality Welsh Food Certification Ltd",
      label: "Quality Welsh Food Certification Ltd (GB-ORG-13)",
      website: "www.wlbp.co.uk",
    },
    "GB-ORG-17": {
      code: "GB-ORG-17",
      name: "OF&G (Scotland) Ltd",
      label: "OF&G (Scotland) Ltd (GB-ORG-17)",
      website: "www.ofgorganic.org",
    },
  };

  if (options?.array)
    return [
      controlBodies["GB-ORG-02"],
      controlBodies["GB-ORG-04"],
      controlBodies["GB-ORG-05"],
      controlBodies["GB-ORG-06"],
      controlBodies["GB-ORG-13"],
      controlBodies["GB-ORG-17"],
    ];
  else return controlBodies;
}
