import { useState, useEffect } from "react";
import { useGetCurrentUser, useLogOut } from "../../api";
import { useLocation } from "react-router-dom";
import getAdminLinks from "./getAdminLinks";
import getProfileLink from "./getProfileLink";
import RenderedLinks from "./RenderedLinks";
import getPublicLinks from "./getPublicLinks";
import getDashboardLinks from "./getDashboardLinks";
import SignInModal from "../SignInModal";
import classNames from "classnames";
import Hamburger from "./Hamburger";

function NavLinks() {
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setIsSignInOpen(false);
  }, [location]);

  const logOutAPI = useLogOut();
  const handleLogOut = () => {
    logOutAPI.mutate();
  };

  const currentUserApi = useGetCurrentUser();
  const currentUser = currentUserApi.data;

  if (!currentUser || !location) return;

  const adminRoute = location.pathname.startsWith("/admin");
  const businessRoute = location.pathname.startsWith("/dashboard");
  const publicRoute = !adminRoute && !businessRoute;

  const adminLinks = adminRoute ? getAdminLinks(currentUser) : [];
  const publicLinks = publicRoute
    ? getPublicLinks(currentUser, () => setIsSignInOpen(true))
    : [];
  const profileLink = getProfileLink(currentUser, handleLogOut);
  const dashboardLinks = businessRoute ? getDashboardLinks(currentUser) : [];
  const links = [
    ...adminLinks,
    ...publicLinks,
    ...dashboardLinks,
    ...profileLink,
  ];

  return (
    <div
      className={classNames(
        "p-2 rounded-lg flex flex-row items-center justify-center gap-4",
        {
          "text-hgCream-50": !adminRoute,
        }
      )}
    >
      <RenderedLinks links={links} adminRoute={adminRoute} />
      <Hamburger links={links} adminRoute={adminRoute} />
      {isSignInOpen && <SignInModal onClose={() => setIsSignInOpen(false)} />}
    </div>
  );
}

export default NavLinks;
