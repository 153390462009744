import FlexCentered from "../Containers/FlexCentered";
import formattedNumber from "../utils/formattedNumber";
import P from "./P";
function RangeSlider({ value, low, high }) {
  const positionalValue = Math.max(Math.min(value, high), low);
  const position = Math.floor(((positionalValue - low) / (high - low)) * 100);

  return (
    <FlexCentered col className="w-full h-full bg-inherit px-[2.25rem]">
      <div className="relative w-full bg-hgBlue-900 h-1  select-none ">
        <FlexCentered
          className="absolute w-[3rem] h-[3rem] top-[50%] text-sm -translate-x-1/2 -translate-y-1/2 bg-hgGreen-1100 text-hgCream-50  rounded-full p-2 shadow-lg"
          style={{ left: `${position}%` }}
        >
          <P className="text-center">{formattedNumber(value)}</P>
        </FlexCentered>
      </div>
    </FlexCentered>
  );
}
export default RangeSlider;
