import { AiOutlineClose } from "react-icons/ai";
import ModalWindow from "../Components/ModalWindow";
import FieldRecord from "./FieldRecord";
import { useGetCurrentUser, useGetBusiness, useGetFields } from "../api";
import Loading from "../Components/Loading";
import FlexCentered from "../Containers/FlexCentered";
import Heading from "../Components/Heading";

function AddFieldModal({ onClose }) {
  const getCurrentUserApi = useGetCurrentUser();
  const currentUser = getCurrentUserApi?.data?.user;

  const getBusinessApi = useGetBusiness({
    businessRef: currentUser?.linkedBusiness,
    params: {
      fields: "tradingName,location",
    },
  });
  const linkedBusiness = getBusinessApi?.data?.business;

  const getFieldsApi = useGetFields({
    businessRef: currentUser?.linkedBusiness,
  });
  const fields = getFieldsApi?.data?.fields;

  const handleSuccess = (newField) => {
    if (!onClose) return;
    onClose(newField);
  };

  let content;
  if (
    !getCurrentUserApi.isFetched ||
    !getBusinessApi.isFetched ||
    !getFieldsApi.isFetched ||
    !linkedBusiness ||
    !fields
  ) {
    content = <Loading />;
  } else {
    content = (
      <div className="relative bg-hgBlue-900 rounded overflow-hidden shadow-lg">
        <div
          className="bg-hgCream-50 w-fit h-fit rounded-full p-1 absolute right-[5px] top-[5px] cursor-pointer hover:scale-105  hover:text-hgRed-700"
          onClick={onClose}
        >
          <AiOutlineClose className=" text-xl" />
        </div>
        <Heading primary className="text-center text-xl text-hgCream-50 py-1 ">
          Add Field
        </Heading>
        <FieldRecord
          linkedBusiness={linkedBusiness}
          fields={fields}
          forceAddMode
          onSuccess={handleSuccess}
        />
      </div>
    );
  }

  return (
    <ModalWindow
      options={{
        blur: true,
      }}
    >
      <FlexCentered col className="h-full">
        {content}
      </FlexCentered>
    </ModalWindow>
  );
}
export default AddFieldModal;
