import { useState } from "react";
import { BsChevronLeft, BsChevronDown } from "react-icons/bs";
import Heading from "../../../../Components/Heading";
import P from "../../../../Components/P";
import Button from "../../../../Components/Button";
import Link from "../../../../Components/Link";

function ProductData({ product }) {
  const [isOpen, setIsOpen] = useState(false);

  const metrics = [
    { field: "moistureContent", label: "Moisture Content", units: "%" },
    { field: "protein", label: "Protein", units: "%" },
    { field: "hagberg", label: "Hagberg" },
    { field: "gluten", label: "Gluten", units: "%" },
    { field: "specificWeight", label: "Specific Weight", units: " g/l" },
    { field: "germinationRate", label: "GerminationRate", units: "%" },
    {
      field: "thousandGrainWeightGrams",
      label: "Thousand Grain Weight",
      units: "g",
    },
    { field: "DON", label: "DON", units: "ppb" },
    { field: "ZON", label: "ZON", units: "ppb" },
    { field: "septoria", label: "Septoria", units: "%" },
    { field: "bunt", label: "Bunt", units: "sps" },
  ];

  const renderedData = [];
  const nullishValues = [undefined, null];
  metrics.forEach((metric) => {
    if (!product[metric.field] && nullishValues.includes(product[metric.field]))
      return;
    const classes =
      "border  border-hgBlue-500 mb-1 py-1 px-2 group-hover:scale-105 group-hover:bg-hgBlue-900 group-hover:text-hgCream-50 select-none";
    renderedData.push(
      <div key={metric.field} className="group contents">
        <P className={classes + " border-r-0"}>{metric.label}: </P>
        <P className={classes + " border-l-0"}>
          {product[metric.field]}
          {[metric.units || ""]}
        </P>
      </div>
    );
  });

  return (
    <div className="max-w-lg w-fit ">
      <div
        className="flex flex-row items-center gap-x-2 cursor-pointer hover:text-hgGreen-900"
        onClick={() => setIsOpen((curr) => !curr)}
      >
        <Heading secondary className="text-xl font-light text-hgBlue-900">
          Product Data
        </Heading>

        {isOpen && <BsChevronDown />}
        {!isOpen && <BsChevronLeft />}
      </div>
      <div className="pt-1 border-b-[1px] border-hgGreen-900 pr-[4vw]" />
      {isOpen && (
        <div className="pt-8">
          {renderedData.length > 0 && (
            <>
              <div className="grid grid-cols-[auto_1fr] mb-4">
                {renderedData}
              </div>
              {renderedData.length < metrics.length && (
                <div className="bg-hgRed-100 px-4 py-2 w-fit rounded shadow mt-2 mb-4">
                  <P>
                    Product data is incomplete. Click the "Edit" button below to
                    add additional product data.
                  </P>
                </div>
              )}
            </>
          )}
          {renderedData.length === 0 && (
            <div className="bg-hgRed-100 px-4 py-2 w-fit rounded shadow mb-8">
              <P>
                No data recorded. Click the "Edit" button below to add product
                data
              </P>
            </div>
          )}
          <Link
            to={`/dashboard/products/${product.reference}/edit-product-data`}
          >
            <Button secondary outline>
              Edit
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}
export default ProductData;
