import purgeIncompatibleFilters from "./purgeIncompatibleFilters";

function setQuery(filters, field, operator, value, value2) {
  const fieldName = field?.fieldName;
  let fieldLabel = field?.label;
  if (!fieldName || !fieldLabel || !operator) return;
  if (!filters[fieldName]) filters[fieldName] = {};

  fieldLabel = `${fieldLabel[0].toUpperCase()}${fieldLabel
    .slice(1)
    .toLowerCase()}`;

  purgeIncompatibleFilters(filters, fieldName, operator);

  if (operator === "Equals") {
    let queries;
    if (value instanceof Boolean || value === "Undefined") {
      if (value === true) {
        queries = [[`${fieldName}`, true]];
      }
      if (value === false) {
        queries = [[`${fieldName}`, "false"]];
      }
      if (value === "Undefined") {
        queries = [[`${fieldName}[exists]`, "false"]];
      }
    } else if (value instanceof Date) {
      queries = [
        [
          `${fieldName}[gt]`,
          new Date(value.getFullYear(), value.getMonth(), value.getDate()),
        ],
        [
          `${fieldName}[lt]`,
          new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1),
        ],
      ];
    } else queries = [[`${fieldName}`, value]];

    filters[fieldName].eq = {
      queries,
      label: `${fieldLabel} equals ${
        value instanceof Date
          ? new Date(value).toLocaleDateString("en-gb")
          : `"${value}"`
      }`,
    };
  }
  if (operator === "Not equal to") {
    let queries;
    if (value instanceof Boolean || value === "Undefined") {
      if (value === true) {
        queries = [[`${fieldName}[ne]`, true]];
      }
      if (value === false) {
        queries = [[`${fieldName}[ne]`, "false"]];
      }
      if (value === "Undefined") {
        queries = [
          [`${fieldName}[exists]`, true],
          [`${fieldName}[ne]`, null],
        ];
      }
    } else {
      queries = [[`${fieldName}[ne]`, value]];
    }
    filters[fieldName].ne = {
      queries,
      label: `${fieldLabel} not equal to "${value}"`,
    };
  }
  if (operator === "Contains") {
    filters[fieldName].con = {
      queries: [[`${fieldName}[regex]`, value]],
      label: `${fieldLabel} contains "${value}"`,
    };
  }
  if (operator === "Does not contain") {
    filters[fieldName].ncon = {
      queries: [[`${fieldName}[not]`, value]],
      label: `${fieldLabel} does not contain "${value}"`,
    };
  }
  if (operator === "Exists") {
    filters[fieldName].ex = {
      queries: [
        [`${fieldName}[exists]`, true],
        [`${fieldName}[ne]`, null],
      ],
      label: `${fieldLabel} exists`,
    };
  }
  if (operator === "Does not exist") {
    filters[fieldName].nex = {
      queries: [[`${fieldName}[exists]`, "false"]],
      label: `${fieldLabel} does not exist`,
    };
  }
  if (operator === "Greater than") {
    filters[fieldName].gt = {
      queries: [[`${fieldName}[gt]`, value]],
      label: `${fieldLabel} greater than ${value}`,
    };
  }
  if (operator === "Greater than or equal to") {
    filters[fieldName].gte = {
      queries: [[`${fieldName}[gte]`, value]],
      label: `${fieldLabel} greater than or equal to ${value}`,
    };
  }
  if (operator === "Less than") {
    filters[fieldName].lt = {
      queries: [[`${fieldName}[lt]`, value]],
      label: `${fieldLabel} less than ${value}`,
    };
  }
  if (operator === "Less than or equal to") {
    filters[fieldName].lte = {
      queries: [[`${fieldName}[lte]`, value]],
      label: `${fieldLabel} less than or equal to ${value}`,
    };
  }
  if (operator === "Between") {
    filters[fieldName].bet = {
      queries: [
        [
          `${fieldName}[gt]`,
          new Date(value.getFullYear(), value.getMonth(), value.getDate()),
        ],
        [
          `${fieldName}[lt]`,
          new Date(
            value2.getFullYear(),
            value2.getMonth(),
            value2.getDate() + 1
          ),
        ],
      ],
      label: `${fieldLabel} between ${new Date(value).toLocaleDateString(
        "en-gb"
      )} and ${new Date(value2).toLocaleDateString("en-gb")}`,
    };
  }
}
export default setQuery;
