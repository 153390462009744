import { Routes, Route } from "react-router-dom";
import FormContext from "../helpers/formContext";
import UserDetailsSection from "./FormSpecificSections/UserDetailsSection";
import BusinessDetailsSection from "../ReusableSections/BusinessDetailsSection";
import ConfirmationSection from "../ReusableSections/ConfirmationSection";

function RegisterWithBusinessDetailsForm({ type }) {
  const defaultValues = {
    givenName: "",
    familyName: "",
    email: "",
    password: "",
    confirmPassword: "",
    businessType: null,
    tradingName: "",
    CPHNumber: "",
    formattedAddress: "",
    postcode: "",
    location: "",
  };

  return (
    <FormContext defaultState={defaultValues}>
      <Routes>
        <Route exact path="/" element={<UserDetailsSection type={type} />} />
        <Route
          path="/business-details"
          element={<BusinessDetailsSection type={type} isUserRegistration />}
        />
        <Route
          path="/confirmation"
          element={
            <ConfirmationSection
              type={type}
              isUserRegistration
              onPrevious={`/registration-form/business-details?type=${type}`}
            />
          }
        />
      </Routes>
    </FormContext>
  );
}

export default RegisterWithBusinessDetailsForm;
