import { useMemo, useState, useEffect } from "react";
import { useTableContext } from "../../TableContext";
import Dropdown from "../../../Dropdown";
import Label from "../../../Label";
import Value from "./Value";
import Button from "../../../Button";
import FlexCentered from "../../../../Containers/FlexCentered";

function SetFilter({ onClose }) {
  const [selectedField, setSelectedField] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValue2, setSelectedValue2] = useState("");

  useEffect(() => {
    setSelectedValue("");
    setSelectedValue2("");
  }, [selectedOperator, selectedField]);

  const { config, Filter, setQuery } = useTableContext();

  const fieldNames = [];
  config.columns.forEach((col) => {
    col.fieldNames.forEach((fieldName, i) => {
      fieldNames.push({
        fieldName: fieldName,
        type: col.filterType,
        label: col.fieldNamesLabels[i],
        options: col.options,
      });
    });
  });

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (selectedValue instanceof Date) {
      if (selectedOperator?.label === "Equals") {
        setQuery.filter(selectedField, selectedOperator.label, selectedValue);
      }
      if (selectedOperator?.label === "Between") {
        setQuery.filter(
          selectedField,
          selectedOperator.label,
          selectedValue,
          selectedValue2
        );
      }
    } else if (typeof selectedValue === "object") {
      setQuery.filter(
        selectedField,
        selectedOperator.label,
        selectedValue.value
      );
    } else {
      setQuery.filter(selectedField, selectedOperator.label, selectedValue);
    }
    onClose();
  };

  const fieldDropdownConfig = {
    data: fieldNames,
    getKey: (field) => field.fieldName,
  };

  const operatorDropdownConfig = useMemo(() => {
    if (!selectedField) return;
    const operators = Filter.getOperators(selectedField.type);
    if (!operators) return;
    return {
      data: operators.map((itm) => {
        return { label: itm };
      }),
      getKey: (itm) => itm.label,
    };
  }, [Filter, selectedField]);

  const valueOptions = useMemo(() => {
    if (!selectedOperator) return;
    if (selectedOperator.label === "Exists") return;
    if (selectedOperator.label === "Does not exist") return;
    const options = Filter.getValueOptions(
      selectedField?.type,
      selectedField?.options
    );
    if (!options) return;
    return options;
  }, [Filter, selectedField?.options, selectedField?.type, selectedOperator]);

  return (
    <form
      className="px-12 pt-4 pb-4 bg-hgCream-50 rounded w-max min-h-[12rem]"
      onSubmit={handleSubmit}
    >
      <Label>Field</Label>
      <Dropdown
        modalId="fields-dropdown"
        config={fieldDropdownConfig}
        value={selectedField}
        onChange={setSelectedField}
        className="mt-2 mb-4 text-left"
        maxHeight="max-h-30"
      />
      <Label>Operator</Label>

      <Dropdown
        modalId="operators-dropdown"
        config={operatorDropdownConfig}
        value={selectedOperator}
        onChange={setSelectedOperator}
        className="mt-2 mb-4 text-left"
        disabled={!operatorDropdownConfig}
        maxHeight="max-h-30"
      />
      <Label>Value</Label>
      <Value
        className="mt-2"
        options={valueOptions}
        operator={selectedOperator}
        value={selectedValue}
        onChange={setSelectedValue}
        value2={selectedValue2}
        onChange2={setSelectedValue2}
      />
      <FlexCentered row>
        <Button primary className="my-4">
          Apply
        </Button>
      </FlexCentered>
    </form>
  );
}

export default SetFilter;
