export default function getCSV({
  url,
  params,
  fileName,
  setError,
  setLoading,
  onError,
  onSuccess,
}) {
  setLoading(true);
  setError("");

  let queryString = "";
  if (params) queryString = `?${new URLSearchParams(params).toString()}`;

  fetch(`/api/${url}${queryString}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/csv",
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        setLoading(false);
        throw Error("Failed to get registration form");
      }
      return res;
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setLoading(false);
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      if (onError) onError();
      setLoading(false);
      setError("Failed to download file");
    });
}
