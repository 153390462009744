import { useEffect } from "react";
import useQuery from "../../../hooks/useQuery";
import { useGetAllPopulations, useGetCropTypes } from "../../../api";
import Page from "../../../Containers/Page";
import PopulationsList from "../../../Sections/PopulationsList";
import PopulationListContext from "../../../Sections/PopulationsList/PopulationListContext";

function PopulationsTablePage() {
  const defaultQuery = {
    fields: "name,type,cropType,shortDesc,image,reference",
    "populate[cropType]": "croptypes.name",
  };
  const [query, isStale, setIsStale, setQuery] = useQuery(defaultQuery);
  const getPopulationsApi = useGetAllPopulations({
    params: query,
  });

  useEffect(() => {
    if (!isStale) {
      getPopulationsApi.refetch();
      setIsStale(true);
    }
  }, [getPopulationsApi, isStale, query, setIsStale]);

  const getCropTypesApi = useGetCropTypes({});
  const populations = getPopulationsApi.data?.populations;
  const cropTypes = getCropTypesApi?.data?.cropTypes;

  return (
    <PopulationListContext
      api={getPopulationsApi}
      query={query}
      setQuery={setQuery}
      cropTypes={cropTypes}
    >
      <Page title="Populations" className="px-4">
        <PopulationsList
          populations={populations}
          getLink={(ref) => `/admin/populations/${ref}`}
        />
      </Page>
    </PopulationListContext>
  );
}

export default PopulationsTablePage;
