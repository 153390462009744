import { useEffect } from "react";
import classNames from "classnames";

function Page({ title, children, allowHorizontalScroll, className, ...props }) {
  useEffect(() => {
    if (!allowHorizontalScroll) return;
    document.body.classList.add("w-full");
    document.body.classList.add("min-w-max");
    return () => {
      document.body.classList.remove("w-full");
      document.body.classList.remove("min-w-max");
    };
  });

  useEffect(() => {
    if (window.location.hash) {
      const el = document.getElementById(window.location.hash.replace("#", ""));
      if (el) el.scrollIntoView({ behavior: "smooth" });
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const wrapper = document.getElementById("wrapper");
      if (wrapper) {
        wrapper.style.minHeight = getMinPageHeight();
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  if (!title) return console.error("Error - Page title required");
  document.title = title;

  const getMinPageHeight = () => {
    const headerHeight =
      document.getElementById("header")?.getBoundingClientRect()?.height || 80;
    return `${window.innerHeight - headerHeight}px`;
  };

  return (
    <main
      id="wrapper"
      {...props}
      className={classNames(
        {
          "bg-hgBlue-50 h-full": !(className || "").includes("bg"),
          "pb-56": !(className || "").includes("pb"),
        },
        className
      )}
      style={{ minHeight: getMinPageHeight() }}
    >
      {children}
    </main>
  );
}

export default Page;
