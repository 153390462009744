import { useGetProduct, useUpdateProduct } from "../../../api";
import { useToastContext } from "../../../providers/toastContext";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../Containers/Page";
import FlexCentered from "../../../Containers/FlexCentered";
import Heading from "../../../Components/Heading";
import EditGeneralDataForm from "../../../Forms/productForms/EditGeneralDataForm";
import { useEffect } from "react";
import getApiStatusPage from "../../APIStatusPage";

function EditGeneralDataPage() {
  const { productRef } = useParams();
  const updateProductApi = useUpdateProduct();
  const { createSuccessToast } = useToastContext();

  const getProductApi = useGetProduct({
    productRef,
    refetchOnWindowFocus: false,
    params: {
      //prettier-ignore
      fields: 'isArchived,seedLot,population,parentBusiness,reference,growingData,moistureContent,protein,hagberg,gluten,specificWeight,germinationRate,thousandGrainWeightGrams,DON,ZON,septoria,bunt',
      "populate[population]": "name",
      "populate[seedLot]": "reference,date,quantityKg",
      "populate[growingData/field]": "name,location",
      includeMassBalanceFigures: true,
    },
  });

  const product = getProductApi?.data?.product;

  const navigateTo = useNavigate();
  useEffect(() => {
    if (updateProductApi.data) {
      createSuccessToast("Product updated");
      return navigateTo(
        `/dashboard/products/${updateProductApi.data?.product?.reference}`
      );
    }
  }, [
    createSuccessToast,
    updateProductApi.data,
    navigateTo,
    product?.reference,
  ]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getProductApi,
        type: "Product",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Product" className="px-[4vw]">
      <FlexCentered col className="pt-6">
        <Heading primary className={"text-hgGreen-1000 text-xl"}>
          Edit Growing Data
        </Heading>
        <Heading secondary className="mb-8">
          {product.reference}
        </Heading>
      </FlexCentered>
      <EditGeneralDataForm
        product={product}
        updateProductApi={updateProductApi}
      />
    </Page>
  );
}

export default EditGeneralDataPage;
