import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useForgotPassword } from "../api";

import Button from "../Components/Button";
import Input from "../Components/Input";
import FlexCentered from "../Containers/FlexCentered";
import Heading from "../Components/Heading";
import P from "../Components/P";
import RenderedErrors from "../Components/RenderedErrors";

function ForgotPasswordForm() {
  const getTokenApi = useForgotPassword();

  const form = useForm();
  const errors = form.formState.errors;
  let apiError = getTokenApi.error;

  const { register } = form;
  const emailProps = register("email", {
    required: "Enter your email address",
  });

  const handleSubmit = (data) => {
    getTokenApi.mutate({ body: data });
    form.reset();
  };

  return (
    <>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
        className="pt-[10vh]"
      >
        <FlexCentered col>
          <Heading primary className="text-hgCream-50 mb-6">
            Reset Your Password
          </Heading>
          <Input
            type="email"
            id="email"
            placeholder="Email"
            className="w-[20rem] max-w-full mb-4"
            {...emailProps}
            ref={null}
            innerRef={emailProps.ref}
          />

          <Button primary type="submit" loading={getTokenApi.isLoading}>
            Go
          </Button>

          {form.formState.isSubmitted && (
            <RenderedErrors
              errors={errors}
              apiError={apiError}
              className="bg-hgCream-50"
            />
          )}
          {getTokenApi.data && (
            <FlexCentered row className="gap-2 mt-4 bg-hgCream-50 p-2 rounded">
              <AiOutlineCheckCircle className="text-hgGreen-900" />
              <P>
                Request received, if an account with the email provided exists,
                a reset link will be sent there. The code will expire after 30
                minutes.
              </P>
            </FlexCentered>
          )}
        </FlexCentered>
      </form>
      <DevTool control={form.control} />
    </>
  );
}

export default ForgotPasswordForm;
