import Page from "../../Containers/Page";
import EditProfileForm from "../../Forms/EditProfileForm";
import UpdatePasswordForm from "../../Forms/UpdatePasswordForm";
import FlexCentered from "../../Containers/FlexCentered";
import DeleteMeForm from "../../Forms/DeleteMeForm";

function ProfilePage() {
  return (
    <Page title="My Profile">
      <FlexCentered col className="pt-12">
        <EditProfileForm />
        <UpdatePasswordForm />
        <DeleteMeForm />
      </FlexCentered>
    </Page>
  );
}

export default ProfilePage;
