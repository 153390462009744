import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "../../../../providers/toastContext";
import {
  useUpdateProduct,
  useGetProduct,
  useGetCurrentUser,
} from "../../../../api";
import { useParams } from "react-router-dom";
import Page from "../../../../Containers/Page";
import FlexCentered from "../../../../Containers/FlexCentered";
import Heading from "../../../../Components/Heading";
import P from "../../../../Components/P";
import OverviewData from "./OverviewData";
import ProductData from "./ProductData";
import GrowingData from "./GrowingData";
import ChildLotData from "./ChildLotData";
import Link from "../../../../Components/Link";
import getApiStatusPage from "../../../APIStatusPage";

function ProductPage() {
  const { productRef } = useParams();
  const updateProductApi = useUpdateProduct(false);
  const { createSuccessToast } = useToastContext();

  const getProductApi = useGetProduct({
    productRef,
    params: {
      //prettier-ignore
      fields: 'isArchived,seedLot,population,parentBusiness,reference,growingData,moistureContent,protein,hagberg,gluten,specificWeight,germinationRate,thousandGrainWeightGrams,DON,ZON,septoria,bunt',
      "populate[population]": "name,reference",
      "populate[seedLot]": "reference,date",
      "populate[growingData/field]": "name,location",
      includeMassBalanceFigures: true,
    },
    refetchOnWindowFocus: false,
  });

  const getCurrentUserApi = useGetCurrentUser();
  const isGrower = getCurrentUserApi?.data?.linkedBusinessType === "Grower";

  const navigateTo = useNavigate();
  useEffect(() => {
    if (updateProductApi?.data?.product) {
      const updatedRef = updateProductApi?.data?.product?.reference;
      if (updatedRef !== productRef) {
        createSuccessToast("Reference successfully updated");
        return navigateTo(`/dashboard/products/${updatedRef}`);
      }
    }
  });

  const product = getProductApi?.data?.product;
  const massBalanceFigures = getProductApi?.data?.massBalanceFigures;

  useEffect(() => {
    if (productRef && product && product.reference !== productRef)
      getProductApi.refetch();
  }, [getProductApi, product, productRef]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getProductApi,
        type: "Product",
        dataRequired: true,
      },
      {
        api: getCurrentUserApi,
        type: "CurrentUser",
        dataRequired: true,
        initialLoadOnly: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Product" className="px-[4vw] md:px-[4rem] pb-16">
      <FlexCentered col>
        <FlexCentered col className="max-w-2xl w-full">
          <FlexCentered col className="pt-6">
            <Heading primary>{product.reference}</Heading>
            <Heading secondary className="mb-4">
              Product Page
            </Heading>
            <div className="grid grid-cols-[auto_1fr] gap-y-1 gap-x-[4vw] bg-hgGreen-1000 text-hgCream-50  font-semibold border px-[4vw] md:px-[4rem] py-2 shadow">
              <P>Population:</P>
              <Link
                to={`/populations/${product?.population?.reference}`}
                className="underline hover:text-hgCream-500"
              >
                <P>{product.population?.name}</P>
              </Link>
              <P>Source Material:</P>
              <Link
                to={`/dashboard/seed-lots/${product.seedLot?.reference}`}
                className="underline hover:text-hgCream-500"
              >
                <P>{product.seedLot?.reference}</P>
              </Link>
            </div>
          </FlexCentered>
          <div className="w-full bg-hgCream-50 px-[4vw] md:px-[4rem] pt-8 pb-12 rounded-lg shadow-lg mt-8">
            <Heading
              secondary
              className="text-center text-lg text-hgGreen-1100 mb-6"
            >
              Product Information
            </Heading>
            <div className="">
              <OverviewData
                product={product}
                updateProductApi={updateProductApi}
              />
            </div>
            {isGrower && (
              <div className="mt-8">
                <GrowingData product={product} />
              </div>
            )}
            <div className="mt-8">
              <ProductData product={product} />
            </div>
          </div>
          <div className="w-full bg-hgCream-50 px-[4vw] md:px-[4rem] pt-8 pb-12 rounded-lg shadow-lg mt-8">
            <Heading
              secondary
              className="text-center text-lg text-hgGreen-1100 mb-6"
            >
              Child Seed Lots
            </Heading>
            <ChildLotData
              product={product}
              massBalanceFigures={massBalanceFigures}
            />
          </div>
        </FlexCentered>
      </FlexCentered>
    </Page>
  );
}

export default ProductPage;
