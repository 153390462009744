export default function getPDF({ url, fileName, setLoading, setError }) {
  setLoading(true);
  setError(undefined);

  fetch(`/api/${url}`, {
    method: "GET",
    headers: {
      Accept: "application/octet-stream",
      "Content-Type": "application/octet-stream",
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        setLoading(false);
        throw Error("Failed to get registration form");
      }
      return res.body;
    })
    .then((response) => {
      const reader = response.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        },
      });
    })
    // Create a new response out of the stream
    .then((stream) => new Response(stream))
    // Create an object URL for the response
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob))
    .then((url) => {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.pdf`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setLoading(false);
    })
    // Update image
    .catch((err) => {
      setLoading(false);
      setError("Failed to download file");
    });
}
