import classNames from "classnames";

function FlexCentered({ row, col, innerRef, className, children, ...props }) {
  return (
    <div
      ref={innerRef}
      {...props}
      className={classNames(
        "flex",
        {
          "flex-col": col,
          "flex-row": row,
        },
        "items-center justify-center",
        className
      )}
    >
      {children}
    </div>
  );
}

export default FlexCentered;
