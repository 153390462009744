import { BsFillPersonFill } from "react-icons/bs";

function getProfileLink(currentUser, handleLogOut) {
  if (!currentUser || !currentUser?.isLoggedIn) return [];

  const profileLink = [
    {
      to: "/profile",
      minLabel: "Profile",
      label: <BsFillPersonFill className="text-2xl" />,
      children: [
        {
          to: "/log-out",
          onClick: handleLogOut,
          label: "Log Out",
        },
      ],
    },
  ];
  return profileLink;
}

export default getProfileLink;
