import { createContext, useContext } from "react";

const ProductListContext = createContext();

const Provider = function ({
  api,
  query,
  setQuery,
  children,
  populations,
  populationsObj,
}) {
  const model = {
    query,
    setQuery,
    api,
    populations,
    populationsObj,
  };

  return (
    <ProductListContext.Provider value={model}>
      {children}
    </ProductListContext.Provider>
  );
};

const useProductListContext = function () {
  return useContext(ProductListContext);
};

export { useProductListContext };
export default Provider;
