function getValueOptions(filterType, options) {
  if (filterType === "String" || filterType === "StringOptional")
    return {
      type: "String",
    };

  if (filterType === "Number" || filterType === "NumberOptional") {
    return {
      type: "Number",
    };
  }

  if (filterType === "Date") {
    return {
      type: "Date",
    };
  }

  if (filterType === "Bool")
    return {
      type: "Enum",
      options: [
        {
          label: "True",
          id: "true",
          value: true,
        },
        {
          label: "False",
          id: "false",
          value: "false",
        },
      ],
    };

  if (filterType === "Enum")
    return {
      type: "Enum",
      options,
    };
}
export default getValueOptions;
