function getMinColWidths(wd) {
  const minWidths = {
    "4xs": 25,
    "3xs": 50,
    "2xs": 75,
    xs: 100,
    sm: 125,
    md: 150,
    lg: 175,
    xl: 200,
    "2xl": 225,
    "3xl": 250,
    "4xl": 255,
  };

  return minWidths[wd];
}

export default getMinColWidths;
