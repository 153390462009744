import {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  useCallback,
} from "react";
import useTableColumns from "./hooks/useTableColumns";
import cloneDeep from "lodash.clonedeep";

const TableContext = createContext();

const Provider = function ({
  config,
  api,
  query,
  setQuery,
  Filter,
  url,
  children,
}) {
  const [tableHeight, setTableHeight] = useState(null);
  const [configState, setConfigState] = useState(cloneDeep(config));
  const tableRef = useRef();

  useEffect(() => {
    setTableHeight(tableRef.current?.offsetHeight);
  }, [config.data, tableRef]);

  const {
    resizeColumns,
    gridTemplateColumns,
    activeTab,
    visibleColumns,
    moveColumnTo,
    moveColumIcon,
  } = useTableColumns(
    tableRef.current,
    config.resizeableColumns || {},
    configState
  );

  const toggleColumnVisibility = useCallback(
    (label) => {
      const updatedConfig = cloneDeep(configState);
      let col;
      let visibleCount = 0;
      for (let i = 0; i < updatedConfig.columns?.length; i++) {
        const currCol = updatedConfig.columns[i];
        if (currCol.label === label) col = currCol;
        if (currCol.visible !== false) visibleCount++;
      }

      if (!col) return;
      if (col.visible === false) col.visible = true;
      else {
        if (visibleCount < 2) return;
        col.visible = false;
      }
      setConfigState(updatedConfig);
      // handleToggleVisibility(updatedConfig);
    },
    [configState]
  );

  const model = {
    resizeColumns,
    gridTemplateColumns,
    activeTab,
    visibleColumns,
    url,
    moveColumnTo,
    moveColumIcon,
    tableRef,
    api,
    tableHeight,
    query,
    setQuery,
    Filter,
    config: configState,
    toggleColumnVisibility,
  };

  return (
    <TableContext.Provider value={model}>{children}</TableContext.Provider>
  );
};

const useTableContext = function () {
  return useContext(TableContext);
};

export { useTableContext };
export default Provider;
