function getBody(state, isUserRegistration) {
  if (!state) return;
  if (isUserRegistration)
    return {
      givenName: state.givenName,
      familyName: state.familyName,
      email: state.email,
      password: state.password,
      confirmPassword: state.confirmPassword,
      submittedBusinessDetails: {
        tradingName: state.tradingName,
        businessType: state.businessType?.label,
        CPH_Number: state.CPH_Number,
        formattedAddress: state.address?.formattedAddress,
        postcode: state.address?.postcode,
        location: state.address?.location,
      },
    };
  else
    return {
      tradingName: state.tradingName,
      businessType: state.businessType?.label,
      CPH_Number: state.CPH_Number,
      formattedAddress: state.address?.formattedAddress,
      postcode: state.address?.postcode,
      location: state.address?.location,
    };
}
export default getBody;
