import Input from "../../../Input";
import Dropdown from "../../../Dropdown";
import DateInput from "../../../DateInput";
import P from "../../../P";

function Value({
  options,
  operator,
  className,
  value,
  onChange,
  value2,
  onChange2,
}) {
  if (!options)
    return (
      <div className={className}>
        <Input disabled value={value} />
      </div>
    );

  if (options.type === "String")
    return (
      <div className={className}>
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="text-left"
        />
      </div>
    );

  if (options.type === "Number")
    return (
      <div className={className}>
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="text-left"
          type="number"
        />
      </div>
    );

  if (options.type === "Date") {
    if (operator?.label === "Equals") {
      return (
        <div className={className}>
          <DateInput value={value} onChange={onChange} small />
        </div>
      );
    }
    if (operator?.label === "Between") {
      return (
        <>
          <div className={className}>
            <DateInput value={value} onChange={onChange} small />
          </div>
          <P className="text-center">To</P>
          <div className={className}>
            <DateInput value={value2} onChange={onChange2} small />
          </div>
        </>
      );
    }
  }

  if (options.type === "Enum")
    return (
      <div className={className}>
        <Dropdown
          modalId="values-dropdown"
          value={value}
          onChange={onChange}
          config={{ data: options.options }}
          className="text-left"
        />
      </div>
    );
}

export default Value;
