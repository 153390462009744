import { v4 as uuid } from "uuid";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import getRelativePosition from "./helpers/getRelativePosition";
import mouseIntersectsEl from "./helpers/mouseIntersectsEl";
import classNames from "classnames";

function ModalWindow({ options, children }) {
  const childId = uuid();

  //Where blur is set as an option, set document height to viewport and stop scroll
  useEffect(() => {
    if (!options?.blur) return;
    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    };
  });

  //Where a mouse out handler is passed - Determine when the mouse has left the element and
  //the relative to ref where passed
  useEffect(() => {
    if (!options?.onMouseOut) return;
    const handleMouseMove = (e) => {
      const childWrapper = document.getElementById(childId);
      if (!childWrapper) return;

      const isOverChild = mouseIntersectsEl(e, childWrapper);

      const relativeToRef = options?.relativeTo?.ref;
      let isOverRelativeEl = true;
      if (relativeToRef) {
        isOverRelativeEl = mouseIntersectsEl(e, relativeToRef);
      }
      if (isOverChild || isOverRelativeEl) return;

      if (options.modalIncludes && e?.target) {
        for (let i = 0; i < options.modalIncludes.length; i++) {
          const el = document.getElementById(options.modalIncludes[i]);
          if (el?.contains(e.target)) return;
        }
      }

      options.onMouseOut();
    };
    document.addEventListener("mousemove", handleMouseMove);
    return () => document.removeEventListener("mousemove", handleMouseMove);
  });

  //Where on resize handle is called, call on resize
  useEffect(() => {
    if (!options.onResize) return;
    const handleResize = () => {
      options.onResize();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  //Where on click out handle is called, call on click outside
  //of the modal / relative to ref
  useEffect(() => {
    if (!options.onClickOut) return;
    const handleClick = (e) => {
      const childWrapper = document.getElementById(childId);
      if (!childWrapper) return;

      const isOverChild =
        mouseIntersectsEl(e, childWrapper) || childWrapper.contains(e.target);
      const relativeToRef = options?.relativeTo?.ref;
      let isOverRelativeEl = true;
      if (relativeToRef) {
        isOverRelativeEl = mouseIntersectsEl(e, relativeToRef);
        if (relativeToRef.contains(e.target)) isOverRelativeEl = true;
      }
      if (isOverChild || isOverRelativeEl) return;

      if (options.modalIncludes && e?.target) {
        for (let i = 0; i < options.modalIncludes.length; i++) {
          const el = document.getElementById(options.modalIncludes[i]);
          if (el?.contains(e.target)) return;
        }
      }

      options.onClickOut();
    };
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  });

  useEffect(() => {
    const childWrapper = document.getElementById(childId);
    if (!childWrapper) return;
    const { top, height } = childWrapper.getBoundingClientRect();
    const bottom = top + height + window.scrollY;
    const padding = 20;
    const overlap = bottom + padding - window.innerHeight + window.scrollY;
    if (overlap > 0) {
      childWrapper.style.height = `${height - overlap}px`;
    }
  });

  let positions;
  //Where relativeTo is provided - Set position relative to the passed ref
  if (options?.relativeTo) {
    const relativeTo = options?.relativeTo?.ref;
    if (!relativeTo) return;
    positions = getRelativePosition(relativeTo, options?.pos);
  }

  //Set Modal Height
  const root = document.getElementById("modal");

  return createPortal(
    <div
      className={classNames(
        "absolute inset-0 w-[100vw] h-[100vh] max-w-full ",
        {
          "pointer-events-none": !options.blur,
        }
      )}
      style={{
        top: `${window.scrollY}px`,
        left: `${window.scrollX}px`,
      }}
    >
      <div className={classNames("relative w-full h-full z-20")}>
        {/*Where the modal is not positioned relative to an element the child element is centered within a window sized div*/}
        {!options?.relativeTo && (
          <>
            {/*If blur is selected then an additional div with a light bg and opacity is added*/}
            {options?.blur && (
              <div
                className={classNames("absolute inset-0 ", {
                  "bg-hgBlue-100 opacity-90": !options?.darkMode,
                  "bg-hgBlue-900 opacity-70": options?.darkMode,
                })}
              />
            )}
            <div
              className={classNames(
                "absolute inset-0  flex flex-col items-center"
              )}
              style={{
                paddingTop: options?.pos?.top ? `${options?.pos?.top}vh` : "",
                paddingBottom: "5vh",
              }}
            >
              {children}
            </div>
          </>
        )}

        {/*Where the modal is positioned relative to an element, it is just placed in the relevant position*/}
        {options?.relativeTo && (
          <div
            className="absolute w-fit overflow-x-hidden overflow-y-auto  pointer-events-auto"
            id={childId}
            style={{ ...positions }}
          >
            {children}
          </div>
        )}
      </div>
    </div>,

    root
  );
}

export default ModalWindow;
