import { useParams } from "react-router-dom";
import { useGetOneUser, useGetCurrentUser } from "../../../../api";
import FlexCentered from "../../../../Containers/FlexCentered";
import Page from "../../../../Containers/Page";
import Heading from "../../../../Components/Heading";
import P from "../../../../Components/P";
import Menu from "./Menu";
import Yes from "../Components/Yes";
import No from "../Components/No";
import getApiStatusPage from "../../../APIStatusPage";

function UserPage() {
  const { userRef } = useParams();
  const getUserApi = useGetOneUser({
    ref: userRef,
    params: {
      fields:
        "fullName,email,linkedBusiness,createdOn,isActive,restrictedAccess,admin,emailConfirmed,permissions,slug",
      "populate[linkedBusiness]": "tradingName, business_Reference",
    },
  });
  const currentUserApi = useGetCurrentUser();
  const currentUserPermissions = currentUserApi?.data?.permissions;
  const user = getUserApi.data?.user;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getUserApi,
        type: "User",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title={user.fullName}>
      <FlexCentered col>
        <div className="max-w-lg">
          <FlexCentered row>
            <Heading primary className="text-3xl text-hgGreen-900">
              {user.fullName}
            </Heading>
            <Menu
              user={user}
              permissions={currentUserPermissions}
              currentUser={currentUserApi?.data?.user}
            />
          </FlexCentered>
          <Heading
            tertiary
            className="text-center font-normal text-md text-hgBlue-700 mb-12"
          >
            {user.email}
          </Heading>
          <div className="w-full grid grid-cols-[auto_1fr] gap-x-[4vw] gap-y-2 bg-hgCream-50 rounded-lg shadow-lg py-[2vw] px-[4vw] mb-8">
            <Heading
              tertiary
              className="col-span-2 text-hgGreen-900 text-lg font-normal mb-2"
            >
              Status
            </Heading>
            <P>Created On: </P>
            <P>{new Date(user.createdOn).toLocaleDateString("en-gb")}</P>

            <P>Email Confirmed: </P>
            <P>{user.emailConfirmed ? <Yes /> : <No />}</P>

            <P>Active: </P>
            <P>{user.isActive ? <Yes /> : <No />}</P>
          </div>
          <div className="w-full grid grid-cols-[auto_1fr] gap-x-[4vw] gap-y-2 bg-hgCream-50 rounded-lg shadow-lg py-[2vw] px-[4vw] mb-8">
            <Heading
              tertiary
              className="col-span-2 text-hgGreen-900 text-lg font-normal mb-2"
            >
              Permissions
            </Heading>
            <Heading
              tertiary
              className="col-span-2 text-hgGreen-900 text-md font-normal mb-2"
            >
              General
            </Heading>

            <P>Linked Business: </P>
            <P>
              {user.linkedBusiness ? user.linkedBusiness?.tradingName : <No />}
            </P>

            <P>Unrestricted Access: </P>
            <P>{!user.restrictedAccess ? <Yes /> : <No />}</P>

            <P>Admin: </P>
            <P>{user.admin ? <Yes /> : <No />}</P>

            {user.admin && (
              <>
                <Heading
                  tertiary
                  className="col-span-2 text-hgGreen-900 text-md font-normal mt-4 mb-2"
                >
                  Write Permissions
                </Heading>
                <P>Access: </P>
                <P>{user.permissions?.writeAccess ? <Yes /> : <No />}</P>

                <P>Linked Business: </P>
                <P>
                  {user.permissions?.writeLinkedBusiness ? <Yes /> : <No />}
                </P>

                <P>Business: </P>
                <P>{user.permissions?.writeBusiness ? <Yes /> : <No />}</P>

                <P>Populations: </P>
                <P>{user.permissions?.writePopulations ? <Yes /> : <No />}</P>

                <P>Seed Lots: </P>
                <P>{user.permissions?.writeSeedLots ? <Yes /> : <No />}</P>

                <P>Products: </P>
                <P>{user.permissions?.writeProducts ? <Yes /> : <No />}</P>
              </>
            )}
          </div>
        </div>
      </FlexCentered>
    </Page>
  );
}

export default UserPage;
