/**
 * Creates an array of seed lot objects
 * in a given lot's lineage
 *
 * @param {Object} Lot A seed lot object
 * @returns The lot's lineage as an array with index 0 being the current lot
 */
function getSeedLotLineage(lot) {
  const lineage = [lot];
  let stopLoop = false;
  let currentLot = lot;
  while (!stopLoop) {
    const currentParent = currentLot.parentObj;
    if (currentParent) {
      lineage.push(currentParent);
      currentLot = currentParent;
    }
    if (!currentParent) {
      stopLoop = true;
    }
  }
  return lineage;
}

function updateRowData({ rows, fromRow, toRow }) {
  for (let i = fromRow; i <= toRow; i++) {
    const currentRow = rows[i];
    currentRow.maxCol = currentRow.maxColLot.getColumn();
  }
}

/**
 * ADJUST COLUMNS TO REMOVE OVERLAP
 * Gets a lineage for the current and clashing
 * lots. Finds the top level ancestor of the
 * current lot not shared with the clashing lot
 * applies the adjustment to this lot
 *
 * @param {Number} adjustment Amount needed to remove clash
 * @param {Object} currentLot The current lot
 * @param {Object} clashingLot The lot the current lot clashes with
 */
function adjustColumnToRemoveOverlap({
  adjustment,
  currentLot,
  clashingLot,
  rows,
}) {
  const currentLotLineage = getSeedLotLineage(currentLot);
  const clashingLotLineage = getSeedLotLineage(clashingLot);

  let lotToAdjust;
  for (let i = currentLotLineage.length - 1; i >= 0; i--) {
    if (!clashingLotLineage.includes(currentLotLineage[i])) {
      lotToAdjust = currentLotLineage[i];
      break;
    }
  }
  lotToAdjust.adjustment = lotToAdjust.adjustment + adjustment;
  updateRowData({
    rows,
    fromRow: lotToAdjust.row,
    toRow: currentLot.row,
  });
  /*
  if (lotToAdjust.getColumn() > rows[lotToAdjust.row].maxCol) {
    rows[lotToAdjust.row].maxCol = lotToAdjust.getColumn();
    rows[lotToAdjust.row].maxColLot = lotToAdjust;
  } */
}

/**
 * CHECK FOR OVERLAP
 * This functions stores the max column number in each row
 * if a lot's column number is less or equal to the max
 * column in its row then an overlap is found and another function
 * is called to remove this overlap
 *
 * @param {Object} row An object storing data about each row
 * @param {Object} Seed Lot The seedLot at the top of the tree
 * @returns
 */

function checkForOverlap(rows, lot) {
  const columnNumber = lot.getColumn();

  if (!rows[lot.row])
    return (rows[lot.row] = {
      maxCol: columnNumber,
      minCol: columnNumber,
      maxColLot: lot,
    });

  if (rows[lot.row].maxCol >= columnNumber) {
    adjustColumnToRemoveOverlap({
      adjustment: rows[lot.row].maxCol + 1 - columnNumber,
      currentLot: lot,
      clashingLot: rows[lot.row].maxColLot,
      rows,
    });
  }

  //IF LOT ROW IS THE HIGHEST IN THAT ROW, RECORD IT AS THE MAX COL
  if (lot.getColumn() > rows[lot.row].maxCol) {
    rows[lot.row].maxCol = lot.getColumn();
    rows[lot.row].maxColLot = lot;
  }
}

/**
 * CALCULATE GRID POSITIONS
 * The heterogen API returns a list of lots with a generation no. (used as row)
 * and an offset which, when added to the parent column, should place the children
 * beneath the parent. This function goes through each lot and calls functions
 * to check whether there is an overlap in a row. Where an overlap is found an adjustment
 * is applied to a lot to remove this overlap. A method is also added to each lot to
 * calculate its position as parent column + offset + adjustment.
 *
 * @param {*} seedLotsObject An object with seedLots as fields (id as key)
 * @param {*} rootLot The seedLot at the top of the tree
 * @returns
 */

function calculateGridPositions(seedLotsObject, rootLot) {
  //Object to store data about rows/ specifically
  // The max column used
  const rows = { 1: { maxCol: 0 } };
  let minCol = 0;
  let maxCol = 0;

  function calculateColumnsandRows(lot) {
    //1. SET LOT ROW EQUAL TO GENERATION NUMBER
    lot.row = lot.generation;

    //1. IF LOT HAS A PARENT
    if (lot.parent) {
      //1A. GET PARENT OBJECT AS COLUMN IS RELATIVE TO PARENT
      lot.parentObj = seedLotsObject[lot.parent];
      //1B. INITIALISE AN ADJUSTMENT FIELD
      if (!lot.adjustment) lot.adjustment = 0;

      //1C. ATTACH METHOD TO CALCULATE COLUMN
      lot.getColumn = function () {
        return this.parentObj.getColumn() + this.offset + this.adjustment;
      };

      //2. Check for overlap
      checkForOverlap(rows, lot);
    }
    // 2. If LOT IS ROOT THEN RETURN 0 AS THE COLUMN
    else {
      lot.getColumn = () => 0;
    }

    if (lot.getColumn() < minCol) minCol = lot.getColumn();
    if (lot.getColumn() > maxCol) maxCol = lot.getColumn();

    //3. RECURSIVELY CALL THIS FUNCTION ON EACH OF THE LOT'S CHILDREN
    lot.children.forEach((childLot) => {
      calculateColumnsandRows(seedLotsObject[childLot]);
    });
  }
  calculateColumnsandRows(rootLot);
  return {
    rowCount: Object.keys(rows).length,
    columnCount: maxCol - minCol + 1,
    gridColOffset: 1 - minCol,
  };
}

export default calculateGridPositions;
