import { useRef } from "react";
import HeaderLink from "./HeaderLink";
import Hamburger from "./Hamburger";

function RenderedLinks({ links, adminRoute }) {
  const linksRef = useRef([]);
  return links.map((link, i) => {
    return (
      <div
        key={`${link.to}-main`}
        className="relative hidden md:block"
        ref={(el) => (linksRef.current[i] = el)}
      >
        <HeaderLink link={link} adminRoute={adminRoute} />
      </div>
    );
  });
}

export default RenderedLinks;
