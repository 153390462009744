import { useFormContext } from "../helpers/formContext";
import { useNavigate } from "react-router-dom";

import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";
import BusinessDetailsForm from "../BusinessDetailsForm";
import Link from "../../Components/Link";
import P from "../../Components/P";
import classNames from "classnames";

function BusinessDetailsSection({ type, isUserRegistration }) {
  const { formState, updateFormState } = useFormContext();
  const navigateTo = useNavigate();

  const handleSubmit = (data) => {
    updateFormState(data);
    if (!isUserRegistration) {
      return navigateTo(`/admin/businesses/add-business/confirmation`);
    }
    return navigateTo(`/registration-form/confirmation?type=${type}`);
  };

  const onPrevious = (e, data) => {
    e.preventDefault();
    updateFormState(data);
    return navigateTo(`/registration-form?type=${type}`);
  };

  return (
    <FlexCentered col>
      {isUserRegistration && (
        <div className="flex flex-row items-center w-full max-w-md pt-[5vh] text-hgCream-50 gap-2">
          <Link
            to={`/registration-form?type=${type}`}
            className="underline hover:text-hgGreen-100"
          >
            Your Details
          </Link>
          <P>{`>`}</P>
          <P>Business Details</P>
        </div>
      )}

      <Heading
        secondary
        className={classNames("pt-[6vh] text-2xl mb-8", {
          "text-hgCream-50": isUserRegistration,
        })}
      >
        Business Details
      </Heading>
      <BusinessDetailsForm
        state={formState}
        onSubmit={handleSubmit}
        type={type}
        darkMode={isUserRegistration}
        onPrevious={!isUserRegistration ? undefined : onPrevious}
      />
    </FlexCentered>
  );
}

export default BusinessDetailsSection;
