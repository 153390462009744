import Page from "../../../Containers/Page";
import AddBusinessForm from "../../../Forms/AddBusinessForm";

function AddBusinessPage() {
  return (
    <Page title="Add Business">
      <AddBusinessForm />
    </Page>
  );
}

export default AddBusinessPage;
