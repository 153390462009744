import { useEffect } from "react";
import { useGetAllUsers } from "../../../api";
import useQuery from "../../../hooks/useQuery";
import Page from "../../../Containers/Page";
import Table from "../../../Components/Table/Table";
import TableContext from "../../../Components/Table/TableContext";
import getApiStatusPage from "../../APIStatusPage";

function UsersTablePage() {
  const fields =
    "givenName,familyName,email,admin,slug,linkedBusiness,restrictedAccess";
  const defaultQuery = {
    fields,
    "populate[linkedBusiness]": "businesses.tradingName",
  };
  const [query, isStale, setIsStale, setQuery, Filter] = useQuery(defaultQuery);

  const getAllUsersApi = useGetAllUsers({
    params: query,
  });

  useEffect(() => {
    if (!isStale) {
      getAllUsersApi.refetch();
      setIsStale(true);
    }
  }, [getAllUsersApi, isStale, query, setIsStale]);

  const users = getAllUsersApi?.data?.users;

  const tableConfig = {
    resizeableColumns: { fixed: true },
    movableColumns: true,
    rowLink: (row) => `/admin/users/${row.slug}`,
    showControls: true,
    showPagination: true,
    columns: [
      {
        label: "Name",
        fieldNames: ["familyName", "givenName"],
        fieldNamesLabels: ["Family Name", "Given Name"],
        filterType: "String",
        searchFieldNames: ["fullName"],
        row: (row) => `${row.familyName}, ${row.givenName} `,
        width: "sm",
        searchable: true,
      },
      {
        label: "Business",
        fieldNames: ["linkedBusiness/tradingName"],
        fieldNamesLabels: ["Business"],
        filterType: "StringOptional",
        searchFieldNames: ["linkedBusiness/tradingName"],
        row: (row) => row.linkedBusiness?.tradingName || "-",
        width: "sm",
        searchable: true,
      },
      {
        label: "Email",
        fieldNames: ["email"],
        fieldNamesLabels: ["Email"],
        filterType: "String",
        row: (row) => row.email,
        width: "sm",
        searchable: true,
        visible: false,
      },
      {
        label: "Admin",
        fieldNames: ["admin"],
        fieldNamesLabels: ["Admin"],
        filterType: "Bool",
        row: (row) => (row.admin ? "Yes" : "-"),
        width: "sm",
        centered: true,
      },
      {
        label: "Restricted Access",
        fieldNames: ["restrictedAccess"],
        fieldNamesLabels: ["Restricted Access"],
        filterType: "Bool",
        row: (row) => (row.restrictedAccess ? "Yes" : "-"),
        width: "md",
        centered: true,
        visible: false,
      },
    ],
  };
  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getAllUsersApi,
        type: "Users",
        dataRequired: true,
        initialLoadOnly: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Users" allowHorizontalScroll className="min-h-screen">
      <TableContext
        config={tableConfig}
        api={getAllUsersApi}
        url="users"
        query={query}
        setQuery={setQuery}
        Filter={Filter}
      >
        <Table data={users} />
      </TableContext>
    </Page>
  );
}

export default UsersTablePage;
