import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateProduct } from "../../api";
import Image from "../../Components/Image";
import P from "../../Components/P";
import Button from "../../Components/Button";
import formattedNumber from "../../utils/formattedNumber";

function SeedLotItem({ seedLot, population, handleArchive, loading }) {
  const createProductApi = useCreateProduct();

  const handleCreateProduct = () => {
    createProductApi.mutate({
      body: { seedLot: seedLot._id },
    });
  };

  const navigateTo = useNavigate();
  useEffect(() => {
    if (createProductApi?.data?.product) {
      const product = createProductApi.data.product;
      return navigateTo(`/dashboard/products/${product.reference}`);
    }
  });

  if (!population || !seedLot) return;
  return (
    <div className="bg-hgCream-50 rounded-lg shadow-lg overflow-hidden select-none">
      <div className="p-1 bg-hgGreen-300 text-hgBlue-900 shadow w-full text-center text-xl">
        {seedLot.reference}
      </div>
      <div className="grid grid-cols-[5rem_1fr_1fr] md:grid-cols-[5rem_1fr_1fr_auto] gap-x-4 px-4 py-2  text-sm sm:text-base ">
        <Image
          src={population.image}
          alt={population.name}
          className="w-[5rem] h-[5rem] rounded-lg overflow-hidden self-center"
        />
        <div className="flex flex-col justify-center bg-hgBlue-200 bg-opacity-10 rounded shadow text-sm lg:text-base">
          <div className="grid grid-cols-[auto_auto] gap-x-4 h-[5rem] w-fit  p-1 mt-1  text-hgBlue-900">
            <P className="">Population:</P>
            <P className="text-hgGreen-1200">{population.name}</P>
            <P className="">Crop Type:</P>
            <P className="text-hgGreen-1200">{population.cropType?.name}</P>
          </div>
        </div>
        <div className="pt-4 text-sm lg:text-base">
          <div className="grid grid-cols-[auto_1fr] gap-x-2 h-fit gap-y-2">
            <P className="">From:</P>
            <P className>{seedLot.issuedBy?.tradingName}</P>
            <P className="">Date:</P>
            <P className>
              {new Date(seedLot.date).toLocaleDateString("en-gb")}
            </P>
            <P className="">Weight:</P>
            <P className>{formattedNumber(seedLot.quantityKg)} kg</P>
          </div>
        </div>
        <div className="flex flex-row justify-center gap-x-2 md:flex-col mt-2 gap-y-2 py-1 col-span-3 md:col-span-1">
          <Button
            primary
            outline
            className="md:w-full"
            onClick={handleCreateProduct}
          >
            New product
          </Button>

          <Button
            secondary
            outline
            className="md:w-full"
            onClick={() => handleArchive(seedLot._id)}
            loading={loading}
          >
            Archive
          </Button>
        </div>
      </div>
    </div>
  );
}
export default SeedLotItem;
