import { useState, useRef } from "react";
import Link from "../../Components/Link";
import ModalWindow from "../../Components/ModalWindow";
import classNames from "classnames";

function HeaderLink({ link, adminRoute }) {
  const [secondaryNavIsOpen, setSecondaryNavIsOpen] = useState(false);
  const linkRef = useRef(null);
  return (
    <div
      onMouseOver={() => setSecondaryNavIsOpen(true)}
      key={`${link.to}-main`}
    >
      <Link
        to={link.to}
        onClick={link.onClick}
        innerRef={linkRef}
        className={classNames({
          "px-4 py-2 border-hgGreen-800 hover:border-hgGreen-600 bg-hgGreen-800 hover:bg-hgGreen-600 text-hgCream-50 hover:text-hgCream-50":
            link.cta,
          "hover:text-hgCream-500": !adminRoute,
        })}
      >
        {link.label}
      </Link>
      {link.children && secondaryNavIsOpen && (
        <ModalWindow
          options={{
            relativeTo: {
              ref: linkRef.current,
            },
            pos: {
              bottom: 0,
              right: 0,
            },
            onMouseOut: () => setSecondaryNavIsOpen(false),
            onResize: () => setSecondaryNavIsOpen(false),
          }}
        >
          <div className="flex flex-col items-end bg-hgBlue-500 w-max px-6 py-4">
            {link.children.map((childLink) => {
              return (
                <Link
                  to={childLink.to}
                  onClick={childLink.onClick}
                  className={classNames(
                    "text-hgCream-50 hover:text-hgCream-500"
                  )}
                  key={`${childLink.to}-secondary`}
                >
                  {childLink.label}
                </Link>
              );
            })}
          </div>
        </ModalWindow>
      )}
    </div>
  );
}

export default HeaderLink;
