import { useEffect, useMemo, useState, useRef } from "react";
import classNames from "classnames";
import Page from "../../../../Containers/Page";
import {
  useGetAllSeedLots,
  useGetAllBusinesses,
  useGetAllPopulations,
} from "../../../../api";
import useQuery from "../../../../hooks/useQuery";
import Controls from "./Controls";
import FlexCentered from "../../../../Containers/FlexCentered";
import Loading from "../../../../Components/Loading";
import SeedLotItem from "./SeedLotItem";
import Heading from "../../../../Components/Heading";
import Link from "../../../../Components/Link";
import Pagination from "../../../../Sections/PopulationsList/Pagination";

function SeedLotsPage() {
  const [queryEnabled, setQueryEnabled] = useState(false);
  const seedLotWrapper = useRef(null);

  const defaultQuery = {
    fields:
      "reference,population,date,quantityKg,isArchived,issuedBy,receivedBy,childProduct",
    sort: "-date",
    "populate[issuedBy]": "businesses.tradingName,business_Reference",
    "populate[receivedBy]": "businesses.tradingName,business_Reference",
  };

  const [query, isStale, setIsStale, setQuery] = useQuery(defaultQuery);

  const getAllSeedLotsApi = useGetAllSeedLots({
    params: query,
    enabled: queryEnabled,
  });

  const getAllBusinessesApi = useGetAllBusinesses({
    params: {
      fields: "tradingName,business_Reference",
      completeList: true,
    },
  });

  const getAllPopulationsApi = useGetAllPopulations({
    params: {
      fields: "name,image,cropType",
      "populate[cropType]": "croptypes.name",
      completeList: true,
    },
  });

  useEffect(() => {
    if (!isStale) {
      getAllSeedLotsApi.refetch();
      setQueryEnabled(true);
      setIsStale(true);
    }
  }, [getAllSeedLotsApi, isStale, query, setIsStale]);

  useEffect(() => {
    if (!seedLotWrapper?.current) return;
    seedLotWrapper.current.classList.remove("opacity-0");
  });

  //SET INITIAL FILTER
  useEffect(() => {
    if (query.receivedBy || query.issuedBy) return;
    setQuery.filter(
      {
        fieldName: "receivedBy",
        label: "receivedBy",
        type: "String",
      },
      "Equals",
      "$$LINKED_BUSINESS$$"
    );
  }, [query.issuedBy, query.receivedBy, setQuery]);

  const seedLots = getAllSeedLotsApi?.data?.seedLots;
  const businesses = getAllBusinessesApi?.data?.businesses;
  const populations = getAllPopulationsApi?.data?.populations;
  const populationsObj = useMemo(() => {
    if (!populations) return {};
    let rtnObj = {};
    populations.forEach((population) => {
      rtnObj[population._id.toString()] = population;
    });
    return rtnObj;
  }, [populations]);

  const getTabClasses = (active) => {
    return classNames("text-center text-lg py-1 cursor-pointer", {
      "font-light": !active,
      "bg-hgGreen-900 font-semi-bold text-hgCream-50": active,
    });
  };

  const handleChangeTab = (set, unset) => {
    setQuery.filter(
      {
        fieldName: set,
        label: set,
        type: "String",
      },
      "Equals",
      "$$LINKED_BUSINESS$$"
    );
    if (query[unset]) {
      setQuery.unsetFilter({
        fieldName: `${set}/business_Reference`,
        operator: "eq",
      });
      setQuery.unsetFilter({
        fieldName: unset,
        operator: "eq",
      });
    }
    setQuery.unsetFilter({
      fieldName: `isArchived`,
      operator: "eq",
    });
    setQuery.unsetFilter({
      fieldName: `childProduct`,
      operator: "ex",
    });
    setQuery.unsetFilter({
      fieldName: `childProduct`,
      operator: "nex",
    });
  };

  const renderedSeedLots = seedLots?.map((seedLot) => {
    return (
      <Link to={seedLot.reference} key={seedLot._id.toString()}>
        <SeedLotItem
          seedLot={seedLot}
          population={populationsObj[seedLot.population]}
          isReceived={query?.receivedBy === "$$LINKED_BUSINESS$$"}
        />
      </Link>
    );
  });

  return (
    <Page title="Seed Lots" className="px-4 pt-4 pb-56 text-center">
      <FlexCentered col>
        <div className="w-full max-w-4xl">
          <Heading primary className="text-xl text-hgBlue-700 mb-4">
            Seed Lots
          </Heading>
          <div className="bg-hgCream-50 rounded-t overflow-hidden border border-hgBlue-200 border-b-0">
            <div className="grid grid-cols-2  rounded">
              <div
                className={getTabClasses(
                  query?.receivedBy === "$$LINKED_BUSINESS$$"
                )}
                onClick={() => handleChangeTab("receivedBy", "issuedBy")}
              >
                Received
              </div>
              <div
                className={getTabClasses(
                  query?.issuedBy === "$$LINKED_BUSINESS$$"
                )}
                onClick={() => handleChangeTab("issuedBy", "receivedBy")}
              >
                Issued
              </div>
            </div>
          </div>
          <div className="px-2 py-2 border border-hgBlue-200 bg-hgBlue-100 rounded-b shadow ">
            <Controls
              query={query}
              setQuery={setQuery}
              populations={populations}
              businesses={businesses}
              isReceived={query?.receivedBy === "$$LINKED_BUSINESS$$"}
            />
          </div>
          {(!renderedSeedLots || getAllSeedLotsApi.isFetching) && (
            <div className="px-2 py-2 h-100vh">
              <Loading />
            </div>
          )}
          {renderedSeedLots && !getAllSeedLotsApi.isFetching && (
            <>
              {renderedSeedLots.length > 0 && (
                <div
                  className="flex flex-col gap-y-4 px-2 py-8 transition-opacity duration-700 opacity-0"
                  ref={seedLotWrapper}
                >
                  {renderedSeedLots}
                  <Pagination api={getAllSeedLotsApi} setQuery={setQuery} />
                </div>
              )}

              {renderedSeedLots.length === 0 && (
                <div className="px-2 py-2 ">No matching seed lots found</div>
              )}
            </>
          )}
        </div>
      </FlexCentered>
    </Page>
  );
}

export default SeedLotsPage;
