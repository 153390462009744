import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useTableContext } from "../../TableContext";
import FlexCentered from "../../../../Containers/FlexCentered";
import classNames from "classnames";
function SetVisibleColumns() {
  const { config, toggleColumnVisibility } = useTableContext();

  const EyeCon = ({ label, visible }) => {
    const handleClick = () => {
      toggleColumnVisibility(label);
    };
    const classes = classNames("text-2xl cursor-pointer", {
      "text-hgGreen-900 hover:scale-105": visible,
      "text-red-900": !visible,
    });
    if (visible)
      return <AiOutlineEye className={classes} onClick={handleClick} />;
    if (!visible)
      return (
        <AiOutlineEyeInvisible className={classes} onClick={handleClick} />
      );
  };

  if (!config?.columns) return;
  const renderedCols = config.columns.map((col) => {
    return (
      <div
        className="grid grid-cols-[1fr_auto] gap-x-2 w-full border rounded bg-hgBlue-50 mb-1"
        key={col.label}
      >
        <div className="px-2 py-1 select-none">{col.label}</div>
        <FlexCentered col>
          <EyeCon visible={col.visible !== false} label={col.label} />
        </FlexCentered>
      </div>
    );
  });
  return <div className="w-max">{renderedCols}</div>;
}
export default SetVisibleColumns;
