import { useGetCurrentUser } from "./api";
import PublicRouter from "./routes/PublicRouter";
import LoadingPage from "./Pages/LoadingPage";
import FailedToConnectPage from "./Pages/FailedToConnectPage";

function App() {
  const currentUserAPI = useGetCurrentUser();

  const currentUser = currentUserAPI.data;
  if (currentUserAPI.error || currentUserAPI.isPaused)
    return <FailedToConnectPage />;
  if (!currentUser) return <LoadingPage />;

  return <PublicRouter />;
}

export default App;
