import dateValidator from "../helpers/dateValidator";
import numberValidator from "../helpers/numberValidator";

function populationSchema() {
  return {
    name: {
      required: "Enter the population name",
      maxLength: {
        value: 25,
        message: "The name field cannot exceed 25 characters. ",
      },
    },
    reference: {
      required: "Enter a reference",
      maxLength: {
        value: 4,
        message: "The reference field cannot exceed 4 characters. ",
      },
    },
    type: {
      required: "Select a population type",
      getBody: (val) => val.label,
    },
    cropType: {
      required: "Select a crop type",
      getBody: (val) => val._id,
    },
    image: {
      required: "An image is required",
    },
    shortDesc: {
      required: "Enter a short description for the crop",
      maxLength: {
        value: 65,
        message: "The short description cannot exceed 65 characters. ",
      },
    },
    fullDesc: {
      required: "Enter a full description for the crop",
      maxLength: {
        value: 700,
        message: "The full description cannot exceed 700 characters. ",
      },
    },
    APHA_Registration_Form: {},
    introducedBy: {
      required: "Select the business issuing the initial lot",
      getBody: (val) => val._id,
    },
    quantityKg: {
      required: "Quantity required for the initial lot",
      validate: (value) => {
        return numberValidator({
          value,
          label: "Quantity Sown",
          min: 1,
          max: 99999,
          units: "kg",
        });
      },
    },
    date: {
      required: "Date required for the initial lot",
      validate: (value) => {
        const minDate = new Date(2010, 0, 1, 0, 0, 0, 0);

        return dateValidator({
          value,
          label: "Sowing date",
          min: minDate,
          minLabel: "the source seed lot",
          max: new Date(),
          maxLabel: "the current date",
        });
      },
    },
  };
}

export default populationSchema;
