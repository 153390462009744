import classnames from "classnames";
import { BiChevronsDown } from "react-icons/bi";
import { GiWeight } from "react-icons/gi";
import { FaSeedling } from "react-icons/fa";
import Link from "../../Link";

function FamilyTreeCard({ lot, style, getSeedLotLink }) {
  if (!lot) return;

  let borderColour = "border-red-900";
  if (lot.isInitialLot) borderColour = "border-hgYellow-500";
  else if (lot.parent) borderColour = "border-hgGreen-700";

  return (
    <Link to={getSeedLotLink(lot.reference)}>
      <div
        className={classnames(
          "absolute border rounded shadow flex flex-col items-center bg-hgCream-50 text-[11px] transition-all duration-300 ease-in-out cursor-pointer truncate",
          borderColour
        )}
        key={lot.id}
        style={style}
      >
        <p>{lot.reference}</p>
        <div
          className={classnames(
            "border-b-[1px] h-[1px] w-full mb-[5px]",
            borderColour
          )}
        />
        <p className="w-full text-center truncate px-1">{lot.issuedBy}</p>
        <BiChevronsDown />
        <p className="w-full text-center truncate px-1">{lot.receivedBy}</p>
        <div className="w-full px-2">
          <div
            className={classnames(
              "border-b-[1px] h-[1px] w-full border-gray-400 opacity-50 mb-[2px] mt-[5px]"
            )}
          />
        </div>
        <p>{new Date(lot.date).toLocaleDateString("en-gb")}</p>
        <div className="w-full px-2 mb-[5px] pt-[2px]">
          <div
            className={classnames(
              "border-b-[1px] h-[1px] w-full border-gray-400 opacity-50"
            )}
          />
        </div>

        <div className="grid grid-cols-[auto_auto] items-center gap-x-[5px]">
          <GiWeight />
          <p>{Number(lot.quantityKg).toLocaleString("en-gb")}kg</p>

          <FaSeedling />
          <p>{Number(lot.totalYield || 0).toLocaleString("en-gb")}</p>
        </div>
      </div>
    </Link>
  );
}
export default FamilyTreeCard;
