import Image from "../../../../Components/Image";
import P from "../../../../Components/P";
import { useRef } from "react";

function SeedLotItem({ seedLot, population, isReceived }) {
  const wrapperRef = useRef(null);

  return (
    <div
      ref={wrapperRef}
      className="grid grid-cols-[auto_8rem_1fr] md:grid-cols-[auto_12rem_1fr] gap-x-4 px-4 py-2 bg-hgCream-50 rounded-lg shadow-lg text-sm sm:text-base select-none items-center text-hgBlue-900 hover:scale-[101%]"
    >
      <Image
        src={population?.image}
        alt={population?.name}
        className="w-0 h-0 sm:w-[5rem] sm:h-[5rem] rounded-lg overflow-hidden"
      />
      <div className="flex flex-col justify-center">
        <div className="p-1 bg-hgBlue-900 text-hgCream-50 rounded shadow md:whitespace-nowrap">
          <P className="font-semibold">{seedLot.reference}</P>
        </div>

        <div className="flex flex-col justify-center bg-gray-200 p-1 mt-1 rounded shadow text-hgBlue-900">
          <P className="italic text-sm">{population?.name}</P>
          <P className="italic text-sm">{population?.cropType?.name}</P>
        </div>
      </div>
      <div className="grid grid-cols-[auto_1fr] gap-x-4 w-fit">
        {isReceived && (
          <>
            <P>From: </P>
            <P className="font-semibold">{seedLot.issuedBy?.tradingName}</P>
          </>
        )}
        {!isReceived && (
          <>
            <P>To: </P>
            <P className="font-semibold">{seedLot.receivedBy?.tradingName}</P>
          </>
        )}
        <P>Date: </P>
        <P className="font-semibold">
          {new Date(seedLot.date).toLocaleDateString("en-gb")}
        </P>
        <P>Weight: </P>
        <P className="font-semibold">
          {Number(seedLot.quantityKg).toLocaleString("en-gb")} kg
        </P>
      </div>
    </div>
  );
}
export default SeedLotItem;
