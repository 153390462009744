import { useEffect, useRef } from "react";
import classNames from "classnames";
import Input from "../../Components/Input";

function CPHNumberInput({
  className,
  value,
  onChange,
  error,
  disabled,
  darkMode,
}) {
  //Each element of the CPH number has an individual input. Assign refs for each input
  const countyRef = useRef(null);
  const parishRef = useRef(null);
  const holdingRef = useRef(null);

  //Get initial values where default passed
  let countyDefault = "";
  let parishDefault = "";
  let holdingDefault = "";
  if (value) {
    const els = value.split("-");
    countyDefault = els[0] || "";
    parishDefault = els[1] || "";
    holdingDefault = els[2] || "";
  }

  //Set initial values
  useEffect(() => {
    if (countyRef.current && parishRef.current && holdingRef.current) {
      countyRef.current.value = countyDefault.replaceAll(" ", "");
      parishRef.current.value = parishDefault.replaceAll(" ", "");
      holdingRef.current.value = holdingDefault.replaceAll(" ", "");
    }
  });

  //If an element is blurred and focus is not shifted to another element in the current
  //component call onchange and set the val
  const handleBlur = (e) => {
    const nextElId = e.relatedTarget?.id;
    const inputIds = ["county", "parish", "holding"];

    if (inputIds.includes(nextElId)) return;

    if (!countyRef.current || !parishRef.current || !holdingRef.current) return;
    const county = `${countyRef.current.value}`.padEnd(2, " ");
    const parish = `${parishRef.current.value}`.padEnd(3, " ");
    const holding = `${holdingRef.current.value}`.padEnd(4, " ");
    onChange(`${county}-${parish}-${holding}`);
  };

  const wrapperClasses = classNames(
    "flex flex-row justify-between items-center",

    className
  );

  const Hypen = () => (
    <p
      className={classNames("text-xl font-bold", {
        "text-hgCream-50": darkMode,
      })}
    >
      -
    </p>
  );

  return (
    <div className={wrapperClasses}>
      <Input
        id="county"
        type="text"
        maxLength={2}
        innerRef={countyRef}
        className="w-[20%]"
        onBlur={handleBlur}
        onChange={(e) => {
          const val = e?.target?.value;
          if (val?.length === 2 && parishRef.current) {
            parishRef.current.focus();
          }
        }}
        error={error}
        disabled={disabled}
      />
      <Hypen />
      <Input
        id="parish"
        innerRef={parishRef}
        type="text"
        maxLength={3}
        className="w-[30%]"
        onBlur={handleBlur}
        onChange={(e) => {
          const val = e?.target?.value;
          if (val?.length === 3 && holdingRef.current) {
            holdingRef.current.focus();
          }
        }}
        error={error}
        disabled={disabled}
      />
      <Hypen />
      <Input
        id="holding"
        innerRef={holdingRef}
        maxLength={4}
        type="text"
        className="w-[40%]"
        onBlur={handleBlur}
        onChange={(e) => {
          const val = e?.target?.value;
          if (val?.length === 4 && holdingRef.current) {
            holdingRef.current.blur();
          }
        }}
        error={error}
        disabled={disabled}
      />
    </div>
  );
}

export default CPHNumberInput;
