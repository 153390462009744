import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";
import P from "../../Components/P";
import BusinessAddress from "./BusinessAddress";
import Button from "../../Components/Button";
import Link from "../../Components/Link";
import Image from "../../Components/Image";
import BusinessAbout from "./BusinessAbout";

function ViewBusiness({ business, editLink }) {
  return (
    <div>
      <FlexCentered col className="pt-8">
        {business.logo && (
          <div className="w-[10rem] h-[10rem] mb-2 rounded overflow-hidden">
            <Image src={business.logo} alt={`${business.tradingName} logo`} />
          </div>
        )}
        <FlexCentered col className={"w-full max-w-2xl px-2"}>
          <FlexCentered row className="gap-x-[2vw] sm:gap-x-4">
            <Heading
              primary
              className="text-3xl md:text-4xl font-light text-hgBlue-700"
            >
              {business.tradingName}
            </Heading>
            {editLink && (
              <Link to={editLink}>
                <Button className="py-1 px-2 hover:text-hgGreen-1100">
                  Edit
                </Button>
              </Link>
            )}
          </FlexCentered>
          <div className="grid grid-cols-[auto_1fr] gap-y-1 gap-x-[4vw] bg-hgGreen-1000 text-hgCream-50  font-semibold border px-[4vw] py-2 shadow mt-8">
            <P>Business Type:</P>
            <P>{business.businessType}</P>
            {business.businessType === "Grower" && (
              <>
                <P>CPH Number:</P>
                <P>{business.CPH_Number || "-"}</P>
              </>
            )}
            <div className="col-span-2 py-1" />
            <P>Email:</P>
            {business.email ? (
              <a
                href={`mailto:${business.email}`}
                target="_blank"
                rel="noreferrer"
              >
                <P className="hover:text-hgYellow-100">{business.email}</P>
              </a>
            ) : (
              <P>"-"</P>
            )}
            <P>Website:</P>

            {business.website ? (
              <a href={business.website} target="_blank" rel="noreferrer">
                <P className="hover:text-hgYellow-100">
                  {business.website.replace("https://", "")}
                </P>
              </a>
            ) : (
              <P>"-" </P>
            )}
          </div>
          {business.about && (
            <div className="mt-12 w-full">
              <BusinessAbout business={business} />
            </div>
          )}
          <div className="mt-12 w-full">
            <BusinessAddress
              address={business.formattedAddress}
              business={business}
            />
          </div>
        </FlexCentered>
      </FlexCentered>
    </div>
  );
}
export default ViewBusiness;
