import Address from "../../Components/Address";
import Heading from "../../Components/Heading";
import Map from "../../Components/Map";

function BusinessAddress({ business, location }) {
  return (
    <div className="w-full rounded overflow-hidden shadow-lg">
      <div className="bg-hgBlue-700 py-4">
        <Heading
          tertiary
          className="text-xl text-hgCream-50 text-center w-full font-light"
        >
          Business Address
        </Heading>
      </div>
      <div className="p-4 bg-hgCream-50 grid sm:grid-cols-2">
        <div className="bg-hgBlue-100 bg-opacity-30 w-full p-4 pb-8 sm:pb-4 rounded flex flex-col justify-center">
          <Address formattedAddress={business.formattedAddress} />
        </div>
        <div className="min-h-[12rem]">
          <Map
            coords={business?.location?.coordinates}
            markers={[
              {
                coords: business?.location?.coordinates,
                icon: `${business.businessType}Icon`,
                title: business.tradingName,
              },
            ]}
            zoom={6}
            mapStyle="hybrid"
          />
        </div>
      </div>
    </div>
  );
}
export default BusinessAddress;
