export default function compareDates(a, b) {
  const aDate = new Date(a);
  const bDate = new Date(b);

  const date = new Date(
    aDate.getFullYear(),
    aDate.getMonth(),
    aDate.getDate(),
    12,
    0,
    0,
    0
  );
  const comparitorDate = new Date(
    bDate.getFullYear(),
    bDate.getMonth(),
    bDate.getDate(),
    12,
    0,
    0,
    0
  );

  if (date.getTime() > comparitorDate.getTime()) return 1;
  if (date.getTime() < comparitorDate.getTime()) return -1;
  return 0;
}
