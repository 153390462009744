import classNames from "classnames";
import P from "./P";
function TextBox({
  max,
  className,
  disabled,
  error,
  value,
  onChange,
  ...props
}) {
  const handleInput = (e) => {
    const val = e.target?.value;
    if (!/^[a-z 0-9.'"();:!,]*$/i.test(val)) return;
    onChange(e.target?.value);
  };

  const classes = classNames(
    "outline-none focus:ring-transparent active:ring-transparent border ",
    {
      "px-2": !(className || "").includes("px"),
      "py-1": !(className || "").includes("py"),
      "text-left": !(className || "").includes("text"),
      "active:border-red-700 focus:border-red-700 border-red-700": error,
      "active:border-hgGreen-800 focus:border-hgGreen-800": !error,
      "bg-gray-200 pointer-events-none": disabled,
    },
    className
  );
  return (
    <>
      <textarea
        {...props}
        className={classes}
        onChange={handleInput}
        value={value}
      />
      <P
        className={classNames({
          "text-red-900": value?.length > max,
        })}
        title={`You can use a maximum of ${max} characters`}
      >
        {value?.length || 0}/{max} characters
      </P>
    </>
  );
}
export default TextBox;
