import { useParams } from "react-router-dom";
import ViewPopulation from "../../Sections/ViewPopulation";
import Page from "../../Containers/Page";

function PopulationPage() {
  const { populationRef } = useParams();

  return (
    <Page title={populationRef}>
      <ViewPopulation
        familyTreeLink={(ref) => `/populations/${ref}/family-tree`}
        notFoundLink="/"
        notFoundLabel="Home"
      />
    </Page>
  );
}

export default PopulationPage;
