import { useForm, Controller, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "../providers/toastContext";
import { useEditSeedLot, useGetAllSeedLots, useDeleteSeedLot } from "../api";
import seedLotSchema from "./schemas/seedLotSchema";
import Label from "../Components/Label";
import Dropdown from "../Components/Dropdown";
import DateInput from "../Components/DateInput";
import Input from "../Components/Input";
import FlexCentered from "../Containers/FlexCentered";
import RenderedErrors from "../Components/RenderedErrors";
import Button from "../Components/Button";
import getControlBodyDictionary from "../utils/getControlBodyDictionary";
import { useEffect } from "react";
import Heading from "../Components/Heading";
import P from "../Components/P";

function EditSeedLotForm({ seedLot }) {
  let defaultIsOrganic;
  switch (seedLot.isOrganic) {
    case true:
      defaultIsOrganic = { label: "Organic", id: "organic" };
      break;
    case false:
      defaultIsOrganic = { label: "Not Organic", id: "notOrganic" };
      break;
    default:
      defaultIsOrganic = { label: "Not specified", id: "Not specified" };
  }

  const controlBodies = getControlBodyDictionary({ array: true });

  const defaultControlBody = controlBodies.find(
    (body) => body.code === seedLot.controlBody
  );

  const form = useForm({
    values: {
      ...seedLot,
      parentLot: seedLot?.parentLot
        ? seedLot?.parentLot
        : seedLot.isInitialLot
        ? {
            reference: "N/A",
            id: "N/A",
          }
        : {
            reference: "Unknown",
            _id: "Unknown",
            style: "danger",
          },
      isOrganic: defaultIsOrganic,
      controlBody: defaultControlBody,
    },
  });
  const errors = form.formState.errors;

  const editSeedLotApi = useEditSeedLot();
  const deleteSeedLotApi = useDeleteSeedLot();
  let apiError = editSeedLotApi.error || deleteSeedLotApi.error;

  const { createSuccessToast } = useToastContext();

  const navigateTo = useNavigate();
  useEffect(() => {
    if (editSeedLotApi?.data) {
      createSuccessToast("New seed lot successfully issued");
      const seedLotRef = editSeedLotApi?.data?.seedLot?.reference;
      if (seedLotRef) {
        return navigateTo(`/admin/seed-lots/${seedLotRef}`);
      }
    }
  }, [createSuccessToast, editSeedLotApi?.data, navigateTo]);

  useEffect(() => {
    if (deleteSeedLotApi?.isSuccess) {
      createSuccessToast("Seed lot deleted");
      return navigateTo(`/admin`);
    }
  }, [createSuccessToast, deleteSeedLotApi?.isSuccess, navigateTo]);

  let parentLot;

  const { register } = form;
  const schema = seedLotSchema({});

  if (!seedLot.isInitialLot) {
    register("parentLot", schema.parentLot);
  }

  register("date", schema.date);
  const quantityKgProps = register("quantityKg");
  register("isOrganic", schema.isOrganic);
  register("controlBody", schema.controlBody);

  const isOrganic = useWatch({
    control: form.control,
    name: "isOrganic",
  });

  parentLot = useWatch({
    control: form.control,
    name: "parentLot",
  });

  const getSeedLotsApi = useGetAllSeedLots({
    params: {
      fields: "reference,date,quantityKg",
      receivedBy: seedLot?.issuedBy?._id,
      population: seedLot?.population?._id,
      completeList: true,
    },
  });

  const seedLots = getSeedLotsApi?.data?.seedLots;

  const handleSubmit = (data) => {
    const body = {
      parentLot: data?.parentLot?._id,
      date: data?.date,
      quantityKg: data?.quantityKg,
      isOrganic:
        data?.isOrganic?.label === "Organic"
          ? true
          : data?.isOrganic?.label === "Not Organic"
          ? false
          : "undefined",
      controlBody:
        data?.isOrganic?.label === "Organic"
          ? data?.controlBody?.code
          : undefined,
    };
    editSeedLotApi.mutate({ seedLotRef: seedLot._id, body });
  };

  const handleDelete = () => {
    deleteSeedLotApi.mutate({
      seedLotRef: seedLot._id,
    });
  };

  return (
    <form
      className="pb-56"
      onSubmit={form.handleSubmit(handleSubmit)}
      noValidate
    >
      <FlexCentered col>
        <FlexCentered col>
          <Heading primary className="pb-1">
            Edit Seed Lot
          </Heading>
          <Heading tertiary className="pb-12 text-hgGreen-1100">
            {seedLot?.reference}
          </Heading>
          <Heading
            secondary
            className="w-full pb-2"
            title="Details about the source of this seed lot"
          >
            Source
          </Heading>
          <div className="border-b border-hgGreen-900 w-full mb-8" />

          <Label
            htmlFor="parentLot"
            className="mb-2"
            title="Select the source seed lot"
          >
            Parent Lot
          </Label>

          <div>
            <Controller
              control={form.control}
              name="parentLot"
              render={({ field: { onChange, onBlur, value } }) => (
                <Dropdown
                  id="parentLot"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className="w-[20rem] max-w-full mb-12"
                  config={{
                    data: seedLot.parentLot ? [seedLot.parentLot] : seedLots,
                    getLabel: (el) => el?.reference,
                    defaultField: {
                      reference: "Unknown",
                      _id: "Unknown",
                      style: "danger",
                    },
                    options: {
                      disallowEmptyValues: true,
                    },
                  }}
                  title="Select the source seed lot"
                  error={errors.parentLot}
                  rules={schema.parentLot}
                  disabled={seedLot.isInitialLot}
                />
              )}
            />
          </div>

          <Heading
            secondary
            className="w-full pb-2"
            title="Details about this seed lot"
          >
            Details
          </Heading>
          <div className="border-b border-hgGreen-900 w-full mb-8" />

          <Label htmlFor="date" title="On which date was this seed lot issued?">
            Date
          </Label>
          <Controller
            control={form.control}
            name="date"
            render={({ field: { onChange, onBlur, value } }) => (
              <DateInput
                id="date"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                min={new Date(2010, 0, 1)}
                max={new Date()}
                className="max-w-[20rem] w-full mb-4 mt-2"
                title="On which date was this seed lot issued?"
                error={errors.date}
                rules={schema.date}
              />
            )}
          />

          <Label
            htmlFor="quantityKg"
            title="Specify the batch weight of this seed lot in kilograms"
          >
            Quantity (kg)
          </Label>
          <div className="mt-2">
            <Input
              type="text"
              id="quantityKg"
              className="w-[20rem] max-w-full mb-12"
              {...quantityKgProps}
              ref={null}
              innerRef={quantityKgProps.ref}
              error={errors.quantityKg}
              title="Specify the batch weight of this seed lot in kilograms"
            />
          </div>

          {seedLot.issuedBy?.businessType === "Grower" && (
            <>
              <Label
                htmlFor="isOrganic"
                className="mb-2"
                title="Is the seed lot organic"
              >
                Organic
              </Label>

              <div>
                <Controller
                  control={form.control}
                  name="isOrganic"
                  defaultValue={{ label: "Not specified", id: "Not specified" }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropdown
                      id="isOrganic"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      className="w-[20rem] max-w-full  mt-2 mb-4"
                      config={{
                        data: [
                          { label: "Not specified", id: "Not specified" },
                          { label: "Organic", id: "organic" },
                          { label: "Not Organic", id: "notOrganic" },
                        ],
                        options: {
                          disallowEmptyValues: true,
                        },
                      }}
                      title="Is the seed lot organic"
                      error={errors.isOrganic}
                      rules={schema.isOrganic}
                    />
                  )}
                />
              </div>

              {isOrganic?.id === "organic" && (
                <>
                  <Label
                    htmlFor="isOrganic"
                    className="mb-2"
                    title="Specify the control body"
                  >
                    Control Body
                  </Label>

                  <div>
                    <Controller
                      control={form.control}
                      name="controlBody"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Dropdown
                          id="controlBody"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          className="w-[20rem] max-w-full mb-4 mt-2"
                          config={{
                            data: controlBodies,
                            getKey: (el) => el.code,
                            options: {
                              disallowEmptyValues: true,
                            },
                          }}
                          title="Specify the control body"
                          error={errors.controlBody}
                          rules={schema.controlBody}
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </>
          )}

          <FlexCentered col className="col-span-2 mb-4 mt-8">
            {parentLot?.reference === "Unknown" && (
              <div className="bg-hgRed-100 px-4 py-2 w-[20rem] max-w-full rounded shadow mb-4">
                <P className="text-center font-semibold mb-2">Warning!</P>
                <P className="text-center">"Unknown" parent lot</P>
              </div>
            )}
            <FlexCentered row className="gap-x-2">
              <Button
                primary
                type="submit"
                loading={editSeedLotApi.isLoading}
                disabled={!form.formState.isDirty}
              >
                Save
              </Button>
              <Button
                danger
                outline
                type="button"
                onClick={handleDelete}
                loading={deleteSeedLotApi.isLoading}
              >
                Delete
              </Button>
            </FlexCentered>

            <RenderedErrors
              errors={errors || []}
              apiError={apiError || ""}
              className="bg-hgCream-50 max-w-prose"
            />
          </FlexCentered>
        </FlexCentered>
      </FlexCentered>
    </form>
  );
}
export default EditSeedLotForm;
