import { useForm, Controller, useWatch } from "react-hook-form";
import { useUpdateBusiness, useDeleteBusiness } from "../api";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "../providers/toastContext";
import { useEffect } from "react";
import businessDetailsSchema from "./schemas/businessDetailsSchema";
import FlexCentered from "../Containers/FlexCentered";
import Heading from "../Components/Heading";
import AddressInput from "./components/AddressInput";
import CPHNumberInput from "./components/CPHNumberInput";
import Dropdown from "../Components/Dropdown";
import Input from "../Components/Input";
import Label from "../Components/Label";
import RenderedErrors from "../Components/RenderedErrors";
import Button from "../Components/Button";
import TextBox from "../Components/TextBox";
import UploadImage from "../Components/UploadImage";

function EditBusinessForm({ business, isAdmin, onSuccessLink }) {
  const updateBusinessApi = useUpdateBusiness();
  const deleteBusinessApi = useDeleteBusiness();

  const navigateTo = useNavigate();
  const { createSuccessToast } = useToastContext();
  useEffect(() => {
    if (deleteBusinessApi?.isSuccess) {
      createSuccessToast("Business deleted successfully");
      navigateTo("/admin/businesses");
    }
  });

  useEffect(() => {
    if (updateBusinessApi.isSuccess && onSuccessLink) {
      createSuccessToast("Business updated");
      navigateTo(onSuccessLink);
    }
  });

  const form = useForm({
    values: {
      ...business,
      businessType:
        business.businessType === "Grower"
          ? { label: "Grower", id: "Grower" }
          : { label: "Merchant", id: "Merchant" },
      address: {
        postcode: business.postcode,
        formattedAddress: business.formattedAddress,
        location: business.location,
      },
      email: business.email || "",
      website: business.website || "",
      about: business.about || "",
    },
  });

  const errors = form.formState.errors;
  const apiError = updateBusinessApi?.error;

  const businessType = useWatch({
    control: form.control,
    name: "businessType",
  });

  const { register } = form;
  const schema = businessDetailsSchema();

  const tradingNameProps = register("tradingName", schema.tradingName);
  const emailProps = register("email", schema.email);
  register("about", schema.about);
  const websiteProps = register("website", {});
  register("logo", {});
  register("address", schema.address);

  const dropDownConfig = {
    data: [
      { label: "Grower", id: "Grower" },
      { label: "Merchant", id: "Merchant" },
    ],
  };

  const onSubmit = (data) => {
    const body = new FormData();
    Object.keys(form.formState.dirtyFields).forEach((key) => {
      const value = data[key];
      if (key === "logo") {
        if (data.logo === null) body.append("unsetLogo", true);
        body.append(key, value);
      } else {
        body.append(key, value);
      }
    });
    if (body.address) {
      body.append("postcode", body.address.postcode);
      body.append("formattedAddress", body.address.formattedAddress);
      body.append("location", body.address.location);
      body.delete("address");
    }

    if (Array.from(body.keys()).length === 0) return;
    updateBusinessApi.mutate({
      businessRef: business._id,
      body,
    });
  };

  const handleDelete = () => {
    deleteBusinessApi.mutate({
      businessRef: business._id,
    });
  };

  return (
    <FlexCentered>
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate className="pb-20">
        <Heading
          primary
          className="text-center text-xl mt-12 mb-12 text-hgGreen-1100"
        >
          Edit Business{isAdmin ? `: ${business.tradingName}` : ` Profile`}
        </Heading>
        <FlexCentered col>
          {isAdmin && (
            <FlexCentered col className="mb-20">
              <Heading tertiary className="w-full">
                Permissions
              </Heading>
              <div className="mt-1 mb-8 bg-hgBlue-900  h-[1px] w-full" />
              <FlexCentered col className="mb-2">
                <Label htmlFor="businessType" className="mb-2">
                  Business Type
                </Label>
                <Controller
                  control={form.control}
                  name="businessType"
                  rules={schema.businessType}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Dropdown
                      className="w-[20rem] max-w-full"
                      config={dropDownConfig}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={errors.businessType}
                    />
                  )}
                />
              </FlexCentered>
              {businessType?.label === "Grower" && (
                <>
                  <Label htmlFor="businessType" className="mb-2">
                    CPH Number
                  </Label>
                  <Controller
                    control={form.control}
                    name="CPH_Number"
                    rules={schema.CPH_Number}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <CPHNumberInput
                        className="w-[20rem] max-w-full"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        error={errors.CPH_Number}
                      />
                    )}
                  />
                </>
              )}
            </FlexCentered>
          )}

          <Heading tertiary className="w-full">
            Details
          </Heading>
          <div className="mt-1 mb-8 bg-hgBlue-900  h-[1px] w-full" />

          <Label htmlFor="tradingName" className="mb-2">
            Trading Name
          </Label>
          <Input
            type="text"
            id="tradingName"
            className="w-[20rem] max-w-full mb-4"
            {...tradingNameProps}
            ref={null}
            innerRef={tradingNameProps.ref}
            error={errors.tradingName}
          />
          <Label htmlFor="email" className="mb-2">
            Business Email
          </Label>
          <Input
            type="email"
            id="email"
            className="w-[20rem] max-w-full mb-4"
            {...emailProps}
            ref={null}
            innerRef={emailProps.ref}
            error={errors.email}
          />
          <Label htmlFor="website" className="mb-2">
            Website
          </Label>
          <Input
            type="website"
            id="website"
            className="w-[20rem] max-w-full mb-4"
            {...websiteProps}
            ref={null}
            innerRef={websiteProps.ref}
            error={errors.website}
          />
          <Label
            htmlFor="about"
            className="mb-2 mt-4"
            title="Enter a brief description of the business"
          >
            About
          </Label>
          <Controller
            control={form.control}
            name="about"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextBox
                id="about"
                className="w-[20rem] max-w-full mt-2 h-56"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.about}
                rules={schema.about}
                title="Enter a brief description of the business"
                max={700}
              />
            )}
          />

          <Label
            htmlFor="logo"
            className="mb-2"
            title="Upload a logo for your business"
          >
            Image
          </Label>
          <Controller
            control={form.control}
            name="logo"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <UploadImage
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.logo}
                rules={schema.logo}
              >
                Upload Image
              </UploadImage>
            )}
          />
          {isAdmin && (
            <>
              <Heading tertiary className="w-full mt-12">
                Address
              </Heading>
              <div className="mt-1 mb-8 bg-hgBlue-900  h-[1px] w-full" />
              <Controller
                control={form.control}
                name="address"
                rules={schema.address}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <AddressInput
                    className="w-[20rem] max-w-full"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={errors.businessType}
                  />
                )}
              />
            </>
          )}
          <FlexCentered row className="mt-12 gap-x-2">
            <Button
              primary
              loading={updateBusinessApi.isLoading}
              disabled={Object.keys(form.formState?.dirtyFields).length === 0}
            >
              Save
            </Button>
            {isAdmin && (
              <Button
                onClick={handleDelete}
                danger
                outline
                loading={deleteBusinessApi.isLoading}
                type="button"
              >
                Delete
              </Button>
            )}
          </FlexCentered>

          {(form.formState.isSubmitted || deleteBusinessApi.error) && (
            <RenderedErrors
              errors={errors}
              apiError={apiError || deleteBusinessApi.error}
              className="bg-hgCream-50 w-full max-w-[20rem]"
            />
          )}
        </FlexCentered>
      </form>
    </FlexCentered>
  );
}
export default EditBusinessForm;
