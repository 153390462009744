import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDeleteMe } from "../api";
import { useToastContext } from "../providers/toastContext";
import Button from "../Components/Button";
import RequirePasswordModal from "../Sections/RequirePasswordModal";
import RenderedErrors from "../Components/RenderedErrors";
import FlexCentered from "../Containers/FlexCentered";

function DeleteMeForm() {
  const [showRequirePasswordModal, setShowRequirePasswordModal] =
    useState(false);

  const { createSuccessToast } = useToastContext();
  const deleteMeApi = useDeleteMe();
  useEffect(() => {
    if (deleteMeApi.isSuccess) {
      createSuccessToast("So Long, and Thanks for All the Fish");
    }
  });

  const form = useForm();
  const { register } = form;
  const passwordProps = register("password");

  const handleSubmit = (data) => {
    setShowRequirePasswordModal(false);
    if (!data.password) return setShowRequirePasswordModal(true);
    deleteMeApi.mutate({
      body: data,
    });
    form.reset();
  };

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      noValidate
      className=" pb-20"
      id="update-me-form"
    >
      <FlexCentered col>
        <Button danger outline>
          Delete Me
        </Button>
        <div className="mt-2">
          <RenderedErrors
            errors={[]}
            apiError={deleteMeApi.error}
            className="max-w-[20rem] bg-hgCream-50"
          />
        </div>
      </FlexCentered>
      {showRequirePasswordModal && (
        <RequirePasswordModal
          onClose={() => {
            setShowRequirePasswordModal(false);
          }}
          {...passwordProps}
          ref={null}
          innerRef={passwordProps.ref}
          warning="Your account will be deactivated and you will be logged-out from this application."
          buttonText="Delete Me"
          loading={deleteMeApi.isLoading}
        />
      )}
    </form>
  );
}
export default DeleteMeForm;
