import { useRef, useState, useEffect } from "react";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import Dropdown from "../../Components/Dropdown";
import { useProductListContext } from "./ProductListContext";
import Label from "../../Components/Label";

function Control() {
  const searchbarRef = useRef(null);
  const { query, setQuery, populations, populationsObj } =
    useProductListContext();
  const [isArchived, setIsArchived] = useState(null);
  const [population, setPopulation] = useState(null);

  const statusOptions = [
    { label: "Archived", id: "archived", value: true },
    { label: "Active", id: "active", value: false },
  ];

  useEffect(() => {
    if (query?.isArchived === undefined) {
      if (isArchived) setIsArchived(null);
    } else {
      if (query?.isArchived !== isArchived?.value) {
        if (query?.isArchived) {
          setIsArchived({ label: "Archived", id: "archived", value: true });
        } else setIsArchived({ label: "Active", id: "active", value: false });
      }
    }
  }, [isArchived, query?.isArchived]);

  useEffect(() => {
    if (!query.population) {
      if (population) setPopulation(null);
    } else {
      if (query.population !== population?._id?.toString()) {
        setPopulation(populationsObj[query.population]);
      }
    }
  }, [population, populationsObj, query.population]);

  const handleSearch = (e) => {
    e?.preventDefault();
    if (!searchbarRef.current) return;
    const searchterm = searchbarRef.current.value;
    setQuery?.search(searchterm, ["reference"]);
  };

  return (
    <div className="p-2 bg-hgGreen-900 rounded shadow-lg">
      <form
        className="flex flex-row items-stretch justify-center"
        onSubmit={handleSearch}
      >
        <Input
          innerRef={searchbarRef}
          className="w-full h-10 bg-gray-50"
          placeholder="Search products"
        />
        <Button secondary className="px-2 py-1">
          Search
        </Button>
      </form>
      <div className="md:hidden grid grid-cols-[auto_1fr] gap-x-4 gap-y-2 mt-4 ">
        <Label className="whitespace-nowrap text-hgCream-50">Status: </Label>
        <Dropdown
          className="w-full"
          value={isArchived}
          onChange={(val) => {
            if (val) {
              setQuery.filter(
                {
                  fieldName: "isArchived",
                  label: "isArchived",
                  type: "String",
                },
                "Equals",
                val?.value
              );
            } else {
              setQuery.unsetFilter({
                fieldName: "isArchived",
                operator: "eq",
              });
            }
          }}
          placeholder="Any"
          config={{
            data: statusOptions,
          }}
        />
        <Label className="whitespace-nowrap text-hgCream-50">Population</Label>
        <div className="flex flex-row justify-stretch items-stretch bg-red-200">
          <Dropdown
            className="w-full"
            value={population}
            onChange={(val) => {
              if (val) {
                setQuery.filter(
                  {
                    fieldName: "population",
                    label: "population",
                    type: "String",
                  },
                  "Equals",
                  val?._id
                );
              } else {
                setQuery.unsetFilter({
                  fieldName: "population",
                  operator: "eq",
                });
              }
            }}
            placeholder="Any"
            config={{
              data: populations,
              getLabel: (population) => population.name,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Control;
