export default function getMassBalanceValidator({
  sourceSeedLotWeight,
  weightIssuedToChildLots,
}) {
  return (values) => {
    let totalSown = 0;
    let totalYield = 0;
    values?.forEach((row) => {
      if (Number(row.qtySownKg)) totalSown += +row.qtySownKg;
      if (Number(row.totalYieldT)) totalYield += +row.totalYieldT * 1000;
    });

    const unsownSeed = sourceSeedLotWeight - totalSown;

    if (unsownSeed < 0) {
      return `The total sown cannot exceed the weight of the source seed lot (${Number(
        sourceSeedLotWeight
      ).toLocaleString("en-gb")} kg)`;
    }
    const grossAvailable = unsownSeed + totalYield;
    const balance = grossAvailable - weightIssuedToChildLots;

    if (balance < 0) {
      return `The mass balance cannot be less than 0`;
    }

    return true;
  };
}
