import { AiOutlineExclamationCircle, AiOutlineClose } from "react-icons/ai";
import ModalWindow from "../Components/ModalWindow";
import Heading from "../Components/Heading";
import FlexCentered from "../Containers/FlexCentered";

function ServerErrorModal({ serverError, onClose }) {
  if (!serverError) return;
  return (
    <ModalWindow options={{ blur: true, darkMode: false, pos: { top: 20 } }}>
      <div className="relative border-2 border-red-900 w-max px-[8vw] py-8 bg-hgCream-50">
        <FlexCentered col>
          <FlexCentered row className="gap-x-2 mb-4">
            <AiOutlineExclamationCircle className="text-red-900 text-2xl" />
            <Heading primary className="text-lg font-normal text-red-900">
              Error
            </Heading>
          </FlexCentered>
          <div className="font-semibold">{serverError}</div>
        </FlexCentered>
        <div
          className="bg-hgCream-50 hover:text-hgBlue-400 cursor-pointer rounded-full absolute top-[5px] right-[5px] text-2xl text-hgBlue-900"
          onClick={() => onClose()}
        >
          <AiOutlineClose />
        </div>
      </div>
    </ModalWindow>
  );
}

export default ServerErrorModal;
