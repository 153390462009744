import { useParams } from "react-router-dom";
import { useGetCurrentUser } from "../../../api";
import ViewPopulation from "../../../Sections/ViewPopulation";
import Page from "../../../Containers/Page";

function PopulationPage() {
  const { populationRef } = useParams();
  const getCurrentUserApi = useGetCurrentUser();
  const currentUser = getCurrentUserApi?.data?.user;

  return (
    <Page title={populationRef}>
      <ViewPopulation
        familyTreeLink={(ref) => `/admin/populations/${ref}/family-tree`}
        editLink={
          currentUser?.permissions?.writePopulations
            ? (ref) => `/admin/populations/${ref}/edit`
            : undefined
        }
        notFoundLink="/admin"
        notFoundLabel="admin dashboard"
      />
    </Page>
  );
}

export default PopulationPage;
