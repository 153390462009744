import { useEffect, useState } from "react";
import {
  useGetOneUser,
  useGetCurrentUser,
  useUpdatePermissions,
} from "../../../api";
import { useParams, useNavigate } from "react-router-dom";
import Page from "../../../Containers/Page";
import Yes from "./Components/Yes";
import No from "./Components/No";
import FlexCentered from "../../../Containers/FlexCentered";
import classNames from "classnames";
import Heading from "../../../Components/Heading";
import Button from "../../../Components/Button";
import RenderedErrors from "../../../Components/RenderedErrors";
import getApiStatusPage from "../../APIStatusPage";

function CheckBox({ checked, ...props }) {
  return (
    <FlexCentered
      col
      className={classNames("p-1 cursor-pointer rounded shadow-sm", {
        "bg-hgGreen-100": checked,
        "bg-hgRed-100": !checked,
      })}
      {...props}
    >
      {checked && <Yes />}
      {!checked && <No />}
    </FlexCentered>
  );
}
function UserPermissionsPage() {
  const [updatedPermissions, setUpdatedPermissions] = useState(null);
  const { userRef } = useParams();
  const getUserApi = useGetOneUser({
    ref: userRef,
    params: {
      fields:
        "fullName,email,linkedBusiness,createdOn,isActive,restrictedAccess,admin,emailConfirmed,permissions,slug",
      "populate[linkedBusiness]": "tradingName, business_Reference",
    },
  });

  const updatePermissionsApi = useUpdatePermissions();

  const currentUserApi = useGetCurrentUser();

  const user = getUserApi.data?.user;

  const navigateTo = useNavigate();
  useEffect(() => {
    if (getUserApi.isFetching) return;
    if (!user || user?.slug !== userRef) {
      navigateTo(`/admin/users/${userRef}`);
    }
  }, [getUserApi, navigateTo, user, userRef]);

  useEffect(() => {
    if (updatePermissionsApi.data) {
      navigateTo(`/admin/users/${userRef}`);
    }
  }, [navigateTo, updatePermissionsApi.data, userRef]);

  useEffect(() => {
    if (getUserApi.isFetching) return;
    const user = getUserApi.data?.user;
    if (user?.permissions) {
      setUpdatedPermissions({ ...user.permissions, admin: user.admin });
    }
  }, [getUserApi.data, getUserApi.isFetching]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getUserApi,
        type: "Seed Lot",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  const isRootUser = currentUserApi?.data?.isRootUser;

  const handleTogglePermission = (field) => {
    const newPermissions = { ...updatedPermissions };
    newPermissions[field] = !updatedPermissions[field];
    if (field === "writeLinkedBusiness") {
      if (
        !updatedPermissions.writeBusiness &&
        !updatedPermissions.writeLinkedBusiness
      ) {
        newPermissions.writeBusiness = true;
      }
    }
    if (field === "writeBusiness") {
      if (
        updatedPermissions.writeBusiness &&
        updatedPermissions.writeLinkedBusiness
      ) {
        newPermissions.writeLinkedBusiness = false;
      }
    }
    setUpdatedPermissions(newPermissions);
  };

  const handleSubmit = () => {
    const body = {
      admin: updatedPermissions.admin,
      permissions: { ...updatedPermissions },
    };
    delete body.permissions.admin;
    delete body.permissions._id;
    updatePermissionsApi.mutate({
      userRef: user._id,
      body,
    });
  };

  return (
    <Page title={`${user.fullName} - Permissions`}>
      <FlexCentered col>
        <div className=" flex flex-col bg-hgCream-50 w-max px-[4vw] py-4 rounded shadow-lg mt-8">
          <Heading primary>Edit Permissions</Heading>
          <Heading tertiary className="mb-8">
            {user.fullName}
          </Heading>
          <div className="grid grid-cols-[1fr_auto] w-max gap-x-4 gap-y-2 ">
            <div>Admin</div>
            <CheckBox
              checked={updatedPermissions?.admin}
              onClick={() => handleTogglePermission("admin")}
            />
            {updatedPermissions?.admin && (
              <>
                {isRootUser && (
                  <>
                    <div>Edit Permissions</div>
                    <CheckBox
                      checked={updatedPermissions?.writePermissions}
                      onClick={() => handleTogglePermission("writePermissions")}
                    />
                  </>
                )}
                <div>Edit Access</div>
                <CheckBox
                  checked={updatedPermissions?.writeAccess}
                  onClick={() => handleTogglePermission("writeAccess")}
                />
                <div>Edit Linked Business</div>
                <CheckBox
                  checked={updatedPermissions?.writeLinkedBusiness}
                  onClick={() => handleTogglePermission("writeLinkedBusiness")}
                />
                <div>Edit Business</div>
                <CheckBox
                  checked={updatedPermissions?.writeBusiness}
                  onClick={() => handleTogglePermission("writeBusiness")}
                />
                <div>Edit Populations</div>
                <CheckBox
                  checked={updatedPermissions?.writePopulations}
                  onClick={() => handleTogglePermission("writePopulations")}
                />
                <div>Edit Seed Lots</div>
                <CheckBox
                  checked={updatedPermissions?.writeSeedLots}
                  onClick={() => handleTogglePermission("writeSeedLots")}
                />
                <div>Edit Products</div>
                <CheckBox
                  checked={updatedPermissions?.writeProducts}
                  onClick={() => handleTogglePermission("writeProducts")}
                />
              </>
            )}
          </div>
          <FlexCentered col>
            <Button primary className="mt-8 w-max" onClick={handleSubmit}>
              Save
            </Button>
          </FlexCentered>
        </div>
        {updatePermissionsApi.error && (
          <RenderedErrors
            errors={{}}
            apiError={updatePermissionsApi.error}
            className="bg-hgCream-50 max-w-2"
          />
        )}
      </FlexCentered>
    </Page>
  );
}

export default UserPermissionsPage;
