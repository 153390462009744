import classNames from "classnames";
import { useRef, useEffect, useState } from "react";
import Input from "../Input";
import { AiOutlineCalendar } from "react-icons/ai";
import CalenderModal from "./CalenderModal";

function InputBox({
  value,
  title,
  small,
  min,
  max,
  onChange,
  className,
  darkMode,
  error,
  disabled,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const calenderIconRef = useRef(null);

  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const wrapperRef = useRef(null);

  const handleBlur = (e, isEnterPress) => {
    if (wrapperRef?.current?.contains(e.relatedTarget) && !isEnterPress) return;
    const day = dayRef?.current?.value;
    const month = monthRef?.current?.value;
    const year = yearRef?.current?.value;
    const updatedDate = new Date(
      year || "x",
      month - 1 ?? "x",
      day || "x",
      12,
      0,
      0,
      0
    );
    if (Number.isFinite(updatedDate.getTime())) {
      onChange(updatedDate);
    } else {
      onChange(null);
    }
  };

  useEffect(() => {
    const handleEnterPress = (e) => {
      if (!wrapperRef.current?.contains(e.target)) return;
      if (e?.key !== "Enter") return;
      handleBlur(e, true);
    };
    document.addEventListener("keypress", handleEnterPress);
    return () => document.removeEventListener("keypress", handleEnterPress);
  });

  useEffect(() => {
    if (!value || !dayRef?.current || !monthRef?.current || !yearRef?.current)
      return;

    dayRef.current.value = `${new Date(value)?.getDate()}`.padStart(2, "0");
    monthRef.current.value = `${new Date(value)?.getMonth() + 1}`.padStart(
      2,
      "0"
    );
    yearRef.current.value = `${new Date(value)?.getFullYear()}`;
  });

  const handlefocus = (e) => e?.target?.select();

  const Slash = () => (
    <p
      className={classNames("text-xl mx-1 text-hgBlue-500", {
        "text-hgCream-50": darkMode,
      })}
    >
      /
    </p>
  );

  const inputClasses = (customClasses) =>
    classNames(
      "border-0 text-center focus:bg-hgGreen-900 focus:text-hgCream-50 selection:bg-hgGreen-900",
      customClasses
    );

  const wrapperClasses = classNames(
    "relative flex flex-row justify-start items-center  border p-2 caret-transparent relative",
    {
      "border border-red-700": error,
      "bg-hgCream-50": !disabled,
      "bg-gray-200 pointer-events-none": disabled,
      "w-max": small,
    },
    className
  );

  return (
    <div className={wrapperClasses} ref={wrapperRef} title={title}>
      <Input
        type="text"
        placeholder="DD"
        maxLength={2}
        innerRef={dayRef}
        className={inputClasses("w-[2.8rem]")}
        onBlur={handleBlur}
        onChange={(e) => {
          const val = e?.target?.value;
          if (val?.length === 2 && monthRef.current) {
            monthRef.current.focus();
          }
        }}
        onFocus={handlefocus}
        error={error}
        disabled={disabled}
      />
      <Slash />
      <Input
        placeholder="MM"
        onFocus={handlefocus}
        innerRef={monthRef}
        type="text"
        maxLength={3}
        className={inputClasses("w-[2.8rem]")}
        onBlur={handleBlur}
        onChange={(e) => {
          const val = e?.target?.value;
          if (val?.length === 2 && yearRef.current) {
            yearRef.current.focus();
          }
        }}
        error={error}
        disabled={disabled}
      />
      <Slash />
      <Input
        placeholder="YYYY"
        onFocus={handlefocus}
        innerRef={yearRef}
        maxLength={4}
        type="text"
        className={inputClasses("w-[3.2rem]")}
        onBlur={handleBlur}
        onChange={(e) => {
          const val = e?.target?.value;
          if (val?.length === 4 && yearRef.current) {
            return;
          }
        }}
        error={error}
        disabled={disabled}
      />
      {!small && (
        <div
          className="absolute inset-y-0 right-2 flex flex-col items-center justify-center"
          ref={calenderIconRef}
          title={title}
        >
          <AiOutlineCalendar
            className="text-xl hover:text-hgGreen-900 hover:scale-105 cursor-pointer"
            onClick={() => {
              setModalIsOpen((curr) => !curr);
            }}
          />
        </div>
      )}
      {modalIsOpen && (
        <CalenderModal
          wrapper={wrapperRef}
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          onClose={() => setModalIsOpen(false)}
          iconRef={calenderIconRef}
        />
      )}
    </div>
  );
}
export default InputBox;
