import Heading from "../../../Components/Heading";
import Link from "../../../Components/Link";
import FlexCentered from "../../../Containers/FlexCentered";

function DisconnectedSeedLots({ disconnectedSeedLots }) {
  if (!disconnectedSeedLots || disconnectedSeedLots.length === 0) return;

  return (
    <div className="w-full rounded shadow overflow-hidden h-fit ">
      <div className="bg-hgBlue-700 py-4">
        <Heading
          tertiary
          className="text-xl text-hgRed-50 text-center w-full font-light"
        >
          Disconnected Lots
        </Heading>
      </div>
      <FlexCentered col className="gap-y-2 bg-hgRed-100 py-4 px-2">
        {disconnectedSeedLots.map((seedLot) => {
          return (
            <Link
              key={seedLot._id}
              to={`/admin/seed-lots/${seedLot.reference}`}
              className="w-full hover:text-hgBlue-900 hover:scale-[101%]"
            >
              <div className="w-full bg-hgCream-50 py-4 px-2 rounded shadow">
                {seedLot.reference}
              </div>
            </Link>
          );
        })}
      </FlexCentered>
    </div>
  );
}
export default DisconnectedSeedLots;
