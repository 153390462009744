import classNames from "classnames";
import Heading from "../Components/Heading";
import { GiWheat, GiShoppingCart } from "react-icons/gi";
import { GoDeviceDesktop } from "react-icons/go";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import FlexCentered from "../Containers/FlexCentered";

function UserTypeCard({ type, ...props }) {
  let icon;
  if (type === "Grower")
    icon = <GiWheat className="text-hgGreen-900 text-4xl" />;
  if (type === "Merchant")
    icon = <GiShoppingCart className="text-hgRed-500 text-4xl" />;
  if (type === "Admin")
    icon = <GoDeviceDesktop className=" text-gray-400 text-4xl" />;

  const checkIcon = (
    <AiOutlineCheckCircle className="text-hgGreen-900 text-xl" />
  );
  const crossIcon = <AiOutlineCloseCircle className="text-red-900 text-xl" />;

  const features = {
    canBeAdmin: checkIcon,
    tradesSeed:
      type === "Grower" || type === "Merchant" ? checkIcon : crossIcon,
    growsSeed: type === "Grower" ? checkIcon : crossIcon,
  };

  const renderedFeatures = (
    <div className="grid grid-cols-[auto_auto] items-center gap-y-1 gap-x-2 border rounded-lg px-6 py-4 shadow-lg bg-gray-100">
      <div>Grows seed lots:</div>
      <div>{features.growsSeed}</div>
      <div>Trades seed lots:</div>
      <div>{features.tradesSeed}</div>
      <div>May be admin:</div>
      <div>{features.canBeAdmin}</div>
    </div>
  );

  return (
    <FlexCentered
      col
      {...props}
      className={classNames(
        "bg-hgCream-50 px-4 py-12 rounded-lg  hover:scale-[101%] cursor-pointer text-lg"
      )}
    >
      <div>{icon}</div>
      <Heading primary className="mb-8 mt-2 text-[1.6rem] text-hgBlue-600">
        {type}
      </Heading>
      {renderedFeatures}
    </FlexCentered>
  );
}

export default UserTypeCard;
