import { useState, useEffect, Fragment } from "react";
import {
  useGetBusiness,
  useGetOneUser,
  useCreateBusinessLink,
} from "../../../../api";
import { useToastContext } from "../../../../providers/toastContext";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import Page from "../../../../Containers/Page";
import FlexCentered from "../../../../Containers/FlexCentered";
import Address from "../../../../Components/Address";
import Heading from "../../../../Components/Heading";
import P from "../../../../Components/P";
import Button from "../../../../Components/Button";
import classNames from "classnames";
import RenderedErrors from "../../../../Components/RenderedErrors";
import getApiStatusPage from "../../../APIStatusPage";

function LinkToExistingBusiness() {
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});
  const [searchParams] = useSearchParams();
  const businessRef = searchParams.get("businessRef");

  const createBusinessLinkApi = useCreateBusinessLink();

  const getBusinessApi = useGetBusiness({
    businessRef,
    params: {
      fields:
        "tradingName, businessType, CPH_Number, formattedAddress, location, postcode",
    },
  });

  const { userRef } = useParams();
  const getUserApi = useGetOneUser({
    ref: userRef,
    params: {
      fields: "fullName,submittedBusinessDetails,slug,linkedBusiness",
    },
  });

  const businessDetails = getBusinessApi.data?.business;
  const user = getUserApi.data?.user;
  const submittedDetails = user?.submittedBusinessDetails;

  //IF BUSINESS OR USER NOT FOUND - NAVIGATE BACK TO THE USER PAGE
  const navigateTo = useNavigate();
  const { createSuccessToast } = useToastContext();
  useEffect(() => {
    if (createBusinessLinkApi.isSuccess) {
      createSuccessToast("Business link established");
    }
    if (getUserApi.isFetching && getBusinessApi.isFetching) return;
    if (!businessDetails || businessDetails._id !== businessRef) {
      navigateTo(`/admin/users/${userRef}`);
    }
    if (!user || user.slug !== userRef) {
      navigateTo(`/admin/users/${userRef}`);
    }
    if (user?.linkedBusiness) {
      navigateTo(`/admin/users/${userRef}`);
    }
  }, [
    businessDetails,
    businessRef,
    createBusinessLinkApi.isSuccess,
    createSuccessToast,
    getBusinessApi,
    getUserApi,
    navigateTo,
    user,
    userRef,
  ]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getUserApi,
        type: "User",
        dataRequired: true,
      },
      {
        api: getBusinessApi,
        type: "Business",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  const fields = [
    {
      label: "Trading Name",
      fieldName: "tradingName",
      linkData: (data) => data.tradingName,
    },
    {
      label: "BusinessType",
      fieldName: "businessType",
      linkData: (data) => data.businessType,
      proxies: ["CPH_Number"],
    },
    {
      label: "CPH Number",
      fieldName: "CPH_Number",
      linkData: (data) => data.CPH_Number,
      proxies: ["businessType"],
    },
    {
      label: "Address",
      fieldName: "formattedAddress",
      linkData: (data) => <Address formattedAddress={data.formattedAddress} />,
      proxies: ["location", "postcode"],
    },
  ];

  const handleToggleUpdate = (field) => {
    const newFieldsToUpdate = { ...fieldsToUpdate };
    if (newFieldsToUpdate[field.fieldName]) {
      delete newFieldsToUpdate[field.fieldName];
      field?.proxies?.forEach((proxy) => {
        delete newFieldsToUpdate[proxy];
      });
    } else {
      newFieldsToUpdate[field.fieldName] = submittedDetails[field.fieldName];
      field?.proxies?.forEach((proxy) => {
        newFieldsToUpdate[proxy] = submittedDetails[proxy];
      });
    }
    setFieldsToUpdate(newFieldsToUpdate);
  };

  const renderedRows = fields.map((field) => {
    const submittedValue = field.linkData(submittedDetails);
    const businessValue = field.linkData(businessDetails);
    let matched = submittedValue === businessValue;
    if (field.fieldName === "formattedAddress") {
      matched =
        businessDetails.formattedAddress.join("") ===
        submittedDetails.formattedAddress.join("");
    }
    const valueClasses = classNames(
      "border border-t-0 border-l-0 px-2 py-1 select-none",
      {
        "bg-gray-100": matched,
      }
    );
    const updated = !matched && fieldsToUpdate[field.fieldName];

    return (
      <Fragment key={field.label}>
        <div
          className={classNames(
            "border border-t-0 px-2 py-1 bg-gray-700 text-hgCream-50 select-none"
          )}
        >
          {field.label}
        </div>
        <div
          className={classNames(valueClasses, {
            "bg-hgGreen-100  pointer-events-none": updated,
            "cursor-pointer": !updated,
          })}
          onClick={() => handleToggleUpdate(field)}
        >
          {submittedValue}
        </div>
        <div
          className={classNames(valueClasses, {
            "bg-hgGreen-100 pointer-events-none": !updated,
            "cursor-pointer": updated,
          })}
          onClick={() => handleToggleUpdate(field)}
        >
          {businessValue}
        </div>
      </Fragment>
    );
  });

  const handleSubmit = () => {
    createBusinessLinkApi.mutate({
      ref: user._id,
      body: {
        businessRef: businessDetails._id,
        businessUpdates: fieldsToUpdate,
      },
    });
  };

  return (
    <Page title="Link to New Business">
      <FlexCentered col className="pt-12">
        <FlexCentered
          col
          className="bg-hgCream-50 px-[4vw] py-4 rounded shadow-lg"
        >
          <Heading primary className=" mb-4">
            Create Link
          </Heading>
          <P className="mb-2 w-full">
            Select 'Confirm' to link the user to the selected business.
          </P>
          <P className="mb-12  w-full">
            If the details of the selected business differ from those submitted
            by the user, you may select which values you wish to keep.
          </P>
          <div className="grid grid-cols-3">
            <div className="select-none border text-center font-semibold px-2 py-1 bg-gray-50 text-hgCream-50" />
            <div className="select-none border border-l-0 text-center font-semibold px-2 py-1 bg-hgBlue-500 text-hgCream-50">
              Submitted
            </div>
            <div className="select-none border border-l-0 text-center font-semibold px-2 py-1 bg-hgGreen-900 text-hgCream-50">
              Selected Business
            </div>
            {renderedRows}
          </div>
          <Button
            primary
            className="mt-8 mb-4"
            onClick={handleSubmit}
            loading={createBusinessLinkApi.isLoading}
          >
            Confirm
          </Button>
          <RenderedErrors errors={[]} apiError={createBusinessLinkApi.error} />
        </FlexCentered>
      </FlexCentered>
    </Page>
  );
}

export default LinkToExistingBusiness;
