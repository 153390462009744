function getId(id) {
  if (!id) return;
  let numId = "";
  id.split("").forEach((char) => {
    if (Number.isInteger(Number(char))) {
      numId += char;
    } else {
      numId += char.charCodeAt(0);
    }
  });
  return Number(numId);
}

function getMarkerGeoJson(markers) {
  const backgroundFeatures = [];
  const editedFeatures = [];

  markers.forEach((marker) => {
    if (!marker) return;

    const geoJson = {
      type: "Feature",
      id: getId(marker.id),

      geometry: {
        type: "Point",
        coordinates: marker.coords,
      },
      properties: {
        id: marker.id,
        title: marker.title || "",
        description: marker.description || "",
        icon: marker.icon,
        isActive: marker.isActive,
        disablePopUps: marker.disablePopUps || marker.isEditing,
      },
    };
    if (marker.isEditing) {
      editedFeatures.push(geoJson);
    } else {
      backgroundFeatures.push(geoJson);
    }
  });

  const backgroundFeaturesCollection = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:OGC:1.3:CRS84",
      },
    },
    features: backgroundFeatures,
  };

  //NOT IN USE

  const editedFeaturesCollection = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:OGC:1.3:CRS84",
      },
    },
    features: editedFeatures,
  };

  return [backgroundFeaturesCollection, editedFeaturesCollection];
}

export default getMarkerGeoJson;
