import { useForm, Controller } from "react-hook-form";
import businessDetailsSchema from "./schemas/businessDetailsSchema";
import FlexCentered from "../Containers/FlexCentered";
import Button from "../Components/Button";
import Heading from "../Components/Heading";
import Label from "../Components/Label";
import Input from "../Components/Input";
import AddressInput from "./components/AddressInput";
import RenderedErrors from "../Components/RenderedErrors";
import classNames from "classnames";
import Dropdown from "../Components/Dropdown";
import CPHNumberInput from "./components/CPHNumberInput";

function BusinessDetailsForm({ state, type, darkMode, onSubmit, onPrevious }) {
  const options = { defaultValues: state || {} };
  if (type) {
    options.defaultValues.businessType = { label: type, id: type };
  }

  const form = useForm(options);
  const errors = form.formState.errors;

  form.watch("businessType");

  const { register } = form;
  const schema = businessDetailsSchema();

  const tradingNameProps = register("tradingName", schema.tradingName);
  register("address", schema.postcode);

  const labelClasses = (className) =>
    classNames(className, {
      "text-hgCream-50": darkMode,
    });

  const dropDownConfig = {
    data: [
      { label: "Grower", id: "Grower" },
      { label: "Merchant", id: "Merchant" },
    ],
  };
  const isGrower = form.getValues("businessType")?.label === "Grower";

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} noValidate className="pb-20">
      <FlexCentered col>
        {!type && (
          <>
            <Label htmlFor="businessType" className={labelClasses("mb-2")}>
              Business Type
            </Label>
            <Controller
              control={form.control}
              name="businessType"
              rules={schema.businessType}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Dropdown
                  className="w-[20rem] max-w-full mb-6"
                  config={dropDownConfig}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={errors.businessType}
                />
              )}
            />
          </>
        )}
        <Label htmlFor="tradingName" className={labelClasses("mb-2")}>
          Trading Name
        </Label>
        <Input
          type="text"
          id="tradingName"
          className="w-[20rem] max-w-full mb-4"
          {...tradingNameProps}
          ref={null}
          innerRef={tradingNameProps.ref}
          error={errors.tradingName}
        />
        {isGrower && (
          <>
            <Label htmlFor="businessType" className={labelClasses("mb-2")}>
              CPH Number
            </Label>
            <Controller
              control={form.control}
              name="CPH_Number"
              rules={schema.CPH_Number}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CPHNumberInput
                  className="w-[20rem] max-w-full mb-4"
                  darkMode={darkMode}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={errors.CPH_Number}
                />
              )}
            />
          </>
        )}
        <Heading
          secondary
          className={classNames(" pt-12 text-2xl mb-8", {
            "text-hgCream-50": darkMode,
          })}
        >
          Business Address
        </Heading>
        <Controller
          control={form.control}
          name="address"
          rules={schema.address}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <AddressInput
              className="w-[20rem] max-w-full mb-12"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={errors.businessType}
              darkMode={darkMode}
            />
          )}
        />

        <FlexCentered row className="gap-2">
          {onPrevious && (
            <Button
              primary
              onClick={(e) => {
                const data = form.getValues();
                onPrevious(e, data);
              }}
            >
              Previous
            </Button>
          )}

          <Button primary>Next</Button>
        </FlexCentered>

        {form.formState.isSubmitted && (
          <RenderedErrors errors={errors} className="bg-hgCream-50" />
        )}
      </FlexCentered>
    </form>
  );
}
export default BusinessDetailsForm;
