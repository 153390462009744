function getProductDataConfig() {
  return [
    {
      field: "moistureContent",
      label: "Moisture Content",
      units: "%",
      low: 12,
      high: 20,
    },
    {
      field: "protein",
      label: "Protein",
      units: "%",
      low: 8,
      high: 15,
    },
    {
      field: "hagberg",
      label: "Hagberg",
      low: 150,
      high: 400,
    },
    {
      field: "gluten",
      label: "Gluten",
      units: "%",
      low: 8,
      high: 17,
    },
    {
      field: "specificWeight",
      label: "Specific Weight",
      units: "g/l",
      low: 65,
      high: 85,
    },
    {
      field: "germinationRate",
      label: "Germination Rate",
      units: "%",
      low: 80,
      high: 100,
    },
    {
      field: "thousandGrainWeightGrams",
      label: "1,000 Grain Weight",
      units: "g",
      low: 30,
      high: 60,
    },
    {
      field: "DON",
      label: "DON",
      units: "ppb",
      low: 0,
      high: 1250,
    },
    {
      field: "ZON",
      label: "ZON",
      units: "ppb",
      low: 0,
      high: 100,
    },
    {
      field: "septoria",
      label: "Septoria",
      units: "%",
      low: 0,
      high: 10,
    },
    {
      field: "bunt",
      label: "Bunt",
      units: "sps",
      low: 0.99,
      high: 1,
    },
  ];
}
export default getProductDataConfig;
