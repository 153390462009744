import { useGetCurrentUser, useGetBusiness } from "../../../api";
import Page from "../../../Containers/Page";
import ViewBusiness from "../../../Sections/ViewBusiness.js";
import getApiStatusPage from "../../APIStatusPage";

function BusinessProfilePage() {
  const getCurrentUserApi = useGetCurrentUser();
  const currentUser = getCurrentUserApi?.data?.user;
  const getBusinessApi = useGetBusiness({
    businessRef: currentUser?.linkedBusiness,
    params: {
      fields:
        "tradingName,formattedAddress,businessType,location,logo,about,website,email,reference,CPH_Number",
    },
  });
  const business = getBusinessApi?.data?.business;
  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getBusinessApi,
        type: "Business",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Business Profile">
      <ViewBusiness business={business} editLink="/dashboard/business/edit" />
    </Page>
  );
}

export default BusinessProfilePage;
