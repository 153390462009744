import { useForm } from "react-hook-form";
import { useRegister } from "../api";
import userDetailsSchema from "./schemas/userDetailsSchema";
import FlexCentered from "../Containers/FlexCentered";
import Button from "../Components/Button";
import Label from "../Components/Label";
import Input from "../Components/Input";
import RenderedErrors from "../Components/RenderedErrors";

function RegistrationForm({ state, onSubmit, onNext, onPrevious }) {
  const registerAdminApi = useRegister();

  let options;
  if (state) {
    options = { defaultValues: state };
  }

  const form = useForm(options);
  const errors = form.formState.errors;
  let apiError = registerAdminApi.error;

  const { register } = form;
  const schema = userDetailsSchema();

  const givenNameProps = register("givenName", schema.givenName);
  const familyNameProps = register("familyName", schema.familyName);
  const emailProps = register("email", schema.email);
  const passwordProps = register("password", schema.password);
  const confirmPasswordProps = register(
    "confirmPassword",
    schema.confirmPassword
  );

  const handleSubmit = (data) => {
    registerAdminApi.mutate({ body: data });
  };

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit || handleSubmit)}
      noValidate
      className="pb-20"
    >
      <FlexCentered col>
        <Label htmlFor="givenName" className="mb-2 text-hgCream-50">
          Given Name
        </Label>
        <Input
          type="text"
          id="givenName"
          className="w-[20rem] max-w-full mb-4"
          {...givenNameProps}
          ref={null}
          innerRef={givenNameProps.ref}
          error={errors.givenName}
        />
        <Label htmlFor="familyName" className="mb-2 text-hgCream-50">
          Family Name
        </Label>
        <Input
          type="text"
          id="familyName"
          className="w-[20rem] max-w-full mb-8"
          {...familyNameProps}
          ref={null}
          innerRef={familyNameProps.ref}
          error={errors.familyName}
        />
        <Label htmlFor="email" className="mb-2 text-hgCream-50">
          Email
        </Label>
        <Input
          type="email"
          id="email"
          className="w-[20rem] max-w-full mb-8"
          {...emailProps}
          ref={null}
          innerRef={emailProps.ref}
          error={errors.email}
        />

        <Label htmlFor="password" className="mb-2 text-hgCream-50">
          Password
        </Label>
        <Input
          type="password"
          id="password"
          className="w-[20rem] max-w-full mb-4"
          {...passwordProps}
          ref={null}
          innerRef={passwordProps.ref}
          error={errors.password}
        />

        <Label htmlFor="confirmPassword" className="mb-2 text-hgCream-50">
          Confirm Password
        </Label>
        <Input
          type="password"
          id="confirmPassword"
          className="w-[20rem] max-w-full mb-8"
          {...confirmPasswordProps}
          ref={null}
          innerRef={confirmPasswordProps.ref}
          error={errors.confirmPassword}
        />

        {!onSubmit && (
          <Button primary type="submit" loading={registerAdminApi.isLoading}>
            Register
          </Button>
        )}
        {onSubmit && (
          <FlexCentered row>
            {onPrevious && (
              <Button primary onClick={onPrevious}>
                Previous
              </Button>
            )}

            <Button primary>Next</Button>
          </FlexCentered>
        )}

        {form.formState.isSubmitted && (
          <RenderedErrors
            errors={errors}
            apiError={apiError}
            className="bg-hgCream-50"
          />
        )}
      </FlexCentered>
    </form>
  );
}

export default RegistrationForm;
