import classNames from "classnames";
import Heading from "../../Components/Heading";
import Link from "../../Components/Link";
import { useProductListContext } from "./ProductListContext";
import Dropdown from "../../Components/Dropdown";
import { useState, useEffect } from "react";

function SideBarFilter({ className }) {
  const { query, setQuery, populations, populationsObj } =
    useProductListContext();
  const [population, setPopulation] = useState();

  useEffect(() => {
    if (!query.population) {
      if (population) setPopulation(null);
    } else {
      if (query.population !== population?._id?.toString()) {
        setPopulation(populationsObj[query.population]);
      }
    }
  }, [population, populationsObj, query.population]);

  const statusOptions = ["Any", false, true];
  const renderedStatusOptions = statusOptions.map((option) => {
    return (
      <Link
        key={`status-${option.toString()}`}
        className={classNames("text-hgBlue-500 w-max", {
          "font-semibold pointer-events-none":
            query?.isArchived === option ||
            (query?.isArchived === undefined && option === "Any"),
        })}
        onClick={() => {
          if (option === "Any") {
            return setQuery.unsetFilter({
              fieldName: "isArchived",
              operator: "eq",
            });
          }
          setQuery.filter(
            {
              fieldName: "isArchived",
              label: "isArchived",
              type: "Bool",
            },
            "Equals",
            option
          );
        }}
      >
        {option === true && "Archived"}
        {option === false && "Active"}
        {option === "Any" && "Any"}
      </Link>
    );
  });

  const completenessOptions = ["Any", "None", "Partial", "Complete"];
  const getCompletenessOptions = (field) => {
    return completenessOptions.map((option) => {
      return (
        <Link
          key={`${field}-${option}`}
          className={classNames("text-hgBlue-500 w-max", {
            "font-semibold pointer-events-none":
              query[field] === option ||
              (query[field] === undefined && option === "Any"),
          })}
          onClick={() => {
            if (option === "Any") {
              return setQuery.unsetFilter({
                fieldName: field,
                operator: "eq",
              });
            }
            setQuery.filter(
              {
                fieldName: field,
                label: field,
                type: "String",
              },
              "Equals",
              option
            );
          }}
        >
          {option}
        </Link>
      );
    });
  };
  const growingDataCompletenessOptions =
    getCompletenessOptions("growingDataStatus");

  const productDataCompletenessOptions =
    getCompletenessOptions("productDataStatus");

  return (
    <div className={className}>
      <Heading tertiary className="text-lg text-hgBlue-500 mb-1">
        Population
      </Heading>
      <div className="border-b w-full border-hgBlue-200 mb-2" />
      <Dropdown
        className="w-full"
        value={population}
        onChange={(val) => {
          if (val) {
            setQuery.filter(
              {
                fieldName: "population",
                label: "population",
                type: "String",
              },
              "Equals",
              val?._id
            );
          } else {
            setQuery.unsetFilter({
              fieldName: "population",
              operator: "eq",
            });
          }
        }}
        placeholder="Any"
        config={{
          data: populations,
          getLabel: (population) => population.name,
        }}
      />
      <Heading tertiary className="text-lg text-hgBlue-500 mb-1 mt-8 ">
        Status
      </Heading>
      <div className="border-b w-full border-hgBlue-200 mb-2" />
      <div className="flex flex-col justify-center">
        {renderedStatusOptions}
      </div>
      <Heading tertiary className="text-lg text-hgBlue-500 mb-1 mt-8 ">
        Growing Data
      </Heading>
      <div className="border-b w-full border-hgBlue-200 mb-2" />
      <div className="flex flex-col justify-center">
        {growingDataCompletenessOptions}
      </div>
      <Heading tertiary className="text-lg text-hgBlue-500 mb-1 mt-8 ">
        Product Data
      </Heading>
      <div className="border-b w-full border-hgBlue-200 mb-2" />
      <div className="flex flex-col justify-center">
        {productDataCompletenessOptions}
      </div>
    </div>
  );
}
export default SideBarFilter;
