import { useState, useRef, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import Input from "./Input";

function Dropdown({
  value,
  onChange,
  config,
  className,
  error,
  required,
  disabled,
  innerRef,
  maxHeight,
  modalId,
  ...props
}) {
  //State to determine if dropdown is open, and where a filter is applied,
  //the searchterm value
  const [isOpen, setIsOpen] = useState(false);
  const [searchterm, setSearchTerm] = useState(false);

  const isFilteredDropdown = config?.options?.filter ?? false;
  const disallowEmptyValues = config?.options?.disallowEmptyValues ?? false;

  /* 
  Listen for clicks outside of the input element
  Where an external click is heard close the dropdown
  */
  const inputRef = useRef(null);
  useEffect(() => {
    if (!inputRef.current || !isOpen) return;
    const handleClick = (e) => {
      if (!inputRef.current?.contains(e.target)) {
        setIsOpen(false);
        setSearchTerm("");
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  //Close dropdown on reszie
  useEffect(() => {
    if (!isOpen) return;
    const handleResize = (e) => setIsOpen(false);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  //PSEUDO FIELDS
  const emptyItem = useMemo(() => {
    return {
      id: "emptyItem",
      label: "-",
    };
  }, []);

  const defaultField = useMemo(() => {
    if (config?.defaultField) return config.defaultField;
    return undefined;
  }, [config?.defaultField]);

  //Get dropdown items
  let dropdownItems = [];
  if (config?.data) {
    dropdownItems = [...config.data];
  }

  //Where filter option set and search term entered, filter dropdown
  if (isFilteredDropdown && searchterm) {
    dropdownItems = dropdownItems.filter((item) => {
      const label = item.label || config?.getLabel(item);
      return label.toLowerCase().includes(searchterm.toLowerCase());
    });
  }

  //Allow an empty value unless expressly
  //disallowed
  if (!disallowEmptyValues) {
    dropdownItems.unshift(emptyItem);
  }

  if (defaultField) {
    dropdownItems.push(defaultField);
  }

  const handleSelection = (item) => {
    if (item?.id === "emptyItem") return onChange(null);
    onChange(item);
  };

  //HANDLE INPUT BUTTON CLICK - FUNCTIONALITY DEPENDS WHETHER THE DROPDOWN
  //IS FILTERED
  const handleInputClick = () => {
    //If the dropdown is not filtered, simply toggle
    // whether the dropdown is visible
    if (!isFilteredDropdown) {
      setIsOpen((curr) => !curr);
    }
    //If the dropdown is filtered, unset the value and searchterm
    if (isFilteredDropdown) {
      if (!isOpen) {
        setSearchTerm("");
        onChange(undefined);
        setIsOpen(true);
      }
    }
  };

  //RENDER DROPDOWN LIST ICONS
  let renderedItems;
  renderedItems = dropdownItems.map((item) => {
    const itemKey = item.id ?? item._id ?? config.getKey(item);

    const dropDownClasses = classNames(
      "cursor-pointer border-b-[1px] border-gray-100 px-2 truncate ",
      {
        "text-transparent": item.id === "emptyItem",
        "odd:bg-hgBlue-100 even:bg-hgBlue-50 hover:bg-hgGreen-700":
          item.style !== "danger" && item.style !== "cta",
        "bg-hgRed-700 hover:bg-hgRed-900 text-hgCream-50":
          item.style === "danger",
        "bg-hgBlue-300 hover:bg-hgBlue-500 text-hgCream-50":
          item.style === "cta",
      }
    );
    return (
      <div
        key={itemKey}
        className={dropDownClasses}
        onClick={() => handleSelection(item)}
        title={item.label ?? config.getLabel(item)}
      >
        {item.label ?? config.getLabel(item)}
      </div>
    );
  });

  //RENDER THE DROPDOWN WHERE ISOPEN === TRUE
  //USED CREATE PORTAL TO PREVENT OVERFLOW IN THE CONTAINING DIV
  let renderedDropdown;
  const root = document.getElementById("modal");
  let inputDimensions;
  if (isOpen && (innerRef?.current || inputRef?.current)) {
    const ref = innerRef?.current || inputRef?.current;

    inputDimensions = ref.getBoundingClientRect();
    const scroll = window.scrollY;

    renderedDropdown = createPortal(
      <div
        id={modalId}
        className={classNames(
          "absolute bg-white top-100  shadow overflow-auto z-50",
          { "max-h-56": !maxHeight },
          maxHeight
        )}
        style={{
          top: `${inputDimensions.bottom + scroll}px`,
          left: `${inputDimensions.left}px`,
          width: `${inputDimensions.width}px`,
        }}
      >
        {renderedItems}
      </div>,
      root
    );
  }

  //RETURN THE DROPDOWN COMPONENT
  return (
    <div className="relative w-full">
      <Input
        {...props}
        error={error}
        required={required === true}
        type="text"
        disabled={disabled}
        value={
          value?.label ||
          (value && config.getLabel ? config.getLabel(value) : undefined) ||
          searchterm ||
          ""
        }
        onChange={(e) => {
          if (!config?.options?.filter) return;
          setSearchTerm(e?.target.value);
        }}
        className={classNames(
          "focus:border-main active:border-main focus:ring-transparent",
          {
            "caret-transparent cursor-pointer": !isFilteredDropdown,
            "text-transparent": value?.id === "emptyItem",
          },
          className
        )}
        onClick={handleInputClick}
        onFocus={() => {}}
        innerRef={innerRef || inputRef}
      />
      {renderedDropdown}
    </div>
  );
}
export default Dropdown;
