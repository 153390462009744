import { useRef, useState, useEffect } from "react";
import { useGetAllPopulations, useGetSeedLot } from "../../../api";
import { useNavigate } from "react-router-dom";
import Heading from "../../../Components/Heading";
import PopulationSlider from "./PopulationSlider";
import Button from "../../../Components/Button";

function Explore() {
  const inputRef = useRef(null);
  const [seedLotRef, setSeedLotRef] = useState(null);
  const [error, setError] = useState("");

  const getSeedLotApi = useGetSeedLot({
    seedLotRef: seedLotRef,
    retry: 0,
    params: {
      fields:
        "reference,population,parentLot,parentProduct,isInitialLot,date,quantityKg,isArchived,issuedBy,receivedBy",
      "populate[receivedBy]": "tradingName,business_Reference",
      "populate[issuedBy]": "tradingName,business_Reference",
      "populate[population]": "name",
      "populate[parentLot]": "reference",
      "populate[parentProduct]":
        "reference,DON,ZON,bunt,germinationRate,gluten,hagberg,moistureContent,protein,septoria,specificWeight,thousandGrainWeightGrams",
      includeLineageData: true,
    },
  });

  const navigateTo = useNavigate();
  useEffect(() => {
    if (getSeedLotApi?.error) {
      setError(getSeedLotApi.error);
    }
    if (getSeedLotApi?.data?.seedLot?.reference) {
      navigateTo(
        `/seed-lot-view?ref=${getSeedLotApi?.data?.seedLot?.reference}`
      );
    }
  }, [getSeedLotApi?.data, getSeedLotApi.error, navigateTo]);

  const getAllPopulationsApi = useGetAllPopulations({
    params: {
      completeList: true,
      fields: "name,shortDesc,image,reference",
    },
  });
  const populations = getAllPopulationsApi?.data?.populations;

  const handleFindLot = () => {
    if (!inputRef?.current) return;
    const value = inputRef.current.value;
    if (!value) setError("Enter a seed lot reference");
    if (value) setSeedLotRef(value);
  };

  return (
    <div>
      <div className="flex flex-col items-center min-h-[100vh]">
        <div className="mt-[10vh]">
          <Heading
            secondary
            className="text-base sm:text-2xl md:text-3xl font-light text-center text-hgGreen-1100"
          >
            Find a Seed Lot
          </Heading>
          <div className="w-fit rounded overflow-hidden shadow mt-4 flex flex-row">
            <input
              ref={inputRef}
              className="w-[15rem] md:w-[20rem] py-4 outline-none text-center"
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              placeholder="Reference"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleFindLot();
                }
              }}
            />
            <Button
              primary
              className="px-4 border-0 bg-hgGreen-700 text-hgCream-50 hover:bg-opacity-70"
              onClick={handleFindLot}
              loading={getSeedLotApi.isFetching}
            >
              Go
            </Button>
          </div>
          <div className="text-red-900 mt-2">{error}</div>
        </div>

        <div className="mt-[10vh] bg-hgCream-50 border rounded shadow overflow-hidden">
          <PopulationSlider populations={populations} />
        </div>
      </div>
    </div>
  );
}
export default Explore;
