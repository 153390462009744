import { useEffect, useState } from "react";
import { useGetCurrentUser } from "../api/index";
import { Routes, Route, useNavigate } from "react-router-dom";
import Navigation from "../Sections/Navigation";
import Home from "../Pages/public/Home";
import SignUp from "../Pages/auth/SignUp";
import RegistrationPage from "../Pages/auth/RegistrationPage";
import ProfilePage from "../Pages/auth/ProfilePage";
import NotFound from "../Pages/NotFound";
import BusinessRouter from "./BusinessRouter";
import AdminRouter from "./AdminRouter";
import ForgotPassword from "../Pages/auth/ForgotPassword";
import ResetPassword from "../Pages/auth/ResetPassword";
import Footer from "../Sections/Footer";
import PowerlessUserDash from "../Pages/powerlessUserDash";
import ConfirmEmailRouter from "./ConfirmEmailRouter";
import PopulationPage from "../Pages/public/PopulationPage";
import PopulationsPage from "../Pages/public/PopulationsPage";
import SeedLotPage from "../Pages/public/SeedLotPage";
import BusinessPage from "../Pages/public/BusinessPage";
import FamilyTreePage from "../Pages/public/FamilyTreePage";

function PublicRouter() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const currentUserAPI = useGetCurrentUser();
  const currentUser = currentUserAPI.data;

  //HANDLE REDIRECTS WHERE USER LOGS IN OR OUT
  //Looks at the current logged-in state - A state change
  //from true or false value will trigger a redirect
  const navigateTo = useNavigate();
  useEffect(() => {
    if (currentUser) {
      if (isLoggedIn === false && currentUser.isLoggedIn) {
        if (!currentUser.emailConfirmed) {
          navigateTo("/dashboard");
        } else navigateTo("/");
      }
      if (isLoggedIn === true && !currentUser.isLoggedIn) {
        navigateTo("/");
      }
      setIsLoggedIn(currentUser.isLoggedIn);
    }
  }, [currentUser, isLoggedIn, navigateTo]);

  if (!currentUser || currentUserAPI.isLoading) return <div>Loading</div>;

  return (
    <>
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/sign-up" element={<SignUp />} />
        <Route path="/registration-form/*" element={<RegistrationPage />} />
        <Route exact path="/profile" element={<ProfilePage />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/populations" element={<PopulationsPage />} />
        <Route
          exact
          path="/populations/:populationRef"
          element={<PopulationPage />}
        />
        <Route
          exact
          path="/populations/:populationRef/family-tree"
          element={<FamilyTreePage />}
        />
        <Route exact path="/seed-lot-view" element={<SeedLotPage />} />
        <Route exact path="/business-view" element={<BusinessPage />} />

        {currentUser.isLoggedIn && !currentUser.emailConfirmed && (
          <Route exact path="/dashboard/*" element={<ConfirmEmailRouter />} />
        )}
        {currentUser.isLoggedIn &&
          currentUser.emailConfirmed &&
          ((!currentUser.hasLinkedBusiness && !currentUser.isAdmin) ||
            currentUser.restrictedAccess) && (
            <Route exact path="/dashboard/*" element={<PowerlessUserDash />} />
          )}

        {currentUser.isLoggedIn &&
          currentUser.emailConfirmed &&
          currentUser.hasLinkedBusiness &&
          !currentUser.restrictedAccess && (
            <Route exact path="/dashboard/*" element={<BusinessRouter />} />
          )}
        {currentUser.isLoggedIn &&
          currentUser.isAdmin &&
          currentUser.emailConfirmed &&
          !currentUser.restrictedAccess && (
            <Route exact path="/admin/*" element={<AdminRouter />} />
          )}
        <Route path="/*" element={<NotFound redirect="/" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default PublicRouter;
