import { useGetCurrentUser } from "../api/index";
import { Routes, Route } from "react-router-dom";
import AdminDash from "../Pages/admin/AdminDash";
import UsersTablePage from "../Pages/admin/admin-users/UsersTablePage";
import UserPage from "../Pages/admin/admin-users/UserPage";
import CreateBusinessLink from "../Pages/admin/admin-users/CreateBusinessLink";
import BusinessesTablePage from "../Pages/admin/admin-businesses/BusinessesTablePage";
import AddBusinessPage from "../Pages/admin/admin-businesses/AddBusinessPage";
import NotFound from "../Pages/NotFound";
import LinkToNewBusiness from "../Pages/admin/admin-users/CreateBusinessLink/LinkToNewBusiness";
import LinkToExistingBusiness from "../Pages/admin/admin-users/CreateBusinessLink/LinkToExistingBusiness";
import UserPermissionsPage from "../Pages/admin/admin-users/UserPermissionsPage";
import PopulationsTablePage from "../Pages/admin/admin-populations/PopulationsTablePage";
import AddPopulationPage from "../Pages/admin/admin-populations/AddPopulationPage";
import CropTypesPage from "../Pages/admin/admin-populations/CropTypesPage";
import PopulationPage from "../Pages/admin/admin-populations/PopulationPage";
import FamilyTreePage from "../Pages/admin/admin-populations/FamilyTreePage";
import SeedLotPage from "../Pages/admin/admin-seedLots/SeedLotPage";
import SeedLotsTablePage from "../Pages/admin/admin-seedLots/SeedLotsTablePage";
import EditSeedLotPage from "../Pages/admin/admin-seedLots/EditSeedLotPage";
import AddSeedLotPage from "../Pages/admin/admin-seedLots/AddSeedLotPage";
import BusinessProfilePage from "../Pages/admin/admin-businesses/BusinessPage";
import EditBusinessPage from "../Pages/admin/admin-businesses/EditBusinessPage";
import EditPopulationPage from "../Pages/admin/admin-populations/EditPopulationPage";

function AdminRouter() {
  const currentUserAPI = useGetCurrentUser();
  const currentUser = currentUserAPI.data;
  const permissions = currentUser?.permissions;

  if (!currentUser) return <div>Loading</div>;
  if (!currentUser.emailConfirmed) return;

  return (
    <>
      <Routes>
        {/*ADMIN HOME*/}
        <Route exact path="/" element={<AdminDash />} />

        {/*USER READ ROUTES*/}
        <Route exact path="/users" element={<UsersTablePage />} />
        <Route exact path="/users/:userRef" element={<UserPage />} />

        {/*USER WRITE ROUTES*/}
        {permissions.writePermissions && (
          <Route
            exact
            path="/users/:userRef/edit-permissions"
            element={<UserPermissionsPage />}
          />
        )}
        {permissions.writeLinkedBusiness && (
          <>
            <Route
              exact
              path="/users/:userRef/create-business-link"
              element={<CreateBusinessLink />}
            />
            <Route
              exact
              path="/users/:userRef/create-business-link/new-business"
              element={<LinkToNewBusiness />}
            />
            <Route
              exact
              path="/users/:userRef/create-business-link/existing-business"
              element={<LinkToExistingBusiness />}
            />
          </>
        )}

        {/*BUSINESS READ ROUTES*/}
        <Route exact path="/businesses" element={<BusinessesTablePage />} />
        <Route
          exact
          path="/businesses/:businessRef"
          element={<BusinessProfilePage />}
        />
        {/*BUSINESS WRITE ROUTES*/}
        {permissions.writeBusiness && (
          <>
            <Route
              path="/businesses/add-business/*"
              element={<AddBusinessPage />}
            />
            <Route
              exact
              path="/businesses/:businessRef/edit"
              element={<EditBusinessPage />}
            />
          </>
        )}

        {/*POPULATION READ ROUTES*/}
        <Route exact path="/populations" element={<PopulationsTablePage />} />
        <Route
          exact
          path="/populations/:populationRef"
          element={<PopulationPage />}
        />
        <Route
          path="/populations/:populationRef/family-tree"
          element={<FamilyTreePage />}
        />
        {/*POPULATION WRITE ROUTES*/}
        {permissions.writePopulations && (
          <>
            <Route
              exact
              path="/populations/add-population"
              element={<AddPopulationPage />}
            />
            <Route
              exact
              path="/populations/crop-types"
              element={<CropTypesPage />}
            />
            <Route
              exact
              path="/populations/:populationRef/edit"
              element={<EditPopulationPage />}
            />
          </>
        )}

        {/*SEED LOT READ ROUTES*/}
        <Route exact path="/seed-lots" element={<SeedLotsTablePage />} />
        <Route exact path="/seed-lots/:seedLotRef" element={<SeedLotPage />} />
        {/*SEED LOT WRTIE ROUTES*/}
        {permissions.writeSeedLots && (
          <>
            <Route
              exact
              path="/seed-lots/:seedLotRef/edit-lot"
              element={<EditSeedLotPage />}
            />
            <Route
              exact
              path="/seed-lots/add-seed-lot"
              element={<AddSeedLotPage />}
            />
          </>
        )}

        <Route path="/*" element={<NotFound redirect="/" />} />
      </Routes>
    </>
  );
}

export default AdminRouter;
