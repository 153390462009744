import { BsChevronLeft, BsChevronDown } from "react-icons/bs";
import { useState } from "react";
import Heading from "../../../../Components/Heading";
import Link from "../../../../Components/Link";
import Button from "../../../../Components/Button";
import FlexCentered from "../../../../Containers/FlexCentered";

function OverviewData({ product }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="max-w-fit">
      <div
        className="flex flex-row items-center gap-x-2 cursor-pointer hover:text-hgGreen-900"
        onClick={() => setIsOpen((curr) => !curr)}
      >
        <Heading secondary className="text-xl font-light text-hgBlue-900">
          General
        </Heading>

        {isOpen && <BsChevronDown />}
        {!isOpen && <BsChevronLeft />}
      </div>
      <div className="pt-1 border-b-[1px] border-hgGreen-900 pr-[4vw]" />
      {isOpen && (
        <div>
          <FlexCentered col className="my-2 mt-8 gap-y-4">
            <div className="grid grid-cols-[auto_auto] gap-x-2">
              <div>Reference:</div>
              <div>{product.reference}</div>
              <div>Archived:</div>
              <div>{product.isArchived ? "Yes" : "No"}</div>
            </div>
          </FlexCentered>

          <Link to={`/dashboard/products/${product.reference}/edit-general`}>
            <Button secondary outline className="mt-4">
              Edit
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}
export default OverviewData;
