function getDashboardLinks(currentUser) {
  if (!currentUser.emailConfirmed || currentUser.restrictedAccess) return [];

  const isGrower = currentUser?.linkedBusinessType === "Grower";

  const dashboardLinks = [
    {
      to: "/dashboard/business",
      label: "Business",
      children: isGrower
        ? [
            {
              to: "/dashboard/business/fields",
              label: "Field Record",
            },
          ]
        : undefined,
    },
    {
      to: "/dashboard/products",
      label: "Products",
    },
    {
      to: "/dashboard/seed-lots",
      label: "Seed Lots",
    },
  ];

  return dashboardLinks;
}
export default getDashboardLinks;
