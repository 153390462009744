import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useCreateProduct() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: "/products",
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["products"]);
      queryClient.invalidateQueries(["seedLot"]);
    },
  });

  return queryApi;
}

function useGetProduct({ productRef, params, refetchOnWindowFocus = true }) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    enabled: !!productRef,
    queryKey: ["product", `product${productRef}`],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/products/${productRef}`,
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useGetAllProducts({ params, refetchOnWindowFocus = true }) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    queryKey: ["products"],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/products`,
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useUpdateProduct(invalidateQueries = true) {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ productRef, body }) =>
      request(
        {
          req: {
            url: `/products/${productRef}`,
            method: "PATCH",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      if (!invalidateQueries) return;
      queryClient.invalidateQueries(["products"]);
      queryClient.invalidateQueries(["product"]);
      queryClient.invalidateQueries(["seedLot"]);
    },
  });

  return queryApi;
}

export { useCreateProduct, useGetProduct, useUpdateProduct, useGetAllProducts };
