import classNames from "classnames";

function Heading({
  primary,
  secondary,
  tertiary,
  children,
  className,
  ...props
}) {
  if (primary) {
    return (
      <h1
        className={classNames(
          "",
          {
            "font-semibold tracking-wide": !(className || "").includes("font-"),
            "text-hgBlue-700 text-xl": !(className || "").includes("text-"),
          },
          className
        )}
        {...props}
      >
        {children}
      </h1>
    );
  }

  if (secondary) {
    return (
      <h2
        className={classNames(
          "",
          {
            "font-semibold tracking-wide": !(className || "").includes("font-"),
            "text-hgBlue-700 text-lg": !(className || "").includes("text-"),
          },
          className
        )}
        {...props}
      >
        {children}
      </h2>
    );
  }

  if (tertiary) {
    return (
      <h3
        className={classNames(
          "",
          {
            "font-semibold tracking-wide": !(className || "").includes("font-"),
            "text-hgBlue-700 text-md": !(className || "").includes("text-"),
          },
          className
        )}
        {...props}
      >
        {children}
      </h3>
    );
  }
}

export default Heading;
