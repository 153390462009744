import ModalWindow from "../Components/ModalWindow";
import Heading from "../Components/Heading";
import CloseButton from "../Components/CloseButton";
import Input from "../Components/Input";
import Button from "../Components/Button";
import FlexCentered from "../Containers/FlexCentered";
import { AiOutlineExclamation } from "react-icons/ai";
import P from "../Components/P";

function RequirePasswordModal({ warning, buttonText, onClose, ...inputProps }) {
  return (
    <ModalWindow options={{ blur: true, pos: { top: 20 } }}>
      <FlexCentered
        col
        className="relative bg-hgCream-50 p-8 rounded-lg border border-hgBlue-900 overflow-y-auto"
      >
        <Heading tertiary className="text-hgBlue-500 text-center mb-4">
          Re-enter your password
        </Heading>
        {warning && (
          <div className="relative mt-2 mb-4 py-4 px-2 max-w-[20rem] bg-hgRed-500 bg-opacity-30">
            <FlexCentered
              col
              className="absolute top-[-5px] left-[-5px] bg-red-900 rounded-full"
            >
              <AiOutlineExclamation className="text-xl text-hgCream-50" />
            </FlexCentered>

            <P className="">{warning}</P>
          </div>
        )}
        <form onSubmit={() => onClose({ submitted: true })}>
          <FlexCentered col>
            <Input
              type="password"
              id="password"
              className="w-[20rem] max-w-full mb-8"
              placeholder="Password"
              {...inputProps}
            />
            <Button primary={!warning} danger={!!warning} type="submit">
              {buttonText || "Go"}
            </Button>
          </FlexCentered>
        </form>
        <CloseButton
          className="absolute top-[5px] right-[5px] bg-hgCream-50 rounded-full p-1 cursor-pointer hover:bg-hgCream-200"
          onClick={onClose}
        />
      </FlexCentered>
    </ModalWindow>
  );
}

export default RequirePasswordModal;
