import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useEditSeedLot } from "../../api";
import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";
import P from "../../Components/P";
import Button from "../../Components/Button";
import Link from "../../Components/Link";
import ProductData from "./ProductData";
import GrowingData from "./GrowingData";
import Lineage from "./Lineage";

function ViewSeedLot({
  seedLot,
  lineageData,
  getSeedLotLink,
  getBusinessLink,
  getPopulationLink,
  canEdit,
  canMakeActive,
}) {
  const editSeedLotApi = useEditSeedLot();

  const navigateTo = useNavigate();
  useEffect(() => {
    if (editSeedLotApi?.data) {
      navigateTo("/dashboard");
    }
  });

  const handleReactivateLot = () => {
    editSeedLotApi.mutate({
      seedLotRef: seedLot._id,
      body: {
        isArchived: false,
      },
    });
  };

  return (
    <FlexCentered col className="pt-6 px-2">
      <FlexCentered row className="gap-4">
        <Heading primary>{seedLot.reference}</Heading>
        {canEdit && (
          <Link to={`${window.location.pathname}/edit-lot`}>
            <Button secondary outline className="py-1 px-2">
              Edit
            </Button>
          </Link>
        )}
      </FlexCentered>
      <Heading secondary className="mb-4">
        Seed Lot
      </Heading>
      <div className="grid grid-cols-[auto_1fr] gap-y-1 gap-x-[4vw] bg-hgGreen-1000 text-hgCream-50  font-semibold border px-[4vw] py-2 shadow">
        <P>Population:</P>
        <Link
          to={getPopulationLink(seedLot?.population?.reference)}
          className="underline hover:text-hgCream-500"
        >
          <P>{seedLot.population?.name}</P>
        </Link>
        <div className="col-span-2 py-1" />
        <P>Date</P>
        <P>{new Date(seedLot?.date).toLocaleDateString("en-gb")}</P>
        <P>Quantity</P>
        <P>{Number(seedLot?.quantityKg).toLocaleString("en-gb")}kg</P>
        <div className="col-span-2 py-1" />
        <P>Issued By</P>
        <Link
          to={getBusinessLink(seedLot.issuedBy?.business_Reference)}
          className="underline hover:text-hgCream-500"
        >
          <P>{seedLot.issuedBy?.tradingName}</P>
        </Link>
        <P>Received By</P>
        <Link
          to={getBusinessLink(seedLot.receivedBy?.business_Reference)}
          className="underline hover:text-hgCream-500"
        >
          <P>{seedLot.receivedBy?.tradingName}</P>
        </Link>
      </div>
      {canMakeActive && seedLot.isArchived && (
        <div className="mt-4 bg-hgYellow-300 p-4">
          <FlexCentered col>
            <P>This seed lot has been archived.</P>
            <Button
              secondary
              className="mt-2"
              onClick={handleReactivateLot}
              loading={editSeedLotApi.isLoading}
            >
              Reactivate
            </Button>
          </FlexCentered>
        </div>
      )}
      <div className="grid lg:grid-cols-2 gap-x-4 w-full mt-20 max-w-5xl">
        <div className="flex flex-col items-center mt-6 lg:mt-0">
          <ProductData productData={seedLot.parentProduct} />
        </div>
        <div className="flex flex-col items-center mt-20 lg:mt-0">
          <GrowingData
            growingData={lineageData?.growingData}
            dataSource={lineageData?.growingDataSource}
            isInherited={
              seedLot?._id?.toString() !==
              lineageData?.growingDataSource?._id?.toString()
            }
            getLink={getSeedLotLink}
          />
        </div>
        <div className="lg:col-span-2 mt-20">
          <div className="mt-8 mb-56">
            <Lineage
              lineageData={lineageData?.lineage}
              getLink={getSeedLotLink}
            />
          </div>
        </div>
      </div>
    </FlexCentered>
  );
}
export default ViewSeedLot;
