import classNames from "classnames";
import { useEffect, useRef } from "react";

function LineageLineCanvas({ lineageLines, width, height, top, left, hidden }) {
  const canvasRef = useRef(null);
  const dpr = Math.ceil(window.devicePixelRatio);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const wrapper = document.querySelector("#canvas-wrapper");
    wrapper.classList.remove("transition-opacity");
    wrapper.classList.remove("duration-300");
    wrapper.style.opacity = "0%";

    canvas.width = width * dpr;
    canvas.height = height * dpr;

    const c = canvas.getContext("2d");
    c.scale(dpr, dpr);

    c.strokeStyle = "rgb(49 51 73)";
    c.lineWidth = 2;

    const drawLineageLine = (lineageLine) => {
      if (!lineageLine) return;
      const [x, y] = lineageLine.parentConnection;

      c.beginPath();
      c.moveTo(Math.floor(x), Math.floor(y));
      c.lineTo(Math.floor(x), Math.floor(y + lineageLine.height / 2));
      c.stroke();
      c.closePath();

      c.beginPath();
      c.moveTo(
        Math.floor(lineageLine.minX),
        Math.floor(y + lineageLine.height / 2)
      );
      c.lineTo(
        Math.floor(lineageLine.maxX),
        Math.floor(y + lineageLine.height / 2)
      );
      c.stroke();
      c.closePath();

      lineageLine.childConnections.forEach((childConnection) => {
        const [x, y] = childConnection;

        if (x > width * 1.5 || x < -width * 1.5) return;
        if (y > height * 1.5 || y < -height * 1.5) return;
        c.beginPath();
        c.moveTo(Math.floor(x), Math.floor(y));
        c.lineTo(Math.floor(x), Math.floor(y + lineageLine.height / 2));
        c.stroke();
        c.closePath();
      });
    };

    const drawLineageLines = () => {
      lineageLines.forEach((line) => {
        drawLineageLine(line);
      });
    };
    if (!hidden) {
      wrapper.classList.add("transition-opacity");
      wrapper.classList.add("duration-300");
      wrapper.style.opacity = "60%";
    }

    drawLineageLines();

    //Draw a card for a given label
  }, [dpr, height, hidden, left, lineageLines, top, width]);

  return (
    <div
      className={classNames("absolute inset-0 pointer-events-none", {})}
      id="canvas-wrapper"
    >
      <canvas
        className="border "
        ref={canvasRef}
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      />
    </div>
  );
}
export default LineageLineCanvas;
