import numberValidator from "../helpers/numberValidator";
import dateValidator from "../helpers/dateValidator";

function seedLotSchema({ product, massBalanceFigures, startingAmount }) {
  return {
    receivedBy: {
      required: "Issued to is required",
    },
    parentLot: {
      required: "Please complete the parent lot field",
    },
    issuedBy: {
      required: "Issued by is required",
    },

    population: {
      required: "Population is required",
    },
    date: {
      required: "Date is required",
      validate: (value, values) => {
        let min;
        let minLabel;
        if (values?.parentLot?.date) {
          min = values.parentLot.date;
          minLabel = "parent lot";
        } else {
          min = product?.seedLot?.date
            ? new Date(product?.seedLot?.date)
            : undefined;
          minLabel = "source lot";
        }
        return dateValidator({
          value,
          label: "Date",
          min,
          minLabel,
          max: new Date(),
          maxLabel: "the current date",
        });
      },
    },
    quantityKg: {
      required: "Quantity is required",
      validate: (value, values) => {
        let max = 99999;
        if (values?.parentLot?.quantityKg) max = values?.parentLot?.quantityKg;

        if (massBalanceFigures?.balance !== undefined)
          max = massBalanceFigures.balance + (startingAmount || 0);

        return numberValidator({
          value,
          label: "Quantity",
          min: 1,
          max,
          units: "kg",
        });
      },
    },
    isOrganic: {},
    controlBody: {
      validate: (value, formValues) => {
        if (formValues?.isOrganic?.id === "organic") {
          return !!value || "Please specify the organic food control body";
        }
      },
    },
  };
}

export default seedLotSchema;
