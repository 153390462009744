import numberValidator from "../helpers/numberValidator";
import dateValidator from "../helpers/dateValidator";

function growingDataSchema(product) {
  return {
    field: {
      required: "Please select a field",
    },
    areaSownHa: {
      required: "Area sown is required",
      validate: (value) => {
        return numberValidator({
          value,
          label: "Area Sown",
          min: 0.1,
          max: 9999,
          decimalPlaces: 1,
          units: "ha",
        });
      },
    },
    qtySownKg: {
      required: "Quantity sown is required",
      validate: (value) => {
        return numberValidator({
          value,
          label: "Quantity Sown",
          min: 1,
          max: 99999,
          units: "kg",
        });
      },
    },
    sowingDate: {
      required: "Sowing date is required",
      validate: (value) => {
        const seedLotDate = product?.seedLot?.date;

        return dateValidator({
          value,
          label: "Sowing date",
          min: new Date(seedLotDate),
          minLabel: "the source seed lot",
          max: new Date(),
          maxLabel: "the current date",
        });
      },
    },
    harvestDate: {},
    totalYieldT: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "Total yield",
          decimalPlaces: 2,
          min: 0.01,
          max: 9999,
          units: "t",
        });
      },
    },
  };
}

export default growingDataSchema;
