import Image from "../../Components/Image";
import Heading from "../../Components/Heading";
import P from "../../Components/P";
import Link from "../../Components/Link";
import classNames from "classnames";

function ProductListItem({ product, link, population, isGrower }) {
  const dataStatusClasses = (status) => {
    return classNames("font-semibold w-fit px-2 py-1 rounded", {
      "text-hgBlue-900 bg-hgBlue-500 bg-opacity-10": status === "None",
      "bg-hgRed-500 text-hgCream-50": status === "Partial",
      "bg-hgGreen-700 text-hgCream-50": status === "Complete",
    });
  };

  return (
    <Link to={link}>
      <div className="w-full bg-hgCream-50 rounded-lg shadow-xl grid grid-cols-[auto_1fr] md:grid-cols-[5rem_8rem_1fr] lg:grid-cols-[6rem_12rem_1fr] gap-x-4 gap-y-2 py-3 px-4 hover:scale-[101%] cursor-pointer select-none">
        <Image
          src={population?.image}
          alt={product.reference}
          className="w-[5rem] h-[5rem] rounded-lg overflow-hidden"
        />
        <div className="flex flex-col justify-center ">
          <div className="bg-hgGreen-900 w-fit px-2 py-1 rounded-xl shadow">
            <Heading tertiary className="text-hgCream-50">
              {product.reference}
            </Heading>
          </div>
          <P className="italic px-1 py-1">{population?.name}</P>
        </div>
        <div className="grid grid-cols-[auto_1fr] items-center gap-x-2 bg-gray-50 shadow-sm rounded-xl px-2 col-span-2 md:col-span-1 ">
          {isGrower && (
            <>
              <P className=" text-hgBlue-600">Growing Data:</P>
              <P className={dataStatusClasses(product.growingDataStatus)}>
                {product.growingDataStatus}
              </P>
            </>
          )}
          <P className=" text-hgBlue-600">Product Data:</P>
          <P className={dataStatusClasses(product.productDataStatus)}>
            {product.productDataStatus}
          </P>
        </div>
      </div>
    </Link>
  );
}

export default ProductListItem;
