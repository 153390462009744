import Page from "../../Containers/Page";
import ResetPasswordForm from "../../Forms/ResetPasswordForm";

function ResetPassword() {
  return (
    <Page title="reset-password" className="bg-hgBlue-500">
      <ResetPasswordForm />
    </Page>
  );
}

export default ResetPassword;
