import { BsFillTrash2Fill } from "react-icons/bs";
import { Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import Label from "../../Components/Label";
import Input from "../../Components/Input";
import DateInput from "../../Components/DateInput";
import Dropdown from "../../Components/Dropdown";
import classNames from "classnames";
import AddFieldModal from "../../Sections/AddFieldModal";
import RenderedErrors from "../../Components/RenderedErrors";

function GrowingDataRow({
  fieldProps,
  sowingDateProps,
  areaSownHaProps,
  qtySownKgProps,
  harvestDateProps,
  totalYieldProps,
  fields,
  form,
  schema,
  onDelete,
  rows,
  i,
  values,
}) {
  const [addFieldFormIsOpen, setAddFieldFormIsOpen] = useState(false);

  useEffect(() => {
    if (values?.field?.label === "Add Field" && !addFieldFormIsOpen) {
      setAddFieldFormIsOpen(true);
    }
  }, [addFieldFormIsOpen, values?.field?.label]);

  if (!form) return;

  const handleCloseAddFieldForm = (newField) => {
    setAddFieldFormIsOpen(false);
    form.setValue(fieldProps.name, newField);
  };

  let errors;
  if (form?.formState?.errors?.growingData) {
    errors = form.formState.errors.growingData[i];
  }

  return (
    <div
      className={classNames(
        "relative grid grid-cols-[auto_1fr] text-sm sm:text-base md:grid-cols-3 gap-x-[4vw] md:gap-x-4 smallgap-y-4 w-max  px-[4vw] max-w-full py-4 rounded shadow-lg",
        {
          "mb-8": i !== rows - 1,
          "bg-hgBlue-600": i % 3 === 0,
          "bg-hgGreen-900": i % 3 === 1,
          "bg-hgRed-900": i % 3 === 2,
        }
      )}
    >
      {addFieldFormIsOpen && (
        <AddFieldModal onClose={handleCloseAddFieldForm} />
      )}
      {rows > 1 && (
        <div
          className="absolute top-[5px] right-[5px] bg-hgCream-50 text-hgBlue-500 hover:text-red-900 p-2 text-xl rounded-full hover:scale-105 cursor-pointer"
          onClick={onDelete}
          title="Delete the selected row"
        >
          <BsFillTrash2Fill />
        </div>
      )}
      <div>
        <Label htmlFor={fieldProps.name} className="text-hgCream-50">
          Field
        </Label>
        <Controller
          control={form.control}
          name={fieldProps.name}
          render={({ field: { onChange, onBlur, value } }) => (
            <Dropdown
              id={fieldProps.name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              className="w-[14rem] max-w-full mt-2 py-[0.75rem]"
              config={{
                data: fields,
                getLabel: (el) => el.name,
                defaultField: {
                  id: "addField",
                  label: "Add Field",
                  style: "cta",
                },
              }}
              error={errors?.type}
              rules={schema.field}
              title="Select a field"
            />
          )}
        />
      </div>
      <div className="flex flex-col ">
        <Label htmlFor={areaSownHaProps.name} className="text-hgCream-50">
          Area Sown (ha)
        </Label>
        <Input
          type="number"
          id={areaSownHaProps.name}
          title="Enter the area sown in hectares"
          className="w-[6rem] md:w-full max-w-full md:max-w-[14rem] max-w-full mt-2 py-[0.75rem]"
          {...areaSownHaProps}
          ref={null}
          innerRef={areaSownHaProps.ref}
          error={errors?.areaSownHa}
          disabled={!values?.field}
        />
      </div>
      <div>
        <Label htmlFor={sowingDateProps.name} className="text-hgCream-50">
          Sowing Date
        </Label>
        <Controller
          control={form.control}
          name={sowingDateProps.name}
          render={({ field: { onChange, onBlur, value } }) => (
            <DateInput
              className="w-[14rem] md:max-w-[14rem] max-w-full mt-2"
              id={sowingDateProps.name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              min={new Date(2010, 0, 1)}
              max={new Date()}
              title="On which date was the seed sown?"
              error={errors?.sowingDate}
              rules={schema.sowingDate}
              disabled={!values?.field}
            />
          )}
        />
      </div>
      <div className="flex flex-col">
        <Label htmlFor={qtySownKgProps.name} className="text-hgCream-50">
          Quantity Sown (kg)
        </Label>
        <Input
          type="number"
          id={qtySownKgProps.name}
          title="Enter the quantity of seed sown in the selected field"
          className="w-[6rem] md:w-full max-w-full md:max-w-[14rem] max-w-full mt-2 py-[0.75rem]"
          {...qtySownKgProps}
          ref={null}
          innerRef={qtySownKgProps.ref}
          error={errors?.qtySownKg}
          disabled={!values?.field}
        />
      </div>
      <div>
        <Label htmlFor={harvestDateProps.name} className="text-hgCream-50">
          Harvest Date
        </Label>
        <Controller
          control={form.control}
          name={harvestDateProps.name}
          render={({ field: { onChange, onBlur, value } }) => (
            <DateInput
              className="w-[14rem] max-w-full mt-2"
              id={harvestDateProps.name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              min={new Date(2010, 0, 1)}
              max={new Date()}
              title="On which date was the crop harvested?"
              error={errors?.harvestDate}
              rules={schema.harvestDate}
              disabled={
                !values?.field ||
                !values?.areaSownHa ||
                !values?.sowingDate ||
                !values?.qtySownKg
              }
            />
          )}
        />
      </div>
      <div className="flex flex-col">
        <Label htmlFor={totalYieldProps.name} className="text-hgCream-50">
          Total Yield (t)
        </Label>
        <Input
          type="number"
          id={totalYieldProps.name}
          title="Enter the total quantity of seed harvested"
          className="w-[6rem] md:w-full max-w-full md:max-w-[14rem] max-w-full mt-2 py-[0.75rem]"
          {...totalYieldProps}
          ref={null}
          innerRef={totalYieldProps.ref}
          error={errors?.totalYield}
          disabled={
            !values?.field ||
            !values?.areaSownHa ||
            !values?.sowingDate ||
            !values?.qtySownKg
          }
        />
      </div>
      <div className="col-span-2 md:col-span-3">
        {errors && <RenderedErrors errors={errors} className="bg-hgCream-50" />}
      </div>
    </div>
  );
}
export default GrowingDataRow;
