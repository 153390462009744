import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useResetPassword } from "../api";

import Button from "../Components/Button";
import Input from "../Components/Input";
import FlexCentered from "../Containers/FlexCentered";
import Heading from "../Components/Heading";
import P from "../Components/P";
import RenderedErrors from "../Components/RenderedErrors";

function ResetPasswordForm() {
  const params = new URL(document.location).searchParams;
  const token = params.get("token");

  const resetPasswordApi = useResetPassword();
  const form = useForm();
  const errors = form.formState.errors;
  let apiError = resetPasswordApi.error;

  const { register } = form;
  const passwordProps = register("password", {
    required: "New password required",
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters",
    },
  });
  const confirmPasswordProps = register("confirmPassword", {
    required: "confirm password required",
    validate: (value, formValues) => {
      return formValues.password === value || "The passwords do not match";
    },
  });

  const handleSubmit = (data) => {
    resetPasswordApi.mutate({ body: data, token });
    form.reset();
  };

  return (
    <>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
        className="pt-[10vh]"
      >
        <FlexCentered col>
          <Heading primary className="text-hgCream-50 mb-6">
            Reset Your Password
          </Heading>
          <Input
            type="password"
            id="password"
            placeholder="New Password"
            className="w-[20rem] max-w-full mb-2"
            {...passwordProps}
            ref={null}
            innerRef={passwordProps.ref}
          />
          <Input
            type="password"
            id="confirmPassword"
            placeholder="Confirm Password"
            className="w-[20rem] max-w-full mb-4"
            {...confirmPasswordProps}
            ref={null}
            innerRef={confirmPasswordProps.ref}
          />
          <Button primary type="submit" loading={resetPasswordApi.isLoading}>
            Set
          </Button>

          {form.formState.isSubmitted && !resetPasswordApi.data && (
            <RenderedErrors
              errors={errors}
              apiError={apiError}
              className="bg-hgCream-50"
            />
          )}
          {resetPasswordApi.data && (
            <FlexCentered row className="gap-2 mt-4 bg-hgCream-50 p-2 rounded">
              <AiOutlineCheckCircle className="text-hgGreen-900" />
              <P>Your password has been reset.</P>
            </FlexCentered>
          )}
        </FlexCentered>
      </form>
      <DevTool control={form.control} />
    </>
  );
}

export default ResetPasswordForm;
