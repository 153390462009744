import { useEffect } from "react";
import { useGetAllSeedLots } from "../../../api";
import useQuery from "../../../hooks/useQuery";
import Page from "../../../Containers/Page";
import Table from "../../../Components/Table/Table";
import TableContext from "../../../Components/Table/TableContext";
import getApiStatusPage from "../../APIStatusPage";

function UsersTablePage() {
  const fields =
    "reference,population,parentLot,isInitialLot,date,receivedBy,issuedBy,isOrganic,controlBody,quantityKg";
  const defaultQuery = {
    fields,
    "populate[population]": "populations.name",
    "populate[issuedBy]": "businesses.tradingName",
    "populate[receivedBy]": "businesses.tradingName",
    "populate[parentLot]": "seedlots.reference",
  };
  const [query, isStale, setIsStale, setQuery, Filter] = useQuery(defaultQuery);

  const getAllSeedLots = useGetAllSeedLots({
    params: query,
  });

  useEffect(() => {
    if (!isStale) {
      getAllSeedLots.refetch();
      setIsStale(true);
    }
  }, [getAllSeedLots, isStale, query, setIsStale]);

  const seedLots = getAllSeedLots?.data?.seedLots;

  const tableConfig = {
    resizeableColumns: { fixed: true },
    movableColumns: true,
    rowLink: (row) => `/admin/seed-lots/${row.reference}`,
    showControls: true,
    showPagination: true,
    columns: [
      {
        label: "Population",
        fieldNames: ["population/name"],
        fieldNamesLabels: ["Population"],
        filterType: "String",
        row: (row) => row.population?.name,
        width: "sm",
        searchable: true,
        visible: true,
      },
      {
        label: "Reference",
        fieldNames: ["reference"],
        fieldNamesLabels: ["Reference"],
        filterType: "String",
        searchFieldNames: ["reference"],
        row: (row) => row.reference,
        width: "sm",
        visible: true,
        searchable: true,
      },
      {
        label: "Date",
        fieldNames: ["date"],
        fieldNamesLabels: ["Date"],
        filterType: "Date",
        row: (row) => new Date(row.date).toLocaleDateString("en-gb"),
        centered: true,
        width: "sm",
        visible: false,
      },
      {
        label: "Qty (kg)",
        fieldNames: ["quantityKg"],
        fieldNamesLabels: ["Quantity"],
        filterType: "Number",
        row: (row) => Number(row.quantityKg).toLocaleString("en-gb"),
        centered: true,
        width: "sm",
        visible: false,
      },
      {
        label: "From",
        fieldNames: ["issuedBy/tradingName"],
        fieldNamesLabels: ["From"],
        filterType: "String",
        row: (row) => row.issuedBy?.tradingName,
        width: "sm",
        searchable: true,
        visible: true,
      },
      {
        label: "To",
        fieldNames: ["receivedBy/tradingName"],
        fieldNamesLabels: ["To"],
        filterType: "String",
        row: (row) => row.receivedBy?.tradingName,
        width: "sm",
        searchable: true,
        visible: true,
      },
      {
        label: "Organic",
        fieldNames: ["isOrganic"],
        fieldNamesLabels: ["Organic"],
        filterType: "Enum",
        options: [
          { label: "True", id: "True", value: true },
          { label: "False", id: "False", value: false },
          { label: "Undefined", id: "Undefined", value: "Undefined" },
        ],
        row: (row) =>
          row.isOrganic ? "Yes" : row.isOrganic === false ? "No" : "-",
        centered: true,
        width: "sm",
        visible: false,
      },
      {
        label: "Control Body",
        fieldNames: ["controlBody"],
        fieldNamesLabels: ["Control Body"],
        options: [
          { label: "GB-ORG-02", id: "GB-ORG-02", value: "GB-ORG-02" },
          { label: "GB-ORG-04", id: "GB-ORG-04", value: "GB-ORG-04" },
          { label: "GB-ORG-05", id: "GB-ORG-05", value: "GB-ORG-05" },
          { label: "GB-ORG-06", id: "GB-ORG-06", value: "GB-ORG-06" },
          { label: "GB-ORG-13", id: "GB-ORG-13", value: "GB-ORG-13" },
          { label: "GB-ORG-17", id: "GB-ORG-17", value: "GB-ORG-17" },
        ],
        filterType: "Enum",
        row: (row) => row.controlBody || "-",
        centered: true,
        width: "sm",
        visible: false,
      },
      {
        label: "Parent Lot",
        fieldNames: ["parentLot/reference"],
        fieldNamesLabels: ["Parent Lot"],
        filterType: "String",
        searchFieldNames: ["parentLot/reference"],
        row: (row) =>
          row.parentLot?.reference
            ? row.parentLot?.reference
            : row.isInitialLot
            ? "Is Initial Lot"
            : "-",
        width: "sm",
        visible: false,
        searchable: true,
      },
      {
        label: "Initial Lot",
        fieldNames: ["isInitialLot"],
        fieldNamesLabels: ["Initial Lot"],
        filterType: "Bool",
        row: (row) => (row.isInitialLot ? "Yes" : "-"),
        centered: true,
        width: "sm",
        visible: false,
      },
    ],
  };

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getAllSeedLots,
        type: "Seed Lots",
        dataRequired: true,
        initialLoadOnly: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Seed Lots" className="min-h-screen" allowHorizontalScroll>
      <TableContext
        config={tableConfig}
        api={getAllSeedLots}
        url="seed-lots"
        query={query}
        setQuery={setQuery}
        Filter={Filter}
      >
        <Table data={seedLots} />
      </TableContext>
    </Page>
  );
}

export default UsersTablePage;
