import { useFormContext } from "../../helpers/formContext";
import { useNavigate } from "react-router-dom";
import RegistrationForm from "../../RegistrationForm";
import FlexCentered from "../../../Containers/FlexCentered";
import Heading from "../../../Components/Heading";

function UserDetailsSection({ type }) {
  const { formState, updateFormState } = useFormContext();
  const navigateTo = useNavigate();

  const handleSubmit = (data) => {
    updateFormState(data);
    return navigateTo(`/registration-form/business-details?type=${type}`);
  };
  return (
    <FlexCentered col>
      <Heading primary className="text-hgCream-50 pt-[8vh] text-2xl mb-8">
        Your Details
      </Heading>
      <RegistrationForm onSubmit={handleSubmit} state={formState} />
    </FlexCentered>
  );
}
export default UserDetailsSection;
