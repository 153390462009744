function userDetailsSchema() {
  return {
    givenName: {
      required: "Enter your given name",
      maxLength: {
        value: 18,
        message: "The given name field cannot exceed 18 characters. ",
      },
    },
    familyName: {
      required: "Enter your family name",
      maxLength: {
        value: 18,
        message: "The family name field cannot exceed 18 characters. ",
      },
    },
    email: {
      required: "Enter your email address",
      pattern: {
        value:
          // eslint-disable-next-line no-control-regex
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
        message: "Invalid email",
      },
    },
    password: {
      required: "Enter a password",
      minLength: {
        value: 8,
        message: "Your password must be at least 8 characters long",
      },
      maxLength: {
        value: 36,
        message: "Your password cannot exceed 36 characters",
      },
    },
    confirmPassword: {
      required: "Confirm password required",
      validate: (value, formValues) => {
        if (formValues.newPassword)
          return (
            formValues.newPassword === value || "The passwords do not match"
          );
        return formValues.password === value || "The passwords do not match";
      },
    },
  };
}

export default userDetailsSchema;
