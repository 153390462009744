import { useState, useEffect, useCallback } from "react";
import { useToastContext } from "../../providers/toastContext";
import Map from "../../Components/Map";
import ControlOverlay from "./ControlOverlay";
import AddFieldOverlay from "./AddFieldOverlay";
import EditFieldOverlay from "./EditFieldOverlay";
import DeleteFieldOverlay from "./DeleteFieldOverlay";

function FieldRecord({ linkedBusiness, fields, forceAddMode, onSuccess }) {
  const [activeMarker, setActiveMarker] = useState(null);
  const [isAddMode, setIsAddMode] = useState(forceAddMode || false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState(null);

  const { createSuccessToast } = useToastContext();

  const getMarkers = useCallback(
    (blurAll, markerToEdit) => {
      return fields.map((field) => {
        const isActiveMarker = field._id.toString() === activeMarker;
        let currIcon = "FieldDeemphasizedIcon";
        if (isActiveMarker && !blurAll) currIcon = "FieldIcon";
        if (isDeleteMode && isActiveMarker) currIcon = "FieldDeleteIcon";
        return {
          coords: [...field.location.coordinates],
          title: field.name,
          icon: currIcon,
          id: field._id.toString(),
          isActive: isActiveMarker && !blurAll,
          disablePopUps: isAddMode || blurAll || isEditMode || isDeleteMode,
          isEditing:
            field._id === markerToEdit ||
            (!markerToEdit && field._id === fieldToEdit),
        };
      });
    },
    [fields, activeMarker, isDeleteMode, isAddMode, isEditMode, fieldToEdit]
  );

  const [draggableMarker, setDraggableMarker] = useState(null);
  const [markers, setMarkers] = useState(getMarkers());

  useEffect(() => {
    if (draggableMarker) {
    }
  }, [draggableMarker]);

  useEffect(() => {
    setMarkers(getMarkers());
  }, [getMarkers]);

  const onClickAddField = () => {
    setIsAddMode(true);
    setActiveMarker(null);
  };

  const onClickEditField = () => {
    const markerToEdit = markers.find((marker) => marker.id === activeMarker);
    if (!markerToEdit) return;
    setIsEditMode(true);
    setFieldToEdit(activeMarker);
    setDraggableMarker({
      id: "draggableMarker",
      coords: markerToEdit.coords,
      isEditing: true,
    });
    setMarkers(getMarkers(true, activeMarker));
  };

  const onClickDeleteField = () => {
    setIsDeleteMode(true);
  };

  const handleDragEnd = (coords) => {
    setDraggableMarker((curr) => {
      return {
        id: "draggableMarker",
        coords: [coords.lng, coords.lat],
        isEditing: curr?.isEditing,
      };
    });
  };

  const updateActiveMarker = useCallback(
    (id) => {
      if (id === null) {
        setActiveMarker(null);
        // setMarkers(getMarkers(true));
      } else if (activeMarker !== id) setActiveMarker(id);
    },
    [activeMarker]
  );

  const handleMapClick = useCallback(
    (e) => {
      if (e?.iconId) {
        setActiveMarker(e.iconId);
      } else if (isAddMode && !draggableMarker) {
        setDraggableMarker({
          title: "New Field",
          id: "newField",
          coords: [e?.lngLat.lng, e?.lngLat.lat],
          icon: "FieldAdd",
        });
      }
    },
    [draggableMarker, isAddMode]
  );

  if (!linkedBusiness?.location) return;

  const handleCloseAddFieldForm = () => {
    if (forceAddMode) return;
    setDraggableMarker(null);
    setIsAddMode(false);
  };

  const handleCloseEditFieldForm = () => {
    setDraggableMarker(null);
    setIsEditMode(false);
    setFieldToEdit(null);
    setMarkers(getMarkers(false));
  };

  const handleCloseDeleteFieldForm = () => {
    setIsDeleteMode(false);
  };

  const onAddFieldSuccess = (newField) => {
    createSuccessToast("New field successfully created");
    handleCloseAddFieldForm();
    if (onSuccess) {
      onSuccess(newField);
    }
  };

  const onEditFieldSuccess = () => {
    handleCloseEditFieldForm();
  };

  const onDeleteFieldSuccess = () => {
    handleCloseDeleteFieldForm();
  };

  let defaultCenter = linkedBusiness.location.coordinates;
  if (fields?.length > 0) {
    let lng = 0;
    let lat = 0;

    fields.forEach((field) => {
      lng += field.location.coordinates[0];
      lat += field.location.coordinates[1];
    });
    lng /= fields.length;
    lat /= fields.length;
    defaultCenter = [lng, lat];
  }

  return (
    <div className="relative w-[90vw] h-[90vw] max-w-[80vh] max-h-[80vh]">
      <Map
        coords={defaultCenter}
        mapStyle="hybrid"
        markers={markers}
        zoom={14}
        onMapClick={handleMapClick}
        draggableMarker={draggableMarker}
        onDragEnd={handleDragEnd}
        onUpdateActiveMarker={updateActiveMarker}
      />
      {!isAddMode && !isEditMode && !isDeleteMode && (
        <ControlOverlay
          onAdd={onClickAddField}
          onEdit={onClickEditField}
          onDelete={onClickDeleteField}
          fields={fields}
          activeMarker={activeMarker}
          setActiveMarker={setActiveMarker}
        />
      )}
      {isAddMode && (
        <AddFieldOverlay
          onClose={handleCloseAddFieldForm}
          icon={draggableMarker}
          businessRef={linkedBusiness?._id?.toString()}
          onSuccess={onAddFieldSuccess}
          forceAddMode={forceAddMode}
        />
      )}
      {isEditMode && (
        <EditFieldOverlay
          onClose={handleCloseEditFieldForm}
          icon={draggableMarker}
          businessRef={linkedBusiness?._id?.toString()}
          onSuccess={onEditFieldSuccess}
          fieldToEdit={fields.find(
            (field) => field._id?.toString() === fieldToEdit
          )}
        />
      )}

      {isDeleteMode && (
        <DeleteFieldOverlay
          businessRef={linkedBusiness?._id?.toString()}
          activeMarker={activeMarker}
          fieldToDelete={fields.find(
            (field) => field._id?.toString() === activeMarker
          )}
          onSuccess={onDeleteFieldSuccess}
          onClose={handleCloseDeleteFieldForm}
        />
      )}
    </div>
  );
}

export default FieldRecord;
