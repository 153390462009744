import { useState, useRef } from "react";
import { AiOutlineUpload, AiOutlineDelete } from "react-icons/ai";
import RenderedErrors from "./RenderedErrors";
import Image from "./Image";
import FlexCentered from "../Containers/FlexCentered";
import classNames from "classnames";

function UploadImage({ value, onChange, error: parentError }) {
  const [error, setError] = useState("");

  const controlsRef = useRef(null);

  const handleUpload = (e) => {
    const validFileTypes = ["image/jpg", "image/jpeg", "image/png"];
    const file = e?.target?.files[0];
    if (!validFileTypes.includes(file.type))
      return setError("File must be jpg or png");
    onChange(file);

    setError("");
  };

  const handleClick = (e) => {
    if (value) {
      if (!controlsRef?.current?.contains(e.target)) return;
    }
    e?.preventDefault();
    const imageInput = document.querySelector("#imageInput");
    imageInput.click();
  };

  return (
    <>
      <div
        className={classNames("relative w-fit h-fit group", {
          "border border-red-700": error || parentError,
        })}
      >
        {value && (
          <FlexCentered
            row
            className="absolute top-[5px] right-[5px] gap-2 bg-hgBlue-800 invisible group-hover:visible rounded shadow z-50 p-1 text-2xl text-hgCream-50"
            innerRef={controlsRef}
          >
            <AiOutlineUpload
              className="cursor-pointer hover:text-hgGreen-500"
              onClick={handleClick}
            />
            <AiOutlineDelete
              className="cursor-pointer hover:text-hgRed-500"
              onClick={() => onChange(null)}
            />
          </FlexCentered>
        )}
        <Image
          src={
            value
              ? typeof value === "string"
                ? value
                : URL.createObjectURL(value)
              : undefined
          }
          alt="Population"
          title="Upload an image"
          upload
          className="w-[20rem] h-[20rem] max-w-full"
          onClick={handleClick}
        />
      </div>

      <input
        className="hidden"
        type="file"
        id="imageInput"
        onChange={handleUpload}
      />
      {error && (
        <RenderedErrors
          className="bg-hgCream-50"
          errors={[]}
          apiError={error}
        />
      )}
    </>
  );
}
export default UploadImage;
