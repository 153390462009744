import { useEffect, useState } from "react";
import { useToggleAccess, useDisconnectBusinessLink } from "../../../../api";
import classNames from "classnames";
import Kebab from "../../../../Components/Kebab";
import ServerErrorModal from "../../../../Sections/ServerErrorModal";
import Link from "../../../../Components/Link";

function Menu({ user, permissions, currentUser }) {
  const [isOpen, setIsOpen] = useState(false);
  const [serverError, setServerError] = useState(null);

  const toggleAccessApi = useToggleAccess();
  const disconnectBusinessLinkApi = useDisconnectBusinessLink();

  useEffect(() => {
    if (toggleAccessApi?.error) {
      setServerError(toggleAccessApi?.error);
    }
    if (disconnectBusinessLinkApi?.error) {
      setServerError(disconnectBusinessLinkApi?.error);
    }
  }, [disconnectBusinessLinkApi?.error, toggleAccessApi?.error]);

  const menuClasses = classNames("hover:text-hgGreen-200 cursor-pointer");

  const editPermissions = (
    <Link key="editPermissions" className={menuClasses} to="edit-permissions">
      Edit Permissions
    </Link>
  );

  const toggleAccess = (
    <div
      key="editAccess"
      className={menuClasses}
      onClick={() => {
        setIsOpen(false);
        toggleAccessApi.mutate(user?.id);
      }}
    >
      {user.restrictedAccess ? "Restore Access" : "Restrict Access"}
    </div>
  );
  const editBusinessLink = user.linkedBusiness ? (
    <div
      key="editBusinessLink"
      className={menuClasses}
      onClick={() => {
        setIsOpen(false);
        disconnectBusinessLinkApi.mutate({ ref: user?.id });
      }}
    >
      Disconnect Business Link
    </div>
  ) : (
    <Link
      key="editBusinessLink"
      className={menuClasses}
      to="create-business-link"
    >
      Create Business Link
    </Link>
  );

  const menuItems = [];
  if (permissions?.writeLinkedBusiness) menuItems.push(editBusinessLink);
  if (permissions?.writePermissions && currentUser.id !== user.id)
    menuItems.push(editPermissions);
  if (permissions?.writeAccess && currentUser.id !== user.id)
    menuItems.push(toggleAccess);

  if (menuItems.length === 0) return;

  return (
    <>
      <Kebab isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex flex-col items-end justify-center text-right">
          {menuItems}
        </div>
      </Kebab>
      <ServerErrorModal
        serverError={serverError}
        onClose={() => setServerError(null)}
      />
    </>
  );
}

export default Menu;
