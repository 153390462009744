import { useGetBusiness } from "../../api";
import getApiStatusPage from "../APIStatusPage";
import Page from "../../Containers/Page";
import ViewBusiness from "../../Sections/ViewBusiness.js";

function BusinessProfilePage() {
  const params = new URL(document.location).searchParams;
  const businessRef = params.get("ref");

  const getBusinessApi = useGetBusiness({
    businessRef,
    params: {
      fields:
        "tradingName,formattedAddress,businessType,location,logo,about,website,email,reference,CPH_Number",
    },
  });
  const business = getBusinessApi?.data?.business;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getBusinessApi,
        type: "Business",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Business Profile">
      <ViewBusiness business={business} />
    </Page>
  );
}

export default BusinessProfilePage;
