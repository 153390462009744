import { useForm } from "react-hook-form";
import { useConfirmEmail, useResendConfirmationCode } from "../api";
import RenderedErrors from "../Components/RenderedErrors";
import FlexCentered from "../Containers/FlexCentered";
import Button from "../Components/Button";
import Input from "../Components/Input";
import P from "../Components/P";

function EmailConfirmationForm({ currentUser }) {
  const form = useForm();
  const errors = form.formState.errors;

  const confirmEmailAPI = useConfirmEmail();
  const resendConfirmationCodeApi = useResendConfirmationCode();

  const handleResendConfirmationCode = (e) => {
    e.preventDefault();
    form.reset({}, { keepIsSubmitted: false });
    resendConfirmationCodeApi.mutate({ body: { email: currentUser.email } });
  };

  const { register } = form;
  const tokenProps = register("token", {
    required: "Enter your confirmation code",
    maxLength: 6,
  });

  const handleSubmit = (data) => {
    confirmEmailAPI.mutate({ body: { ...data, email: currentUser.email } });
    form.reset({}, { keepIsSubmitted: false });
  };

  let apiError = confirmEmailAPI.error;

  return (
    <>
      <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
        <FlexCentered col>
          <Input
            type="text"
            id="token"
            maxLength="6"
            placeholder="Confirmation Code"
            className="w-[20rem] max-w-full mb-4"
            {...tokenProps}
            ref={null}
            innerRef={tokenProps.ref}
          />

          <FlexCentered row className="gap-1">
            <Button primary type="submit" loading={confirmEmailAPI.isLoading}>
              Validate
            </Button>
            <Button
              secondary
              outline
              loading={resendConfirmationCodeApi.isLoading}
              onClick={handleResendConfirmationCode}
            >
              Resend
            </Button>
          </FlexCentered>

          {form.formState.isSubmitted && (
            <RenderedErrors errors={errors} apiError={apiError} />
          )}

          {resendConfirmationCodeApi.data && !form.formState.isSubmitted && (
            <FlexCentered col>
              <FlexCentered row className="gap-2 mt-4">
                <P className="inline-block text-center">
                  Request received, a fresh confirmation code has been sent to{" "}
                  <span className="font-semibold">{currentUser.email}</span>
                </P>
              </FlexCentered>
            </FlexCentered>
          )}
        </FlexCentered>
      </form>
    </>
  );
}

export default EmailConfirmationForm;
