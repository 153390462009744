import { Routes, Route } from "react-router-dom";
import FormContext from "./helpers/formContext";
import BusinessDetailsSection from "./ReusableSections/BusinessDetailsSection";
import ConfirmationSection from "./ReusableSections/ConfirmationSection";

function AddBusinessForm() {
  const defaultValues = {
    businessType: null,
    tradingName: "",
    CPHNumber: "",
    formattedAddress: "",
    postcode: "",
    location: "",
  };

  return (
    <FormContext defaultState={defaultValues}>
      <Routes>
        <Route path="/" element={<BusinessDetailsSection />} />
        <Route
          path="/confirmation"
          element={
            <ConfirmationSection
              onPrevious={`/admin/businesses/add-business`}
            />
          }
        />
      </Routes>
    </FormContext>
  );
}

export default AddBusinessForm;
