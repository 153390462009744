import { useState } from "react";
import P from "../../../../Components/P";
import FlexCentered from "../../../../Containers/FlexCentered";
import AddEditSeedLotForBusinessForm from "../../../../Forms/AddEditSeedLotForBusinessForm";
import MassBalanceCalculation from "../../../../Forms/components/MassBalanceCalculation";
import Button from "../../../../Components/Button";
import ChildLotListItem from "./ChildLotListItem";

function ChildLotData({ product, massBalanceFigures }) {
  const [newLotQty, setNewLotQty] = useState(0);
  const [showAddEditLotForm, setShowAddEditLotForm] = useState(false);
  const [seedLotToEdit, setSeedLotToEdit] = useState(null);

  let renderedChildLots = massBalanceFigures?.childLots?.map((childLot) => {
    return (
      <ChildLotListItem
        key={childLot?._id?.toString()}
        childLot={childLot}
        onEdit={(lot) => setSeedLotToEdit(lot)}
      />
    );
  });
  if (!renderedChildLots || renderedChildLots.length === 0) {
    renderedChildLots = (
      <div className="bg-hgYellow-100 px-4 py-2 w-fit rounded shadow mb-4">
        <P>
          No seed lots found. To issue a new seed lot from this product click
          the "Issue Seed Lot" button below
        </P>
      </div>
    );
  }

  return (
    <FlexCentered col>
      <div className="w-full flex flex-col gap-y-4 mb-4">
        {renderedChildLots}
      </div>
      {!showAddEditLotForm && !seedLotToEdit && (
        <Button
          primary
          title="Click to issue a new seed lot from this product"
          onClick={() => setShowAddEditLotForm(true)}
        >
          Issue Seed Lot
        </Button>
      )}
      {(showAddEditLotForm || seedLotToEdit) && (
        <div className="mt-4 mb-2">
          <AddEditSeedLotForBusinessForm
            product={product}
            seedLot={seedLotToEdit}
            massBalanceFigures={massBalanceFigures}
            setNewLotQty={setNewLotQty}
            onClose={() => {
              setShowAddEditLotForm(false);
              setSeedLotToEdit(null);
              setNewLotQty(0);
            }}
          />
        </div>
      )}
      <div className="mt-24">
        <MassBalanceCalculation
          figures={{
            sourceSeedLot: massBalanceFigures?.totalReceived,
            totalSown: massBalanceFigures?.totalSown,
            totalYield: massBalanceFigures?.totalYield,
            issuedToSeedLots: massBalanceFigures?.totalIssued + newLotQty,
          }}
        />
      </div>
    </FlexCentered>
  );
}
export default ChildLotData;
