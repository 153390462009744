import ProductDataSchema from "../schemas/productDataSchema";
import Label from "../../Components/Label";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import RenderedErrors from "../../Components/RenderedErrors";
import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";
import { useForm } from "react-hook-form";

function EditProductDataForm({ product, updateProductApi }) {
  const form = useForm({
    values: { ...product, growingData: undefined, reference: undefined },
  });
  const errors = form.formState.errors;
  const schema = ProductDataSchema();

  const { register } = form;
  const moistureContentProps = register(
    "moistureContent",
    schema.moistureContent
  );
  const proteinProps = register("protein", schema.protein);
  const hagbergProps = register("hagberg", schema.hagberg);
  const glutenProps = register("gluten", schema.gluten);
  const specificWeightProps = register("specificWeight", schema.specificWeight);
  const germinationRateProps = register(
    "germinationRate",
    schema.germinationRate
  );
  const thousandGrainWeightGramsProps = register(
    "thousandGrainWeightGrams",
    schema.thousandGrainWeightGrams
  );
  const DONProps = register("DON", schema.DON);
  const ZONProps = register("ZON", schema.ZON);
  const septoriaProps = register("septoria", schema.septoria);
  const buntProps = register("bunt", schema.bunt);

  const handleSubmit = (data) => {
    updateProductApi.mutate({
      productRef: product._id,
      body: data,
    });
  };

  return (
    <div className="h-full">
      <FlexCentered col className="h-full">
        <form className="" onSubmit={form.handleSubmit(handleSubmit)}>
          <FlexCentered col>
            <Heading
              tertiary
              className="text-xl font-light text-hgGreen-1000 w-full mb-6"
            >
              Grain Quality
            </Heading>
            <Label htmlFor="moistureContent" className="mb-2">
              Moisture Content (%)
            </Label>
            <Input
              type="text"
              id="moistureContent"
              className={"w-[20rem] max-w-full mb-4"}
              {...moistureContentProps}
              ref={null}
              innerRef={moistureContentProps.ref}
              error={errors.moistureContent}
            />
            <Label htmlFor="protein" className="mb-2">
              Protein (%)
            </Label>
            <Input
              type="text"
              id="protein"
              className={"w-[20rem] max-w-full mb-4"}
              {...proteinProps}
              ref={null}
              innerRef={proteinProps.ref}
              error={errors.protein}
            />
            <Label htmlFor="hagberg" className="mb-2">
              Hagberg
            </Label>
            <Input
              type="text"
              id="hagberg"
              className={"w-[20rem] max-w-full mb-4"}
              {...hagbergProps}
              ref={null}
              innerRef={hagbergProps.ref}
              error={errors.hagberg}
            />
            <Label htmlFor="gluten" className="mb-2">
              Gluten (%)
            </Label>
            <Input
              type="text"
              id="gluten"
              className={"w-[20rem] max-w-full mb-4"}
              {...glutenProps}
              ref={null}
              innerRef={glutenProps.ref}
              error={errors.gluten}
            />
            <Label htmlFor="specificWeight" className="mb-2">
              Specific Weight (g/l)
            </Label>
            <Input
              type="text"
              id="specificWeight"
              className={"w-[20rem] max-w-full mb-4"}
              {...specificWeightProps}
              ref={null}
              innerRef={specificWeightProps.ref}
              error={errors.specificWeight}
            />
            <Heading
              tertiary
              className="text-xl font-light text-hgGreen-1000 w-full mb-6 mt-8"
            >
              Seed Quality
            </Heading>
            <Label htmlFor="germinationRate" className="mb-2">
              Germination Rate (%)
            </Label>
            <Input
              type="text"
              id="germinationRate"
              className={"w-[20rem] max-w-full mb-4"}
              {...germinationRateProps}
              ref={null}
              innerRef={germinationRateProps.ref}
              error={errors.germinationRate}
            />
            <Label htmlFor="thousandGrainWeightGrams" className="mb-2">
              Thousand Grain Weight (g)
            </Label>
            <Input
              type="text"
              id="thousandGrainWeightGrams"
              className={"w-[20rem] max-w-full mb-4"}
              {...thousandGrainWeightGramsProps}
              ref={null}
              innerRef={thousandGrainWeightGramsProps.ref}
              error={errors.thousandGrainWeightGrams}
            />
            <Label htmlFor="DON" className="mb-2">
              DON (ppb)
            </Label>
            <Input
              type="text"
              id="DON"
              className={"w-[20rem] max-w-full mb-4"}
              {...DONProps}
              ref={null}
              innerRef={DONProps.ref}
              error={errors.DON}
            />
            <Label htmlFor="ZON" className="mb-2">
              ZON (ppb)
            </Label>
            <Input
              type="text"
              id="ZON"
              className={"w-[20rem] max-w-full mb-4"}
              {...ZONProps}
              ref={null}
              innerRef={ZONProps.ref}
              error={errors.ZON}
            />
            <Label htmlFor="septoria" className="mb-2">
              Septoria (%)
            </Label>
            <Input
              type="text"
              id="septoria"
              className={"w-[20rem] max-w-full mb-4"}
              {...septoriaProps}
              ref={null}
              innerRef={septoriaProps.ref}
              error={errors.septoria}
            />
            <Label htmlFor="bunt" className="mb-2">
              Bunt (sps)
            </Label>
            <Input
              type="text"
              id="bunt"
              className={"w-[20rem] max-w-full mb-4"}
              {...buntProps}
              ref={null}
              innerRef={buntProps.ref}
              error={errors.bunt}
            />
            <Button
              primary
              className="mt-4 mb-12"
              loading={updateProductApi.isLoading}
              disabled={!form.formState.isDirty}
            >
              Save
            </Button>
            {form.formState.isSubmitted && (
              <RenderedErrors
                className="bg-hgCream-50"
                errors={errors}
                apiError={updateProductApi?.error || ""}
              />
            )}
          </FlexCentered>
        </form>
      </FlexCentered>
    </div>
  );
}

export default EditProductDataForm;
