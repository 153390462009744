import RangeSlider from "../../Components/RangeSlider";
import getProductDataConfig from "../../utils/getProductDataConfig";
import Heading from "../../Components/Heading";
import Label from "../../Components/Label";
import P from "../../Components/P";

function ProductData({ productData, populationRoute }) {
  const fieldKeys = getProductDataConfig();
  const renderedProductData = [];

  fieldKeys.forEach((fieldKey) => {
    if (!productData) return;
    const { field, label, units, high, low } = fieldKey;
    const value = productData[field];
    if (value === undefined || value === null || value === "") return;
    else
      renderedProductData.push(
        <div
          className="flex flex-col w-full contents bg-hgBlue-100 even:bg-opacity-30 "
          key={field}
        >
          <Label className="text-sm md:text-base bg-inherit pl-[1.25rem] py-4 select-none cursor-default">
            {label}
            {units ? ` (${units})` : ""}
          </Label>
          <RangeSlider value={value} low={low} high={high} />
        </div>
      );
  });

  return (
    <div className="w-full max-w-md  rounded overflow-hidden">
      <div className="bg-hgBlue-700 py-4">
        <Heading
          tertiary
          className="text-xl text-hgCream-50 text-center w-full font-light"
        >
          {!populationRoute ? "Product Data" : "General"}
        </Heading>
      </div>
      {renderedProductData && renderedProductData.length > 0 && (
        <div className="grid grid-cols-[auto_1fr] w-full items-center  select-none">
          {renderedProductData}
        </div>
      )}
      {(!renderedProductData || !renderedProductData.length > 0) && (
        <div className="mt-2 mx-2">
          <P>No data found</P>
        </div>
      )}
    </div>
  );
}
export default ProductData;
