import { useRef, useState, useEffect } from "react";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import Dropdown from "../../Components/Dropdown";
import { usePopulationListContext } from "./PopulationListContext";
import Label from "../../Components/Label";

function Control() {
  const searchbarRef = useRef(null);
  const { query, setQuery, cropTypes } = usePopulationListContext();
  const [type, setType] = useState(null);
  const [cropType, setCropType] = useState(null);

  useEffect(() => {
    if (!query?.type) {
      if (type) setType(null);
    } else {
      if (query?.type !== type?.label) {
        setType({
          label: query?.type,
          id: query?.type,
        });
      }
    }
  }, [query.type, type]);

  useEffect(() => {
    if (!query["cropType/name"]) {
      if (cropType) setCropType(null);
    } else {
      if (query["cropType/name"] !== cropType?.label) {
        setCropType({
          label: query["cropType/name"],
          id: query["cropType/name"],
        });
      }
    }
  }, [query, cropType]);

  const handleSearch = (e) => {
    e?.preventDefault();
    if (!searchbarRef.current) return;
    const searchterm = searchbarRef.current.value;
    setQuery?.search(searchterm, ["name", "reference"]);
  };

  return (
    <div className="p-2 bg-hgGreen-900 rounded shadow-lg">
      <form
        className="flex flex-row items-stretch justify-center"
        onSubmit={handleSearch}
      >
        <Input
          innerRef={searchbarRef}
          className="w-full h-10 bg-gray-50"
          placeholder="Search populations"
        />
        <Button secondary className="px-2 py-1">
          Search
        </Button>
      </form>
      <div className="md:hidden grid grid-cols-[auto_1fr] gap-x-4 gap-y-2 mt-4 ">
        <Label className="whitespace-nowrap text-hgCream-50">
          Population Type:{" "}
        </Label>
        <div className="flex flex-row justify-stretch items-stretch bg-red-200">
          <Dropdown
            className="w-full"
            value={type}
            onChange={(val) => {
              if (val) {
                setQuery.filter(
                  {
                    fieldName: "type",
                    label: "type",
                    type: "String",
                  },
                  "Equals",
                  val?.label
                );
              } else {
                setQuery.unsetFilter({
                  fieldName: "type",
                  operator: "eq",
                });
              }
            }}
            placeholder="Any"
            config={{
              data: [
                {
                  label: "CCP",
                  id: "CCP",
                },
                {
                  label: "Dynamic",
                  id: "Dynamic",
                },
                {
                  label: "Landrace and Farmer's Selection",
                  id: "Landrace and Farmer's Selection",
                },
              ],
            }}
          />
        </div>
        <Label className="whitespace-nowrap text-hgCream-50">Crop Type: </Label>
        <Dropdown
          className="w-full"
          value={cropType}
          onChange={(val) => {
            if (val) {
              setQuery.filter(
                {
                  fieldName: "cropType/name",
                  label: "Crop Type",
                  type: "String",
                },
                "Equals",
                val?.label
              );
            } else {
              setQuery.unsetFilter({
                fieldName: "cropType/name",
                operator: "eq",
              });
            }
          }}
          placeholder="Any"
          config={{
            data: cropTypes?.map((cropType) => {
              return { label: cropType.name, id: cropType.name };
            }),
          }}
        />
      </div>
    </div>
  );
}

export default Control;
