import { useParams } from "react-router-dom";
import Page from "../../../Containers/Page";
import FamilyTreeReport from "../../../Sections/PopulationFamilyTreeReport";

function FamilyTreePage() {
  const { populationRef } = useParams();

  return (
    <Page title={`${populationRef} - Family Tree`}>
      <FamilyTreeReport
        populationRef={populationRef}
        getSeedLotLink={(ref) => `/admin/seed-lots/${ref}`}
      />
    </Page>
  );
}

export default FamilyTreePage;
