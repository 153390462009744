import { useEffect, useState, useMemo } from "react";
import {
  FiChevronUp,
  FiChevronRight,
  FiChevronDown,
  FiChevronLeft,
} from "react-icons/fi";
import getFamilyTreeClone from "./helpers/getFamilyTreeClone";
import calculateGridPositions from "./helpers/calculateGridPositions";
import FamilyTreeCard from "./components/FamilyTreeCard";
import getLineageLines from "./helpers/getLineageLines";
import LineageLineCanvas from "./components/LineageLineCanvas";

function FamilyTree({ familyTree, focusOn, getSeedLotLink }) {
  const [viewPortWidth, setViewPortWidth] = useState(null);
  const [viewPortHeight, setViewPortHeight] = useState(500);
  const [viewPortLeft, setViewPortLeft] = useState(0);
  const [viewPortTop, setViewPortTop] = useState(0);
  const [container, setContainer] = useState(undefined);
  const [hidden, setHidden] = useState(false);
  const [defaultLeft, setDefaultLeft] = useState(null);
  const [focussedOn, setFocussedOn] = useState(null);
  const [minLeft, setMinLeft] = useState(null);

  /******************************************
   * CLONE FAMILY TREE STATE
   * Clone each object in the family tree
   * to allow mutation - And attach an offset
   * which, when added to parent column will
   * center children beneath
   ******************************************/
  const { seedLotsObject, rootLot } = useMemo(
    () => getFamilyTreeClone(familyTree),
    [familyTree]
  );

  /******************************************
   * CALCULATE GRID POSITIONS
   * This function calculates grid rows ands columns
   * and attaches them to the lot objects
   * It also returns an object of table data
   * including row count and min col. We will
   * add the difference between the min col and 1
   * to get rid of negative figures
   ******************************************/

  const tableData = useMemo(
    () => calculateGridPositions(seedLotsObject, rootLot),
    [seedLotsObject, rootLot]
  );

  const hideLines = () => {
    setHidden(true);
    setTimeout(() => {
      if (setHidden) setHidden(false);
    }, 300);
  };

  /*Store the lots in an array of rows, each row being an array of cells.
    This allows us to quickly find and render only the visible part of the tree
    + a margin to scroll into.
  */
  const tableRowColArray = useMemo(() => {
    const tableRowColArray = [];
    for (let x = 1; x <= tableData.rowCount; x++) {
      const row = [];
      for (let y = 1; y <= tableData.columnCount; y++) {
        row.push({ row: x, col: y });
      }
      tableRowColArray.push(row);
    }

    familyTree.lots.forEach((lot) => {
      const currLot = seedLotsObject[lot.id];
      const row = currLot.row;
      const col = currLot.getColumn() + tableData.gridColOffset;
      tableRowColArray[row - 1][col - 1].id = currLot.id.toString();
    });
    return tableRowColArray;
  }, [
    familyTree.lots,
    seedLotsObject,
    tableData.columnCount,
    tableData.gridColOffset,
    tableData.rowCount,
  ]);

  const paddingX = 40;
  const paddingY = 20;
  const cardWidth = 125;
  const cardHeight = 140;
  const dirButtonSize = 30;

  //Center focussed card
  useEffect(() => {
    if (defaultLeft) {
      const focussedLeft =
        defaultLeft + cardWidth / 2 - (viewPortWidth - dirButtonSize * 2) / 2;
      setViewPortLeft(focussedLeft);
      setMinLeft(Math.min(0, focussedLeft));
      const treeContainer = document.getElementById("family-tree");
      if (treeContainer) treeContainer.style.opacity = "100%";
    }
  }, [defaultLeft, viewPortWidth, familyTree, focussedOn, focusOn]);

  const maxTreeWidth =
    tableData.columnCount * (cardWidth + paddingX) + paddingX / 2;
  const maxTreeHeight =
    tableData.rowCount * (cardHeight + paddingY) + paddingY / 2;

  const maxColsInView = Math.ceil(viewPortWidth / (cardWidth + paddingX));
  const maxRowsInView = Math.ceil(viewPortHeight / (cardHeight + paddingY));
  const firstColInView = Math.floor(viewPortLeft / (cardWidth + paddingX)) + 1;
  const firstRowInView = Math.floor(viewPortTop / (cardHeight + paddingY)) + 1;

  const minRenderedRow = Math.max(
    1,
    firstRowInView - Math.ceil(maxRowsInView / 2)
  );
  const maxRenderedRow = Math.min(
    tableData.rowCount,
    firstRowInView + Math.ceil(maxRowsInView * 1.5)
  );
  const minRenderedCol = Math.max(
    1,
    firstColInView - Math.ceil(maxColsInView / 2)
  );
  const maxRenderedCol = Math.min(
    tableData.columnCount,
    firstColInView + Math.ceil(maxColsInView * 1.5)
  );

  useEffect(() => {
    const wrapper = document.getElementById("family-tree-wrapper");
    if (wrapper && !container) {
      const { width, height } = wrapper.getBoundingClientRect();
      setContainer(wrapper);
      setViewPortWidth(width);
      setViewPortHeight(height);
    }
  }, [container]);

  useEffect(() => {
    const handleTreeClick = (e) => {
      if (e.target.id !== "family-tree") return;
      const { width: totalWidth, height: totalHeight } =
        e.target.getBoundingClientRect();
      const leftClick = e.layerX;
      const topClick = e.layerY;

      const offsetX = totalWidth / 2 - leftClick;
      const offsetY = totalHeight / 2 - topClick;

      const centeredLeft = viewPortLeft - offsetX;
      const centeredTop = viewPortTop - offsetY;

      let moveToLeft = Math.max(
        Math.min(
          Math.max(minLeft, centeredLeft),
          maxTreeWidth - (viewPortWidth - dirButtonSize * 2)
        ),
        minLeft
      );

      let moveToTop = Math.max(
        Math.min(
          Math.max(0, centeredTop),
          maxTreeHeight - (viewPortHeight - dirButtonSize * 2)
        ),
        0
      );

      setViewPortLeft(moveToLeft);
      setViewPortTop(moveToTop);
      hideLines();
    };
    document.addEventListener("click", handleTreeClick);
    return () => document.removeEventListener("click", handleTreeClick);
  });

  useEffect(() => {
    if (!container) return;
    const handleResize = () => {
      const { width, height } = container.getBoundingClientRect();
      setViewPortWidth(width);
      setViewPortHeight(height);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [container]);

  const handlePanView = (direction) => {
    hideLines();
    let newPos;
    switch (direction) {
      case "up":
        newPos = viewPortTop - viewPortHeight / 2;
        let moveToTop = Math.max(0, newPos);

        setViewPortTop(moveToTop);
        break;
      case "right":
        newPos = viewPortLeft + viewPortWidth / 2;
        let moveToRight = Math.min(
          Math.max(maxTreeWidth - (viewPortWidth - dirButtonSize * 2), 0),
          newPos
        );
        setViewPortLeft(moveToRight);
        break;
      case "down":
        newPos = viewPortTop + viewPortHeight / 2;
        let moveDown = Math.min(
          Math.max(maxTreeHeight - (viewPortHeight - dirButtonSize * 2), 0),
          newPos
        );

        setViewPortTop(moveDown);
        break;
      case "left":
        newPos = viewPortLeft - viewPortWidth / 2;
        let moveToLeft = Math.max(minLeft, newPos);
        setViewPortLeft(moveToLeft);
        break;
      default:
        console.error("Invalid family tree pan selection");
    }
  };

  const getCoordinates = (row, col) => {
    let top;
    if (row === 1) top = paddingY;
    else top = (cardHeight + paddingY) * (row - 1) + paddingY;

    let left;
    if (col === 1) left = paddingX;
    else left = (cardWidth + paddingX) * (col - 1) + paddingX;
    return [`${top - viewPortTop}px`, `${left - viewPortLeft}px`];
  };

  const lineageLines = [];
  const linesDrawnFor = [];

  const drawLineageLines = (lot) => {
    if (
      lot.children &&
      lot.children.length > 0 &&
      !linesDrawnFor.includes(lot.id)
    ) {
      lineageLines.push(
        getLineageLines(
          cardWidth,
          cardHeight,
          lot,
          paddingY,
          getCoordinates,
          seedLotsObject,
          tableData
        )
      );
      linesDrawnFor.push(lot.id);
    }

    if (lot.parent && !linesDrawnFor.includes(lot.parent)) {
      const parentLot = seedLotsObject[lot.parent];
      lineageLines.push(
        getLineageLines(
          cardWidth,
          cardHeight,
          parentLot,
          paddingY,
          getCoordinates,
          seedLotsObject,
          tableData
        )
      );
      linesDrawnFor.push(parentLot.id);
    }
  };

  const renderedCards = [];

  for (let x = minRenderedRow; x <= maxRenderedRow; x++) {
    for (let y = minRenderedCol; y <= maxRenderedCol; y++) {
      const currentLot = tableRowColArray[x - 1][y - 1];
      if (!currentLot.id) continue;
      const lot = seedLotsObject[currentLot.id];

      const [top, left] = getCoordinates(x, y);

      if (lot.reference === focusOn) {
        const numLeft = Number(left.replace("px", ""));
        if (focussedOn !== lot.reference) {
          setFocussedOn(lot.reference);
          setDefaultLeft(numLeft);
        }
      }

      lot.left = left;
      lot.top = top;

      drawLineageLines(lot);
      if (minLeft === null) break;

      renderedCards.push(
        <FamilyTreeCard
          lot={lot}
          key={currentLot.id}
          getSeedLotLink={getSeedLotLink}
          style={{
            left: lot.left,
            top: lot.top,
            width: `${cardWidth}px`,
            height: `${cardHeight}px`,
          }}
        />
      );
    }
  }

  return (
    <div
      className="grid bg-hgBlue-50"
      style={{
        gridTemplateColumns: `${dirButtonSize}px ${
          viewPortWidth - dirButtonSize * 2
        }px ${dirButtonSize}px`,
      }}
    >
      <div
        className="col-span-3 bg-hgBlue-500  text-hgCream-50 h-[30px] flex flex-col items-center justify-center cursor-pointer"
        onClick={() => handlePanView("up", "click")}
        data-dir="up"
      >
        <FiChevronUp className="text-2xl font-semibold" />
      </div>

      <div
        className="bg-hgBlue-700 text-hgCream-50 w-[30px] flex flex-col items-center justify-center cursor-pointer"
        onClick={() => handlePanView("left", "click")}
        data-dir="left"
      >
        <FiChevronLeft className="text-2xl font-semibold" />
      </div>

      <div
        id="family-tree"
        className="relative border overflow-hidden cursor-crosshair transition-opacity"
        style={{
          width: `${viewPortWidth - dirButtonSize * 2}px`,
          height: `${viewPortHeight - dirButtonSize * 2}px`,
          opacity: 0,
        }}
      >
        {renderedCards}

        {
          <LineageLineCanvas
            lineageLines={lineageLines}
            width={viewPortWidth - dirButtonSize * 2}
            height={viewPortHeight - dirButtonSize * 2}
            top={viewPortTop}
            left={viewPortLeft}
            hidden={hidden}
          />
        }
      </div>

      <div
        className="bg-hgBlue-700 text-hgCream-50 w-[30px] flex flex-col items-center justify-center cursor-pointer"
        onClick={() => handlePanView("right", "click")}
        data-dir="right"
      >
        <FiChevronRight className="text-2xl font-semibold" />
      </div>
      <div
        className="col-span-3 bg-hgBlue-500  text-hgCream-50 h-[30px] flex flex-col items-center justify-center cursor-pointer"
        onClick={() => handlePanView("down", "click")}
        data-dir="down"
      >
        <FiChevronDown className="text-2xl font-semibold" />
      </div>
    </div>
  );
}
export default FamilyTree;
