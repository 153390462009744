import Hamburger from "hamburger-react";
import { useState, useRef, useEffect } from "react";
import Link from "../../Components/Link";
import classNames from "classnames";

function HamburgerMenu({ links, adminRoute }) {
  const [isOpen, setOpen] = useState(false);
  const menuRef = useRef();
  const hamburgerRef = useRef();

  useEffect(() => {
    if (!isOpen) return;
    if (!hamburgerRef?.current) return;
    const handleClick = (e) => {
      if (
        !menuRef?.current.contains(e.target) &&
        !hamburgerRef?.current.contains(e.target)
      ) {
        setOpen(false);
      }
      if (menuRef?.current.contains(e.target)) {
        if (e.target.closest("a")) setOpen(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  });

  const header = document.getElementById("topNav");
  let headerHeight = 60;
  if (header) headerHeight = header.getBoundingClientRect().height;

  const renderedLinks = links.map((link) => {
    return (
      <div key={`${link.to}-0`} className="flex flex-col items-start">
        <Link
          to={link.to}
          onClick={link.onClick}
          className={classNames("text-right", {
            "text-hgCream-50 hover:text-hgGreen-300 ": !adminRoute,
            "text-hgBlue-600 hover:text-hgGreen-900 ": adminRoute,
          })}
        >
          {link.minLabel ? link.minLabel : link.label}
        </Link>
        {link.children && link.children.length > 0 && (
          <div>
            {/* <div className="w-full h-[1px] bg-hgGreen-900" /> */}
            <div className="flex flex-col items-start py-1">
              {link.children.map((childLink) => {
                return (
                  <Link
                    key={`${childLink.to}-1`}
                    to={childLink.to}
                    onClick={childLink.onClick}
                    className={classNames("text-sm pl-2", {
                      "text-hgCream-50 hover:text-hgGreen-300": !adminRoute,
                    })}
                  >
                    {" "}
                    {childLink.minLabel ? childLink.minLabel : childLink.label}
                  </Link>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="md:hidden">
      <div className="w-fit h-fit" ref={hamburgerRef}>
        <Hamburger
          toggled={isOpen}
          toggle={setOpen}
          size={25}
          color={!adminRoute ? "#fefefc" : "#3d405b"}
        />
      </div>
      {isOpen && (
        <div
          ref={menuRef}
          className={classNames(
            "absolute px-4 rounded-b overflow-hidden shadow w-[50vw]",
            {
              "bg-hgCream-50": adminRoute,
              "bg-hgBlue-700": !adminRoute,
            }
          )}
          style={{ top: `${headerHeight}px`, right: 0, zIndex: 999 }}
        >
          <div className="flex flex-col items-start py-4 pl-8">
            <div className="flex flex-col items-start gap-y-4 ">
              {renderedLinks}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default HamburgerMenu;
