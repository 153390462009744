import Image from "../../Components/Image";
import P from "../../Components/P";
import Link from "../../Components/Link";
import Button from "../../Components/Button";
import classNames from "classnames";

function ProductItem({
  product,
  population,
  isGrower,
  handleArchiveProduct,
  loading,
}) {
  const dataStatusClasses = (status) => {
    return classNames("font-semibold w-fit px-2 py-1 rounded", {
      "text-hgBlue-900 bg-hgBlue-500 bg-opacity-10": status === "None",
      "bg-hgRed-500 text-hgCream-50": status === "Partial",
      "bg-hgGreen-700 text-hgCream-50": status === "Complete",
    });
  };

  if (!population || !product) return;
  return (
    <div className="bg-hgCream-50 rounded-lg shadow-lg overflow-hidden select-none">
      <div className="p-1 bg-hgGreen-300 text-hgBlue-900 shadow w-full text-center text-xl">
        {product.reference}
      </div>
      <div className="grid grid-cols-[5rem_1fr] md:grid-cols-[5rem_1.3fr_1fr_auto] gap-x-4 px-4 py-2  text-sm sm:text-base ">
        <Image
          src={population.image}
          alt={population.name}
          className="w-[5rem] h-[5rem] rounded-lg overflow-hidden self-center"
        />
        <div className="flex flex-col justify-center bg-hgBlue-200 bg-opacity-10 rounded shadow text-sm lg:text-base">
          <div className="grid grid-cols-[auto_auto] gap-x-4 h-[5rem] w-fit  p-1 mt-1  text-hgBlue-900">
            <P className="">Parent:</P>
            <P className="text-hgGreen-1200">{product.seedLot?.reference}</P>
            <P className="">Population:</P>
            <P className="text-hgGreen-1200">{population.name}</P>
            <P className="">Crop Type:</P>
            <P className="text-hgGreen-1200">{population.cropType?.name}</P>
          </div>
        </div>
        <div className="pt-4 text-sm lg:text-base">
          <div className="hidden md:grid grid-cols-[auto_1fr] gap-x-2 h-fit gap-y-2">
            {isGrower && (
              <>
                <P className="py-1">Growing Data:</P>
                <P className={dataStatusClasses(product.growingDataStatus)}>
                  {product.growingDataStatus}
                </P>
              </>
            )}
            <P className="py-1">Product Data:</P>
            <P className={dataStatusClasses(product.productDataStatus)}>
              {product.productDataStatus}
            </P>
          </div>
        </div>
        <div className="flex flex-row justify-center gap-x-2 md:flex-col mt-2 gap-y-2 py-1 col-span-2 md:col-span-1">
          <Link to={`/dashboard/products/${product.reference}`}>
            <Button primary outline className="md:w-full">
              View
            </Button>
          </Link>

          <Button
            secondary
            outline
            className="md:w-full"
            onClick={() => handleArchiveProduct(product._id)}
            loading={loading}
          >
            Archive
          </Button>
        </div>
      </div>
    </div>
  );
}
export default ProductItem;
