import { useGetCurrentUser, useGetDashboardReport } from "../../../api";
import Page from "../../../Containers/Page";
import FlexCentered from "../../../Containers/FlexCentered";
import Heading from "../../../Components/Heading";
import RecentSeedLots from "./RecentSeedLots";
import UsersRequiringAttention from "./UsersRequiringAttention";
import DisconnectedSeedLots from "./DisconnectedSeedLots";
import classNames from "classnames";
import getApiStatusPage from "../../APIStatusPage";

function AdminDash() {
  const getCurrentUserApi = useGetCurrentUser();
  const currentUser = getCurrentUserApi?.data?.user;

  const getDashboardReportApi = useGetDashboardReport();
  const dashboardReports = getDashboardReportApi?.data;
  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getCurrentUserApi,
        type: "Current User",
        dataRequired: true,
      },
      {
        api: getDashboardReportApi,
        type: "Dashboard report",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  let colCount = 0;
  const recentSeedLots = dashboardReports?.recentSeedLots;
  if (recentSeedLots && recentSeedLots.length > 0) colCount++;

  const usersRequiringAttention = dashboardReports?.usersRequiringAttention;
  const hasUsersReport =
    usersRequiringAttention && usersRequiringAttention.length > 0;

  const disconnectedSeedLots = dashboardReports?.disconnectedSeedLots;
  const hasLotReport = disconnectedSeedLots && disconnectedSeedLots.length > 0;

  if (hasUsersReport || hasLotReport) colCount++;

  return (
    <Page title="Admin - Dashbaord" className="pt-12 px-4">
      <FlexCentered col>
        <Heading primary>Welcome {currentUser.givenName}</Heading>
        {colCount > 0 && (
          <div
            className={classNames("grid w-full max-w-md  gap-x-4 mt-12", {
              "md:grid-cols-2 md:max-w-4xl": colCount > 1,
            })}
          >
            <RecentSeedLots recentSeedLots={dashboardReports?.recentSeedLots} />
            <div className="flex flex-col gap-y-8 order-first md:order-last mb-12 md:mb-0">
              <UsersRequiringAttention
                usersRequiringAttention={usersRequiringAttention}
              />
              <DisconnectedSeedLots
                disconnectedSeedLots={disconnectedSeedLots}
              />
            </div>
          </div>
        )}
      </FlexCentered>
    </Page>
  );
}

export default AdminDash;
