import { useState, createContext, useContext, useCallback } from "react";

const ToastContext = createContext();

const Provider = function ({ defaultState, children }) {
  const [toast, setToast] = useState(null);

  const createSuccessToast = useCallback((message) => {
    setToast({
      message,
      type: "success",
    });
  }, []);

  const createErrorToast = useCallback((message) => {
    setToast({
      message,
      type: "error",
    });
  }, []);

  const clearToast = useCallback(() => {
    setToast(null);
  }, []);

  const model = {
    toast,
    createSuccessToast,
    createErrorToast,
    clearToast,
  };

  return (
    <ToastContext.Provider value={model}>{children}</ToastContext.Provider>
  );
};

const useToastContext = function () {
  return useContext(ToastContext);
};

export { useToastContext };
export default Provider;
