import Heading from "../../../Components/Heading";
import Link from "../../../Components/Link";
import FlexCentered from "../../../Containers/FlexCentered";
import P from "../../../Components/P";
import formattedNumber from "../../../utils/formattedNumber";

function RecentSeedLots({ recentSeedLots }) {
  if (!recentSeedLots || recentSeedLots.length === 0) return;

  const renderedSeedLots = recentSeedLots.map((seedLot) => {
    return (
      <Link
        key={seedLot._id}
        to={`/admin/seed-lots/${seedLot.reference}`}
        className="w-full hover:text-hgBlue-900 hover:scale-[101%]"
      >
        <div className="rounded-t overflow-hidden bg-hgCream-50">
          <div className="px-2 py-4 bg-hgGreen-500 text-center">
            {seedLot.reference}
          </div>
          <div className="px-2 py-4 grid grid-cols-[auto_1fr] gap-x-4">
            <P>From:</P>
            <P>{seedLot?.issuedBy?.tradingName}</P>
            <P>To:</P>
            <P>{seedLot?.receivedBy?.tradingName}</P>
            <P>Date:</P>
            <P>{new Date(seedLot?.date).toLocaleDateString("en-gb")}</P>
            <P>Weight:</P>
            <P>{formattedNumber(seedLot.quantityKg)} kg</P>
          </div>
        </div>
      </Link>
    );
  });

  return (
    <div className="w-full rounded shadow overflow-hidden h-fit">
      <div className="bg-hgBlue-700 py-4">
        <Heading
          tertiary
          className="text-xl text-hgCream-50 text-center w-full font-light"
        >
          Recent Seed Lots
        </Heading>
      </div>
      <FlexCentered col className="gap-y-2 bg-hgBlue-100 py-4 px-2">
        {renderedSeedLots}
      </FlexCentered>
    </div>
  );
}

export default RecentSeedLots;
