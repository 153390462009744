import { useAddField } from "../../api";
import addFieldIcon from "../../assets/icons/fieldAdd.png";
import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import FlexCentered from "../../Containers/FlexCentered";
import P from "../../Components/P";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import Label from "../../Components/Label";
import RenderedErrors from "../../Components/RenderedErrors";

function AddFieldOverlay({
  onClose,
  onSuccess,
  icon,
  businessRef,
  forceAddMode,
}) {
  const [fieldName, setFieldName] = useState("");
  const [error, setError] = useState("");
  const addFieldApi = useAddField();

  useEffect(() => {
    if (addFieldApi.error) setError(addFieldApi.error);
    if (addFieldApi.data) {
      onSuccess(addFieldApi.data.field);
    }
  }, [addFieldApi, onSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");

    if (!fieldName) {
      setError("Enter the name of the field being added");
      return;
    }
    const body = {
      location: {
        type: "Point",
        coordinates: icon?.coords,
      },
      name: fieldName,
    };
    addFieldApi.mutate({
      businessRef,
      body,
    });
  };

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <div className="relative h-full w-full">
        <FlexCentered
          row
          className="absolute bottom-[2rem]
          inset-x-2"
        >
          <div className="relative pointer-events-auto bg-hgBlue-500 rounded-lg shadow px-8 py-2 text-hgCream-50">
            {!forceAddMode && (
              <div
                className="bg-hgCream-50 w-fit h-fit rounded-full p-1 absolute right-[5px] top-[5px] cursor-pointer hover:scale-105"
                onClick={onClose}
              >
                <AiOutlineClose className=" text-xl text-hgRed-700" />
              </div>
            )}

            {icon && (
              <P className="pt-[20px] text-sm sm:text-base">
                Drag the{" "}
                <img
                  src={addFieldIcon}
                  alt={"add field"}
                  className="inline w-8 mx-1"
                />{" "}
                icon to the center of the relevant field before saving
              </P>
            )}
            {!icon && (
              <P className="pt-[20px] text-sm sm:text-base">
                Click the map in the center of the relevant field
              </P>
            )}
            <form onSubmit={handleSubmit} noValidate id="addFieldForm">
              <FlexCentered col>
                <Label className="pt-2 pb-1">Field Name</Label>
                <div className="flex flex-row items-stretch justify-center">
                  <Input
                    className="text-hgBlue-500 text-center"
                    value={fieldName}
                    onChange={(e) => setFieldName(e.target.value)}
                    disabled={!icon}
                  />
                  <Button
                    primary
                    className=""
                    disabled={!icon}
                    loading={addFieldApi.isFetching}
                  >
                    Save
                  </Button>
                </div>
                <RenderedErrors
                  errors={[]}
                  apiError={error}
                  className="bg-hgCream-50 text-hgBlue-700 mt-1"
                />
              </FlexCentered>
            </form>
          </div>
        </FlexCentered>
      </div>
    </div>
  );
}
export default AddFieldOverlay;
