import classNames from "classnames";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import FamilyTree from "../Components/FamilyTree/FamilyTree";
import Loading from "../Components/Loading";
import { useGetFamilyTree } from "../api";
import FlexCentered from "../Containers/FlexCentered";
import Heading from "../Components/Heading";
import { useState } from "react";

function FamilyTreeReport({ populationRef, getSeedLotLink }) {
  const [i, setI] = useState(0);

  const getFamilyTreeApi = useGetFamilyTree({
    populationRef,
  });

  const familyTrees = getFamilyTreeApi?.data?.familyTrees;

  if (!familyTrees) return <Loading />;

  const focusOn = familyTrees[i]?.lots[0]?.reference;

  return (
    <FlexCentered col>
      <Heading
        primary
        className="pt-8 pb-2 text-2xl font-light text-hgBlue-900"
      >
        {populationRef}: Family Tree
      </Heading>
      <FlexCentered row className="mb-8 gap-x-4">
        <BsChevronLeft
          className={classNames(
            "text-xl mt-1 cursor-pointer hover:text-hgGreen-500",
            {
              "select-none pointer-events-none opacity-50": i === 0,
            }
          )}
          onClick={() => setI((curr) => curr - 1)}
        />
        <Heading secondary className="font-light text-xl">
          {i === 0 ? "Main tree" : `Disconnected #${i}`}
        </Heading>
        <BsChevronRight
          className={classNames(
            "text-xl mt-1 cursor-pointer hover:text-hgGreen-500",
            {
              "select-none pointer-events-none opacity-50":
                i === familyTrees.length - 1,
            }
          )}
          onClick={() => setI((curr) => curr + 1)}
        />
      </FlexCentered>
      <div className="w-full max-w-[97vw]">
        <div
          id="family-tree-wrapper"
          className=" overflow-hidden h-[80vh] w-full"
        >
          <FamilyTree
            key={i}
            familyTree={familyTrees[i]}
            focusOn={focusOn}
            getSeedLotLink={getSeedLotLink}
          />
        </div>
      </div>
    </FlexCentered>
  );
}
export default FamilyTreeReport;
