import { useEffect } from "react";
import { useToastContext } from "../providers/toastContext";
import { useForm, Controller } from "react-hook-form";
import { useUpdatePopulation, useDeletePopulation } from "../api";
import Button from "../Components/Button";
import Input from "../Components/Input";
import UploadImage from "../Components/UploadImage";
import FlexCentered from "../Containers/FlexCentered";
import RenderedErrors from "../Components/RenderedErrors";
import Label from "../Components/Label";
import populationSchema from "./schemas/populationSchema";
import Dropdown from "../Components/Dropdown";
import Heading from "../Components/Heading";
import TextBox from "../Components/TextBox";
import UploadPDF from "../Components/UploadPDF";
import { useNavigate } from "react-router-dom";

function EditPopulationForm({
  population,
  populationTypes,
  cropTypes,
  businesses,
}) {
  const updatePopulationApi = useUpdatePopulation();
  const deletePopulationApi = useDeletePopulation();

  const { createSuccessToast } = useToastContext();
  const navigateTo = useNavigate();
  useEffect(() => {
    if (updatePopulationApi?.data?.population) {
      createSuccessToast("Population updated successfully.");
      navigateTo(
        `/admin/populations/${updatePopulationApi?.data?.population?.reference}`
      );
    }
  }, [createSuccessToast, navigateTo, updatePopulationApi?.data?.population]);

  useEffect(() => {
    if (deletePopulationApi?.isSuccess) {
      createSuccessToast("Population deleted successfully.");
      navigateTo(`/admin/populations`);
    }
  }, [createSuccessToast, deletePopulationApi?.isSuccess, navigateTo]);

  const form = useForm({
    values: {
      name: population?.name,
      reference: population?.reference,
      type: populationTypes.find((type) => type.label === population.type),
      cropType: cropTypes.find((type) => type._id === population.cropType),
      image: population.image,
      shortDesc: population.shortDesc,
      fullDesc: population.fullDesc,
      APHA_Registration_Form: population?.APHA_Registration_Form
        ? { name: `${population.reference}-APHA.pdf` }
        : null,
    },
  });
  const errors = form.formState.errors;
  let apiError = updatePopulationApi.error || deletePopulationApi.error;

  const { register } = form;
  const schema = populationSchema();
  const nameProps = register("name", schema.name);
  const referenceProps = register("reference", schema.reference);
  register("type", schema.type);
  register("cropType", schema.cropType);
  register("shortDesc", schema.shortDesc);
  register("fullDesc", schema.fullDesc);
  register("APHA_Registration_Form", schema.APHA_Registration_Form);
  register("image", schema.image);

  const handleSubmit = (data) => {
    const body = new FormData();
    Object.keys(form.formState.dirtyFields).forEach((key) => {
      let value = data[key];
      if (key === "APHA_Registration_Form" && !value) {
        body.append("unsetAPHA", true);
      }
      if (value) {
        if (key === "type") {
          body.append(key, value.label);
        } else if (key === "cropType") {
          body.append(key, value._id);
        } else {
          body.append(key, value);
        }
      }
    });
    updatePopulationApi.mutate({ body, populationRef: population._id });
  };

  const handleDelete = () => {
    deletePopulationApi.mutate({ populationRef: population._id });
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
        <FlexCentered col className="pt-4 pb-12">
          <Heading primary className="pb-12">
            Edit: {population.name}
          </Heading>
          <Heading
            secondary
            className="w-full pb-2"
            title="Details about the population being added"
          >
            Population Details
          </Heading>
          <div className="border-b border-hgGreen-900 w-full mb-8" />
          <Label htmlFor="name" title="Enter the population name">
            Name
          </Label>
          <Input
            type="text"
            id="name"
            title="Enter the population name"
            className="w-[20rem] max-w-full mb-4 mt-2"
            {...nameProps}
            ref={null}
            innerRef={nameProps.ref}
            error={errors.name}
          />
          <Label
            htmlFor="reference"
            title="Enter a short reference for the population"
          >
            Reference
          </Label>
          <Input
            type="text"
            id="reference"
            title="Enter a short reference for the population"
            className="w-[20rem] max-w-full mb-4 mt-2"
            {...referenceProps}
            ref={null}
            innerRef={referenceProps.ref}
            error={errors.reference}
          />
          <Label
            htmlFor="type"
            className="mb-2"
            title="Select a population type"
          >
            Population Type
          </Label>
          <Controller
            control={form.control}
            name="type"
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                id="type"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className="w-[20rem] max-w-full mb-4 mt-2"
                config={{
                  data: populationTypes,
                  options: {
                    disallowEmptyValues: true,
                  },
                }}
                error={errors.type}
                rules={schema.type}
                title="Select a population type"
              />
            )}
          />
          <Label htmlFor="cropType" className="mb-2" title="Select a crop type">
            Crop Type
          </Label>
          <Controller
            control={form.control}
            name="cropType"
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                id="cropType"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className="w-[20rem] max-w-full mb-4 mt-2"
                config={{
                  data: cropTypes,
                  getLabel: (el) => el?.name,
                  options: {
                    disallowEmptyValues: true,
                  },
                }}
                title="Select a crop type"
                error={errors.cropType}
                rules={schema.cropType}
              />
            )}
          />
          <Heading
            secondary
            className="w-full pb-2 pt-8"
            title="Useful information displayed to users about this population"
          >
            Useful Information
          </Heading>
          <div className="border-b border-hgGreen-900 w-full mb-8" />
          <Label
            htmlFor="image"
            className="mb-2"
            title="Upload an image for the population"
          >
            Image
          </Label>
          <Controller
            control={form.control}
            name="image"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <UploadImage
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.image}
                rules={schema.image}
              >
                Upload Image
              </UploadImage>
            )}
          />
          <Label
            htmlFor="shortDesc"
            className="mb-2 mt-8"
            title="Enter a short description for the population"
          >
            Short Description
          </Label>
          <Controller
            control={form.control}
            name="shortDesc"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextBox
                id="shortDesc"
                className="w-[20rem] max-w-full mt-2"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.shortDesc}
                rules={schema.shortDesc}
                title="Enter a short description for the population"
                max={65}
              />
            )}
          />
          <Label
            htmlFor="fullDesc"
            className="mb-2 mt-4"
            title="Enter a full description for the population"
          >
            Full Description
          </Label>
          <Controller
            control={form.control}
            name="fullDesc"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextBox
                id="fullDesc"
                className="w-[20rem] max-w-full mt-2 h-56"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.fullDesc}
                rules={schema.fullDesc}
                title="Enter a full description for the population"
                max={700}
              />
            )}
          />

          <Heading
            secondary
            className="w-full pb-2 pt-8"
            title="Optionally, you may upload the APHA registration form as a pdf"
          >
            APHA Registration Form (Optional)
          </Heading>
          <Controller
            control={form.control}
            name="APHA_Registration_Form"
            render={({ field: { onChange, value } }) => (
              <UploadPDF
                id="APHA_Registration_Form"
                onChange={onChange}
                value={value}
                className="w-[20rem] max-w-full mb-4 mt-2 "
                title="Upload the APHA registration form as a pdf"
                error={errors.APHA_Registration_Form}
                rules={schema.APHA_Registration_Form}
              />
            )}
          />
        </FlexCentered>
        <FlexCentered row className="gap-x-2">
          <Button
            primary
            outline
            type="submit"
            loading={updatePopulationApi.isLoading}
            disabled={Object.keys(form.formState.dirtyFields).length === 0}
          >
            Save
          </Button>
          <Button
            danger
            outline
            type="button"
            onClick={handleDelete}
            loading={deletePopulationApi.isLoading}
          >
            Delete
          </Button>
        </FlexCentered>

        {(form.formState.isSubmitted || apiError) && (
          <RenderedErrors
            errors={errors}
            apiError={apiError || deletePopulationApi.error}
            className="bg-hgCream-50 mb-12 max-w-[20rem]"
          />
        )}
      </form>
    </>
  );
}

export default EditPopulationForm;
