import { useRef } from "react";
import { CiMenuKebab } from "react-icons/ci";
import classNames from "classnames";
import ModalWindow from "./ModalWindow";

function Kebab({ isOpen, setIsOpen, className, children }) {
  const kebabRef = useRef(null);
  return (
    <div
      className={classNames("h-max w-max", className, {
        "text-hgGreen-900 text-xl": !className?.includes("text"),
        "fill-hgGreen-900": className?.includes("fill"),
      })}
      ref={kebabRef}
      onMouseEnter={() => setIsOpen(true)}
    >
      <CiMenuKebab />
      {isOpen && (
        <ModalWindow
          options={{
            relativeTo: {
              ref: kebabRef.current,
            },
            pos: {
              bottom: 0,
              right: 0,
            },
            onMouseOut: () => setIsOpen(false),
            onResize: () => setIsOpen(false),
            onClick: () => setIsOpen(false),
          }}
        >
          <div className="bg-hgBlue-500 text-hgCream-50 px-[2vw] py-2 w-max">
            {children}
          </div>
        </ModalWindow>
      )}
    </div>
  );
}
export default Kebab;
