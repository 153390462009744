import { createPortal } from "react-dom";

const MoveColumnAvatar = function ({
  activeStyles,
  children,
  top,
  left,
  innerRef,
}) {
  const style = {
    width: activeStyles.width,
    background: activeStyles.background,
    height: activeStyles.height,
    textAlign: activeStyles.textAlign,
    fontSize: activeStyles.fontSize,
    fontWeight: activeStyles.fontWeight,
    top,
    left,
  };
  const root = document.getElementById("modal");

  return createPortal(
    <div
      ref={innerRef}
      className="absolute z-[999] border text-hgCream-50"
      style={style}
    >
      {children}
    </div>,
    root
  );
};

export default MoveColumnAvatar;
