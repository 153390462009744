import { useEditField } from "../../api";
import editFieldIcon from "../../assets/icons/fieldEdit.png";
import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import FlexCentered from "../../Containers/FlexCentered";
import P from "../../Components/P";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import Label from "../../Components/Label";
import RenderedErrors from "../../Components/RenderedErrors";

function EditFieldOverlay({
  onClose,
  onSuccess,
  fieldToEdit,
  icon,
  businessRef,
}) {
  const [fieldName, setFieldName] = useState(fieldToEdit?.name || "");
  const [error, setError] = useState("");
  const editFieldApi = useEditField();

  useEffect(() => {
    if (editFieldApi.error) setError(editFieldApi.error);
    if (editFieldApi.data) {
      onSuccess();
    }
  }, [editFieldApi, onSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");

    if (!fieldName) {
      setError("Enter the name of the field being added");
      return;
    }
    const body = {
      location: {
        type: "Point",
        coordinates: icon?.coords,
      },
      name: fieldName,
    };
    editFieldApi.mutate({
      businessRef,
      fieldRef: fieldToEdit?._id?.toString(),
      body,
    });
  };

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <div className="relative h-full w-full">
        <FlexCentered
          row
          className="absolute bottom-[2rem]
          inset-x-2"
        >
          <div className="relative pointer-events-auto bg-hgBlue-500 rounded-lg shadow px-8 py-2 text-hgCream-50">
            <div
              className="bg-hgCream-50 w-fit h-fit rounded-full p-1 absolute right-[5px] top-[5px] cursor-pointer hover:scale-105"
              onClick={onClose}
            >
              <AiOutlineClose className=" text-xl text-hgRed-700" />
            </div>

            <P className="pt-[20px] text-sm sm:text-base">
              Drag the{" "}
              <img
                src={editFieldIcon}
                alt={"add field"}
                className="inline w-8 mx-1"
              />{" "}
              icon to reposition the field
            </P>

            <form onSubmit={handleSubmit}>
              <FlexCentered col>
                <Label className="pt-2 pb-1">Field Name</Label>
                <div className="flex flex-row items-stretch justify-center">
                  <Input
                    className="text-hgBlue-500 text-center"
                    value={fieldName}
                    onChange={(e) => setFieldName(e.target.value)}
                    disabled={!icon}
                  />
                  <Button
                    primary
                    className=""
                    disabled={!icon}
                    loading={editFieldApi.isFetching}
                  >
                    Save
                  </Button>
                </div>

                <RenderedErrors
                  errors={[]}
                  apiError={error}
                  className="bg-hgCream-50 text-hgBlue-700 "
                />
              </FlexCentered>
            </form>
          </div>
        </FlexCentered>
      </div>
    </div>
  );
}
export default EditFieldOverlay;
