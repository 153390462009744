import { useParams } from "react-router-dom";
import { useGetPopulation } from "../../api";
import { useState } from "react";
import Link from "../../Components/Link";
import FlexCentered from "../../Containers/FlexCentered";
import Loading from "../../Components/Loading";
import Heading from "../../Components/Heading";
import P from "../../Components/P";
import ProductData from "../ViewSeedLot/ProductData";
import GrowingData from "../ViewSeedLot/GrowingData";
import Image from "../../Components/Image";
import Button from "../../Components/Button";
import DownloadButton from "../../Components/DownloadButton";

function ViewPopulation({
  familyTreeLink,
  editLink,
  notFoundLink,
  notFoundLabel,
}) {
  const { populationRef } = useParams();
  const [downloadError, setDownloadError] = useState("");
  const [downloadCSVError, setDownloadCSVError] = useState("");
  const getPopulationApi = useGetPopulation({
    populationRef,
    params: {
      fields: "name,image,fullDesc,APHA_Registration_Form,reference",
      "populate[cropType]": "name",
      includeProductStats: true,
    },
  });
  const population = getPopulationApi?.data?.population;
  const productStats = getPopulationApi?.data?.productStats;

  if (!getPopulationApi.isFetched) {
    return (
      <FlexCentered col className="pt-12 min-h-screen">
        <Loading />
      </FlexCentered>
    );
  }

  if (!population || getPopulationApi.error)
    return (
      <FlexCentered col className="pt-12">
        <Heading primary className="text-xl text-hgGreen-900">
          404 - Population Not Found
        </Heading>
        <P className="mt-4">
          Return to{" "}
          <Link to={notFoundLink} className="underline">
            {notFoundLabel}
          </Link>
        </P>
      </FlexCentered>
    );

  return (
    <FlexCentered col className="pt-12 px-4">
      <FlexCentered col className="w-full max-w-xl lg:max-w-5xl">
        <FlexCentered row className="gap-4  mb-8">
          <Heading primary className="text-3xl text-hgGreen-1100">
            {population.name}
          </Heading>
          {editLink && (
            <Link to={editLink(population.reference)}>
              <Button secondary outline className="py-1 px-2">
                Edit
              </Button>
            </Link>
          )}
        </FlexCentered>

        <div className="grid lg:grid-cols-2 gap-y-4">
          <div className="rounded-lg lg:rounded-r-none overflow-hidden shadow-lg h-full">
            <Image
              src={population.image}
              alt={population.name}
              className="h-full"
            />
          </div>
          <FlexCentered>
            <div className="rounded-lg lg:rounded-l-none w-fit lg:w-full lg:h-full overflow-hidden shadow-lg bg-hgBlue-100 bg-opacity-30 px-2 py-4 lg:px-8 py-4">
              <P className="italic font-semibold tracking-wide mb-4">
                {population.cropType?.name}
              </P>
              <P>{population.fullDesc}</P>
            </div>
          </FlexCentered>
        </div>

        <div className="w-max grid grid-cols-2 mt-12 gap-x-2">
          <Link to={familyTreeLink(populationRef)} className="w-full">
            <Button primary outline className="w-full">
              Family Tree
            </Button>
          </Link>

          <DownloadButton
            url={`populations/${population._id}/registration-form`}
            fileName={`${population.name}`}
            fileType="pdf"
            primary
            outline
            setError={setDownloadError}
            disabled={!population.APHA_Registration_Form}
          >
            Registration Form
          </DownloadButton>
          {downloadError && (
            <div className="col-span-2">
              <P className="text-red-900 mt-2 text-center">{downloadError}</P>
            </div>
          )}
        </div>

        <div className="grid lg:grid-cols-2 gap-x-4 w-full mt-20 max-w-5xl">
          <div className="flex flex-col items-center mt-6 lg:mt-0">
            <ProductData productData={productStats} populationRoute />
          </div>
          <div className="flex flex-col items-center mt-20 lg:mt-0">
            <GrowingData
              growingData={
                productStats?.totalDataPoints > 0 ? [productStats] : undefined
              }
              showMap={false}
            />
          </div>
        </div>
        {editLink && (
          <DownloadButton
            primary
            outline
            fileType="csv"
            fileName={`${populationRef}_product_data`}
            url={`populations/${population?._id}/product-data-csv`}
            className="mt-8"
            setError={setDownloadCSVError}
          >
            Download Data
          </DownloadButton>
        )}
        {downloadCSVError && (
          <div className="col-span-2">
            <P className="text-red-900 mt-2 text-center">{downloadCSVError}</P>
          </div>
        )}
      </FlexCentered>
    </FlexCentered>
  );
}
export default ViewPopulation;
