import { useEffect, useMemo } from "react";
import useQuery from "../../../hooks/useQuery";
import { useGetAllProducts, useGetAllPopulations } from "../../../api";
import Page from "../../../Containers/Page";
import ProductListContext from "../../../Sections/ProductsList/ProductListContext";
import ProductsList from "../../../Sections/ProductsList";
import getApiStatusPage from "../../APIStatusPage";

function ProductsPage() {
  const defaultQuery = {
    fields: "reference,population,seedLot,growingDataStatus,productDataStatus",
    "populate[seedLot]": "seedlots.date",
    parentBusiness: "$$LINKED_BUSINESS$$",
    sort: "-seedLot/date",
  };

  const [query, isStale, setIsStale, setQuery] = useQuery(defaultQuery);

  const getProductsApi = useGetAllProducts({
    params: query,
  });
  const products = getProductsApi?.data?.products;

  const getAllPopulationsApi = useGetAllPopulations({
    params: {
      fields: "name,image,cropType",
      "populate[cropType]": "croptypes.name",
      completeList: true,
    },
  });

  const populations = getAllPopulationsApi?.data?.populations;
  const populationsObj = useMemo(() => {
    if (!populations) return {};
    const docObj = {};
    populations.forEach((population) => {
      docObj[population._id?.toString()] = population;
    });
    return docObj;
  }, [populations]);

  useEffect(() => {
    if (!isStale) {
      getProductsApi.refetch();
      setIsStale(true);
    }
  }, [getProductsApi, isStale, query, setIsStale]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getAllPopulationsApi,
        type: "Populations",
        dataRequired: true,
      },
      {
        api: getProductsApi,
        type: "Products",
        dataRequired: true,
        initialLoadOnly: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <ProductListContext
      api={getProductsApi}
      query={query}
      setQuery={setQuery}
      populations={populations}
      populationsObj={populationsObj}
    >
      <Page title="Products" className="px-4">
        <ProductsList
          products={products}
          getLink={(ref) => `/dashboard/products/${ref}`}
          populations={populations}
          populationsObj={populationsObj}
        />
      </Page>
    </ProductListContext>
  );
}

export default ProductsPage;
