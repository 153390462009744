import { useEffect, useRef, useState, useMemo } from "react";
import classNames from "classnames";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import FlexCentered from "../../Containers/FlexCentered";

function CalenderModal({
  wrapper,
  value,
  onChange,
  min,
  max,
  onClose,
  iconRef,
}) {
  const selectedDate = useMemo(() => {
    return value ? new Date(value) : new Date();
  }, [value]);
  selectedDate.setHours(1, 0, 0, 1);

  const [month, setMonth] = useState(selectedDate.getMonth());
  const [year, setYear] = useState(selectedDate.getFullYear());
  const modalRef = useRef(null);

  //POSITION MODAL BASED ON POSITION RELATIVE TO WINDOW
  useEffect(() => {
    if (!wrapper?.current || !modalRef?.current) return;
    const handlescroll = () => {
      const midWindow = window.scrollY + window.innerHeight / 2;
      const wrapperTop = wrapper?.current?.offsetTop - midWindow;
      if (wrapperTop > 0) {
        modalRef?.current?.classList?.remove("top-[100%]");
        modalRef?.current?.classList?.add("bottom-[100%]");
      } else {
        modalRef?.current?.classList?.remove("bottom-[100%]");
        modalRef?.current?.classList?.add("top-[100%]");
      }
    };
    handlescroll();
    window.addEventListener("scroll", handlescroll);
    return () => window.removeEventListener("scroll", handlescroll);
  });

  //CLOSE MODAL ON EXTERNAL CLICK
  useEffect(() => {
    if (!modalRef?.current || !iconRef?.current) return;
    const handleClick = (e) => {
      if (modalRef?.current?.contains(e.target)) return;
      if (iconRef?.current?.contains(e.target)) return;
      onClose();
    };
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  });

  const handleDirClick = (increment) => {
    if (month + increment < 0) {
      setMonth(11);
      setYear((curr) => curr + increment);
    } else if (month + increment > 11) {
      setMonth(0);
      setYear((curr) => curr + increment);
    } else {
      setMonth((curr) => curr + increment);
    }
  };

  //prettier-ignore
  const months = ["January","February","March","April","May","June","July","August","September","October","November","December",
  ];
  //prettier-ignore
  const days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday",
];

  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const lastDayofMonth = new Date(year, month + 1, 0).getDate();

  const boxDims = 31;
  const renderedHeaders = days.map((day) => {
    return (
      <div
        className="flex flex-col items-center justify-center opacity-70 text-center text-sm select-none"
        key={day}
        style={{ width: `${boxDims}px`, height: `${boxDims}px` }}
      >
        {day[0]}
      </div>
    );
  });
  const renderedDays = [];

  for (let i = 0; i <= 41; i++) {
    if (i < firstDayOfMonth || i + 1 > lastDayofMonth + firstDayOfMonth) {
      renderedDays.push(<div key={i} />);
    } else {
      const date = new Date(year, month, i + 1 - firstDayOfMonth, 1, 0, 0, 1);
      const isActive = date.toISOString() === selectedDate.toISOString();
      const isDisabled = (min && date < min) || (max && date > max);
      renderedDays.push(
        <div
          key={i}
          className={classNames(
            "flex flex-col items-center justify-center rounded-full text-sm font-semibold select-none",
            {
              "bg-hgGreen-900 text-hgCream-50 pointer-events-none": isActive,
              "hover:bg-hgGreen-100 cursor-pointer": !isActive,
              "pointer-events-none opacity-70": isDisabled,
            }
          )}
          style={{ width: `${boxDims}px`, height: `${boxDims}px` }}
          onClick={() => {
            onChange(date);
            onClose();
          }}
        >
          {i + 1 - firstDayOfMonth}
        </div>
      );
    }
  }

  return (
    <div
      className="absolute inset-x-0 top-[100%] pb-4 z-50 border-2 border-hgBlue-500 bg-hgCream-50"
      ref={modalRef}
    >
      <div className="flex flex-row justify-between px-4 py-2 pb-4 text-xl text-hgBlue-500">
        <div>
          {months[month]} {year}
        </div>
        <FlexCentered row className="gap-2">
          <BsChevronLeft
            className={classNames("hover:text-hgGreen-900 cursor-pointer", {
              "opacity-70 select-none pointer-events-none":
                min && new Date(year, month - 1, 1).getTime() < min.getTime(),
            })}
            onClick={() => handleDirClick(-1)}
          />
          <BsChevronRight
            className={classNames("hover:text-hgGreen-900 cursor-pointer", {
              "opacity-70 select-none pointer-events-none":
                max && new Date(year, month + 1, 1).getTime() > max.getTime(),
            })}
            onClick={() => handleDirClick(1)}
          />
        </FlexCentered>
      </div>
      <div className="grid grid-cols-7 px-[5px] gap-y-[1px] h-[16rem]">
        {renderedHeaders}
        {renderedDays}
      </div>
    </div>
  );
}
export default CalenderModal;
