import { useState, createContext, useContext } from "react";

import cloneDeep from "lodash.clonedeep";

const FormContext = createContext();

const Provider = function ({ defaultState, children }) {
  const [formState, setFormState] = useState(defaultState || {});

  const updateFormState = (state) => {
    const updatedFormState = cloneDeep(formState);
    Object.keys(state).forEach((key) => {
      updatedFormState[key] = state[key];
    });
    setFormState(updatedFormState);
  };

  const model = {
    formState,
    updateFormState,
  };

  return <FormContext.Provider value={model}>{children}</FormContext.Provider>;
};

const useFormContext = function () {
  return useContext(FormContext);
};

export { useFormContext };
export default Provider;
