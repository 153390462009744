function getAdminLinks(currentUser) {
  if (!currentUser.emailConfirmed || currentUser.restrictedAccess) return [];
  const permissions = currentUser?.permissions;

  const adminLinks = [
    {
      to: "/admin/users",
      label: "Users",
    },
    {
      to: "/admin/businesses",
      label: "Businesses",
      children: permissions.writeBusiness
        ? [
            {
              to: "/admin/businesses/add-business",
              label: "Add Business",
            },
          ]
        : undefined,
    },
    {
      to: "/admin/populations",
      label: "Populations",
      children: permissions.writePopulations
        ? [
            {
              to: "/admin/populations/add-population",
              label: "Add Population",
            },
            {
              to: "/admin/populations/crop-types",
              label: "Crop Types",
            },
          ]
        : undefined,
    },
    {
      to: "/admin/seed-lots",
      label: "Seed Lots",
      children: permissions.writeSeedLots
        ? [
            {
              to: "/admin/seed-lots/add-seed-lot",
              label: "Add Seed Lot",
            },
          ]
        : undefined,
    },
  ];

  return adminLinks;
}
export default getAdminLinks;
