function getPublicLinks(currentUser, handleSignIn) {
  if (!currentUser.isLoggedIn) {
    return [
      {
        to: "/sign-in",
        label: "Sign In",
        onClick: handleSignIn,
      },
      {
        to: "/sign-up",
        label: "Sign Up",
        cta: true,
      },
    ];
  } else {
    const dashboardLink = {
      to: "/dashboard",
      label: "Dashboard",
    };
    const adminLink = {
      to: "/admin",
      label: "Admin",
    };
    const publicLinks = [];

    if (
      currentUser.hasLinkedBusiness ||
      !currentUser.isAdmin ||
      !currentUser.emailConfirmed ||
      currentUser.restrictedAccess
    ) {
      publicLinks.push(dashboardLink);
    }
    if (
      currentUser.isAdmin &&
      currentUser.emailConfirmed &&
      !currentUser.restrictedAccess
    )
      publicLinks.push(adminLink);

    return publicLinks;
  }
}
export default getPublicLinks;
