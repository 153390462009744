import P from "./P";
function Address({ formattedAddress }) {
  if (!formattedAddress) return;
  const address = formattedAddress.join(",").split(",");
  const renderedLines = [];
  address.forEach((line, i) => {
    if (!line) return;
    renderedLines.push(
      <P key={i}>{`${line}${i !== address.length - 1 ? "," : ""}`}</P>
    );
  });
  return renderedLines;
}

export default Address;
