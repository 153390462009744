import classNames from "classnames";
import logo from "../../assets/logo-w-text.png";
import logoLight from "../../assets/logo-w-text-light.png";
import Link from "../../Components/Link";

function Logo({ adminRoute }) {
  return (
    <Link to="/">
      <div
        className={classNames(
          "w-max p-1 flex flex-col items-center justify center rounded-lg"
        )}
      >
        <img
          src={adminRoute ? logo : logoLight}
          alt="Heterogen logo"
          className="h-[45px] transition-all duration-300 ease-in-out"
        />
      </div>
    </Link>
  );
}

export default Logo;
