import { useGetAddress } from "../../api";
import { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import Input from "../../Components/Input";
import Label from "../../Components/Label";
import FlexCentered from "../../Containers/FlexCentered";
import Button from "../../Components/Button";
import Dropdown from "../../Components/Dropdown";
import Address from "../../Components/Address";
import Heading from "../../Components/Heading";
import RenderedErrors from "../../Components/RenderedErrors";

function AddressInput({ value, onChange, darkMode }) {
  const [postcode, setPostcode] = useState(value?.postcode || "");
  const [formattedPostcode, setFormattedPostcode] = useState(
    value?.postcode || null
  );
  const [isnewData, setIsNewData] = useState(false);
  const [getAddressIsEnabled, setGetAddressIsEnabled] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(
    value?.selectedAddress || null
  );
  const [formattedAddress, setFormattedAddress] = useState(
    value?.formattedAddress || null
  );

  const [location, setLocation] = useState(value?.location || null);

  const getAddressApi = useGetAddress({
    postcode,
    enabled: getAddressIsEnabled && !!postcode,
  });

  const [customError, setCustomError] = useState();
  let apiError = customError || getAddressApi.error;

  useEffect(() => {
    if (value && postcodeRef.current) {
      postcodeRef.current.value = value.postcode;
    }
  }, [value]);

  const handleFind = (e) => {
    e?.preventDefault();
    setGetAddressIsEnabled(true);
    setCustomError("");
    setSelectedAddress(null);
    setFormattedAddress(null);
    onChange(null);

    const val = postcodeRef?.current?.value.replace(" ", "");
    const primaFacieValid =
      /^[a-z0-9]*$/i.test(val) && val.length >= 5 && val.length <= 7;
    if (!primaFacieValid) return setCustomError("Invalid postcode");
    setCustomError("");
    setPostcode(val);
    setIsNewData(true);
  };

  useEffect(() => {
    const handleEnterKey = (e) => {
      if (e?.key !== "Enter") return;
      if (!postcodeRef?.current?.contains(e.target)) return;
      e?.preventDefault();
      handleFind();
    };
    document.addEventListener("keypress", handleEnterKey);
    return () => document.removeEventListener("keypress", handleEnterKey);
  });

  useEffect(() => {
    if (getAddressApi.data && isnewData) {
      if (getAddressApi.data.addresses.length === 0) {
        setCustomError("Address not found");
        onChange(null);
      }
      setIsNewData(false);
      const newPostcode = getAddressApi.data.postcode;
      const newLocation = {
        type: "Point",
        coordinates: [
          getAddressApi.data.longitude,
          getAddressApi.data.latitude,
        ],
      };
      setFormattedPostcode(newPostcode);
      setLocation(newLocation);
      setSelectedAddress(null);
      setFormattedAddress(null);
      onChange({
        postcode: newPostcode,
        location: newLocation,
      });
    }
  }, [getAddressApi.data, isnewData, onChange]);

  const selectAddressConfig = {
    data: getAddressApi.data?.addresses,
    getLabel: (address) => {
      let label = [];
      address?.formatted_address?.forEach((line) => {
        if (line) label.push(line);
      });
      return label.join(", ");
    },
    getKey: (address) => address?.formatted_address?.join(""),
  };

  const postcodeRef = useRef(null);
  const labelClasses = (className) =>
    classNames(className, {
      "text-hgCream-50": darkMode,
    });

  const handleSelectAddress = (val) => {
    setSelectedAddress(val);
    if (!val?.formatted_address) {
      setFormattedAddress(null);
      onChange({
        postcode: formattedPostcode,
        location,
        selectedAddress: val,
      });
    } else {
      const newAddress = [...val.formatted_address, formattedPostcode];
      setFormattedAddress(newAddress);
      onChange({
        postcode: formattedPostcode,
        formattedAddress: newAddress,
        location,
        selectedAddress: val,
      });
    }
  };

  return (
    <FlexCentered
      col
      className={classNames({
        "pb-24": !formattedAddress,
        "pb-8": formattedAddress,
      })}
    >
      <Label htmlFor="postcode" className={labelClasses("mb-2")}>
        Postcode
      </Label>
      <div className="grid grid-cols-[1fr_auto] w-[20rem] max-w-full mb-4">
        <Input
          type="text"
          innerRef={postcodeRef}
          onChange={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
        <Button
          primary
          type="button"
          className="h-full w-[auto]"
          onClick={handleFind}
          loading={getAddressApi.isFetching}
        >
          Find
        </Button>
      </div>
      {apiError && (
        <RenderedErrors
          errors={{}}
          apiError={apiError}
          className="bg-hgCream-50 mb-4"
        />
      )}
      {selectAddressConfig.data &&
        selectAddressConfig.data.length > 0 &&
        !apiError && (
          <Dropdown
            value={selectedAddress}
            onChange={handleSelectAddress}
            config={selectAddressConfig}
            maxHeight="max-h-32"
            className="w-[20rem] max-w-full mb-4"
            placeholder="Select address"
          />
        )}
      {formattedAddress && (
        <div className="bg-hgCream-50 px-8 py-4 rounded-lg w-[20rem] max-w-full">
          <Heading tertiary className="text-center mb-1">
            Address
          </Heading>
          <div className="border-b-2 mb-2" />
          <Address formattedAddress={formattedAddress} />
        </div>
      )}
    </FlexCentered>
  );
}

export default AddressInput;
