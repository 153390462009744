import { useState, useEffect, useCallback } from "react";
import getDefaultColumns from "../helpers/getDefaultColumns";
import useMoveColumn from "./useMoveColumn";

function useTableColumns(tableElement, options, config) {
  const { defaultCols, visibleCols } = getDefaultColumns(config);

  const [gridTemplateColumns, setGridTemplateColumns] = useState(defaultCols);
  const [activeTab, setActiveTab] = useState(null);
  const [tablePadding, setTablePadding] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(visibleCols);
  const [moveColumnTo, setMoveColumnTo] = useState(null);
  const [moveColumIcon, setMoveColumnIcon] = useState(null);

  const handleUpdateColumns = useCallback(
    (from, to) => {
      const updatedGridTemplate = gridTemplateColumns.split(" ");
      const updatedCols = [...visibleColumns];
      let toObj;
      let fromObj;
      for (let i = 0; i < visibleColumns.length; i++) {
        const currentCol = visibleColumns[i];
        if (currentCol.label === to)
          toObj = { col: currentCol, size: updatedGridTemplate[i], i };
        if (currentCol.label === from)
          fromObj = { col: currentCol, size: updatedGridTemplate[i], i };
      }
      if (!fromObj || !toObj) return;
      updatedCols[toObj.i] = fromObj.col;
      updatedCols[fromObj.i] = toObj.col;
      updatedGridTemplate[toObj.i] = fromObj.size;
      updatedGridTemplate[fromObj.i] = toObj.size;

      setVisibleColumns(updatedCols);
      setGridTemplateColumns(updatedGridTemplate.join(" "));
    },
    [gridTemplateColumns, visibleColumns]
  );

  useMoveColumn(
    config.movableColumns,
    tableElement,
    setMoveColumnTo,
    setMoveColumnIcon,
    handleUpdateColumns
  );

  useEffect(() => {
    const { defaultCols, visibleCols } = getDefaultColumns(config);
    setGridTemplateColumns(defaultCols);
    setVisibleColumns(visibleCols);
  }, [config]);

  //If the table has a horizontal overlap, reset the table size
  //to remove this overlap - Only active if resizable columns used
  useEffect(() => {
    if (!config.resizeableColumns) {
    }
    const header = document.getElementById("header");
    const headerWidth = header?.getBoundingClientRect().width;
    if (!tableElement) {
      document.body.classList.remove("min-w-max");
      document.body.classList.add("max-w-full");
      document.body.classList.add("W-[100vw]");
      document.body.classList.add("overflow-hidden");
      if (header && headerWidth) {
        header.style.width = `${headerWidth}px`;
      }

      return;
    }
    document.body.classList.add("min-w-max");
    document.body.classList.remove("max-w-full");
    document.body.classList.remove("overflow-hidden");
    header.style.width = "100%";
    tableElement.classList.remove("opacity-0");

    if (tableElement.scrollWidth > +tableElement.offsetWidth)
      tableElement.style.width = `${tableElement.scrollWidth}px`;
  }, [config.resizeableColumns, gridTemplateColumns, tableElement]);

  // Add listener for window resize where table widths fixed on resize
  useEffect(() => {
    if (!config.resizeableColumns) return;
    const rootWidth = document.getElementById("root").offsetWidth;
    const tableWidth = tableElement?.offsetWidth;
    if (tableWidth && rootWidth) setTablePadding(rootWidth - tableWidth);

    const handleWindowResize = (e) => {
      const hasVerticalScroll = window.innerHeight < document.body.clientHeight;
      const verticalScrollOffest = hasVerticalScroll ? 17 : 0;

      tableElement.style.width = `${
        window.innerWidth - tablePadding - verticalScrollOffest
      }px`;

      if (tableElement.scrollWidth > +tableElement.offsetWidth)
        tableElement.style.width = `${tableElement.scrollWidth}px`;
    };

    if (tableElement) handleWindowResize();

    if (options.fixed) {
      window.addEventListener("resize", handleWindowResize);
    }
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [config.resizeableColumns, options.fixed, tableElement, tablePadding]);

  //Refs - Updated when tab activated
  let headers;
  let activeHeader;

  //////////
  //Add listeners when tab selected to track mouse
  /////////
  const addMouseListeners = () => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  //////////
  //Handle tab activation
  /////////
  const resizeColumns = (activeTab) => {
    if (!tableElement) return;
    const header = tableElement.querySelector("thead");
    headers = Array.from(header.querySelectorAll("td"));
    activeHeader = activeTab.current.closest("td");
    setActiveTab(activeTab.current.dataset.id);
    addMouseListeners();
  };

  //////////
  // Remove listeners on mouse up
  /////////
  const handleMouseUp = (e) => {
    setActiveTab(null);
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  //////////
  // Handle column resizing
  /////////
  const handleMouseMove = (e) => {
    const updatedGridTemplateColumns = headers.map((header, i) => {
      const unit = options.fixed ? "fr" : "px";
      const minWidth = `${header.dataset.minWidth}`;

      if (header === activeHeader) {
        const baseWidth = e.clientX - header.offsetLeft;
        const width = options.fixed
          ? (Math.max(baseWidth, +minWidth.slice(0, -2)) /
              tableElement.offsetWidth) *
            100
          : baseWidth;
        return `minmax(${minWidth},${width}${unit})`;
      } else {
        const baseWidth = header.offsetWidth;
        const width = options.fixed
          ? (Math.max(baseWidth, +minWidth.slice(0, -2)) /
              tableElement.offsetWidth) *
            100
          : baseWidth;
        return `minmax(${minWidth},${width}${unit})`;
      }
    });

    //RETURN NEW GRID COLUMNS - IF WIDTH FIXED THEN SET WIDTH ON TABLE
    if (options.fixed)
      tableElement.style.width = `${tableElement.offsetWidth}px`;
    setGridTemplateColumns(updatedGridTemplateColumns.join(" "));
  };

  return {
    resizeColumns,
    gridTemplateColumns,
    activeTab,
    visibleColumns,
    moveColumnTo,
    moveColumIcon,
  };
  //resizeColumns, gridTemplateColumns, activeTab
}

export default useTableColumns;
