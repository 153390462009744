import classNames from "classnames";
import Heading from "../../Components/Heading";
import { BsChevronDown } from "react-icons/bs";
import P from "../../Components/P";
import FlexCentered from "../../Containers/FlexCentered";
import { Fragment } from "react";
import Link from "../../Components/Link";

function SeedLotCard({ seedLot, isOdd }) {
  return (
    <div
      className={classNames(
        "bg-hgCream-50  rounded-lg shadow overflow-hidden w-[65%] hover:scale-[101%] hover:shadow-lg cursor-pointer select-none",
        {
          "translate-x-[53.84615%]": isOdd,
        }
      )}
    >
      <div className="bg-hgGreen-1100 py-3">
        <Heading
          tertiary
          className="font-light text-center text-lg text-hgCream-50"
        >
          {seedLot.reference}
        </Heading>
      </div>
      <div className="bg-hgGreen-900 text-hgCream-50 text-center py-1">
        {new Date(seedLot.date).toLocaleDateString("en-gb")}
      </div>

      <div className="grid grid-cols-[auto_1fr] ">
        <div className="contents bg-hgBlue-200">
          <div className="bg-inherit p-2 font-semibold tracking-wider">
            <P>From</P>
          </div>
          <div className="flex flex-col justify-center px-2 bg-hgBlue-100">
            <P>{seedLot.issuedBy?.tradingName}</P>
          </div>
          <div className="bg-inherit p-2 font-semibold tracking-wider">
            <P>To</P>
          </div>
          <div className="flex flex-col justify-center px-2 bg-hgBlue-100">
            <P>{seedLot.receivedBy?.tradingName}</P>
          </div>
        </div>
      </div>
    </div>
  );
}

function Lineage({ lineageData, getLink }) {
  const renderedCards = [];
  for (let i = lineageData?.length - 1; i >= 0; i--) {
    const seedLot = lineageData[i];
    renderedCards.push(
      <Fragment key={seedLot._id?.toString()}>
        <Link to={getLink(seedLot.reference)} className="hover:text-hgBlue-900">
          <SeedLotCard seedLot={seedLot} isOdd={i % 2 !== 0} />
          {i !== 0 && (
            <div className="relative w-full h-[2.5rem]">
              <div className="absolute w-[2px] bg-hgBlue-900 inset-y-0 left-[50%] -translate-x-[50%]" />
              <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-fit h-fit p-1 rounded-full bg-hgGreen-900  opacity-95">
                <BsChevronDown className="text-hgCream-50" />
              </div>
            </div>
          )}
        </Link>
      </Fragment>
    );
  }

  return (
    <FlexCentered col>
      <FlexCentered
        col
        className="bg-hgBlue-100 bg-opacity-30 rounded-lg w-full max-w-md  lg:max-w-full overflow-hidden pb-8 mx-4 shadow-lg"
      >
        <div className="bg-hgBlue-700 py-4 w-full mb-8">
          <Heading
            tertiary
            className="text-xl text-hgCream-50 text-center w-full font-light"
          >
            Lineage
          </Heading>
        </div>

        <div className="flex flex-col w-full max-w-sm ">{renderedCards}</div>
      </FlexCentered>
    </FlexCentered>
  );
}

export default Lineage;
