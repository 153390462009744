import { createContext, useContext } from "react";

const PopulationListContext = createContext();

const Provider = function ({ api, query, setQuery, children, cropTypes }) {
  const model = {
    query,
    setQuery,
    api,
    cropTypes,
  };

  return (
    <PopulationListContext.Provider value={model}>
      {children}
    </PopulationListContext.Provider>
  );
};

const usePopulationListContext = function () {
  return useContext(PopulationListContext);
};

export { usePopulationListContext };
export default Provider;
