import { AiOutlineClose } from "react-icons/ai";
import { FaInfo } from "react-icons/fa";
import classNames from "classnames";
import P from "../../Components/P";

function InfoPanel({ children, onClose, className, ...props }) {
  return (
    <div
      className={classNames(
        "relative bg-hgGreen-400 rounded-lg shadow-lg pl-12 pr-12 pt-4 pb-2 w-fit",
        className
      )}
      {...props}
    >
      <div className="absolute top-[0] left-[5px] -translate-y-1/2 bg-hgBlue-600 p-2 text-hgCream-50 rounded-full border-2 border-hgCream-50 shadow-md">
        <FaInfo />
      </div>
      <div
        className="absolute top-[5px] right-[5px] bg-hgCream-50 p-1 rounded-full hover:text-red-900 hover:scale-105 cursor-pointer shadow"
        onClick={onClose}
      >
        <AiOutlineClose />
      </div>

      <P className="">{children}</P>
    </div>
  );
}

export default InfoPanel;
