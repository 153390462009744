import FlexCentered from "../Containers/FlexCentered";
import Heading from "../Components/Heading";
import P from "../Components/P";
function FailedToConnectPage() {
  return (
    <div className="bg-hgBlue-500 h-[100vh]">
      <FlexCentered col className="h-full">
        <div className="p-8 bg-hgCream-50 rounded">
          <Heading primary className=" mb-4 text-center text-xl">
            Failed to Connect
          </Heading>
          <P className="mb-2">
            Sorry, we failed to connect to the database. Please check your
            internet connection
          </P>
          <P>
            If the problem persists please contact{" "}
            <a
              href="mailto:info@heterogen.uk"
              className="underline hover:text-hgCream-300"
            >
              info@heterogen.uk
            </a>
          </P>
        </div>
      </FlexCentered>
    </div>
  );
}
export default FailedToConnectPage;
