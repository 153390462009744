import { useState, useRef } from "react";
import { BiCog } from "react-icons/bi";
import ModalWindow from "../../../ModalWindow";
import classNames from "classnames";
import SetVisibleColumns from "./SetVisibleColumns";

function Settings() {
  const [isOpen, setIsOpen] = useState(false);
  const iconRef = useRef(null);

  return (
    <>
      <div ref={iconRef}>
        <BiCog
          className={classNames("cursor-pointer hover:text-hgGreen-800", {
            "text-hgGreen-800": isOpen,
          })}
          onClick={() => setIsOpen((cur) => !cur)}
        />
      </div>
      {isOpen && (
        <ModalWindow
          options={{
            relativeTo: {
              ref: iconRef?.current,
            },
            pos: {
              bottom: 0,
              right: 0,
            },
            onClickOut: () => setIsOpen(false),
          }}
        >
          <div className="bg-hgBlue-600 rounded-lg p-4 shadow-lg">
            <SetVisibleColumns />
          </div>
        </ModalWindow>
      )}
    </>
  );
}
export default Settings;
