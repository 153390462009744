import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import classNames from "classnames";
import { useAdminLogIn } from "../api";
import Button from "../Components/Button";
import Input from "../Components/Input";
import FlexCentered from "../Containers/FlexCentered";
import Link from "../Components/Link";
import RenderedErrors from "../Components/RenderedErrors";

function SignInForm({ onClose }) {
  const logInApi = useAdminLogIn();

  const form = useForm();
  const errors = form.formState.errors;
  let apiError = logInApi.error;

  const { register } = form;
  const emailProps = register("email", {
    required: "Email required",
  });
  const passwordProps = register("password", {
    required: "Password required",
  });

  const handleSubmit = (data) => {
    logInApi.mutate({ body: data });
  };

  return (
    <>
      <form
        className="bg-hgBlue-500"
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
      >
        <div className="flex flex-row items-stretch justify-stretch overflow-hidden rounded-r-lg bg-inherit">
          <FlexCentered col>
            <Input
              type="email"
              id="email"
              placeholder="email"
              className={classNames(
                "rounded-tl-lg border-0 border-b-[1px] focus:border-b-[0px] border-gray-100 active:border-gray-100 focus:border-gray-100 focus:bg-hgGreen-50  w-[15rem] sm:w-[20rem] max-w-full",
                {
                  "bg-hgRed-50": errors.email,
                }
              )}
              {...emailProps}
              ref={null}
              innerRef={emailProps.ref}
            />
            <Input
              type="password"
              id="password"
              placeholder="password"
              className={classNames(
                "rounded-bl-lg border-gray-900 border-0 focus:border-0 active:border-0 focus:bg-hgGreen-50 w-[15rem] sm:w-[20rem] max-w-full",
                {
                  "bg-hgRed-50": errors.email,
                }
              )}
              {...passwordProps}
              ref={null}
              innerRef={passwordProps.ref}
            />
          </FlexCentered>
          <Button
            primary
            type="submit"
            className="h-[full] rounded-r-lg"
            loading={logInApi.isLoading}
          >
            Go
          </Button>
        </div>

        <div className="mt-2 text-hgCream-50 w-fit h-fit" onClick={onClose}>
          <Link
            to="forgot-password"
            className="text-hgCream-50 hover:text-hgCream-500"
          >
            Forgot Password
          </Link>
        </div>
        {form.formState.isSubmitted && (
          <RenderedErrors
            errors={errors}
            apiError={apiError}
            className="bg-hgCream-50"
          />
        )}
      </form>
      <DevTool control={form.control} />
    </>
  );
}

export default SignInForm;
