import { useEffect, useRef } from "react";
import { useGetCurrentUser } from "../../../api";
import Page from "../../../Containers/Page";
import cropImage from "../../../assets/landing-photo4.jpg";
import Heading from "../../../Components/Heading";
import Explore from "./Explore";

function Home() {
  const currentUserAPI = useGetCurrentUser();
  const minSectionHeight = 350;

  //Handle image brightness relative to scroll position
  const dimmerRef = useRef(null);
  useEffect(() => {
    if (!dimmerRef.current) return;
    const handleScroll = () => {
      const header = document.getElementById("header");
      let headerHeight = 60;
      if (header) headerHeight = header.getBoundingClientRect().height;
      const dimmerDims = dimmerRef?.current?.getBoundingClientRect();
      const visible = dimmerDims.top + window.innerHeight - headerHeight;
      const visiblePercent = Math.floor(
        (visible / (dimmerDims.height + headerHeight)) * 100
      );
      let opacity = Math.floor(65 * (visiblePercent / 100));
      if (opacity > 65) opacity = 65;
      if (opacity < 0) opacity = 0;

      dimmerRef.current.style.opacity = `${opacity}%`;
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  //Handle resize hero
  const heroRef = useRef(null);
  useEffect(() => {
    if (!heroRef?.current) return;
    const handleResize = () => {
      const header = document.getElementById("header");
      let headerHeight = 60;
      if (header) headerHeight = header.getBoundingClientRect().height;
      if (heroRef?.current) {
        const heroHeight = Math.max(
          minSectionHeight,
          window.innerHeight - headerHeight
        );
        heroRef.current.style.height = `${heroHeight}px`;
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const currentUser = currentUserAPI.data;
  if (!currentUser) return <div>Loading</div>;

  const header = document.getElementById("header");
  let headerHeight = 60;
  if (header) headerHeight = header.getBoundingClientRect().height;
  const imageHeight = window.innerHeight - headerHeight;

  return (
    <Page title="HeteroGen" className="pb-0">
      <div
        className="relative w-full"
        ref={heroRef}
        style={{
          height: `${imageHeight}px`,
          minHeight: `${minSectionHeight}px`,
        }}
      >
        <img
          src={cropImage}
          alt="YQ Crop"
          className="object-cover w-full h-full filter brightness-[115%] ]"
        />
        <div
          className="absolute inset-0 bg-black"
          style={{ opacity: `65%` }}
          ref={dimmerRef}
        />
        <div className="absolute inset-0 overflow-hidden">
          <div className="flex flex-col justify-start items-center px-4 py-[15vh] h-full">
            <Heading
              primary
              className="text-6xl md:text-8xl text-hgYellow-100 mb-2 font-light"
            >
              HeteroGen
            </Heading>
            <Heading
              secondary
              className="text-3xl md:text-5xl  text-hgGreen-300 font-light"
            >
              Diversity With Integrity
            </Heading>

            <button
              className="mt-12 border-2 border-hgCream-50 bg-hgGreen-900 bg-opacity-30 text-hgCream-50 text-xl px-4 py-2"
              onClick={() => {
                document
                  .getElementById("explore")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Explore ↓
            </button>
          </div>
        </div>
      </div>
      <div id="explore">
        <Explore />
      </div>
    </Page>
  );
}

export default Home;
