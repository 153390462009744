export default function numberValidator({
  value,
  decimalPlaces,
  max,
  min,
  label,
  units,
}) {
  if (value === undefined || value === "" || value === null) return true;
  if (!Number.isFinite(Number(value))) return "Invalid number";
  const n = Number(value);
  if (min !== undefined && n < min)
    return `${label} should be greater than, or equal to, ${Number(
      min
    ).toLocaleString("en-gb")}${units || ""}`;
  if (max !== undefined && n > max)
    return `${label} should be less than, or equal to, ${Number(
      max
    ).toLocaleString("en-gb")}${units || ""}`;
  if (decimalPlaces === undefined && !Number.isInteger(n))
    return `${label} should be an integer`;
  if (decimalPlaces !== undefined) {
    const decimals = `${value}`.split(".")[1];

    if (decimals && decimals.length > decimalPlaces)
      return `${label} should be specified to a maximum of ${decimalPlaces} decimal place${
        decimalPlaces === 1 ? "" : "s"
      }`;
  }
  return true;
}
