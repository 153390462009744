import { useQuery, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useGetDashboardReport(options) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    queryKey: ["seedLots", "products", "populations", "users", "businesses"],
    queryFn: async () =>
      request(
        {
          req: {
            url: "/admin-reports/dashboard",
            method: "GET",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus: options?.refetchOnWindowFocus || false,
  });

  return queryApi;
}

export { useGetDashboardReport };
