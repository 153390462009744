import { AiOutlinePlus } from "react-icons/ai";
import { useState } from "react";
import { useGetCropTypes } from "../../../../api";
import Page from "../../../../Containers/Page";
import FlexCentered from "../../../../Containers/FlexCentered";
import Heading from "../../../../Components/Heading";
import CropTypeItem from "./CropTypeItem";
import classNames from "classnames";
import getApiStatusPage from "../../../APIStatusPage";

function CropTypesPage() {
  const [cropTypeToEdit, setCropTypeToEdit] = useState(null);
  const getCropTypesApi = useGetCropTypes({});

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getCropTypesApi,
        type: "Crop Types",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  const cropTypes = getCropTypesApi?.data?.cropTypes;

  const renderedCropTypes = cropTypes.map((cropType) => {
    return (
      <CropTypeItem
        key={cropType._id}
        cropType={cropType}
        isEditing={cropTypeToEdit === cropType._id}
        setIsEditing={(ref) => setCropTypeToEdit(ref)}
      />
    );
  });

  return (
    <Page title="Crop Types" className="pt-8">
      <Heading primary className="text-center text-3xl text-hgBlue-500 mb-12">
        Crop Types
      </Heading>
      <FlexCentered col className="gap-y-4">
        {renderedCropTypes}
        {cropTypeToEdit === "new" && (
          <CropTypeItem
            addMode
            isEditing
            setIsEditing={() => setCropTypeToEdit(null)}
          />
        )}
        <div
          className={classNames(
            "bg-hgCream-50 rounded-full p-2 shadow-lg mt-1 text-2xl text-hgBlue-500 hover:text-hgGreen-900 cursor-pointer hover:scale-105 mb-8",
            {
              "pointer-events-none select-none opacity-50":
                cropTypeToEdit === "new",
            }
          )}
          onClick={() => {
            setCropTypeToEdit("new");
          }}
        >
          <AiOutlinePlus />
        </div>
      </FlexCentered>
    </Page>
  );
}

export default CropTypesPage;
