import { useForm, Controller } from "react-hook-form";
import { useEffect } from "react";
import { useToastContext } from "../providers/toastContext";
import { useNavigate } from "react-router-dom";

import { useAddPopulation } from "../api";
import Button from "../Components/Button";
import Input from "../Components/Input";
import UploadImage from "../Components/UploadImage";
import FlexCentered from "../Containers/FlexCentered";
import RenderedErrors from "../Components/RenderedErrors";
import Label from "../Components/Label";
import populationSchema from "./schemas/populationSchema";
import Dropdown from "../Components/Dropdown";
import Heading from "../Components/Heading";
import TextBox from "../Components/TextBox";
import DateInput from "../Components/DateInput";
import UploadPDF from "../Components/UploadPDF";

function AddPopulationForm({ populationTypes, cropTypes, businesses }) {
  const addPopulationApi = useAddPopulation();

  const { createSuccessToast } = useToastContext();
  const navigateTo = useNavigate();
  useEffect(() => {
    if (addPopulationApi?.data?.population) {
      createSuccessToast("Population created successfully.");
      navigateTo(
        `/admin/populations/${addPopulationApi?.data?.population?.reference}`
      );
    }
  }, [addPopulationApi?.data?.population, createSuccessToast, navigateTo]);

  const form = useForm();
  const errors = form.formState.errors;
  let apiError = addPopulationApi.error;

  const { register } = form;
  const schema = populationSchema();
  const nameProps = register("name", schema.name);
  const referenceProps = register("reference", schema.reference);
  register("type", schema.type);
  register("cropType", schema.cropType);
  register("shortDesc", schema.shortDesc);
  register("fullDesc", schema.fullDesc);
  register("introducedBy", schema.introducedBy);
  const quantityKgProps = register("quantityKg", schema.quantityKg);
  //INTRODUCED BY!!!!!
  register("date", schema.date);
  register("APHA_Registration_Form", schema.APHA_Registration_Form);
  register("image", schema.image);

  const handleSubmit = (data) => {
    const form = new FormData();

    Object.keys(data).forEach((key) => {
      let value = data[key];
      if (schema[key].getBody) value = schema[key].getBody(value);
      form.append(key, value);
    });
    addPopulationApi.mutate({ body: form });
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
        <FlexCentered col className="pt-4 pb-12">
          <Heading primary className="pb-12">
            Add a Population
          </Heading>
          <Heading
            secondary
            className="w-full pb-2"
            title="Details about the population being added"
          >
            Population Details
          </Heading>
          <div className="border-b border-hgGreen-900 w-full mb-8" />
          <Label htmlFor="name" title="Enter the population name">
            Name
          </Label>
          <Input
            type="text"
            id="name"
            title="Enter the population name"
            className="w-[20rem] max-w-full mb-4 mt-2"
            {...nameProps}
            ref={null}
            innerRef={nameProps.ref}
            error={errors.name}
          />
          <Label
            htmlFor="reference"
            title="Enter a short reference for the population"
          >
            Reference
          </Label>
          <Input
            type="text"
            id="reference"
            title="Enter a short reference for the population"
            className="w-[20rem] max-w-full mb-4 mt-2"
            {...referenceProps}
            ref={null}
            innerRef={referenceProps.ref}
            error={errors.reference}
          />
          <Label
            htmlFor="type"
            className="mb-2"
            title="Select a population type"
          >
            Population Type
          </Label>
          <Controller
            control={form.control}
            name="type"
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                id="type"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className="w-[20rem] max-w-full mb-4 mt-2"
                config={{
                  data: populationTypes,
                }}
                error={errors.type}
                rules={schema.type}
                title="Select a population type"
              />
            )}
          />
          <Label htmlFor="cropType" className="mb-2" title="Select a crop type">
            Crop Type
          </Label>
          <Controller
            control={form.control}
            name="cropType"
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                id="cropType"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className="w-[20rem] max-w-full mb-4 mt-2"
                config={{
                  data: cropTypes,
                  getLabel: (el) => el?.name,
                }}
                title="Select a crop type"
                error={errors.cropType}
                rules={schema.cropType}
              />
            )}
          />
          <Heading
            secondary
            className="w-full pb-2 pt-8"
            title="Useful information displayed to users about this population"
          >
            Useful Information
          </Heading>
          <div className="border-b border-hgGreen-900 w-full mb-8" />
          <Label
            htmlFor="image"
            className="mb-2"
            title="Upload an image for the population"
          >
            Image
          </Label>
          <Controller
            control={form.control}
            name="image"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <UploadImage
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.image}
                rules={schema.image}
              >
                Upload Image
              </UploadImage>
            )}
          />
          <Label
            htmlFor="shortDesc"
            className="mb-2 mt-8"
            title="Enter a short description for the population"
          >
            Short Description
          </Label>
          <Controller
            control={form.control}
            name="shortDesc"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextBox
                id="shortDesc"
                className="w-[20rem] max-w-full mt-2"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.shortDesc}
                rules={schema.shortDesc}
                title="Enter a short description for the population"
                max={65}
              />
            )}
          />
          <Label
            htmlFor="fullDesc"
            className="mb-2 mt-4"
            title="Enter a full description for the population"
          >
            Full Description
          </Label>
          <Controller
            control={form.control}
            name="fullDesc"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextBox
                id="fullDesc"
                className="w-[20rem] max-w-full mt-2 h-56"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={errors.fullDesc}
                rules={schema.fullDesc}
                title="Enter a full description for the population"
                max={700}
              />
            )}
          />
          <Heading
            secondary
            className="w-full pb-2 pt-8"
            title="Data required to create the initial seed lot for this population"
          >
            Initial Seed Lot
          </Heading>
          <div className="border-b border-hgGreen-900 w-full mb-8" />
          <Label
            htmlFor="introducedBy"
            className="mb-2"
            title="Select the business issuing the initial seed lot"
          >
            Introduced By
          </Label>
          <Controller
            control={form.control}
            name="introducedBy"
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                id="introducedBy"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className="w-[20rem] max-w-full mb-4 mt-2"
                config={{
                  data: businesses,
                  getLabel: (el) => el?.tradingName,
                }}
                title="Select the business issuing the initial seed lot"
                error={errors.introducedBy}
                rules={schema.introducedBy}
              />
            )}
          />
          <Label
            htmlFor="quantityKg"
            title="Enter the quantity for the initial seed lot"
          >
            Batch Quantity (Kg)
          </Label>
          <Input
            type="number"
            id="quantityKg"
            title="Enter the quantity for the initial seed lot"
            className="w-[20rem] max-w-full mb-4 mt-2"
            {...quantityKgProps}
            ref={null}
            innerRef={quantityKgProps.ref}
            error={errors.quantityKg}
          />
          <Label
            htmlFor="date"
            className="mb-2"
            title="On which date was the initial seed lot issued?"
          >
            Date
          </Label>
          <Controller
            control={form.control}
            name="date"
            render={({ field: { onChange, onBlur, value } }) => (
              <DateInput
                id="date"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                min={new Date(2010, 0, 1)}
                max={new Date()}
                className="w-[20rem] max-w-full mb-4 mt-2 "
                title="On which date was the initial seed lot issued?"
                error={errors.date}
                rules={schema.date}
              />
            )}
          />
          <Heading
            secondary
            className="w-full pb-2 pt-8"
            title="Optionally, you may upload the APHA registration form as a pdf"
          >
            APHA Registration Form (Optional)
          </Heading>
          <Controller
            control={form.control}
            name="APHA_Registration_Form"
            render={({ field: { onChange, value } }) => (
              <UploadPDF
                id="APHA_Registration_Form"
                onChange={onChange}
                value={value}
                className="w-[20rem] max-w-full mb-4 mt-2 "
                title="Upload the APHA registration form as a pdf"
                error={errors.APHA_Registration_Form}
                rules={schema.APHA_Registration_Form}
              />
            )}
          />
        </FlexCentered>
        <FlexCentered row>
          <Button primary type="submit" loading={addPopulationApi.isLoading}>
            Create
          </Button>
        </FlexCentered>

        {form.formState.isSubmitted && (
          <RenderedErrors
            errors={errors}
            apiError={apiError}
            className="bg-hgCream-50 mb-12 max-w-[20rem]"
          />
        )}
      </form>
    </>
  );
}

export default AddPopulationForm;
