import Image from "../../Components/Image";
import Heading from "../../Components/Heading";
import P from "../../Components/P";
import Link from "../../Components/Link";

function PopulationListItem({ population, link }) {
  return (
    <Link to={link}>
      <div className="w-full bg-hgCream-50 rounded-lg shadow-xl grid grid-cols-[auto_1fr] md:grid-cols-[6rem_8rem_1fr] gap-x-4 gap-y-2 py-3 px-4 hover:scale-[101%] cursor-pointer select-none">
        <Image
          src={population.image}
          alt={population.name}
          className="w-[5rem] h-[5rem] rounded-lg overflow-hidden"
        />
        <div className="flex flex-col justify-center ">
          <div className="bg-hgGreen-900 w-fit px-2 py-1 rounded-xl shadow">
            <Heading tertiary className="text-hgCream-50">
              {population.name}
            </Heading>
          </div>
          <P className="italic">{population.cropType?.name}</P>
        </div>
        <div className="flex flex-col justify-center items-center bg-gray-50 shadow-sm rounded-xl px-2 col-span-2 md:col-span-1 max-w-md">
          <P className=" text-hgBlue-600 text-center">{population.shortDesc}</P>
        </div>
      </div>
    </Link>
  );
}

export default PopulationListItem;
