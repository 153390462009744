import { createPortal } from "react-dom";
import { useEffect, useRef } from "react";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineExclamation,
} from "react-icons/ai";
import P from "./P";
import { useToastContext } from "../providers/toastContext";
import FlexCentered from "../Containers/FlexCentered";
import classNames from "classnames";

function Toast() {
  const { toast, clearToast } = useToastContext();
  const toastRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const toastEl = toastRef.current;
      if (toastEl)
        toastEl.style.top = `${
          Math.max(headerHeight - window.scrollY, 20) + 5
        }px`;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    if (!toastRef?.current) return;
    let timeout1;
    let timeout2;
    if (toast) {
      toastRef?.current?.classList.remove("opacity-0");
      toastRef?.current?.classList.add("opacity-100");
      const wordCount = toast.message.split(" ").length;
      const secondsPerWord = 0.2;

      const readingTime = 3 * Math.ceil(wordCount * secondsPerWord) * 1000;

      timeout1 = setTimeout(() => {
        toastRef?.current?.classList.remove("opacity-100");
        toastRef?.current?.classList.add("opacity-0");
      }, readingTime);
      timeout2 = setTimeout(() => clearToast(), readingTime + 300);
    }

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [clearToast, toast]);

  const header = document.getElementById("topNav");
  const headerHeight = header?.getBoundingClientRect()?.height || 60;

  const root = document.getElementById("toast");
  if (!toast) return;

  return createPortal(
    <div
      ref={toastRef}
      className={classNames(
        "fixed top-[80px] py-2 inset-x-[10vw] rounded-lg shadow-lg opacity-0 transition-all duration-300",
        {
          "bg-hgGreen-1000": toast.type === "success",
          "bg-red-900": toast.type === "error",
        }
      )}
      style={{ top: `${Math.max(headerHeight - window.scrollY, 20) + 5}px` }}
    >
      <div className="relative">
        <FlexCentered col className="px-[15%]">
          <P className="text-center text-hgCream-50  font-semibold tracking-wide ">
            {toast.message}
          </P>
        </FlexCentered>

        <div
          className={classNames(
            "absolute top-[50%] -translate-y-1/2 left-[5%] text-hgCream-50 rounded-full p-2 border-2 border-hg-Green-300 shadow-lg",
            {
              "bg-hgGreen-700": toast.type === "success",
              "bg-red-800": toast.type === "error",
            }
          )}
        >
          {toast.type === "success" && (
            <AiOutlineCheck className="text-5xl text-hgGreen-100" />
          )}
          {toast.type === "error" && (
            <AiOutlineExclamation className="text-5xl text-red-100" />
          )}
        </div>
        <div
          className="absolute top-[50%] -translate-y-1/2 right-[5px] bg-hgCream-50 rounded-full p-1 border-2 border-hgCream-300 shadow-lg cursor-pointer hover:text-red-900 hover:scale-105"
          onClick={clearToast}
        >
          <AiOutlineClose className="text-lg" />
        </div>
      </div>
    </div>,
    root
  );
}
export default Toast;
