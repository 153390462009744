import FlexCentered from "../Containers/FlexCentered";
import Loading from "../Components/Loading";

function LoadingPage() {
  return (
    <div className="bg-hgBlue-500 h-[100vh]">
      <FlexCentered col className="h-full text-hgCream-50 text-xl relative">
        <Loading />
      </FlexCentered>
    </div>
  );
}

export default LoadingPage;
