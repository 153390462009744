import Label from "../../Components/Label";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import RenderedErrors from "../../Components/RenderedErrors";
import FlexCentered from "../../Containers/FlexCentered";
import { useForm, Controller } from "react-hook-form";
import Dropdown from "../../Components/Dropdown";

function EditGeneralDataForm({ product, updateProductApi }) {
  const form = useForm({
    values: {
      reference: product.reference || "",
      isArchived: product.isArchived
        ? {
            label: "Yes",
            value: true,
          }
        : { label: "No", value: false },
    },
  });
  const errors = form.formState.errors;

  const { register } = form;
  const referenceProps = register("reference", {
    required: "Reference required",
    maxLength: {
      value: 20,
      message: "The reference cannot exceed 20 characters. ",
    },
  });
  register("isArchived");

  const handleSubmit = (data) => {
    updateProductApi.mutate({
      productRef: product._id,
      body: {
        reference: data.reference,
        isArchived: data.isArchived?.value,
      },
    });
  };

  return (
    <div>
      <FlexCentered col>
        <form className="" onSubmit={form.handleSubmit(handleSubmit)}>
          <FlexCentered col>
            <Label htmlFor="reference" className="mb-2">
              Reference
            </Label>
            <Input
              type="text"
              id="reference"
              className={"w-[20rem] max-w-full mb-4"}
              {...referenceProps}
              ref={null}
              innerRef={referenceProps.ref}
              error={errors.reference}
            />
            <Label htmlFor="isArchived" className="mb-2">
              Archived
            </Label>
            <Controller
              control={form.control}
              name="isArchived"
              render={({ field: { onChange, onBlur, value } }) => (
                <Dropdown
                  id="isArchived"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className="w-full "
                  config={{
                    data: [
                      {
                        label: "Yes",
                        value: true,
                      },
                      { label: "No", value: false },
                    ],
                    getKey: (el) => el.label,
                    options: {
                      disallowEmptyValues: true,
                    },
                  }}
                  title="Select the business this seed lot is being issued to"
                />
              )}
            />
            <Button
              primary
              className="mt-4"
              loading={updateProductApi.isLoading}
              disabled={!form.formState.isDirty}
            >
              Save
            </Button>
            {form.formState.isSubmitted && (
              <RenderedErrors
                className="bg-hgCream-50"
                errors={errors}
                apiError={updateProductApi?.error || ""}
              />
            )}
          </FlexCentered>
        </form>
      </FlexCentered>
    </div>
  );
}

export default EditGeneralDataForm;
