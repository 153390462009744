import getMinColWidths from "./getMinColWidths";

function getDefaultColumns(config) {
  const visibleCols = config.columns.filter((col) => {
    return col.visible !== false;
  });
  const defaultCols = visibleCols
    .map((col) => {
      const min = getMinColWidths(col.width || "md");
      return `minmax(${min}px,${min}fr)`;
    })
    .join(" ");
  return { defaultCols, visibleCols };
}

export default getDefaultColumns;
