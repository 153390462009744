function getSearchQuery(searchterm, fieldNames) {
  const query = fieldNames
    .map((field) => {
      return `${field}=${searchterm}`;
    })
    .join(",");
  return query;
}

export default getSearchQuery;
