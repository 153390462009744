import {
  useGetProduct,
  useUpdateProduct,
  useGetFields,
  useGetCurrentUser,
} from "../../../api";
import { useToastContext } from "../../../providers/toastContext";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../Containers/Page";
import FlexCentered from "../../../Containers/FlexCentered";
import Heading from "../../../Components/Heading";
import EditGrowingDataForm from "../../../Forms/productForms/EditGrowingDataForm";
import { useEffect } from "react";
import getApiStatusPage from "../../APIStatusPage";

function EditGrowingDataPage() {
  const { productRef } = useParams();
  const updateProductApi = useUpdateProduct();
  const { createSuccessToast } = useToastContext();

  const getCurrentUserApi = useGetCurrentUser({ refetchOnWindowFocus: false });
  const user = getCurrentUserApi?.data?.user;

  const getProductApi = useGetProduct({
    productRef,
    refetchOnWindowFocus: false,
    params: {
      //prettier-ignore
      fields: 'seedLot,population,parentBusiness,reference,growingData,moistureContent,protein,hagberg,gluten,specificWeight,germinationRate,thousandGrainWeightGrams,DON,ZON,septoria,bunt',
      "populate[population]": "name",
      "populate[seedLot]": "reference,date,quantityKg",
      "populate[growingData/field]": "name,location",
      includeMassBalanceFigures: true,
    },
  });

  const getFieldsApi = useGetFields({
    businessRef: user?.linkedBusiness,
    params: { fields: "name" },
    refetchOnWindowFocus: false,
  });
  const fields = getFieldsApi?.data?.fields;

  const product = getProductApi?.data?.product;
  const massBalanceFigures = getProductApi?.data?.massBalanceFigures;

  const navigateTo = useNavigate();
  useEffect(() => {
    if (updateProductApi.data) {
      createSuccessToast("Growing data updated");
      return navigateTo(`/dashboard/products/${product.reference}`);
    }
  }, [
    createSuccessToast,
    updateProductApi.data,
    navigateTo,
    product?.reference,
  ]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getProductApi,
        type: "Product",
        dataRequired: true,
      },
      {
        api: getFieldsApi,
        type: "Fields",
        dataRequired: true,
      },
      {
        api: getCurrentUserApi,
        type: "Current User",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Product" className="px-[4vw]">
      <FlexCentered col className="pt-6">
        <Heading primary className={"text-hgGreen-1000 text-xl"}>
          Edit Growing Data
        </Heading>
        <Heading secondary className="mb-8">
          {product.reference}
        </Heading>
      </FlexCentered>
      <EditGrowingDataForm
        product={product}
        physicalFields={fields}
        updateProductApi={updateProductApi}
        totalIssued={massBalanceFigures?.totalIssued || 0}
      />
    </Page>
  );
}

export default EditGrowingDataPage;
