import { useEffect } from "react";
import { useToastContext } from "../../../../providers/toastContext";
import { useGetOneUser, useAddBusiness } from "../../../../api";
import { useParams, useNavigate } from "react-router-dom";
import Page from "../../../../Containers/Page";
import FlexCentered from "../../../../Containers/FlexCentered";
import Heading from "../../../../Components/Heading";
import Address from "../../../../Components/Address";
import Map from "../../../../Components/Map";
import P from "../../../../Components/P";
import Button from "../../../../Components/Button";
import RenderedErrors from "../../../../Components/RenderedErrors";
import getApiStatusPage from "../../../APIStatusPage";

function LinkToNewBusiness() {
  const { userRef } = useParams();
  const getUserApi = useGetOneUser({
    ref: userRef,
    params: {
      fields: "fullName,submittedBusinessDetails,slug,linkedBusiness",
    },
  });

  const addBusinessAPI = useAddBusiness();

  const user = getUserApi?.data?.user;
  const submittedDetails = user?.submittedBusinessDetails;

  const navigateTo = useNavigate();
  const { createSuccessToast } = useToastContext();
  useEffect(() => {
    if (getUserApi.isFetching) return;
    if (useAddBusiness.isSuccess) {
      createSuccessToast("Business link established");
    }
    if (!user || user.slug !== userRef) {
      return navigateTo(`/admin/users/${userRef}`);
    }
    if (user?.linkedBusiness) {
      return navigateTo(`/admin/users/${userRef}`);
    }
    if (user && !submittedDetails) {
      return navigateTo(`/admin/users/${userRef}`);
    }
  }, [
    getUserApi,
    addBusinessAPI,
    navigateTo,
    submittedDetails,
    user,
    userRef,
    createSuccessToast,
  ]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getUserApi,
        type: "Seed Lot",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  const dataClasses = "font-semibold tracking-wide";

  const handleSubmit = () => {
    const body = { ...submittedDetails, linkedUser: user.id };
    addBusinessAPI.mutate({
      body,
    });
  };

  return (
    <Page title="Link to New Business">
      <FlexCentered col>
        <FlexCentered
          col
          className="md:w-[70vw] max-w-2xl rounded my-8 text-hgBlue-900"
        >
          <Heading primary className="mb-8">
            Create Business
          </Heading>
          <div className="grid grid-cols-[auto_1fr] gap-x-4 gap-y-2  px-4 py-8 mb-8 w-full bg-hgCream-50 rounded shadow-lg">
            <Heading tertiary className="col-span-2 mb-2  text-hgBlue-700">
              Business Details
            </Heading>
            <P>Type:</P>
            <P className={dataClasses}>{submittedDetails?.businessType}</P>
            <P>Trading Name:</P>
            <P className={dataClasses}>{submittedDetails?.tradingName}</P>
            {submittedDetails?.businessType === "Grower" && (
              <>
                <P>CPH Number:</P>
                <P className={dataClasses}>{submittedDetails?.CPH_Number}</P>
              </>
            )}
          </div>
          <div className="bg-hgCream-50 w-full rounded px-4 py-12 grid md:grid-cols-[auto_1fr] gap-8 lg:gap-16 mb-12 shadow-lg">
            <div>
              <Heading tertiary className="col-span-2 mb-4 text-hgBlue-700">
                Business Address
              </Heading>

              <div className="font-semibold tracking-wide">
                <Address formattedAddress={submittedDetails.formattedAddress} />
              </div>
            </div>
            <div className="bg-gray-200 hidden md:block w-full h-full shadow-lg place-self-center">
              <Map
                coords={submittedDetails.location?.coordinates}
                markers={[
                  {
                    coords: submittedDetails?.location?.coordinates,
                    icon: `${submittedDetails?.businessType}Icon`,
                    title: submittedDetails?.tradingName,
                  },
                ]}
                zoom={6}
              />
            </div>
          </div>

          <Button
            primary
            onClick={handleSubmit}
            loading={addBusinessAPI.isLoading}
          >
            Submit
          </Button>
          {addBusinessAPI.error && (
            <RenderedErrors
              errors={{}}
              apiError={addBusinessAPI.error}
              className="bg-hgCream-50"
            />
          )}
        </FlexCentered>
      </FlexCentered>
    </Page>
  );
}

export default LinkToNewBusiness;
