import numberValidator from "../helpers/numberValidator";

function ProductDataSchema() {
  return {
    moistureContent: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "Moisture content",
          decimalPlaces: 1,
          min: 0,
          max: 100,
          units: "%",
        });
      },
    },
    protein: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "Protein",
          decimalPlaces: 2,
          min: 0,
          max: 100,
          units: "%",
        });
      },
    },
    hagberg: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "Hagberg",
          min: 100,
          max: 999,
        });
      },
    },
    gluten: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "Hagberg",
          decimalPlaces: 2,
          min: 0,
          max: 100,
          units: "%",
        });
      },
    },
    specificWeight: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "Specific weight",
          min: 0,
          max: 999,
          units: "g/l",
        });
      },
    },
    germinationRate: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "Germination rate",
          decimalPlaces: 1,
          min: 0,
          max: 100,
          units: "%",
        });
      },
    },
    thousandGrainWeightGrams: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "Thousand Grain Weight",
          min: 0,
          max: 100,
          units: "g",
        });
      },
    },
    DON: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "DON",
          min: 0,
          max: 9999,
          units: "ppb",
        });
      },
    },
    ZON: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "ZON",
          min: 0,
          max: 9999,
          units: "ppb",
        });
      },
    },
    septoria: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "Septoria",
          decimalPlaces: 1,
          min: 0,
          max: 100,
          units: "%",
        });
      },
    },
    bunt: {
      validate: (value) => {
        return numberValidator({
          value,
          label: "Bunt",
          decimalPlaces: 1,
          min: 0,
          max: 999,
          units: "sps",
        });
      },
    },
  };
}

export default ProductDataSchema;
