function getFamilyTreeClone(familyTree) {
  const seedLotsObject = {};
  let rootLot;

  familyTree.lots.forEach((lot) => {
    //1. CLONE THE LABEL
    const clone = JSON.parse(JSON.stringify(lot));

    //IF THE LABEL HAS A PARENT - ADD AN OFFSET FROM THE PARENT
    if (clone.parent) {
      const middleChild = (clone.siblingsCount + 1) / 2;
      clone.offset = clone.siblingNumber - middleChild;
      if (clone.offset < 0) clone.offset = Math.floor(clone.offset);
      if (clone.offset > 0) clone.offset = Math.ceil(clone.offset);

      clone.adjustment = 0;
    } else rootLot = clone;
    seedLotsObject[clone.id] = clone;
    return clone;
  });
  return { seedLotsObject, rootLot };
}

export default getFamilyTreeClone;
