import classNames from "classnames";
import Heading from "../../Components/Heading";
import Link from "../../Components/Link";
import { usePopulationListContext } from "./PopulationListContext";

function SideBarFilter({ className }) {
  const { cropTypes, query, setQuery } = usePopulationListContext();
  if (!cropTypes) return;

  const populationTypes = [
    "All",
    "CCP",
    "Dynamic",
    `Landrace and Farmer's Selection`,
  ];

  const renderedPopulationTypes = populationTypes.map((populationType) => {
    return (
      <Link
        key={populationType}
        className={classNames("text-hgBlue-500 w-max", {
          "font-semibold pointer-events-none":
            query?.type === populationType ||
            (!query.type && populationType === "All"),
        })}
        onClick={() => {
          if (populationType === "All") {
            return setQuery.unsetFilter({
              fieldName: "type",
              operator: "eq",
            });
          }
          setQuery.filter(
            {
              fieldName: "type",
              label: "type",
              type: "String",
            },
            "Equals",
            populationType
          );
        }}
      >
        {populationType}
      </Link>
    );
  });

  const renderedCropTypes = cropTypes.map((cropType) => {
    return (
      <Link
        key={cropType._id}
        className={classNames("text-hgBlue-500 w-max", {
          "font-semibold pointer-events-none":
            query["cropType/name"] === cropType.name,
        })}
        onClick={() =>
          setQuery.filter(
            {
              fieldName: "cropType/name",
              label: "Crop Type",
              type: "String",
            },
            "Equals",
            cropType.name
          )
        }
      >
        {cropType.name}
      </Link>
    );
  });
  renderedCropTypes.unshift(
    <Link
      key={"default"}
      className={classNames("text-hgBlue-500 w-max", {
        "font-semibold pointer-events-none": !query["cropType/name"],
      })}
      onClick={() => {
        setQuery.unsetFilter({
          fieldName: "cropType/name",
          operator: "eq",
        });
      }}
    >
      Any
    </Link>
  );

  return (
    <div className={className}>
      <Heading tertiary className="text-lg text-hgBlue-500 mb-1">
        Population Types
      </Heading>
      <div className="border-b w-full border-hgBlue-200 mb-2" />
      <div className="flex flex-col justify-center">
        {renderedPopulationTypes}
      </div>
      <Heading tertiary className="text-lg text-hgBlue-500  mb-1 mt-8">
        Crop Types
      </Heading>
      <div className="border-b w-full border-hgBlue-200 mb-2" />
      <div className="flex flex-col justify-center">{renderedCropTypes}</div>
    </div>
  );
}
export default SideBarFilter;
