import classNames from "classnames";
function P({ className, children, ...props }) {
  const classes = classNames("text-small md:text-md max-w-prose", className);
  return (
    <p className={classes} {...props}>
      {children}
    </p>
  );
}

export default P;
