import getOperators from "./helpers/getOperators";
import getValueOptions from "./helpers/getValueOptions";
import setQuery from "./helpers/setQuery";
import unsetQuery from "./helpers/unsetQuery";

function getFilterClass(filters) {
  class Filter {
    constructor(filters) {
      this.filters = filters || {};
    }
    getOperators(filterType) {
      return getOperators(filterType);
    }
    getValueOptions(filterType, options) {
      return getValueOptions(filterType, options);
    }
    setQuery(field, operator, value, value2) {
      setQuery(this.filters, field, operator, value, value2);
      return this.filters;
    }
    unsetQuery(unsetQry) {
      unsetQuery(this.filters, unsetQry);
      return this.filters;
    }
  }

  return new Filter(filters);
}

export default getFilterClass;
