import { useMemo } from "react";
import Page from "../../Containers/Page";
import FlexCentered from "../../Containers/FlexCentered";
import ActiveSeedLots from "../../Sections/ActiveSeedLots";
import getApiStatusPage from "../APIStatusPage";

import {
  useGetCurrentUser,
  useGetActiveSeedLots,
  useGetAllPopulations,
  useGetBusiness,
} from "../../api";
import Heading from "../../Components/Heading";
import ActiveProducts from "../../Sections/ActiveProducts";
import Image from "../../Components/Image";
import P from "../../Components/P";

function BusinessDash() {
  //1. Get current user's linked business
  const getCurrentUserApi = useGetCurrentUser();
  const linkedBusinessId = getCurrentUserApi?.data?.user?.linkedBusiness;
  const getBusinessApi = useGetBusiness({
    businessRef: getCurrentUserApi?.data?.user?.linkedBusiness,
    params: {
      fields: "tradingName,logo",
    },
  });
  const linkedBusiness = getBusinessApi?.data?.business;

  const isGrower = getCurrentUserApi?.data?.linkedBusinessType === "Grower";

  //2. Get active seed lots for that business
  const getActiveSeedLotsApi = useGetActiveSeedLots({
    businessRef: linkedBusinessId,
  });
  const activeSeedLots = getActiveSeedLotsApi?.data?.activeSeedLots;
  const activeProducts = getActiveSeedLotsApi?.data?.activeProducts;

  const hasActiveSeedLots = !!activeSeedLots && activeSeedLots.length > 0;
  const hasActiveProducts = !!activeProducts && activeProducts.length > 0;

  //3. Get an array of unique populations and get their data
  const populationsSet = useMemo(() => {
    if (!activeSeedLots || !activeProducts) return null;

    return Array.from(
      new Set([
        ...activeSeedLots.map((lot) => lot.population),
        ...activeProducts.map((product) => product.population),
      ])
    );
  }, [activeProducts, activeSeedLots]);

  const getAllPopulationsApi = useGetAllPopulations({
    enabled: !!populationsSet,
    params: {
      _id: { in: populationsSet },
      fields: "name,image,cropType",
      "populate[cropType]": "croptypes.name",
    },
  });
  const populations = getAllPopulationsApi?.data?.populations;

  const populationsObj = useMemo(() => {
    if (!populations) return null;
    const rtnObj = {};
    populations.forEach((population) => {
      rtnObj[population._id.toString()] = population;
    });
    return rtnObj;
  }, [populations]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getCurrentUserApi,
        type: "Current User",
        dataRequired: true,
      },
      {
        api: getActiveSeedLotsApi,
        type: "Active seed lots",
        dataRequired: true,
      },
      {
        api: getAllPopulationsApi,
        type: "Populations",
        dataRequired: true,
      },
      {
        api: getBusinessApi,
        type: "Linked business",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Business Dashboard" className="px-2 pt-12 pb-56">
      <FlexCentered col>
        <div className="grid md:grid-cols-[auto_auto] gap-x-[8vw] mb-20 items-center w-fit">
          {linkedBusiness?.logo && (
            <div className="w-[10rem] h-[10rem] md:order-last mb-2 rounded overflow-hidden bg-green">
              <Image
                src={linkedBusiness?.logo}
                alt={`${linkedBusiness?.tradingName} logo`}
              />
            </div>
          )}
          <FlexCentered col className="">
            <Heading
              primary
              className="text-3xl md:text-4xl font-light text-hgBlue-700 mb-4"
            >
              {linkedBusiness.tradingName}
            </Heading>
            <Heading className="text-2xl text-hgGreen-1100 font-light" tertiary>
              Welcome {getCurrentUserApi?.data?.user?.givenName}
            </Heading>
          </FlexCentered>
        </div>
        <div className=" px-2 w-full max-w-4xl">
          {!hasActiveProducts && !hasActiveSeedLots && (
            <div className="bg-hgYellow-300 bg-opacity-20  rounded shadow-lg  overflow-hidden mb-24">
              <div className="bg-hgBlue-500  py-2">
                <Heading
                  secondary
                  className="font-light text-2xl text-hgCream-50 text-center"
                >
                  Business Dashboard
                </Heading>
              </div>
              <div className="mx-2 my-6  p-2">
                <FlexCentered col className="gap-y-4">
                  <P className="w-full">
                    This is the business dashboard. Currently, there are no
                    active products or seed lots requiring attention.
                  </P>
                  <P className="w-full">
                    When a seed lot is issued to {linkedBusiness.tradingName},
                    it will be displayed here.
                  </P>
                  <P className="w-full">
                    You will be able to create new products from seed lots
                    received.
                  </P>
                  <P className="w-full">
                    From these products, you will be able to issue seed lots to
                    other businesses.
                  </P>
                </FlexCentered>
              </div>
            </div>
          )}
          {hasActiveSeedLots && (
            <div className=" bg-hgCream-50  rounded shadow-lg  overflow-hidden mb-24">
              <div className="bg-hgBlue-500  py-2">
                <Heading
                  secondary
                  className="font-light text-2xl text-hgCream-50 text-center"
                >
                  Seed Lots Received (Attention required)
                </Heading>
              </div>
              <div className="mx-2 my-6 bg-hgRed-300 w-fit p-2">
                <P>
                  Seed lots received which have neither been used to create a
                  new product nor archived.
                </P>
              </div>
              <div className="px-[4vw] lg:px-8 pb-12 mt-8 ">
                <ActiveSeedLots
                  populations={populationsObj}
                  activeSeedLots={activeSeedLots}
                />
              </div>
            </div>
          )}
          {hasActiveProducts && (
            <div className=" bg-hgCream-50 rounded shadow-lg  overflow-hidden">
              <div className="bg-hgBlue-500 py-2">
                <Heading
                  secondary
                  className="font-light text-2xl text-hgCream-50 text-center"
                >
                  Active Products
                </Heading>
              </div>
              <div className="px-[4vw] lg:px-8 py-12 ">
                <ActiveProducts
                  populations={populationsObj}
                  activeProducts={activeProducts}
                  isGrower={isGrower}
                />
              </div>
            </div>
          )}
        </div>
      </FlexCentered>
    </Page>
  );
}

export default BusinessDash;
