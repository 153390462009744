import Logo from "./Logo";
import NavLinks from "./NavLinks";
import { useGetCurrentUser } from "../../api";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import classNames from "classnames";

function Navigation() {
  const currentUserApi = useGetCurrentUser();
  const currentUser = currentUserApi.data;

  const location = useLocation();
  const adminRoute = location.pathname.startsWith("/admin");

  if (!currentUser) return;

  return (
    <div
      id="header"
      className={classNames(
        "bg-gray-50 w-full transition-opacity duration-300",
        {
          "bg-gray-50": adminRoute,
          "bg-hgBlue-700": !adminRoute,
        }
      )}
    >
      <div
        id="topNav"
        className={classNames(
          "relative w-full h-[60px]  flex flex-row items-center justify-between px-4 border-b-2",
          {
            "border-hgGreen-800": adminRoute,
            "border-hgBlue-700": !adminRoute,
          }
        )}
      >
        <Logo adminRoute={adminRoute} />
        <NavLinks />
      </div>
      <div>
        <Breadcrumbs />
      </div>
    </div>
  );
}

export default Navigation;
