import { useEffect, useRef } from "react";
import MoveColumnAvatar from "../components/MoveColumnAvatar";

function useMoveColumn(
  enabled,
  tableElement,
  setMoveColumnTo,
  setMoveColumnIcon,
  handleUpdateColumns
) {
  const modalRef = useRef(null);

  useEffect(() => {
    if (!enabled) return;
    let headerToMove;
    const modalOffset = {};
    let header;
    let headers;
    let moveTo;

    const getIcon = (e) => {
      const activeStyles = window.getComputedStyle(headerToMove);
      const top = `${e.pageY + (headerToMove.offsetTop - e.pageY)}px`;
      const left = `${e.pageX + (headerToMove.offsetLeft - e.pageX)}px`;
      modalOffset.x = headerToMove.offsetLeft - e.pageX;
      modalOffset.y = headerToMove.offsetTop - e.pageY;
      const icon = (
        <MoveColumnAvatar
          activeStyles={activeStyles}
          top={top}
          left={left}
          innerRef={modalRef}
        >
          {headerToMove.textContent}
        </MoveColumnAvatar>
      );
      setMoveColumnIcon(icon);
    };

    const handleMouseMove = (e) => {
      //Pin modal to mouse
      modalRef.current.style.top = `${e.pageY + modalOffset.y}px`;
      modalRef.current.style.left = `${e.pageX + modalOffset.x}px`;
      //Determine column to move to
      headers.forEach((header, i) => {
        if (
          header.offsetLeft < e.pageX &&
          header.offsetLeft + header.offsetWidth >= e.pageX
        ) {
          setMoveColumnTo(header.dataset.label);
          moveTo = header.dataset.label;
        }
      });
    };

    const handleMouseDown = (e) => {
      if (e.target.closest(".headerControl")) return;
      header = tableElement.querySelector("thead");
      headers = Array.from(header.querySelectorAll("td"));
      const parentHeader = e.target?.closest("td");
      if (!parentHeader || !parentHeader.dataset?.label) return;
      headerToMove = parentHeader;
      headerToMove.style.opacity = "50%";
      getIcon(e);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseUp = (e) => {
      if (headerToMove) headerToMove.style.opacity = "100%";
      handleUpdateColumns(headerToMove?.dataset?.label, moveTo);
      setMoveColumnIcon(undefined);
      setMoveColumnTo(undefined);
      headerToMove = undefined;
      moveTo = undefined;
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  });
}
export default useMoveColumn;
