import { Routes, Route } from "react-router-dom";
import ConfirmEmail from "../Pages/auth/ConfirmEmail";
import NotFound from "../Pages/NotFound";

function ConfirmEmailRouter() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<ConfirmEmail />} />
        <Route path="/*" element={<NotFound redirect="/" />} />
      </Routes>
    </>
  );
}

export default ConfirmEmailRouter;
