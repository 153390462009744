import { useState, useMemo, useEffect } from "react";
import { useToastContext } from "../../../../providers/toastContext";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetOneUser,
  useGetAllBusinesses,
  useCreateBusinessLink,
} from "../../../../api";
import classNames from "classnames";
import stringDistance from "./helpers/strDistance";
import Page from "../../../../Containers/Page";
import FlexCentered from "../../../../Containers/FlexCentered";
import Heading from "../../../../Components/Heading";
import Label from "../../../../Components/Label";
import Dropdown from "../../../../Components/Dropdown";
import Button from "../../../../Components/Button";
import Link from "../../../../Components/Link";
import RenderedErrors from "../../../../Components/RenderedErrors";
import getApiStatusPage from "../../../APIStatusPage";

function CreateBusinessLink() {
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [defaultMatch, setDefaultMatch] = useState(null);

  const createBusinessLinkApi = useCreateBusinessLink();

  const { createSuccessToast } = useToastContext();
  useEffect(() => {
    if (createBusinessLinkApi.isSuccess) {
      createSuccessToast("Business link established");
    }
  });

  useEffect(() => {
    if (defaultMatch) {
      setSelectedBusiness(defaultMatch.business);
    }
  }, [defaultMatch]);

  const { userRef } = useParams();
  const getUserApi = useGetOneUser({
    ref: userRef,
    params: {
      fields: "fullName,submittedBusinessDetails,slug,linkedBusiness",
    },
  });

  const getAllBusinessesApi = useGetAllBusinesses({
    params: {
      fields: "tradingName,CPH_Number,postcode",
      sort: "tradingName",
      completeList: true,
    },
  });

  const user = getUserApi.data?.user;
  const submittedDetails = user?.submittedBusinessDetails;
  const businesses = getAllBusinessesApi.data?.businesses;

  const handleCreateLink = () => {
    if (submittedDetails || !selectedBusiness) return;
    createBusinessLinkApi.mutate({
      ref: user._id,
      body: {
        businessRef: selectedBusiness._id,
      },
    });
  };

  const [analysedBusinesses, topMatch] = useMemo(() => {
    if (!businesses) return [undefined, undefined];
    if (businesses && !submittedDetails) return [businesses, undefined];
    let topMatch;
    const analysedBusinesses = businesses.map((business) => {
      let runningTotal = 0;
      runningTotal += stringDistance(
        submittedDetails.tradingName,
        business.tradingName
      );
      if (topMatch && topMatch.distance < runningTotal) return business;
      runningTotal += stringDistance(
        submittedDetails.CPH_Number || "",
        business.CPH_Number || ""
      );
      if (topMatch && topMatch.distance < runningTotal) return business;

      runningTotal += stringDistance(
        submittedDetails.postcode,
        business.postcode
      );

      if (!topMatch)
        topMatch = {
          distance: runningTotal,
          business,
        };
      else {
        if (topMatch.distance > runningTotal) {
          topMatch = {
            distance: runningTotal,
            business,
          };
        }
      }
      return business;
    });
    return [analysedBusinesses, topMatch];
  }, [businesses, submittedDetails]);

  const navigateTo = useNavigate();
  useEffect(() => {
    if (getUserApi.isFetching) return;
    if (!user || user?.slug !== userRef || user.linkedBusiness) {
      navigateTo(`/admin/users/${userRef}`);
    }
  }, [getUserApi, navigateTo, user, userRef]);

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getUserApi,
        type: "Seed Lot",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  if (topMatch && !defaultMatch) setDefaultMatch(topMatch);

  return (
    <Page title="Create Business Link">
      <FlexCentered col className="pt-4">
        <Heading primary className="text-2xl text-hgBlue-500 mb-8">
          Create Business Link
        </Heading>
        <FlexCentered col>
          <Label>Find Business</Label>
          <Dropdown
            className="mt-2"
            config={{
              data: analysedBusinesses,
              getLabel: (row) => row.tradingName,
              options: {
                filter: true,
              },
            }}
            value={selectedBusiness}
            onChange={setSelectedBusiness}
          />
        </FlexCentered>

        <FlexCentered
          col
          className="bg-hgCream-50 px-[4vw] py-4 rounded shadow-lg mt-12"
        >
          <Heading primary className="text-center text-hgBlue-500 text-xl mb-8">
            Submitted Business Details
          </Heading>
          {!submittedDetails && "No business details submitted"}
          {submittedDetails && (
            <div
              className={classNames("grid", {
                "grid-cols-3": selectedBusiness,
                "grid-cols-2": !selectedBusiness,
              })}
            >
              <div className="border text-center font-semibold px-2 py-1 bg-gray-50 text-hgCream-50"></div>
              <div className="border border-l-0 text-center font-semibold px-2 py-1 bg-hgBlue-500 text-hgCream-50">
                Submitted
              </div>
              {selectedBusiness && (
                <div className="border border-l-0 text-center font-semibold px-2 py-1 bg-hgGreen-900 text-hgCream-50">
                  Selected Business
                </div>
              )}

              <div className="border border-t-0 px-2 py-1 bg-gray-700 text-hgCream-50">
                Trading Name
              </div>
              <div className="border border-t-0 border-l-0 px-2 py-1 bg-hgBlue-50">
                {submittedDetails.tradingName}
              </div>
              {selectedBusiness && (
                <div className="border border-t-0 border-l-0 px-2 py-1 bg-hgGreen-50">
                  {selectedBusiness.tradingName}
                </div>
              )}

              <div className="border border-t-0 px-2 py-1 bg-gray-700 text-hgCream-50">
                CPH Number
              </div>
              <div className="border border-t-0 border-l-0 px-2 py-1 bg-hgBlue-50">
                {submittedDetails.CPH_Number || "-"}
              </div>
              {selectedBusiness && (
                <div className="border border-t-0 border-l-0 px-2 py-1 bg-hgGreen-50">
                  {selectedBusiness.CPH_Number || "-"}
                </div>
              )}

              <div className="border border-t-0 px-2 py-1 bg-gray-700 text-hgCream-50">
                Postcode
              </div>
              <div className="border border-t-0 border-l-0 px-2 py-1 bg-hgBlue-50">
                {submittedDetails.postcode}
              </div>
              {selectedBusiness && (
                <div className="border border-t-0 border-l-0 px-2 py-1 bg-hgGreen-50">
                  {selectedBusiness.postcode}
                </div>
              )}
            </div>
          )}
        </FlexCentered>
        <FlexCentered row className="mt-12 gap-x-2">
          {submittedDetails && (
            <Link
              to={`/admin/users/${userRef}/create-business-link/new-business`}
              className={"hover:text-hgBlue-500"}
            >
              <Button secondary outline>
                New Business
              </Button>
            </Link>
          )}
          {submittedDetails && (
            <Link
              to={`/admin/users/${userRef}/create-business-link/existing-business?businessRef=${
                selectedBusiness?._id || ""
              }`}
              disabled={!selectedBusiness}
            >
              <Button primary disabled={!selectedBusiness}>
                Create Link
              </Button>
            </Link>
          )}
          {!submittedDetails && (
            <FlexCentered col>
              <Button
                primary
                disabled={!selectedBusiness}
                loading={createBusinessLinkApi.isFetching}
                onClick={handleCreateLink}
              >
                Create Link
              </Button>
              {createBusinessLinkApi.error && (
                <RenderedErrors
                  errors={[]}
                  apiError={createBusinessLinkApi.error}
                  className="bg-hgCream-50"
                />
              )}
            </FlexCentered>
          )}
        </FlexCentered>
      </FlexCentered>
    </Page>
  );
}

export default CreateBusinessLink;
