import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classnames from "classnames";

function Link({
  to,
  onClick,
  className,
  children,
  innerRef,
  showActive,
  disabled,
  ...props
}) {
  const navigateTo = useNavigate();
  const location = useLocation().pathname;

  if (disabled) return children;

  const handleClick = (e) => {
    if (e.ctrlKey || e.button === 1) return;
    e.preventDefault();
    if (!onClick) return navigateTo(to);
    onClick(e);
  };

  const classes = classnames(
    "cursor-pointer",
    {
      "hover:text-hgGreen-900": !(className || "").includes("hover:text"),
      "text-hgGreen-900": showActive && location.includes(to),
    },
    className
  );

  return (
    <a
      href={to}
      onClick={handleClick}
      className={classes}
      ref={innerRef}
      {...props}
    >
      {children}
    </a>
  );
}

export default Link;
