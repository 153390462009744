import { useMemo } from "react";
import Map from "../../Components/Map";

function GrowingLocations({ growingData }) {
  const mapData = useMemo(() => {
    let lng = 0;
    let lat = 0;
    let dataPoints = 0;

    const markers = growingData?.map((row) => {
      const field = row.field;

      const location = field?.location?.coordinates;

      if (location) {
        lng += location[0];
        lat += location[1];
        dataPoints++;
      }

      return {
        coords: field?.location?.coordinates,
        title: field?.name,
        icon: "FieldIcon",
        disablePopUps: true,
        id: field?._id?.toString(),
      };
    });

    const coords = [lng / dataPoints, lat / dataPoints];
    return {
      markers,
      coords,
    };
  }, [growingData]);

  return (
    <div className="w-full h-60 ">
      <Map
        coords={mapData?.coords}
        mapStyle="hybrid"
        markers={mapData?.markers}
        zoom={5}
      />
    </div>
  );
}
export default GrowingLocations;
