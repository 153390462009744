import Page from "../../../Containers/Page";
import AddSeedLotAdminForm from "../../../Forms/AddSeedLotAdminForm";

function AddSeedLotPage() {
  return (
    <Page title="Add Seed Lot">
      <AddSeedLotAdminForm />
    </Page>
  );
}
export default AddSeedLotPage;
