import Page from "../../../Containers/Page";
import { useParams } from "react-router-dom";
import {
  useGetPopulation,
  useGetCropTypes,
  useGetAllBusinesses,
} from "../../../api";
import FlexCentered from "../../../Containers/FlexCentered";
import EditPopulationForm from "../../../Forms/EditPopulationForm";
import getApiStatusPage from "../../APIStatusPage";

function EditPopulationPage() {
  const { populationRef } = useParams();

  const getPopulationApi = useGetPopulation({
    populationRef,
    params: {
      fields:
        "name,image,fullDesc,shortDesc,APHA_Registration_Form,reference,type,cropType",
    },
    refetchOnWindowFocus: false,
  });

  const population = getPopulationApi?.data?.population;

  const getCropTypesApi = useGetCropTypes({ refetchOnWindowFocus: false });
  const getBusinessesApi = useGetAllBusinesses({
    params: {
      fields: "tradingName",
      completeList: true,
    },
    refetchOnWindowFocus: false,
  });
  const cropTypes = getCropTypesApi?.data?.cropTypes;
  const businesses = getBusinessesApi?.data?.businesses;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getPopulationApi,
        type: "Population",
        dataRequired: true,
      },
      {
        api: getCropTypesApi,
        type: "Population",
        dataRequired: true,
      },
      {
        api: getBusinessesApi,
        type: "Population",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  const populationTypes = [
    {
      label: "CCP",
      id: "CCP",
    },
    {
      label: "Dynamic",
      id: "Dynamic",
    },
    {
      label: "Landrace and Farmer's Selection",
      id: "Landrace and Farmer's Selection",
    },
  ];

  return (
    <Page title={`Edit ${population?.name || populationRef}`}>
      <FlexCentered col>
        <EditPopulationForm
          population={population}
          populationTypes={populationTypes}
          cropTypes={cropTypes}
          businesses={businesses}
        />
      </FlexCentered>
    </Page>
  );
}
export default EditPopulationPage;
