import { AiOutlineClose } from "react-icons/ai";

function CloseButton({ ...props }) {
  return (
    <div {...props}>
      <AiOutlineClose />
    </div>
  );
}

export default CloseButton;
