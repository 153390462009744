import { useForm } from "react-hook-form";
import { useUpdatePassword } from "../api";
import userDetailsSchema from "./schemas/userDetailsSchema";
import Button from "../Components/Button";
import Heading from "../Components/Heading";
import Label from "../Components/Label";
import Input from "../Components/Input";
import RenderedErrors from "../Components/RenderedErrors";
import FlexCentered from "../Containers/FlexCentered";
import { AiOutlineCheckCircle } from "react-icons/ai";
import P from "../Components/P";

function UpdatePasswordForm() {
  const updatePasswordApi = useUpdatePassword();
  const form = useForm();

  const errors = form.formState.errors;
  let apiError = updatePasswordApi.error;

  const { register } = form;
  const schema = userDetailsSchema();

  const passwordProps = register("password", {
    required: "Current password required",
  });
  const newPasswordProps = register("newPassword", schema.password);
  const confirmPasswordProps = register(
    "confirmPassword",
    schema.confirmPassword
  );

  const handleSubmit = (data) => {
    updatePasswordApi.mutate({ body: data });
    form.reset({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  return (
    <div>
      <Heading secondary className="text-center mb-2">
        Update My Password
      </Heading>
      <div className="border-b border-hgBlue-900 mb-8" />

      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
        className="pb-20"
        id="update-me-form"
      >
        <FlexCentered col>
          <Label htmlFor="password" className="mb-2">
            Current Password
          </Label>
          <Input
            type="password"
            id="password"
            className="w-[20rem] max-w-full mb-4"
            {...passwordProps}
            ref={null}
            innerRef={passwordProps.ref}
            error={errors.password}
          />
          <Label htmlFor="newPassword" className="mb-2">
            New Password
          </Label>
          <Input
            type="password"
            id="newPassword"
            className="w-[20rem] max-w-full mb-4"
            {...newPasswordProps}
            ref={null}
            innerRef={newPasswordProps.ref}
            error={errors.newPassword}
          />
          <Label htmlFor="confirmPassword" className="mb-2">
            Confirm New Password
          </Label>
          <Input
            type="password"
            id="confirmPassword"
            className="w-[20rem] max-w-full mb-4"
            {...confirmPasswordProps}
            ref={null}
            innerRef={confirmPasswordProps.ref}
            error={errors.confirmPassword}
          />
          <Button
            primary
            type="submit"
            disabled={Object.keys(form.formState.dirtyFields).length === 0}
            loading={updatePasswordApi.isLoading}
          >
            Update
          </Button>

          <RenderedErrors
            errors={errors}
            apiError={apiError}
            className="bg-hgCream-50"
          />

          {updatePasswordApi.data && !form.formState.isDirty && (
            <FlexCentered row className="gap-2 mt-4 bg-hgCream-50 p-2 rounded">
              <AiOutlineCheckCircle className="text-hgGreen-900" />
              <P>Your password has been updated</P>
            </FlexCentered>
          )}
        </FlexCentered>
      </form>
    </div>
  );
}
export default UpdatePasswordForm;
