import { useRef, useEffect } from "react";
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { RiDraggable } from "react-icons/ri";
import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";
import Link from "../../Components/Link";
import classNames from "classnames";

function ControlOverlay({
  onAdd,
  onEdit,
  onDelete,
  fields,
  activeMarker,
  setActiveMarker,
}) {
  const wrapperRef = useRef(null);
  const menuRef = useRef(null);
  const tabRef = useRef(null);

  //HANDLE TAB DRAG
  useEffect(() => {
    if (!wrapperRef?.current || !menuRef?.current || !tabRef?.current) return;
    let startingOffset = 0;

    const handleMouseMove = (e) => {
      if (!wrapperRef?.current || !menuRef?.current || !tabRef?.current) return;

      const wrapperDims = wrapperRef.current.getBoundingClientRect();
      const menuDims = menuRef.current.getBoundingClientRect();

      let newLeft = e.clientX - wrapperDims.left - startingOffset;
      if (newLeft < 0) newLeft = 0;
      if (newLeft > menuDims.width) newLeft = menuDims.width;

      menuRef.current.style.left = `${newLeft}px`;
    };

    const handleMouseUp = (e) => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      if (menuRef?.current) menuRef.current.classList.remove("select-none");
    };

    const handleMouseDown = (e) => {
      if (!tabRef?.current?.contains(e.target)) return;
      const menuDims = menuRef.current.getBoundingClientRect();
      const menuRight = menuDims.left + menuDims.width;
      startingOffset = e.clientX - menuRight;
      if (menuRef?.current) menuRef.current.classList.add("select-none");

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  });

  const renderedFields = fields?.map((field) => {
    return (
      <div>
        <Link
          key={field.name}
          className={classNames("text-hgCream-50 hover:text-hgGreen-500", {
            "text-hgGreen-500 pointer-events-none":
              activeMarker === field._id.toString(),
          })}
          onClick={() => setActiveMarker(field._id.toString())}
        >
          {field.name}
        </Link>
      </div>
    );
  });

  return (
    <div
      ref={wrapperRef}
      className="absolute inset-0 overflow-hidden pointer-events-none"
    >
      <div className="relative h-full w-fit">
        <div
          ref={menuRef}
          className="w-max h-full absolute left-0 -translate-x-[100%] bg-hgBlue-500 border-2 border-hgBlue-300 border-l-0 pointer-events-auto"
        >
          <FlexCentered col className="overflow-hidden pr-[2vw] pl-[10px]">
            <Heading
              secondary
              className="text-xl font-light text-hgCream-50 mt-8 mb-4"
            >
              Fields
            </Heading>
            <div>{renderedFields}</div>
          </FlexCentered>

          <div
            ref={tabRef}
            className="absolute left-[100%] top-0 bottom-0  flex flex-col justify-center pointer-events-none"
          >
            <div className="bg-hgBlue-500 px-2 py-4 rounded-r-lg border-2 border-hgBlue-300 border-l-0 pointer-events-auto">
              <AiOutlinePlus
                className="text-hgCream-50 text-3xl mb-[1vw] cursor-pointer hover:text-hgGreen-500"
                onClick={onAdd}
              />
              <RiDraggable
                className="text-hgCream-50 text-3xl mb-[1vw] cursor-grab "
                onMouseDown={(e) => {
                  e.target.classList.remove("cursor-grab");
                  e.target.classList.add("cursor-grabbing");
                }}
                onMouseOver={(e) => {
                  e.target.classList.add("cursor-grab");
                  e.target.classList.remove("cursor-grabbing");
                }}
              />
              <AiOutlineEdit
                className={classNames(
                  "text-hgCream-50 text-3xl cursor-pointer hover:text-hgGreen-500 mb-[1vw]",
                  { "pointer-events-none opacity-50": !activeMarker }
                )}
                onClick={onEdit}
              />
              <AiOutlineDelete
                className={classNames(
                  "text-hgCream-50 text-3xl cursor-pointer hover:text-hgRed-500",
                  { "pointer-events-none opacity-50": !activeMarker }
                )}
                onClick={onDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ControlOverlay;
