import { useLocation } from "react-router-dom";
import { Fragment, useEffect, useRef } from "react";
import Link from "../../Components/Link";
import classNames from "classnames";

function Breadcrumbs() {
  const location = useLocation();
  const breadcrumbRef = useRef(null);

  useEffect(() => {
    if (!breadcrumbRef?.current) return;
    const page = document.getElementById("wrapper");
    if (page?.classList.contains("bg-hgBlue-500")) {
      breadcrumbRef?.current?.classList.remove("bg-hgBlue-50");
      breadcrumbRef?.current?.classList.add("bg-hgBlue-500");
      breadcrumbRef?.current?.classList.add("text-hgCream-50");
    }
  });

  //Don't display breadcrumbs on registration form pages - These have their own seperate breadcrumb system
  if (location.pathname.includes("registration-form")) return;
  // if (location.pathname.includes("add-business")) return;

  const noFormatting = {
    products: {
      excludedPaths: [],
    },
    populations: {
      excludedPaths: ["add-population", "crop-types"],
    },
    "seed-lots": {
      excludedPaths: ["add-seed-lot"],
    },
    businesses: {
      excludedPaths: ["add-business"],
    },
  };

  const pathEls = location.pathname.split("/");
  const formattedPathEls = [];
  const defaultCasing = (el) => {
    let path = el.split("-");
    let formattedPath = [];
    path.forEach((el) => {
      formattedPath.push(`${el[0].toUpperCase()}${el.slice(1).toLowerCase()}`);
    });
    return formattedPath.join(" ");
  };
  let pathTrace = "";
  pathEls.forEach((el, i) => {
    if (!el) return;
    const precedingEl = i > 0 ? pathEls[i - 1] : undefined;
    let formatEl = true;

    if (
      noFormatting[precedingEl] &&
      !noFormatting[precedingEl].excludedPaths.includes(el)
    ) {
      formatEl = false;
    }

    pathTrace += `${el}/`;
    formattedPathEls.push({
      label: formatEl ? defaultCasing(el) : window.decodeURIComponent(el),
      pathname: pathTrace,
    });
  });

  let renderedPath = formattedPathEls.map((el, i) => {
    const isFinalEl = i === formattedPathEls.length - 1;
    return (
      <Fragment key={el.label}>
        <Link
          to={`/${el.pathname.slice(0, -1)}`}
          className={classNames({
            "pointer-events-none cursor-default opacity-90": isFinalEl,
            underline: !isFinalEl,
          })}
        >
          {el.label}
        </Link>
        {!isFinalEl && <div>/</div>}
      </Fragment>
    );
  });

  if (!pathEls.includes("admin") && !pathEls.includes("dashboard"))
    renderedPath.unshift(
      <Fragment key="home">
        <Link to={`/`} className="underline">
          Home
        </Link>
        <div>/</div>
      </Fragment>
    );

  if (renderedPath.length < 2) return undefined;

  return (
    <div
      className={"flex flex-row items-center py-2 px-4 bg-hgBlue-50"}
      ref={breadcrumbRef}
    >
      <div className="flex flex-row items-center gap-2 flex-wrap">
        {renderedPath}
      </div>
    </div>
  );
}

export default Breadcrumbs;
