import { useEffect } from "react";
import { useRegister, useAddBusiness } from "../../api";
import { useFormContext } from "../helpers/formContext";
import { useNavigate } from "react-router-dom";
import getBody from "../RegisterWithBusinessDetailsForm/helpers/getBody";
import FlexCentered from "../../Containers/FlexCentered";
import Button from "../../Components/Button";
import Heading from "../../Components/Heading";
import Link from "../../Components/Link";
import P from "../../Components/P";
import Address from "../../Components/Address";
import Map from "../../Components/Map";
import RenderedErrors from "../../Components/RenderedErrors";
import classNames from "classnames";

function ConfirmationSection({ type, onPrevious, isUserRegistration }) {
  const { formState } = useFormContext();

  const registerAPI = useRegister();
  const addBusinessAPI = useAddBusiness();

  let apiError = isUserRegistration ? registerAPI.error : addBusinessAPI.error;

  const navigateTo = useNavigate();
  const handleClickPrevious = () => {
    return navigateTo(onPrevious);
  };

  useEffect(() => {
    if (addBusinessAPI.data) {
      navigateTo("/admin/businesses");
    }
  });

  const handleSubmit = () => {
    const body = getBody(formState, isUserRegistration);
    isUserRegistration
      ? registerAPI.mutate({ body })
      : addBusinessAPI.mutate({ body });
  };

  const dataClasses = "font-semibold tracking-wide";

  return (
    <FlexCentered col>
      {isUserRegistration && (
        <div
          className={classNames(
            "flex flex-row items-center w-full w-[70vw] max-w-2xl pt-[5vh] gap-2 text-hgCream-50"
          )}
        >
          <Link
            to={`/registration-form?type=${type}`}
            className={classNames("underline hover:text-hgGreen-100")}
          >
            Your Details
          </Link>
          <P>{`>`}</P>

          <Link
            to={onPrevious}
            className={classNames("underline hover:text-hgGreen-100")}
          >
            Business Details
          </Link>
          <P>{`>`}</P>
          <P>Confirmation</P>
        </div>
      )}
      <FlexCentered
        col
        className="md:w-[70vw] max-w-2xl rounded my-8 text-hgBlue-900"
      >
        <Heading
          secondary
          className={classNames(" pt-[2vh] text-2xl mb-4", {
            "text-hgCream-50": isUserRegistration,
          })}
        >
          Confirmation
        </Heading>
        {isUserRegistration && (
          <div className="grid grid-cols-[auto_1fr] gap-x-4 gap-y-2  px-4 py-8 mb-8 w-full bg-hgCream-50 rounded shadow-lg">
            <Heading tertiary className="col-span-2 mb-4 text-hgBlue-700">
              User Details
            </Heading>
            <P>Name:</P>
            <P
              className={dataClasses}
            >{`${formState.givenName} ${formState.familyName}`}</P>
            <P>Email:</P>
            <P className={dataClasses}>{formState.email}</P>
          </div>
        )}
        <div className="grid grid-cols-[auto_1fr] gap-x-4 gap-y-2  px-4 py-8 mb-8 w-full bg-hgCream-50 rounded shadow-lg">
          <Heading tertiary className="col-span-2 mb-2  text-hgBlue-700">
            Business Details
          </Heading>
          <P>Type:</P>
          <P className={dataClasses}>{formState.businessType?.label}</P>
          <P>Trading Name:</P>
          <P className={dataClasses}>{formState.tradingName}</P>
          {formState.businessType?.label === "Grower" && (
            <>
              <P>CPH Number:</P>
              <P className={dataClasses}>{formState.CPH_Number}</P>
            </>
          )}
        </div>
        <div className="bg-hgCream-50 w-full rounded px-4 py-12 grid md:grid-cols-[auto_1fr] gap-8 lg:gap-16 mb-12 shadow-lg">
          <div>
            <Heading tertiary className="col-span-2 mb-4 text-hgBlue-700">
              Business Address
            </Heading>

            <div className="font-semibold tracking-wide">
              <Address formattedAddress={formState.address?.formattedAddress} />
            </div>
          </div>
          <div className="bg-gray-200 hidden md:block w-full h-full shadow-lg place-self-center">
            <Map
              coords={formState.address?.location?.coordinates}
              markers={[
                {
                  coords: formState.address?.location?.coordinates,
                  icon: `${formState.businessType?.label}Icon`,
                  title: formState.tradingName,
                },
              ]}
              zoom={6}
            />
          </div>
        </div>
        <FlexCentered row className="gap-2">
          {onPrevious && (
            <Button primary onClick={handleClickPrevious}>
              Previous
            </Button>
          )}
          <Button
            primary
            onClick={handleSubmit}
            loading={registerAPI.isLoading}
          >
            Submit
          </Button>
        </FlexCentered>
        {apiError && (
          <RenderedErrors
            errors={{}}
            apiError={apiError}
            className="bg-hgCream-50"
          />
        )}
      </FlexCentered>
    </FlexCentered>
  );
}

export default ConfirmationSection;
