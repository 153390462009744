function purgeIncompatibleFilters(filters, fieldName, operator) {
  if (!filters[fieldName]) return;

  let incompatibleOperators = [];
  if (operator === "Equals") {
    incompatibleOperators = [
      "ne",
      "con",
      "ncon",
      "ex",
      "nex",
      "gt",
      "gte",
      "lt",
      "lte",
      "bet",
    ];
  }
  if (operator === "Not equal to") {
    incompatibleOperators = ["eq", "ex", "nex"];
  }
  if (operator === "Contains") {
    incompatibleOperators = ["eq", "ex", "nex"];
  }
  if (operator === "Does not contain") {
    incompatibleOperators = ["eq", "ex", "nex"];
  }
  if (operator === "Exists") {
    incompatibleOperators = [
      "eq",
      "ne",
      "con",
      "ncon",
      "nex",
      "gt",
      "gte",
      "lt",
      "lte",
      "bet",
    ];
  }
  if (operator === "Does not exist") {
    incompatibleOperators = [
      "eq",
      "ne",
      "con",
      "ncon",
      "ex",
      "gt",
      "gte",
      "lt",
      "lte",
      "bet",
    ];
  }
  if (operator === "Greater than") {
    incompatibleOperators = ["eq", "gte", "bet", "ex", "nex"];
  }
  if (operator === "Greater than or equal to") {
    incompatibleOperators = ["eq", "gt", "bet", "ex", "nex"];
  }
  if (operator === "Less than") {
    incompatibleOperators = ["eq", "lte", "bet", "ex", "nex"];
  }
  if (operator === "Less than or equal to") {
    incompatibleOperators = ["eq", "lt", "bet", "ex", "nex"];
  }
  if (operator === "Between") {
    incompatibleOperators = ["eq", "lt", "lte", "gt", "gte", "ex", "nex"];
  }
  incompatibleOperators.forEach((op) => {
    if (filters[fieldName][op]) delete filters[fieldName][op];
  });
  delete filters[fieldName][operator];
}
export default purgeIncompatibleFilters;
