/**
 * Returns a position object to place an element relative
 * to another.
 *
 * @param {*} relativeTo Element
 * @param {*} pos Object with optional top, right, left, bottom els
 * @returns
 */

function getRelativePosition(relativeTo, pos) {
  const positions = {};

  const relativeToDims = relativeTo?.getBoundingClientRect();

  if (pos.top !== undefined) {
    const top = relativeToDims.top + (pos.top || 0);
    positions.top = `${top}px`;
  }
  if (pos.right !== undefined) {
    const right = relativeToDims.right + (pos.right || 0);
    positions.left = `${right}px`;
    positions.transform = "translateX(-100%)";
  }
  if (pos.bottom !== undefined) {
    const bottom = relativeToDims.top + relativeToDims.height + (pos.top || 0);
    positions.top = `${bottom}px`;
  }

  if (pos.left !== undefined) {
    const left = relativeToDims.right - relativeToDims.width + (pos.left || 0);
    positions.left = `${left}px`;
  }

  return positions;
}

export default getRelativePosition;
