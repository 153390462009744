import Page from "../../../Containers/Page";
import { useGetCurrentUser, useGetBusiness, useGetFields } from "../../../api";
import FlexCentered from "../../../Containers/FlexCentered";
import FieldRecord from "../../../Sections/FieldRecord";
import getApiStatusPage from "../../APIStatusPage";
import Heading from "../../../Components/Heading";

function FieldRecordPage() {
  const getCurrentUserApi = useGetCurrentUser();
  const currentUser = getCurrentUserApi?.data?.user;

  const getBusinessApi = useGetBusiness({
    businessRef: currentUser?.linkedBusiness,
    params: {
      fields: "tradingName,location",
    },
  });
  const linkedBusiness = getBusinessApi?.data?.business;

  const getFieldsApi = useGetFields({
    businessRef: currentUser?.linkedBusiness,
  });
  const fields = getFieldsApi?.data?.fields;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getCurrentUserApi,
        type: "Current User",
        dataRequired: true,
      },
      {
        api: getBusinessApi,
        type: "Business",
        dataRequired: true,
      },
      {
        api: getFieldsApi,
        type: "Fields",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Field Record">
      <FlexCentered col>
        <Heading
          primary
          className="mt-2 text-2xl text-hgBlue-500 font-light mb-8"
        >
          Field Record
        </Heading>
        <FieldRecord linkedBusiness={linkedBusiness} fields={fields} />
      </FlexCentered>
    </Page>
  );
}

export default FieldRecordPage;
