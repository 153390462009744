import { useGetSeedLot } from "../../api";
import Page from "../../Containers/Page";
import ViewSeedLot from "../../Sections/ViewSeedLot";
import getApiStatusPage from "../APIStatusPage";

function SeedLotPage() {
  const params = new URL(document.location).searchParams;
  const seedLotRef = params.get("ref");

  const getSeedLotApi = useGetSeedLot({
    seedLotRef,
    params: {
      fields:
        "reference,population,parentLot,parentProduct,isInitialLot,date,quantityKg,isArchived,issuedBy,receivedBy",
      "populate[receivedBy]": "tradingName,business_Reference",
      "populate[issuedBy]": "tradingName,business_Reference",
      "populate[population]": "name,reference",
      "populate[parentLot]": "reference",
      "populate[parentProduct]":
        "reference,DON,ZON,bunt,germinationRate,gluten,hagberg,moistureContent,protein,septoria,specificWeight,thousandGrainWeightGrams",
      includeLineageData: true,
    },
  });
  const seedLot = getSeedLotApi?.data?.seedLot;
  const lineageData = getSeedLotApi?.data?.lineageData;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getSeedLotApi,
        type: "Seed Lot",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title={`Seed Lot - ${seedLotRef}`}>
      <ViewSeedLot
        seedLot={seedLot}
        lineageData={lineageData}
        getSeedLotLink={(ref) => `/seed-lot-view?ref=${ref}`}
        getBusinessLink={(ref) => `/business-view?ref=${ref}`}
        getPopulationLink={(ref) => `/populations/${ref}`}
      />
    </Page>
  );
}

export default SeedLotPage;
