function getOperators(type) {
  switch (type) {
    case "String":
      return ["Equals", "Not equal to", "Contains", "Does not contain"];

    case "StringOptional":
      return [
        "Exists",
        "Does not exist",
        "Equals",
        "Not equal to",
        "Contains",
        "Does not contain",
      ];

    case "Number":
      return [
        "Greater than",
        "Greater than or equal to",
        "Equals",
        "Not equal to",
        "Less than",
        "Less than or equal to",
      ];

    case "NumberOptional":
      return [
        "Greater than",
        "Greater than or equal to",
        "Equals",
        "Not equal to",
        "Less than",
        "Less than or equal to",
        "Exists",
        "Does not exist",
      ];

    case "Bool":
      return ["Equals"];

    case "Date":
      return ["Equals", "Between"];

    case "Enum":
      return ["Equals", "Not equal to"];

    default:
      return undefined;
  }
}

export default getOperators;
