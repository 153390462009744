import { useGetCropTypes, useGetAllBusinesses } from "../../../api";
import FlexCentered from "../../../Containers/FlexCentered";
import Page from "../../../Containers/Page";
import AddPopulationForm from "../../../Forms/AddPopulationForm";
import getApiStatusPage from "../../APIStatusPage";

function AddPopulationPage() {
  const getCropTypesApi = useGetCropTypes({ refetchOnWindowFocus: false });
  const getBusinessesApi = useGetAllBusinesses({
    params: {
      fields: "tradingName",
      completeList: true,
    },
    refetchOnWindowFocus: false,
  });
  const cropTypes = getCropTypesApi?.data?.cropTypes;
  const businesses = getBusinessesApi?.data?.businesses;

  const populationTypes = [
    {
      label: "CCP",
      id: "CCP",
    },
    {
      label: "Dynamic",
      id: "Dynamic",
    },
    {
      label: "Landrace and Farmer's Selection",
      id: "Landrace and Farmer's Selection",
    },
  ];

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getCropTypesApi,
        type: "Crop Types",
        dataRequired: true,
      },
      {
        api: getBusinessesApi,
        type: "Businesses",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Add Population">
      <FlexCentered col>
        <AddPopulationForm
          cropTypes={cropTypes}
          populationTypes={populationTypes}
          businesses={businesses}
        />
      </FlexCentered>
    </Page>
  );
}

export default AddPopulationPage;
