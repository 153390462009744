import classNames from "classnames";

function Card({ className, children, ...props }) {
  const classes = classNames(
    "border shadow-md rounded",
    {
      "px-4": !(className || "").includes("px-"),
      "py-4": !(className || "").includes("py-"),
    },
    className
  );
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
}

export default Card;
