import Heading from "../../Components/Heading";
import Link from "../../Components/Link";
import P from "../../Components/P";
import footerImage from "../../assets/hgFooter.png";
import { useGetCurrentUser, useLogOut } from "../../api";

function Footer() {
  const currentUserApi = useGetCurrentUser();
  const isLoggedIn = currentUserApi?.data?.isLoggedIn || false;

  const logOutAPI = useLogOut();
  const handleLogOut = () => {
    logOutAPI.mutate();
  };

  return (
    <div className="relative h-[18rem] bg-hgGreen-1100 text-sm sm:text-base">
      {window.location.pathname === "/" && (
        <img
          src={footerImage}
          alt="Wheat field"
          className="w-full h-full object-cover"
        />
      )}
      <div className="absolute inset-0 px-4 py-2 text-hgCream-50 grid grid-cols-2 md:grid-cols-4 content-center">
        <div>
          <P className="opacity-80">Powered by:</P>
          <Heading
            secondary
            className="text-hgCream-50 font-light text-xl sm:text-3xl"
          >
            UK Grain Lab
          </Heading>
          <div className="mt-2">
            <P>33,</P>
            <P>Seely Rd,</P>
            <P>Nottingham,</P>
            <P className="mb-2">NG7 1NU</P>
            <a
              className="italic hover:text-hgGreen-100"
              href="https://www.ukgrainlab.com/"
              target="blank"
            >
              www.ukgrainlab.com/
            </a>
          </div>
        </div>
        <div>
          <Heading
            tertiary
            className="text-hgCream-50 font-light text-lg mdtext-xl mb-2"
          >
            Contact Us
          </Heading>
          <a
            className="italic hover:text-hgGreen-100"
            href="mailto:info@heterogen.uk"
            target="blank"
          >
            info@heterogen.uk
          </a>
        </div>
        <div className="hidden md:block">
          <Heading
            tertiary
            className="text-hgCream-50 font-light text-lg mdtext-xl"
          >
            Explore
          </Heading>
          <Link to="/#explore">
            <P className="mt-2 hover:text-hgGreen-100">Seed lots</P>
          </Link>
          <Link to="/populations">
            <P className="mt-2 hover:text-hgGreen-100">Populations</P>
          </Link>
        </div>
        {isLoggedIn && (
          <div className="hidden md:block">
            <Heading
              tertiary
              className="text-hgCream-50 font-light text-lg mdtext-xl"
            >
              My Account
            </Heading>
            <Link to="/profile">
              <P className="mt-2 hover:text-hgGreen-100">Profile</P>
            </Link>
            <Link to="/sign-out" onClick={handleLogOut}>
              <P className="mt-2 hover:text-hgGreen-100">Sign-Out</P>
            </Link>
          </div>
        )}
        {!isLoggedIn && (
          <div className="hidden md:block">
            <Heading
              tertiary
              className="text-hgCream-50 font-light text-lg mdtext-xl"
            >
              Registration
            </Heading>
            <Link to="/sign-up">
              <P className="mt-2 hover:text-hgGreen-100">Sign-Up</P>
            </Link>
          </div>
        )}
      </div>
      <div className="absolute bottom-[1rem] right-[2rem] text-hgCream-50">
        {`© Ardea Applications ${new Date().getFullYear()}`}
      </div>
    </div>
  );
}
export default Footer;
