import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../Containers/Page";
import UserTypeCard from "../../Sections/UserTypeCard";
import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";

function SignUp() {
  const [userType, setUserType] = useState();
  const handleClick = (type) => {
    setUserType(type);
  };
  const navigateTo = useNavigate();
  useEffect(() => {
    if (userType) {
      navigateTo(`/registration-form?type=${userType}`);
    }
  });
  return (
    <Page title="Sign-Up" className="bg-hgBlue-500">
      {/* IF NO USER TYPE - DISPLAY CARDS FOR USER TO SELECT THEIR TYPE */}
      {!userType && (
        <FlexCentered col>
          <Heading primary className="text-hgCream-50 pt-[8vh]">
            What type of user are you?
          </Heading>
          <div className="flex flex-wrap flex-row justify-center gap-y-2 gap-x-4 pt-[6vh] mx-2">
            <UserTypeCard type="Grower" onClick={() => handleClick("Grower")} />
            <UserTypeCard
              type="Merchant"
              onClick={() => handleClick("Merchant")}
            />
            <UserTypeCard type="Admin" onClick={() => handleClick("Admin")} />
          </div>
        </FlexCentered>
      )}
    </Page>
  );
}

export default SignUp;
