import classNames from "classnames";

function Image({ src, alt, upload, className, ...props }) {
  return (
    <div className={classNames("relative", className)} {...props}>
      {src && (
        <img
          src={src}
          alt={alt}
          className="object-cover w-full h-full pointer-events-none"
        />
      )}
      {upload && !src && (
        <div className="absolute inset-0 bg-gray-200 flex flex-col items-center justify-center cursor-pointer">
          Click to Upload Image
        </div>
      )}
    </div>
  );
}

export default Image;
