const getSortQuery = (currentQuery, fieldNames) => {
  let isCurrentField = true;
  for (let i = 0; i < fieldNames.length; i++) {
    const field = fieldNames[i];
    if (!currentQuery.sort?.includes(field)) isCurrentField = false;
  }
  if (currentQuery.sort?.split(",").length !== fieldNames.length)
    isCurrentField = false;
  let updatedSortDirection;
  if (isCurrentField) {
    updatedSortDirection = currentQuery.sort?.startsWith("-")
      ? undefined
      : "desc";
  } else {
    updatedSortDirection = "asc";
  }

  if (!updatedSortDirection) return;
  if (updatedSortDirection === "asc") return fieldNames.join(",");
  if (updatedSortDirection === "desc")
    return fieldNames.map((field) => `-${field}`).join(",");
};

export default getSortQuery;
