import Heading from "../../../Components/Heading";
import Link from "../../../Components/Link";
import FlexCentered from "../../../Containers/FlexCentered";

function UsersRequiringAttention({ usersRequiringAttention }) {
  if (!usersRequiringAttention || usersRequiringAttention.length === 0) return;

  return (
    <div className="w-full rounded shadow overflow-hidden h-fit ">
      <div className="bg-hgBlue-700 py-4">
        <Heading
          tertiary
          className="text-xl text-hgRed-50 text-center w-full font-light"
        >
          Powerless Users
        </Heading>
      </div>
      <FlexCentered col className="gap-y-2 bg-hgRed-100 py-4 px-2">
        {usersRequiringAttention.map((user) => {
          return (
            <Link
              key={user._id}
              to={`/admin/users/${user.slug}`}
              className="w-full hover:text-hgBlue-900 hover:scale-[101%]"
            >
              <div className="w-full bg-hgCream-50 py-4 px-2 rounded shadow">
                {user.fullName}
              </div>
            </Link>
          );
        })}
      </FlexCentered>
    </div>
  );
}
export default UsersRequiringAttention;
