import { useEffect } from "react";
import { useDeleteSeedLot } from "../../../../api";
import { useToastContext } from "../../../../providers/toastContext";
import FlexCentered from "../../../../Containers/FlexCentered";
import Heading from "../../../../Components/Heading";
import Link from "../../../../Components/Link";
import P from "../../../../Components/P";
import Button from "../../../../Components/Button";
import RenderedErrors from "../../../../Components/RenderedErrors";

function ChildLotListItem({ childLot, onEdit }) {
  const deleteSeedLotApi = useDeleteSeedLot();

  const handleDelete = () => {
    deleteSeedLotApi.mutate({
      seedLotRef: childLot._id,
    });
  };

  const { createSuccessToast } = useToastContext();
  useEffect(() => {
    if (deleteSeedLotApi?.isSuccess) {
      createSuccessToast("Seed lot successfully deleted");
    }
  }, [deleteSeedLotApi, createSuccessToast]);

  return (
    <div>
      <div className="rounded shadow px-[4vw] md:px-[4rem] py-2 border border-hgGreen-1000">
        <Heading tertiary className="text-hgGreen-900">
          {childLot.reference}
        </Heading>
        <div className="grid grid-cols-[auto_auto] w-max max-w-full gap-x-[4vw] md:gap-x-[4rem]">
          <P>Issued To: </P>
          <P className="font-semibold">{childLot.receivedBy?.tradingName}</P>
          <P>Date: </P>
          <P className="font-semibold">
            {" "}
            {new Date(childLot.date)?.toLocaleDateString("en-gb")}
          </P>
          <P>Quantity (kg): </P>
          <P className="font-semibold">
            {Number(childLot.quantityKg)?.toLocaleString("en-gb")}
          </P>
        </div>
        <FlexCentered row className="gap-2 mt-4">
          <Link
            to={`/dashboard/seed-lots/${childLot.reference}`}
            className="hover:text-hgBlue-900"
          >
            <Button primary outline>
              View
            </Button>
          </Link>
          <Button onClick={() => onEdit(childLot)}>Edit</Button>
          <Button
            danger
            outline
            onClick={handleDelete}
            loading={deleteSeedLotApi.isLoading}
          >
            Delete
          </Button>
        </FlexCentered>
        <RenderedErrors errors={[]} apiError={deleteSeedLotApi.error} />
      </div>
    </div>
  );
}

export default ChildLotListItem;
