import classNames from "classnames";
import Heading from "../../Components/Heading";
import P from "../../Components/P";

function MassBalanceCalculation({ figures }) {
  const derivedFigures = {
    unsownSeed: figures.sourceSeedLot - figures.totalSown,
    grossAvailable:
      figures.sourceSeedLot - figures.totalSown + figures.totalYield,
    balance:
      figures.sourceSeedLot -
      figures.totalSown +
      figures.totalYield -
      figures.issuedToSeedLots,
  };

  const getLabelClasses = (className) =>
    classNames("group-hover:bg-hgGreen-200 pl-2 pr-[4vw] ", className);
  const getValueClasses = (className) =>
    classNames("group-hover:bg-hgGreen-200 px-2 text-right", className);

  return (
    <div className="grid grid-cols-[1fr_auto] bg-hgCream-50 w-fit items-center py-4 px-[4vw] rounded shadow select-none">
      <Heading
        tertiary
        className="col-span-2 text-center text-lg text-hgGreen-1000 mb-2"
      >
        Mass Balance (kg)
      </Heading>
      <div className="contents group">
        <P className={getLabelClasses()}>Source seed lot:</P>
        <P className={getValueClasses()}>
          {Number(figures?.sourceSeedLot).toLocaleString("en-gb")}
        </P>
      </div>

      <div className="contents group">
        <P className={getLabelClasses()}>Total sown:</P>
        <P className={getValueClasses()}>
          ({Number(figures?.totalSown).toLocaleString("en-gb")})
        </P>
      </div>

      <div className="contents group">
        <P className={getLabelClasses("font-semibold mt-2")}>Unsown seed:</P>

        <P
          className={getValueClasses(
            classNames("font-semibold mt-2 border-t border-hgBlue-200", {
              "text-red-900": derivedFigures?.unsownSeed < 0,
            })
          )}
        >
          {Number(derivedFigures?.unsownSeed).toLocaleString("en-gb")}
        </P>
      </div>
      <div className="contents group">
        <P className={getLabelClasses()}>Total yield:</P>
        <P className={getValueClasses()}>
          {Number(figures?.totalYield).toLocaleString("en-gb")}
        </P>
      </div>
      <div className="contents group">
        <P className={getLabelClasses("font-semibold mt-2")}>Gross:</P>

        <P
          className={getValueClasses(
            classNames("font-semibold mt-2 border-t border-hgBlue-200", {
              "text-red-900": derivedFigures?.grossAvailable < 0,
            })
          )}
        >
          {Number(derivedFigures?.grossAvailable).toLocaleString("en-gb")}
        </P>
      </div>
      <div className="contents group">
        <P className={getLabelClasses()}>Issued to seed lots:</P>
        <P className={getValueClasses()}>
          ({Number(figures?.issuedToSeedLots).toLocaleString("en-gb")})
        </P>
      </div>
      <div className="contents group">
        <P className={getLabelClasses("font-semibold mt-2")}>Mass balance:</P>

        <P
          className={getValueClasses(
            classNames(
              "font-semibold mt-2 border-t border-hgBlue-600 border-b-2",
              {
                "text-red-900": derivedFigures?.balance < 0,
              }
            )
          )}
        >
          {Number(derivedFigures?.balance).toLocaleString("en-gb")}
        </P>
      </div>
    </div>
  );
}
export default MassBalanceCalculation;
