import { useGetCurrentUser, useGetBusiness } from "../../../api";
import Page from "../../../Containers/Page";
import EditBusinessForm from "../../../Forms/EditBusinessForm";
import getApiStatusPage from "../../APIStatusPage";

function EditBusinessPage() {
  const getCurrentUserApi = useGetCurrentUser();
  const currentUser = getCurrentUserApi?.data?.user;
  const getBusinessApi = useGetBusiness({
    businessRef: currentUser?.linkedBusiness,
    params: {
      fields:
        "tradingName,formattedAddress,postcode,businessType,location,logo,about,website,email,reference,CPH_Number",
    },
    refetchOnWindowFocus: false,
  });
  const business = getBusinessApi?.data?.business;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getBusinessApi,
        type: "Business",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Edit Business Profile">
      <EditBusinessForm
        business={business}
        onSuccessLink={`/dashboard/business`}
      />
    </Page>
  );
}
export default EditBusinessPage;
