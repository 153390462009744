import { useRef, useState } from "react";
import {
  AiOutlineFilePdf,
  AiOutlineUpload,
  AiOutlineDelete,
} from "react-icons/ai";
import RenderedErrors from "./RenderedErrors";
import FlexCentered from "../Containers/FlexCentered";
import P from "./P";
import classNames from "classnames";

function UploadPDF({ value, onChange, className, ...props }) {
  const [error, setError] = useState("");
  const inputRef = useRef(null);

  const handleUpload = (e) => {
    const validFileTypes = ["application/pdf"];
    const file = e?.target?.files[0];
    if (!validFileTypes.includes(file.type))
      return setError("File must be a PDF");
    onChange(file);
    setError("");
  };

  const handleClick = (e) => {
    if (!inputRef?.current) return;
    inputRef.current.click();
  };
  return (
    <>
      <FlexCentered
        col
        {...props}
        className={classNames(
          "h-26 border border-hgBlue-900 py-8 px-4  bg-gray-200 group relative",
          {
            "cursor-pointer": !value,
          },
          className
        )}
        onClick={!value ? handleClick : undefined}
      >
        {!value && <P>Click to add PDF</P>}
        {value && (
          <>
            <FlexCentered
              row
              className="absolute top-[5px] right-[5px] gap-2 bg-hgBlue-800 invisible group-hover:visible rounded shadow z-50 p-1 text-2xl text-hgCream-50"
            >
              <AiOutlineUpload
                className="cursor-pointer hover:text-hgGreen-500"
                onClick={handleClick}
              />
              <AiOutlineDelete
                className="cursor-pointer hover:text-hgRed-500"
                onClick={() => onChange(null)}
              />
            </FlexCentered>
            <AiOutlineFilePdf className="text-2xl text-[#ed0008]" />
            <P className="select-none truncate">{value.name}</P>
          </>
        )}
      </FlexCentered>
      {error && (
        <RenderedErrors
          className="bg-hgCream-50"
          errors={[]}
          apiError={error}
        />
      )}
      <input
        ref={inputRef}
        type="file"
        className="hidden"
        onChange={handleUpload}
      />
    </>
  );
}
export default UploadPDF;
