import React, { useRef, useEffect, useState, useMemo } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import addMarkersToMap from "./helpers/addMarkersToMap";
import getMarkerGeoJson from "./helpers/getMarkerGeoJson";
import fieldAddIcon from "../../assets/icons/fieldAdd.png";
import fieldEditIcon from "../../assets/icons/fieldEdit.png";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYXJkZWEtYXBwcyIsImEiOiJjbGhibm56MTAwdGdjM3JudTFlYThnZ3RtIn0.lFLUhfJCaqJUFXOk6CsERw";

function getMapStyle(style) {
  if (style === "street") return "mapbox://styles/mapbox/streets-v12";
  if (style === "satellite") return "mapbox://styles/mapbox/satellite-v9";
  if (style === "hybrid") return "mapbox://styles/mapbox/satellite-streets-v12";
  return console.error("Invalid map style selection");
}

function Map({
  coords,
  zoom,
  mapStyle,
  markers,
  onMapClick,
  draggableMarker,
  onDragEnd,
  onUpdateActiveMarker,
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(coords ? coords[0] : -1.168524);
  const [lat, setLat] = useState(coords ? coords[1] : 52.9559972);
  const [zoomLevel, setZoomLevel] = useState(zoom || 9);
  const [defaultCenter, setDefaultCenter] = useState(
    coords ?? [-1.168524, 52.9559972]
  );
  const draggableMarkerRef = useRef();

  //GET GEOJSON USED TO DISPLAY MARKERS
  const getGeoJson = useMemo(() => {
    if (!markers) return undefined;
    return () => getMarkerGeoJson(markers);
  }, [markers]);

  useEffect(() => {
    if (!map?.current) return;
    const handleClick = (e) => {
      if (e.clickOnLayer || !onMapClick) return;
      onMapClick(e);
    };

    map.current.on("click", handleClick);
    return () => map.current.off("click", handleClick);
  }, [markers, onMapClick]);

  //INITIALISE MAP AND MARKERS
  const initialiseMap = useMemo(() => {
    return (lat, lng, mapStyle, markers, zoomLevel) => {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: getMapStyle(mapStyle || "street"),
        center: [lng, lat],
        zoom: zoomLevel,
        attributionControl: false,
      });

      map.current.addControl(new mapboxgl.AttributionControl(), "top-right");

      //ADD MARKERS TO MAP
      if (markers) {
        const [markers, markersToEdit] = getGeoJson();
        addMarkersToMap(
          map.current,
          markers,
          markersToEdit,
          onUpdateActiveMarker
        );
      }
    };
  }, [getGeoJson, onUpdateActiveMarker]);

  //IF DRAGGABLE MARKER PASSED ADD THIS TO THE MAP - I WAS HANDLING THIS
  //WITH THE OTHER MARKERS - BUT THE DRAG WAS TOO CHOPPY SO I HAVE TAKEN THIS MORE
  //LIMITING APPROACH FOR A SMOOTHER DRAG
  useEffect(() => {
    let currentMarker = draggableMarkerRef.current;
    map.current?.fire("clearMarkers");

    if (currentMarker) {
      currentMarker.remove();
      currentMarker = undefined;
      draggableMarkerRef.current = null;
    }
    if (!currentMarker && draggableMarker) {
      //THE ICON IS PASSED AS A DOM ELEMENT - SO HERE THE DOM ELEMENT IS
      //CREATED
      const icon = document.createElement("img");
      icon.src = draggableMarker.isEditing ? fieldEditIcon : fieldAddIcon;
      icon.alt = "MapIcon";
      icon.id = draggableMarker.id;

      //CREATE THE MARKER WITH THE DOM ELEMENT AND ADD TO MAP
      const marker = new mapboxgl.Marker({
        draggable: true,
        element: icon,
      })
        .setLngLat(draggableMarker.coords || map.center)
        .addTo(map.current);

      marker.id = draggableMarker.id;

      draggableMarkerRef.current = marker;

      //WHERE A CALLBACK FOR DRAGEND IS PASSED AS A PROP
      //GET THE COORDINATES OF THE MARKER AND PASS TO THE CALLBACK
      const handleDragEnd = (e) => {
        e.clickOnLayer = true;
        const coords = e.target.getLngLat();
        onDragEnd(coords);
      };

      if (onDragEnd) {
        marker.on("dragend", handleDragEnd);
      }
    }
  }, [draggableMarker, draggableMarkerRef, onDragEnd]);

  //Remove draggable marker
  useEffect(() => {
    if (draggableMarkerRef.current && !draggableMarker) {
      draggableMarkerRef.current.remove();
    }
  });

  //CALL INITIALISE MAP
  useEffect(() => {
    if (map.current) return;
    initialiseMap(lat, lng, mapStyle, markers, zoomLevel); // initialize map only once
  });

  //WHERE THE MARKERS ARE UPDATED, REFRESH THE LAYER DATA
  useEffect(() => {
    if (!map?.current || !markers) return;
    const [markersGeoJson, markersToEditGeoJson] = getMarkerGeoJson(markers);
    const mapSourceMarkers = map.current.getSource("markers");
    const mapSourceMarkersToEdit = map.current.getSource("markersToEdit");
    if (!mapSourceMarkers) {
      map.current.on("style.load", () => {
        if (!map.current.getSource("markers")) return;
        if (!map.current.getSource("markersToEdit")) return;
        map.current.getSource("markers").setData(markersGeoJson);
        map.current.getSource("markersToEdit").setData(markersToEditGeoJson);
      });
    } else {
      mapSourceMarkers.setData(markersGeoJson);
      mapSourceMarkersToEdit.setData(markersToEditGeoJson);

      const activeMarker = markers.find((marker) => marker.isActive);
      if (activeMarker) {
        const coords = {
          lng: activeMarker.coords[0],
          lat: activeMarker.coords[1],
        };

        map.current.fire("click", {
          lngLat: coords,
          point: map.current.project(coords),
          originalEvent: {},
        });
      } else {
        map.current.fire("clearMarkers");
      }
    }
  }, [markers, onMapClick]);

  //WHERE THE COORDINATES ARE UPDATED MOVE TO NEW COORDINATES
  useEffect(() => {
    if (
      coords &&
      (coords[0] !== defaultCenter[0] || coords[1] !== defaultCenter[1])
    ) {
      setDefaultCenter(coords);
      // map.current.setCenter(coords);
      map.current.flyTo({
        center: coords,
        zoom: map.current.getZoom(),
        speed: 5,
      });
    }
  }, [coords, defaultCenter]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoomLevel(map.current.getZoom().toFixed(2));
    });
  });

  return (
    <div className="h-full w-full">
      <div ref={mapContainer} className="h-full w-full mapboxgl-map" />
    </div>
  );
}

export default Map;
