import { useDeleteField } from "../../api";

import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import FlexCentered from "../../Containers/FlexCentered";
import P from "../../Components/P";
import Button from "../../Components/Button";
import RenderedErrors from "../../Components/RenderedErrors";

function DeleteFieldOverlay({
  onClose,
  onSuccess,
  fieldToDelete,
  businessRef,
}) {
  const [error, setError] = useState("");
  const deleteFieldApi = useDeleteField();

  useEffect(() => {
    if (deleteFieldApi.error) setError(deleteFieldApi.error);
    if (deleteFieldApi.isSuccess) {
      onSuccess();
    }
  }, [deleteFieldApi, onSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");

    deleteFieldApi.mutate({
      businessRef,
      fieldRef: fieldToDelete?._id?.toString(),
    });
  };

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <div className="relative h-full w-full">
        <FlexCentered
          row
          className="absolute bottom-[2rem]
          inset-x-2"
        >
          <div className="relative pointer-events-auto bg-hgBlue-500 rounded-lg shadow px-8 py-2 text-hgCream-50">
            <div
              className="bg-hgCream-50 w-fit h-fit rounded-full p-1 absolute right-[5px] top-[5px] cursor-pointer hover:scale-105"
              onClick={onClose}
            >
              <AiOutlineClose className=" text-xl text-hgRed-700" />
            </div>

            <FlexCentered col>
              <P className="pt-[20px] text-sm sm:text-base">
                Are you sure you want to delete {fieldToDelete?.name}?
              </P>

              <Button
                danger
                className="mt-2"
                loading={deleteFieldApi.isFetching}
                onClick={handleSubmit}
              >
                Delete
              </Button>

              <RenderedErrors
                errors={[]}
                apiError={error}
                className="bg-hgCream-50 text-hgBlue-700 "
              />
            </FlexCentered>
          </div>
        </FlexCentered>
      </div>
    </div>
  );
}
export default DeleteFieldOverlay;
