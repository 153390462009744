import { useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import growingDataSchema from "../schemas/growingDataSchema";
import GrowingDataRow from "../components/GrowingDataRow";
import Button from "../../Components/Button";
import InfoPanel from "../components/InfoPanel";
import MassBalanceCalculation from "../components/MassBalanceCalculation";
import FlexCentered from "../../Containers/FlexCentered";
import getMassBalanceValidator from "../helpers/getProductMassBalanceValidator";
import RenderedErrors from "../../Components/RenderedErrors";

function EditGrowingDataForm({
  product,
  physicalFields,
  updateProductApi,
  totalIssued,
}) {
  const [displayInfo, setDisplayInfo] = useState(
    product?.growingData?.length > 0 ? false : true
  );

  const schema = growingDataSchema(product);
  const form = useForm({
    values: {
      growingData: product?.growingData,
    },
  });

  const formError = form?.formState?.errors.growingData?.root?.message;

  const { fields, append, remove } = useFieldArray({
    name: "growingData",
    control: form.control,
    rules: {
      validate: getMassBalanceValidator({
        sourceSeedLotWeight: product?.seedLot?.quantityKg,
        weightIssuedToChildLots: totalIssued,
      }),
    },
  });

  const handleAppendRow = () => {
    if (fields.length === 10) return;
    append({
      field: null,
      areaSownHa: "",
      qtySownKgProps: "",
      sowingDateProps: null,
      harvestDate: null,
      totalYield: "",
    });
  };

  if (fields.length === 0) handleAppendRow();

  const values = useWatch({
    name: "growingData",
    control: form.control,
  });

  let totalSown = 0;
  let totalYield = 0;
  values?.forEach((row) => {
    if (Number(row.qtySownKg)) totalSown += +row.qtySownKg;
    if (Number(row.totalYieldT)) totalYield += +row.totalYieldT * 1000;
  });
  const massBalanceFigures = {
    sourceSeedLot: product?.seedLot?.quantityKg,
    totalSown,
    totalYield,
    issuedToSeedLots: totalIssued,
  };

  const onSubmit = (data) => {
    updateProductApi.mutate({
      productRef: product._id,
      body: data,
    });
  };

  const renderedRows = fields.map((field, i) => {
    const fieldProps = form.register(`growingData.${i}.field`, schema.field);
    const areaSownHaProps = form.register(
      `growingData.${i}.areaSownHa`,
      schema.areaSownHa
    );
    const qtySownKgProps = form.register(
      `growingData.${i}.qtySownKg`,
      schema.qtySownKg
    );
    const sowingDateProps = form.register(
      `growingData.${i}.sowingDate`,
      schema.sowingDate
    );
    const harvestDateProps = form.register(
      `growingData.${i}.harvestDate`,
      schema.harvestDate
    );
    const totalYieldProps = form.register(
      `growingData.${i}.totalYieldT`,
      schema.totalYieldT
    );
    return (
      <GrowingDataRow
        key={field.id}
        fieldProps={fieldProps}
        areaSownHaProps={areaSownHaProps}
        qtySownKgProps={qtySownKgProps}
        sowingDateProps={sowingDateProps}
        harvestDateProps={harvestDateProps}
        totalYieldProps={totalYieldProps}
        removeRow={remove}
        fields={physicalFields.filter((field) => {
          return !values.find((row, rowI) => {
            if (rowI === i) return false;
            return row?.field?._id?.toString() === field?._id?.toString();
          });
        })}
        form={form}
        schema={schema}
        onDelete={() => remove(i)}
        rows={fields.length}
        i={i}
        values={values && values[i]}
      />
    );
  });

  return (
    <FlexCentered col className="pb-12">
      <form
        onSubmit={(e) => {
          if (e.target.id !== "growingDataForm") return;
          form.handleSubmit(onSubmit)(e);
        }}
        noValidate
        id="growingDataForm"
      >
        <FlexCentered col>
          {displayInfo && (
            <InfoPanel className="mb-4" onClose={() => setDisplayInfo(false)}>
              You can save without entering the harvest date and/or the total
              yield.
            </InfoPanel>
          )}
          {renderedRows}
          <div
            className="bg-hgCream-50 rounded-full p-2 shadow-lg mt-1 text-2xl text-hgBlue-500 hover:text-hgGreen-900 cursor-pointer hover:scale-105 mb-8"
            onClick={(e) => {
              e?.preventDefault();
              handleAppendRow();
            }}
          >
            <AiOutlinePlus />
          </div>
          <MassBalanceCalculation figures={massBalanceFigures} />
          <Button
            primary
            type="submit"
            className="mt-4"
            loading={updateProductApi.isLoading}
          >
            Save
          </Button>
          {(formError || updateProductApi?.error) && (
            <RenderedErrors
              errors={[]}
              apiError={formError || updateProductApi.error}
              className="bg-hgCream-50 mt-4"
            />
          )}
        </FlexCentered>
      </form>
    </FlexCentered>
  );
}
export default EditGrowingDataForm;
