const getLineageLines = (
  cardWidth,
  cardHeight,
  lot,
  paddingY,
  getCoordinates,
  seedLotsObject,
  tableData
) => {
  const lineWidth = 4;
  const lineageLines = { childConnections: [], height: paddingY };

  const row = lot.row;
  const col = lot.getColumn() + tableData.gridColOffset;
  const [top, left] = getCoordinates(row, col);

  //Parent Connection
  const parentConnectionX = Math.floor(
    Number(left.slice(0, -2)) + cardWidth / 2 - lineWidth / 2
  );
  const parentConnectionY = Number(top.slice(0, -2)) + cardHeight;
  lineageLines.parentConnection = [parentConnectionX, parentConnectionY];
  if (lot.oldTop)
    lineageLines.offsetY = lot.oldTop.slice(0, -2) - lot.top.slice(0, -2);
  if (lot.oldLeft)
    lineageLines.offsetX = lot.oldLeft.slice(0, -2) - lot.left.slice(0, -2);

  lot.children.forEach((childId) => {
    const child = seedLotsObject[childId];
    const row = child.row;
    const col = child.getColumn() + tableData.gridColOffset;
    const [top, left] = getCoordinates(row, col);

    const childConnectionX = Math.floor(
      Number(left.slice(0, -2)) + cardWidth / 2 - lineWidth / 2
    );
    const chilConnectionY = Math.floor(Number(top.slice(0, -2)) - paddingY / 2);
    lineageLines.childConnections.push([childConnectionX, chilConnectionY]);

    if (lineageLines.minX === undefined || childConnectionX < lineageLines.minX)
      lineageLines.minX = childConnectionX;
    if (lineageLines.maxX === undefined || childConnectionX > lineageLines.maxX)
      lineageLines.maxX = childConnectionX;
  });
  return lineageLines;
};

export default getLineageLines;
