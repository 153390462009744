import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useGetAllBusinesses({ params, refetchOnWindowFocus = true }) {
  const keys = ["businesses"];
  if (params?.completeList) keys.push("businesses-all");

  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    queryKey: keys,
    queryFn: async () =>
      request(
        {
          req: {
            url: "/businesses",
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useGetActiveSeedLots({
  businessRef,
  params,
  refetchOnWindowFocus = true,
}) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    queryKey: ["business"],
    enabled: !!businessRef,
    queryFn: async () =>
      request(
        {
          req: {
            url: `/businesses/${businessRef}/seed-lots`,
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useGetBusiness({ businessRef, params, refetchOnWindowFocus = true }) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    enabled: !!businessRef,
    queryKey: ["business", `business/${businessRef}`],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/businesses/${businessRef}`,
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useAddBusiness() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: "/businesses",
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["business"]);
      queryClient.invalidateQueries(["businesses"]);
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["users"]);
    },
  });

  return queryApi;
}

function useUpdateBusiness() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ businessRef, body }) =>
      request(
        {
          req: {
            url: `/businesses/${businessRef}`,
            method: "PATCH",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["business"]);
      queryClient.invalidateQueries(["businesses"]);
    },
  });

  return queryApi;
}

function useAddField() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ businessRef, body }) =>
      request(
        {
          req: {
            url: `/businesses/${businessRef}/fields`,
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["fields"]);
      queryClient.invalidateQueries(["products"]);
    },
  });

  return queryApi;
}

function useEditField() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ businessRef, fieldRef, body }) =>
      request(
        {
          req: {
            url: `/businesses/${businessRef}/fields/${fieldRef}`,
            method: "PATCH",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["fields"]);
      queryClient.invalidateQueries(["products"]);
    },
  });

  return queryApi;
}

function useDeleteField() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ businessRef, fieldRef }) =>
      request(
        {
          req: {
            url: `/businesses/${businessRef}/fields/${fieldRef}`,
            method: "DELETE",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["fields"]);
    },
  });

  return queryApi;
}

function useGetFields({ businessRef, refetchOnWindowFocus = true }) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    enabled: !!businessRef,
    queryKey: ["fields"],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/businesses/${businessRef}/fields`,
            method: "GET",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useDeleteBusiness() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ businessRef }) =>
      request(
        {
          req: {
            url: `/businesses/${businessRef}`,
            method: "DELETE",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["business"]);
      queryClient.invalidateQueries(["businesses"]);
      queryClient.invalidateQueries(["product"]);
      queryClient.invalidateQueries(["products"]);
      queryClient.invalidateQueries(["field"]);
      queryClient.invalidateQueries(["fields"]);
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["users"]);
    },
  });

  return queryApi;
}

export {
  useAddBusiness,
  useGetAllBusinesses,
  useGetBusiness,
  useGetActiveSeedLots,
  useAddField,
  useEditField,
  useDeleteField,
  useGetFields,
  useUpdateBusiness,
  useDeleteBusiness,
};
