import { useState } from "react";
import { useForm } from "react-hook-form";
import { useUpdateMe, useGetCurrentUser } from "../api";
import userDetailsSchema from "./schemas/userDetailsSchema";
import Button from "../Components/Button";
import Heading from "../Components/Heading";
import Label from "../Components/Label";
import Input from "../Components/Input";
import RenderedErrors from "../Components/RenderedErrors";
import FlexCentered from "../Containers/FlexCentered";
import RequirePasswordModal from "../Sections/RequirePasswordModal";

function EditProfileForm() {
  const [showRequirePasswordModal, setShowRequirePasswordModal] =
    useState(false);
  const currentUserApi = useGetCurrentUser();
  const currentUser = currentUserApi?.data?.user;

  const updateMeApi = useUpdateMe();
  const form = useForm({ values: currentUser });
  if (!currentUser) return;

  const errors = form.formState.errors;
  let apiError = updateMeApi.error;

  const { register } = form;
  const schema = userDetailsSchema();

  const givenNameProps = register("givenName", schema.givenName);
  const familyNameProps = register("familyName", schema.familyName);
  const emailProps = register("email", schema.email);
  const passwordProps = register("password");

  const handleSubmit = (data) => {
    setShowRequirePasswordModal(false);
    const body = {};
    Object.keys(form.formState.dirtyFields).forEach((key) => {
      const isDirty = form.formState.dirtyFields[key];
      if (isDirty) body[key] = data[key];
    });
    if (Object.keys(form.formState.dirtyFields).length === 0) return;
    if (
      form.formState.dirtyFields.email &&
      currentUserApi?.data?.emailConfirmed
    ) {
      if (!data.password && !showRequirePasswordModal)
        return setShowRequirePasswordModal(true);
    }
    updateMeApi.mutate({ body });
  };

  return (
    <div>
      <Heading secondary className="text-center mb-2">
        My Profile
      </Heading>
      <div className="border-b border-hgBlue-900 mb-8" />

      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
        className=" pb-20"
        id="update-me-form"
      >
        <FlexCentered col>
          <Label htmlFor="givenName" className="mb-2">
            Given Name
          </Label>
          <Input
            type="text"
            id="givenName"
            className="w-[20rem] max-w-full mb-4"
            {...givenNameProps}
            ref={null}
            innerRef={givenNameProps.ref}
            error={errors.givenName}
          />
          <Label htmlFor="familyName" className="mb-2">
            Family Name
          </Label>
          <Input
            type="text"
            id="familyName"
            className="w-[20rem] max-w-full mb-4"
            {...familyNameProps}
            ref={null}
            innerRef={familyNameProps.ref}
            error={errors.familyName}
          />
          <Label htmlFor="email" className="mb-2">
            Email
          </Label>
          <Input
            type="email"
            id="email"
            className="w-[20rem] max-w-full mb-8"
            {...emailProps}
            ref={null}
            innerRef={emailProps.ref}
            error={errors.email}
          />
          <Button
            primary
            type="submit"
            disabled={Object.keys(form.formState.dirtyFields).length === 0}
            loading={updateMeApi.isLoading}
          >
            Save
          </Button>
          {form.formState.isSubmitted && (
            <RenderedErrors
              errors={errors}
              apiError={apiError}
              className="bg-hgCream-50"
            />
          )}
        </FlexCentered>
        {showRequirePasswordModal && (
          <RequirePasswordModal
            onClose={() => {
              setShowRequirePasswordModal(false);
            }}
            {...passwordProps}
            ref={null}
            innerRef={passwordProps.ref}
          />
        )}
      </form>
    </div>
  );
}
export default EditProfileForm;
