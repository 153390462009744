import PopulationListItem from "./PopulationListItem";
import SideBarFilter from "./SideBarFilter";
import Control from "./Control";
import Loading from "../../Components/Loading";
import Pagination from "./Pagination";
import { usePopulationListContext } from "./PopulationListContext";

function PopulationsList({ populations, getLink }) {
  const { api, setQuery } = usePopulationListContext();
  //   if (!query.limit) setQuery.limit(1);

  if (!populations) return <Loading />;
  const renderedPopulations = populations.map((population) => {
    return (
      <PopulationListItem
        key={population._id}
        population={population}
        link={getLink(population.reference)}
      />
    );
  });

  if (renderedPopulations.length === 0) {
    renderedPopulations.push(
      <div key="default">No matching populations found</div>
    );
  }

  return (
    <>
      <div className="pt-8">
        <Control />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-[auto_1fr] gap-x-4 md:gap-x-8 lg:gap-x-12 pt-4">
        <SideBarFilter className="hidden md:block" />
        <div className="flex flex-col gap-4 pt-2">
          {renderedPopulations}
          <div className="mt-8">
            <Pagination api={api} setQuery={setQuery} />
          </div>
        </div>
      </div>
    </>
  );
}
export default PopulationsList;
