import Button from "../Button";
import { useState } from "react";
import getPDF from "./getPDF";
import getCSV from "./getCSV";
import { useToastContext } from "../../providers/toastContext";

function DownloadButton({
  setError,
  url,
  fileName,
  fileType,
  children,
  showSuccessToast,
  params,
  ...props
}) {
  const [loading, setLoading] = useState(false);

  const { createSuccessToast, createErrorToast } = useToastContext();

  const handleClick = () => {
    if (fileType === "pdf") {
      getPDF({
        url,
        fileName,
        setLoading,
        setError,
      });
    }
    if (fileType === "csv") {
      getCSV({
        url,
        fileName,
        setLoading,
        setError:
          setError ||
          ((err) => {
            if (err) createErrorToast(err);
          }),
        onSuccess: showSuccessToast
          ? () => createSuccessToast("File downloaded successfully")
          : undefined,
        params,
      });
    }
  };

  return (
    <Button {...props} loading={loading} onClick={handleClick}>
      {children}
    </Button>
  );
}
export default DownloadButton;
