import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useGetCurrentUser(options) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () =>
      request(
        {
          req: {
            url: "/auth/current-user",
            method: "GET",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus: options?.refetchOnWindowFocus || false,
  });

  return queryApi;
}

function useAdminLogIn() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: "auth/log-in",
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
  });

  return queryApi;
}

function useLogOut() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async () =>
      request(
        {
          req: {
            url: "auth/log-out",
            method: "POST",
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
  });
  return queryApi;
}

function useResetPassword() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body, token }) =>
      request(
        {
          req: {
            url: `auth/reset-password/${token}`,
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
  });
  return queryApi;
}

function useForgotPassword() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: `auth/forgot-password`,
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
  });
  return queryApi;
}

function useConfirmEmail() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: `auth/confirm-email`,
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
  });
  return queryApi;
}

function useResendConfirmationCode() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: `auth/resend-confirmation-code`,
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
  });
  return queryApi;
}

function useRegister() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: `auth/register`,
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
  });
  return queryApi;
}

function useUpdateMe() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: `auth/update-me`,
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
  });
  return queryApi;
}

function useUpdatePassword() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: `auth/update-password`,
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
  });
  return queryApi;
}

function useDeleteMe() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: `auth/delete-me`,
            method: "DELETE",
            data: body,
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
  });
  return queryApi;
}

function useToggleAccess() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async (userRef) =>
      request(
        {
          req: {
            url: `auth/toggle-access/${userRef}`,
            method: "POST",
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["users"]);
    },
  });
  return queryApi;
}

function useUpdatePermissions() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ userRef, body }) =>
      request(
        {
          req: {
            url: `auth/update-permissions/${userRef}`,
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["users"]);
    },
  });
  return queryApi;
}

export {
  useGetCurrentUser,
  useAdminLogIn,
  useLogOut,
  useResetPassword,
  useForgotPassword,
  useConfirmEmail,
  useResendConfirmationCode,
  useRegister,
  useUpdateMe,
  useUpdatePassword,
  useToggleAccess,
  useUpdatePermissions,
  useDeleteMe,
};
