import { useState } from "react";
import { BsChevronLeft, BsChevronDown } from "react-icons/bs";
import Heading from "../../../../Components/Heading";
import Link from "../../../../Components/Link";
import Button from "../../../../Components/Button";
import P from "../../../../Components/P";
import FlexCentered from "../../../../Containers/FlexCentered";
import classNames from "classnames";

function GrowingData({ product }) {
  const [isOpen, setIsOpen] = useState(false);

  const renderedGrowingData = product?.growingData?.map((data) => {
    const headerClasses = (className) =>
      classNames(
        "border-b text-hgCream-50 font-semibold pb-1  border-hgBlue-200",
        className
      );
    return (
      <div
        key={data._id}
        className="px-[2vw] bg-gray-50 border pt-2 pb-4 rounded-xl  shadow-md w-full hover:scale-[101%]"
      >
        <Heading
          secondary
          className="text-hgGreen-1100  tracking-wider font-light text-xl mb-2"
        >
          {data.field?.name} - {data.areaSownHa} ha
        </Heading>
        <div className="">
          <div className="grid grid-cols-[auto_auto_auto] text-center text-hgBlue-600 select-none border border-hgBlue-200 rounded shadow overflow-hidden">
            <div className={headerClasses("bg-hgBlue-700 border-r")} />
            <P className={headerClasses("bg-hgBlue-700 border-r")}> Date</P>
            <P className={headerClasses("bg-hgBlue-700")}> Qty</P>
            <div className="contents bg-hgGreen-700 hover:bg-hgGreen-900 text-hgCream-50 border-hgCream-50">
              <P className="text-left pr-2 border-r  py-1 bg-inherit border-inherit pl-1">
                Sown
              </P>
              <P className={"px-2 border-r  py-1 bg-inherit border-inherit"}>
                {new Date(data.sowingDate).toLocaleDateString("en-gb", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
              </P>
              <P className="px-2 py-1 bg-inherit border-inherit">
                {Number(data.qtySownKg).toLocaleString("en-gb")} kg
              </P>
            </div>
            <div className="contents bg-hgGreen-800 text-hgCream-50 hover:bg-hgGreen-900 border-hgCream-50">
              <P className="text-left pr-2 border-r  py-1 bg-inherit border-inherit pl-1">
                Harvested
              </P>
              <P className={"px-2 border-r border-inherit py-1 bg-inherit"}>
                {data?.harvestDate
                  ? new Date(data.harvestDate).toLocaleDateString("en-gb", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                  : "-"}
              </P>
              <P className={"px-2 border-inherit py-1 bg-inherit"}>
                {data.totalYieldKg
                  ? `${Number(data.totalYieldKg / 1000).toLocaleString(
                      "en-gb"
                    )} t`
                  : "-"}
              </P>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="max-w-fit">
      <div
        className="flex flex-row items-center gap-x-2 cursor-pointer hover:text-hgGreen-900"
        onClick={() => setIsOpen((curr) => !curr)}
      >
        <Heading secondary className="text-xl font-light text-hgBlue-900">
          Growing Data
        </Heading>

        {isOpen && <BsChevronDown />}
        {!isOpen && <BsChevronLeft />}
      </div>
      <div className="pt-1 border-b-[1px] border-hgGreen-900 pr-[4vw]" />
      {isOpen && (
        <div>
          <FlexCentered col className="my-2 mt-8 gap-y-4">
            {renderedGrowingData}
          </FlexCentered>

          <Link
            to={`/dashboard/products/${product.reference}/edit-growing-data`}
          >
            <Button secondary outline className="mt-4">
              Edit
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}
export default GrowingData;
