function businessDetailsSchema() {
  return {
    tradingName: {
      required: "Trading name required",
      maxLength: {
        value: 25,
        message: "The trading name field exceed 25 characters. ",
      },
    },
    CPH_Number: {
      pattern: {
        value: /^[0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]$/,
        message: "Invalid CPH Number",
      },
    },
    address: {
      required: "Address required",
      validate: (value) => {
        return (
          (value?.postcode && value?.formattedAddress && value?.location) ||
          "Address is required"
        );
      },
    },
    email: {
      pattern: {
        value:
          // eslint-disable-next-line no-control-regex
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
        message: "Invalid email",
      },
    },
    about: {
      maxLength: {
        value: 700,
        message: "The about section cannot exceed 700 characters.",
      },
    },
  };
}

export default businessDetailsSchema;
