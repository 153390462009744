import { useGetCurrentUser } from "../../api";
import SideBarFilter from "./SideBarFilter";
import Control from "./Control";
import Loading from "../../Components/Loading";
import ProductListItem from "./ProductListItem";
import Pagination from "../PopulationsList/Pagination";
import { useProductListContext } from "./ProductListContext";

function ProductsList({ products, getLink, populationsObj }) {
  const { api, setQuery } = useProductListContext();
  const getCurrentUserApi = useGetCurrentUser();
  const isGrower = getCurrentUserApi?.data?.linkedBusinessType === "Grower";

  if (!products || !populationsObj) return <Loading />;

  const renderedProducts = products.map((product) => {
    return (
      <ProductListItem
        key={product._id}
        product={product}
        population={populationsObj[product?.population?.toString()]}
        link={getLink(product.reference)}
        isGrower={isGrower}
      />
    );
  });

  if (renderedProducts.length === 0) {
    renderedProducts.push(<div key="default">No matching products found</div>);
  }

  return (
    <>
      <div className="pt-8">
        <Control />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-[auto_1fr] gap-x-4 md:gap-x-8 lg:gap-x-12 pt-4">
        <SideBarFilter className="hidden md:block" />
        <div className="flex flex-col gap-4 pt-2">
          {renderedProducts}
          <div className="mt-8">
            <Pagination api={api} setQuery={setQuery} />
          </div>
        </div>
      </div>
    </>
  );
}
export default ProductsList;
