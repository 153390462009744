import { useEffect } from "react";
import { useGetAllBusinesses } from "../../../api";
import useQuery from "../../../hooks/useQuery";
import Page from "../../../Containers/Page";
import Table from "../../../Components/Table/Table";
import TableContext from "../../../Components/Table/TableContext";
import getApiStatusPage from "../../APIStatusPage";

function UsersTablePage() {
  const fields =
    "tradingName,businessType,business_Reference,postcode,CPH_Number";
  const defaultQuery = {
    fields,
  };
  const [query, isStale, setIsStale, setQuery, Filter] = useQuery(defaultQuery);

  const getAllBusinessesApi = useGetAllBusinesses({
    params: query,
  });

  useEffect(() => {
    if (!isStale) {
      getAllBusinessesApi.refetch();
      setIsStale(true);
    }
  }, [getAllBusinessesApi, isStale, query, setIsStale]);

  const businesses = getAllBusinessesApi?.data?.businesses;

  const tableConfig = {
    resizeableColumns: { fixed: true },
    movableColumns: true,
    rowLink: (row) => `/admin/businesses/${row.business_Reference}`,
    showControls: true,
    showPagination: true,
    columns: [
      {
        label: "Name",
        fieldNames: ["tradingName"],
        fieldNamesLabels: ["Name"],
        filterType: "String",
        searchFieldNames: ["tradingName"],
        row: (row) => row.tradingName,
        width: "sm",
        searchable: true,
      },
      {
        label: "Ref",
        fieldNames: ["business_Reference"],
        fieldNamesLabels: ["Ref"],
        filterType: "String",
        row: (row) => row.business_Reference,
        width: "xs",
        visible: true,
      },
      {
        label: "Type",
        fieldNames: ["businessType"],
        fieldNamesLabels: ["Type"],
        filterType: "Enum",
        row: (row) => row.businessType,
        width: "sm",
        searchable: true,
        options: [
          { label: "Merchant", id: "Merchant", value: "Merchant" },
          { label: "Grower", id: "Grower", value: "Grower" },
        ],
      },
      {
        label: "Postcode",
        fieldNames: ["postcode"],
        fieldNamesLabels: ["Postcode"],
        filterType: "String",
        row: (row) => row.postcode,
        width: "sm",
        searchable: true,
        visible: false,
      },
      {
        label: "CPH",
        fieldNames: ["CPH_Number"],
        fieldNamesLabels: ["CPH"],
        filterType: "String",
        row: (row) => row.CPH_Number || "-",
        width: "sm",
        searchable: true,
        visible: false,
      },
    ],
  };

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getAllBusinessesApi,
        type: "Businesses",
        dataRequired: true,
        initialLoadOnly: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Businesses" allowHorizontalScroll className="min-h-screen">
      <TableContext
        config={tableConfig}
        api={getAllBusinessesApi}
        url="businesses"
        query={query}
        setQuery={setQuery}
        Filter={Filter}
      >
        <Table data={businesses} />
      </TableContext>
    </Page>
  );
}

export default UsersTablePage;
