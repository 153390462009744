import Address from "../../Components/Address";
import Heading from "../../Components/Heading";
import Map from "../../Components/Map";
import P from "../../Components/P";
import FlexCentered from "../../Containers/FlexCentered";

function BusinessAbout({ business }) {
  return (
    <div className="w-full rounded overflow-hidden shadow-lg">
      <div className="bg-hgBlue-700 py-4">
        <Heading
          tertiary
          className="text-xl text-hgCream-50 text-center w-full font-light"
        >
          About
        </Heading>
      </div>
      <div className="px-4 py-12 bg-hgCream-50 ">
        <FlexCentered col>
          <div>
            <P>{business.about}</P>
          </div>
        </FlexCentered>
      </div>
    </div>
  );
}
export default BusinessAbout;
