import { useEffect } from "react";
import { useGetCurrentUser } from "../api/index";
import { Routes, Route, useNavigate } from "react-router-dom";
import BusinessDash from "../Pages/business/BusinessDash";
import NotFound from "../Pages/NotFound";
import BusinessProfilePage from "../Pages/business/business-business/BusinessProfilePage";
import FieldRecordPage from "../Pages/business/business-business/FieldRecordPage";
import ProductPage from "../Pages/business/business-products/ProductPage";
import ProductsPage from "../Pages/business/business-products/ProductsPage";
import SeedLotPage from "../Pages/business/business-seedLots/SeedLotPage";
import SeedLotsPage from "../Pages/business/business-seedLots/SeedLotsPage";
import EditProductDataPage from "../Pages/business/business-products/EditProductDataPage";
import EditGrowingDataPage from "../Pages/business/business-products/EditGrowingDataPage";
import EditBusinessPage from "../Pages/business/business-business/EditBusinessPage";
import EditGeneralDataPage from "../Pages/business/business-products/EditGeneralDataPage";

function BusinessRouter() {
  const currentUserAPI = useGetCurrentUser();
  const currentUser = currentUserAPI.data;
  const navigateTo = useNavigate();

  useEffect(() => {
    if (currentUser && !currentUser.isLoggedIn) {
      navigateTo("/");
    }
  });

  if (!currentUser) return <div>Loading</div>;

  return (
    <>
      <Routes>
        <Route exact path="/" element={<BusinessDash />} />

        <Route exact path="/business" element={<BusinessProfilePage />} />
        <Route exact path="/business/edit" element={<EditBusinessPage />} />

        <Route exact path="/business/fields" element={<FieldRecordPage />} />

        <Route exact path="products" element={<ProductsPage />} />
        <Route exact path="products/:productRef" element={<ProductPage />} />
        <Route
          exact
          path="products/:productRef/edit-general"
          element={<EditGeneralDataPage />}
        />
        <Route
          exact
          path="products/:productRef/edit-product-data"
          element={<EditProductDataPage />}
        />
        <Route
          exact
          path="products/:productRef/edit-growing-data"
          element={<EditGrowingDataPage />}
        />

        <Route exact path="seed-lots" element={<SeedLotsPage />} />
        <Route exact path="seed-lots/:seedLotRef" element={<SeedLotPage />} />
        <Route
          exact
          path="seed-lots/:seedLotRef/edit-lot"
          element={<SeedLotPage />}
        />
        <Route path="/*" element={<NotFound redirect="/" />} />
      </Routes>
    </>
  );
}

export default BusinessRouter;
