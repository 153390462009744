import { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Heading from "../../../Components/Heading";
import Image from "../../../Components/Image";
import populationImage from "../../../assets/population-image.webp";
import FlexCentered from "../../../Containers/FlexCentered";
import P from "../../../Components/P";
import Link from "../../../Components/Link";
import Button from "../../../Components/Button";

function PopulationSlider({ populations }) {
  const [i, setI] = useState(0);
  let content;

  const stepIndex = (n) => {
    let newI = i + n;
    if (newI < 0) newI = populations.length - 1;
    if (newI > populations.length - 1) newI = 0;
    setI(newI);
  };

  if (!populations || populations.length === 0) {
    content = (
      <Link to="/populations" className="hover:text-hgCream-50">
        <div className="w-full h-full relative min-w-[15rem] min-h-[12rem] w-[40vw] h-[35vw] max-w-[25rem] max-h-[25rem] ">
          <Image
            src={populationImage}
            alt="Wheat close-up"
            className="w-full h-full "
          />
          <div className="absolute inset-0 bg-hgCream-50 bg-opacity-20 group-hover:bg-opacity-30 group-hover:animate-pulse"></div>
        </div>
      </Link>
    );
  } else {
    const population = populations[i];
    const iconClasses = "text-2xl cursor-pointer";
    content = (
      <div className="py-8 min-w-[15rem] w-[40vw]">
        <FlexCentered col>
          <div className="grid grid-cols-3">
            <FlexCentered col>
              <BsChevronLeft
                className={iconClasses}
                onClick={() => stepIndex(-1)}
              />
            </FlexCentered>
            <div className="w-[5rem] h-[5rem] rounded-full overflow-hidden mt-2 shadow-lg">
              <Link to={`/populations/${population.reference}`}>
                {" "}
                <Image
                  src={population.image}
                  alt={population.name}
                  className="w-full h-full"
                />
              </Link>
            </div>
            <FlexCentered col>
              <BsChevronRight
                className={iconClasses}
                onClick={() => stepIndex(1)}
              />
            </FlexCentered>
          </div>
          <Link to={`/populations/${population.reference}`}>
            <Heading
              tertiary
              className="mt-2 text-lg font-light text-hgBlue-400"
            >
              {population.name}
            </Heading>
          </Link>
          <P className="font-light text-hgBlue-900 mt-2 px-2 md:px-4 text-center min-h-[3rem]">
            {population.shortDesc}
          </P>
          <Link to="/populations" className="hover:text-hgCream-50">
            <Button primary className="mt-8">
              All Populations
            </Button>
          </Link>
        </FlexCentered>
      </div>
    );
  }

  return (
    <div className="w-full h-full group">
      <div className="bg-hgGreen-900 py-4 px-2">
        <Heading
          secondary
          className="text-base sm:text-2xl md:text-3xl font-light text-center text-hgCream-50"
        >
          Explore Populations
        </Heading>
      </div>
      {content}
    </div>
  );
}
export default PopulationSlider;
