import { useGetCurrentUser } from "../api";
import Heading from "../Components/Heading";
import FlexCentered from "../Containers/FlexCentered";
import Page from "../Containers/Page";
import P from "../Components/P";
import getApiStatusPage from "./APIStatusPage";

function PowerlessUserDash() {
  const getCurrentUserApi = useGetCurrentUser();
  const currentUser = getCurrentUserApi?.data?.user;
  const restrictedAccess = getCurrentUserApi?.data?.restrictedAccess;
  const isAdmin = getCurrentUserApi?.data?.isAdmin;
  const linkedBusiness = !!getCurrentUserApi?.data?.isAdmin;
  const powerless = !isAdmin && !linkedBusiness;
  const registeredAsAdmin = !currentUser?.submittedBusinessDetails;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getCurrentUserApi,
        type: "Current User",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  let content;
  if (powerless) {
    content = (
      <>
        <P className="mb-4">Thank you for registering with HeteroGen.</P>
        {registeredAsAdmin && (
          <P>
            It looks like you have registered as an administrator. Please give
            us a few days to approve this request.
          </P>
        )}
        {!registeredAsAdmin && (
          <P>
            It looks like you have registered with a business. Please give us a
            few days to approve this request.
          </P>
        )}
        <P className="mt-4">
          If you have any questions, please contact{" "}
          <a
            href="mailto:info@heterogen.uk"
            className="underline hover:text-hgGreen-300"
          >
            info@heterogen.uk
          </a>
        </P>
      </>
    );
  }
  if (restrictedAccess) {
    content = (
      <>
        <P className="mb-4">
          Your access to this application has been restricted.
        </P>

        <P className="">
          If you have any questions, please contact{" "}
          <a
            href="mailto:info@heterogen.uk"
            className="underline hover:text-hgGreen-300"
          >
            info@heterogen.uk
          </a>
        </P>
      </>
    );
  }

  return (
    <Page title="Dashboard" className="min-h-[100vh] pt-12">
      <FlexCentered col>
        <Heading
          className="mb-12 text-2xl text-hgGreen-1100 font-light"
          tertiary
        >
          Welcome {currentUser?.givenName}
        </Heading>

        <div className="bg-hgBlue-500 rounded shadow py-12 px-4 text-hgCream-50">
          {content}
        </div>
      </FlexCentered>
    </Page>
  );
}
export default PowerlessUserDash;
