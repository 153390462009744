import Heading from "../../Components/Heading";
import Link from "../../Components/Link";
import P from "../../Components/P";
import formattedNumber from "../../utils/formattedNumber";
import Label from "../../Components/Label";
import RangeSlider from "../../Components/RangeSlider";
import GrowingLocations from "./GrowingLocations";

function GrowingData({
  growingData,
  dataSource,
  getLink,
  isInherited,
  showMap = true,
}) {
  let renderedInheritedFrom;
  if (dataSource && growingData && growingData.length > 0 && isInherited) {
    renderedInheritedFrom = (
      <div className="bg-hgYellow-100 p-4 my-2 rounded shadow">
        <P>
          Growing data inherited from:{" "}
          <Link
            className="whitespace-nowrap font-semibold"
            to={getLink(dataSource.reference)}
          >
            {dataSource.reference}
          </Link>
        </P>
      </div>
    );
  }

  let dataTable;
  if (growingData && growingData.length > 0) {
    const dataTableConfig = [
      {
        label: "Grown By",
        value: dataSource?.issuedBy?.tradingName,
      },
      {
        label: "Organic",
        value:
          dataSource?.isOrganic === undefined
            ? undefined
            : dataSource?.isOrganic
            ? "Yes"
            : "No",
      },

      {
        label: "Area Sown",
        value: `${formattedNumber(
          dataSource?.parentProduct?.totalAreaSownHa ??
            growingData[0]?.totalAreaSownHa
        )} ha`,
      },
      {
        label: "Quantity Sown",
        value: `${formattedNumber(
          dataSource?.parentProduct?.totalQuantitySownKg ??
            growingData[0]?.totalQuantitySownKg
        )} kg`,
      },
      {
        label: "Total Yield",
        value: `${formattedNumber(
          dataSource?.parentProduct?.totalYieldT ?? growingData[0]?.totalYieldT
        )} t`,
      },
      {
        label: "Seed Rate (kg/ha)",
        value:
          dataSource?.parentProduct?.seedRateKgHa ??
          growingData[0]?.seedRateKgHa,
        low: 80,
        high: 250,
      },
      {
        label: "Yield (t/ha)",
        value: dataSource?.parentProduct?.yieldTHa ?? growingData[0]?.yieldTHa,
        low: 1,
        high: 8,
      },
    ];
    const renderedRows = dataTableConfig.map((row) => {
      if (row.value === undefined) return undefined;
      return (
        <div
          className="flex flex-col w-full contents bg-hgBlue-100 even:bg-opacity-30"
          key={row.label}
        >
          <Label className="text-sm md:text-base bg-inherit pl-[1.25rem] py-4 select-none cursor-default">
            {row.label}
          </Label>

          {row.low !== undefined && row.high !== undefined && (
            <RangeSlider value={row.value} low={row.low} high={row.high} />
          )}
          {(row.low === undefined || row.high === undefined) && (
            <div className="bg-inherit flex flex-col justify-center pl-[1.5rem] font-semibold text-hgGreen-1200">
              <P>{row.value}</P>
            </div>
          )}
        </div>
      );
    });
    dataTable = (
      <div className="grid grid-cols-[auto_1fr] w-full content-stretch  select-none">
        {renderedRows}
      </div>
    );
  }

  let content;
  if (!growingData || growingData.length === 0) {
    content = <div className="p-2">No data found</div>;
  } else {
    content = (
      <>
        {dataTable}
        {showMap && <GrowingLocations growingData={growingData} />}
      </>
    );
  }

  return (
    <div className="w-full max-w-md rounded overflow-hidden">
      <div className="bg-hgBlue-700 py-4">
        <Heading
          tertiary
          className="text-xl text-hgCream-50 text-center w-full font-light"
        >
          Growing Data
        </Heading>
      </div>
      {renderedInheritedFrom}
      <div className="">{content}</div>
    </div>
  );
}
export default GrowingData;
