import { useForm, Controller, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "../providers/toastContext";
import {
  useGetAllBusinesses,
  useGetAllPopulations,
  useIssueSeedLot,
  useGetAllSeedLots,
} from "../api";
import seedLotSchema from "./schemas/seedLotSchema";
import Label from "../Components/Label";
import Dropdown from "../Components/Dropdown";
import DateInput from "../Components/DateInput";
import Input from "../Components/Input";
import FlexCentered from "../Containers/FlexCentered";
import RenderedErrors from "../Components/RenderedErrors";
import Button from "../Components/Button";
import getControlBodyDictionary from "../utils/getControlBodyDictionary";
import Loading from "../Components/Loading";
import { useEffect, useMemo } from "react";
import Heading from "../Components/Heading";
import P from "../Components/P";

function AddSeedLotAdminForm() {
  const getBusinessesApi = useGetAllBusinesses({
    params: {
      fields: "tradingName,businessType",
      completeList: true,
    },
  });

  const getPopulationsApi = useGetAllPopulations({
    params: {
      fields: "name",
      completeList: true,
    },
  });

  const businesses = getBusinessesApi?.data?.businesses;
  const populations = getPopulationsApi?.data?.populations;

  const form = useForm();
  const errors = form.formState.errors;

  const issueSeedLotApi = useIssueSeedLot();
  let apiError = issueSeedLotApi.error;

  const { createSuccessToast } = useToastContext();

  const navigateTo = useNavigate();
  useEffect(() => {
    if (issueSeedLotApi?.data) {
      createSuccessToast("New seed lot successfully issued");
      const seedLotRef = issueSeedLotApi?.data?.seedLot?.reference;
      if (seedLotRef) {
        return navigateTo(`/admin/seed-lots/${seedLotRef}`);
      }
    }
  }, [createSuccessToast, issueSeedLotApi?.data, navigateTo]);

  const controlBodies = getControlBodyDictionary({ array: true });

  let parentLot;

  const { register } = form;
  const schema = seedLotSchema({});

  register("population", schema.population);
  register("parentLot", schema.parentLot);
  register("issuedBy", schema.issuedTo);
  register("receivedBy", schema.receivedBy);
  register("date", schema.date);
  const quantityKgProps = register("quantityKg", schema.quantityKg);
  register("isOrganic", schema.isOrganic);
  register("controlBody", schema.controlBody);

  const isOrganic = useWatch({
    control: form.control,
    name: "isOrganic",
  });

  const population = useWatch({
    control: form.control,
    name: "population",
  });

  const issuedBy = useWatch({
    control: form.control,
    name: "issuedBy",
  });

  parentLot = useWatch({
    control: form.control,
    name: "parentLot",
  });

  const seedLotQuery = useMemo(() => {
    if (population && issuedBy) {
      return {
        fields: "reference,date,quantityKg",
        receivedBy: issuedBy?._id,
        population: population?._id,
        completeList: true,
      };
    } else return null;
  }, [population, issuedBy]);

  const getSeedLotsApi = useGetAllSeedLots({
    params: seedLotQuery,
    enabled: !!seedLotQuery,
  });

  useEffect(() => {
    form.setValue("parentLot", null);
  }, [population, issuedBy, form]);

  useEffect(() => {
    if (seedLotQuery) getSeedLotsApi.refetch();
  }, [getSeedLotsApi, seedLotQuery]);

  const seedLots = getSeedLotsApi?.data?.seedLots;

  const handleSubmit = (data) => {
    const body = {
      population: data?.population?._id,
      parentLot: data?.parentLot?._id,
      issuedBy: data?.issuedBy?._id,
      receivedBy: data?.receivedBy?._id,
      date: data?.date,
      quantityKg: data?.quantityKg,
      isOrganic:
        data?.isOrganic?.label === "Organic"
          ? true
          : data?.isOrganic?.label === "Not Organic"
          ? false
          : undefined,
      controlBody:
        data?.isOrganic?.label === "Organic"
          ? data?.controlBody?.code
          : undefined,
    };
    if (data?.issuedBy?.businessType !== "Grower") {
      body.isOrganic = undefined;
      body.controlBody = undefined;
    }
    issueSeedLotApi.mutate({ body });
  };

  if (!getBusinessesApi.isFetched || !getPopulationsApi.isFetched) return;
  <FlexCentered col>
    <Loading />
  </FlexCentered>;

  return (
    <form
      className="pb-56"
      onSubmit={form.handleSubmit(handleSubmit)}
      noValidate
    >
      <FlexCentered col>
        <FlexCentered col>
          <Heading primary className="py-12">
            Issue a Seed Lot
          </Heading>
          <Heading
            secondary
            className="w-full pb-2"
            title="Details about the source of this seed lot"
          >
            Source
          </Heading>
          <div className="border-b border-hgGreen-900 w-full mb-8" />
          <Label
            htmlFor="population"
            className="mb-2"
            title="Which population does this seed lot belong to?"
          >
            Population
          </Label>

          <div>
            <Controller
              control={form.control}
              name="population"
              render={({ field: { onChange, onBlur, value } }) => (
                <Dropdown
                  id="population"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className="w-[20rem] max-w-full mb-12"
                  config={{
                    data: populations,
                    getLabel: (el) => el?.name,
                    options: {
                      filter: true,
                    },
                  }}
                  title="Which population does this seed lot belong to?"
                  error={errors.population}
                  rules={schema.population}
                />
              )}
            />
          </div>

          <Label
            htmlFor="issuedBy"
            className="mb-2"
            title="Select the business this seed lot is being issued to"
          >
            Issued By
          </Label>

          <div>
            <Controller
              control={form.control}
              name="issuedBy"
              render={({ field: { onChange, onBlur, value } }) => (
                <Dropdown
                  id="issuedBy"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className="w-[20rem] max-w-full mb-4"
                  config={{
                    data: businesses,
                    getLabel: (el) => el?.tradingName,
                    options: {
                      filter: true,
                    },
                  }}
                  title="Select the business this seed lot is being issued to"
                  error={errors.issuedBy}
                  rules={schema.issuedBy}
                />
              )}
            />
          </div>

          <Label
            htmlFor="parentLot"
            className="mb-2"
            title="Select the source seed lot"
          >
            Parent Lot
          </Label>

          <div>
            <Controller
              control={form.control}
              name="parentLot"
              render={({ field: { onChange, onBlur, value } }) => (
                <Dropdown
                  id="parentLot"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className="w-[20rem] max-w-full mb-12"
                  config={{
                    data: seedLots,
                    getLabel: (el) => el?.reference,

                    defaultField: {
                      reference: "Unknown",
                      id: "Unknown",
                      style: "danger",
                    },
                  }}
                  disabled={!seedLots}
                  title="Select the source seed lot"
                  error={errors.parentLot}
                  rules={schema.parentLot}
                />
              )}
            />
          </div>

          <Heading
            secondary
            className="w-full pb-2"
            title="Details about this seed lot"
          >
            Details
          </Heading>
          <div className="border-b border-hgGreen-900 w-full mb-8" />
          <Label
            htmlFor="issuedTo"
            className="mb-2"
            title="Select the business receiving this seed lot"
          >
            Received By
          </Label>

          <div>
            <Controller
              control={form.control}
              name="receivedBy"
              render={({ field: { onChange, onBlur, value } }) => (
                <Dropdown
                  id="receivedBy"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className="w-[20rem] max-w-full mb-12"
                  config={{
                    data: businesses,
                    getLabel: (el) => el?.tradingName,
                    options: {
                      filter: true,
                    },
                  }}
                  title="Select the business receiving this seed lot"
                  error={errors.receivedBy}
                  rules={schema.receivedBy}
                  disabled={!parentLot}
                />
              )}
            />
          </div>

          <Label htmlFor="date" title="On which date was this seed lot issued?">
            Date
          </Label>
          <Controller
            control={form.control}
            name="date"
            render={({ field: { onChange, onBlur, value } }) => (
              <DateInput
                id="date"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                min={new Date(2010, 0, 1)}
                max={new Date()}
                className="max-w-[20rem] w-full mb-4 mt-2"
                title="On which date was this seed lot issued?"
                error={errors.date}
                rules={schema.date}
                disabled={!parentLot}
              />
            )}
          />

          <Label
            htmlFor="quantityKg"
            title="Specify the batch weight of this seed lot in kilograms"
          >
            Quantity (kg)
          </Label>
          <div className="mt-2">
            <Input
              type="text"
              id="quantityKg"
              className="w-[20rem] max-w-full mb-12"
              {...quantityKgProps}
              ref={null}
              innerRef={quantityKgProps.ref}
              error={errors.quantityKg}
              disabled={!parentLot}
              title="Specify the batch weight of this seed lot in kilograms"
            />
          </div>

          {issuedBy?.businessType === "Grower" && (
            <>
              <Label
                htmlFor="isOrganic"
                className="mb-2"
                title="Is the seed lot organic"
              >
                Organic
              </Label>

              <div>
                <Controller
                  control={form.control}
                  name="isOrganic"
                  defaultValue={{ label: "Not specified", id: "Not specified" }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropdown
                      id="isOrganic"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      className="w-[20rem] max-w-full  mt-2 mb-4"
                      config={{
                        data: [
                          { label: "Not specified", id: "Not specified" },
                          { label: "Organic", id: "organic" },
                          { label: "Not Organic", id: "notOrganic" },
                        ],
                        options: {
                          disallowEmptyValues: true,
                        },
                      }}
                      title="Is the seed lot organic"
                      error={errors.isOrganic}
                      rules={schema.isOrganic}
                      disabled={!parentLot}
                    />
                  )}
                />
              </div>

              {isOrganic?.id === "organic" && (
                <>
                  <Label
                    htmlFor="isOrganic"
                    className="mb-2"
                    title="Specify the control body"
                  >
                    Control Body
                  </Label>

                  <div>
                    <Controller
                      control={form.control}
                      name="controlBody"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Dropdown
                          id="controlBody"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          className="w-[20rem] max-w-full mb-4 mt-2"
                          config={{
                            data: controlBodies,
                            getKey: (el) => el.code,
                            options: {
                              disallowEmptyValues: true,
                            },
                          }}
                          title="Specify the control body"
                          error={errors.controlBody}
                          rules={schema.controlBody}
                          disabled={!parentLot}
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </>
          )}

          <FlexCentered col className="col-span-2 mb-4 mt-8">
            {parentLot?.reference === "Unknown" && (
              <div className="bg-hgRed-100 px-4 py-2 w-[20rem] max-w-full rounded shadow mb-4">
                <P className="text-center font-semibold mb-2">Warning!</P>
                <P className="text-center">"Unknown" parent lot</P>
              </div>
            )}
            <Button primary type="submit" loading={issueSeedLotApi.isLoading}>
              Issue
            </Button>
            <RenderedErrors
              errors={errors || []}
              apiError={apiError || ""}
              className="bg-hgCream-50 max-w-prose"
            />
          </FlexCentered>
        </FlexCentered>
      </FlexCentered>
    </form>
  );
}
export default AddSeedLotAdminForm;
