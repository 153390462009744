import { useParams } from "react-router-dom";
import { useGetSeedLot } from "../../../api";
import Page from "../../../Containers/Page";
import EditSeedLotForm from "../../../Forms/EditSeedLotForm";
import getApiStatusPage from "../../APIStatusPage";

function EditSeedLotPage() {
  const { seedLotRef } = useParams();
  const getSeedLotApi = useGetSeedLot({
    seedLotRef,
    params: {
      fields:
        "reference,population,parentLot,isInitialLot,date,quantityKg,isArchived,issuedBy,receivedBy,isOrganic,controlBody",
      "populate[receivedBy]": "tradingName",
      "populate[issuedBy]": "tradingName,businessType",
      "populate[population]": "name",
      "populate[parentLot]": "reference",
    },
  });
  const seedLot = getSeedLotApi?.data?.seedLot;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getSeedLotApi,
        type: "Seed Lot",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title="Seed Lots">
      <EditSeedLotForm seedLot={seedLot} />
    </Page>
  );
}
export default EditSeedLotPage;
