import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../Containers/Page";
import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";
import RegistrationForm from "../../Forms/RegistrationForm";
import RegisterWithBusinessDetailsForm from "../../Forms/RegisterWithBusinessDetailsForm";

function RegistrationPage() {
  const params = new URL(document.location).searchParams;
  const type = params.get("type");
  const validTypes = ["Grower", "Merchant", "Admin"];
  const navigateTo = useNavigate();

  useEffect(() => {
    if (!validTypes.includes(type)) return navigateTo("/sign-up");
  });

  if (!validTypes.includes(type)) return;

  return (
    <Page title="Registration Form" className="bg-hgBlue-500">
      {/* IF NO USER TYPE - DISPLAY CARDS FOR USER TO SELECT THEIR TYPE */}
      {type === "Admin" && (
        <FlexCentered col>
          <Heading secondary className="text-hgCream-50 pt-[8vh] pb-[4vh]">
            Register as Admin
          </Heading>
          <RegistrationForm />
        </FlexCentered>
      )}
      {(type === "Grower" || type === "Merchant") && (
        <RegisterWithBusinessDetailsForm type={type} />
      )}
    </Page>
  );
}

export default RegistrationPage;
