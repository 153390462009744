import { useTableContext } from "../../TableContext";
import { AiOutlineClose } from "react-icons/ai";

function FiltersList() {
  const { Filter, setQuery } = useTableContext();

  const filtersObj = Filter?.filters;
  if (!filtersObj) return;
  const labels = [];
  Object.keys(filtersObj).forEach((fieldName) => {
    Object.keys(filtersObj[fieldName]).forEach((operator) => {
      const label = filtersObj[fieldName][operator].label;
      labels.push({
        label,
        key: {
          fieldName,
          operator,
        },
      });
    });
  });
  if (labels.length === 0) return;
  const renderedLabels = labels.map((label) => {
    return (
      <div
        key={label.label}
        className="relative bg-hgYellow-100  rounded-xl pl-2 pr-4 py-1 select-none"
      >
        {label.label}
        <div
          className="absolute flex flex-center items-center justify-center p-1 rounded-full bg-hgYellow-300 top-0 right-0  translate-x-1/2 cursor-pointer hover:bg-hgYellow-400"
          onClick={() => setQuery.unsetFilter(label.key)}
        >
          <AiOutlineClose />
        </div>
      </div>
    );
  });
  return (
    <div className="w-full max-w-[90vw] flex flex-row flex-wrap gap-x-4 gap-y-1 mt-2">
      {renderedLabels}
    </div>
  );
}
export default FiltersList;
