import { useEffect, useState, useRef } from "react";

function Loading() {
  const [dotCount, setDotCount] = useState(1);
  const loadingRef = useRef(null);

  useEffect(() => {
    if (!loadingRef.current) return;
    loadingRef.current.classList.remove("opacity-0");
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (dotCount >= 3) setDotCount(1);
      else {
        setDotCount((curr) => curr + 1);
      }
    }, 350);
    return () => clearInterval(interval);
  });

  return (
    <div
      className="flex flex-row w-20 opacity-0 duration-300 transition-opacity"
      ref={loadingRef}
    >
      {"Loading "}
      <div>{`.`.repeat(dotCount)}</div>
    </div>
  );
}

export default Loading;
