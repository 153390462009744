import { useState, useRef, useEffect } from "react";
import {
  useAddCropType,
  useEditCropType,
  useDeleteCropType,
} from "../../../../api";
import { useToastContext } from "../../../../providers/toastContext";
import Button from "../../../../Components/Button";
import classNames from "classnames";

function CropTypeItem({ cropType, isEditing, setIsEditing, addMode }) {
  const [cropTypeName, setCropTypeName] = useState(cropType?.name || "");
  const inputRef = useRef(null);

  const addCropTypeApi = useAddCropType();
  const editCropTypeApi = useEditCropType();
  const deleteCropTypeApi = useDeleteCropType();
  const { createSuccessToast, createErrorToast } = useToastContext();

  //SHOW SUCCESS AND ERROR MESSAGES FOR EDIT CROP TYPE
  useEffect(() => {
    if (editCropTypeApi?.data) {
      setIsEditing(null);
      editCropTypeApi.reset();
      createSuccessToast("Crop type edited");
    }
    if (editCropTypeApi?.error) {
      editCropTypeApi.reset();
      createErrorToast(editCropTypeApi?.error);
    }
  }, [
    createErrorToast,
    createSuccessToast,
    editCropTypeApi,
    editCropTypeApi?.data,
    setIsEditing,
  ]);

  //SHOW SUCCESS AND ERROR MESSAGES FOR ADD CROP TYPE
  useEffect(() => {
    if (addCropTypeApi?.isSuccess) {
      setIsEditing(null);
      addCropTypeApi.reset();
      createSuccessToast("Crop type added");
    }
    if (addCropTypeApi?.error) {
      addCropTypeApi.reset();
      createErrorToast(addCropTypeApi?.error);
    }
  }, [addCropTypeApi, createErrorToast, createSuccessToast, setIsEditing]);

  //SHOW SUCCESS AND ERROR MESSAGES FOR DELETE CROP TYPE
  useEffect(() => {
    if (deleteCropTypeApi?.isSuccess) {
      setIsEditing(null);
      deleteCropTypeApi.reset();
      createSuccessToast("Crop type deleted");
    }
    if (deleteCropTypeApi?.error) {
      deleteCropTypeApi.reset();
      createErrorToast(deleteCropTypeApi?.error);
    }
  }, [createErrorToast, createSuccessToast, deleteCropTypeApi, setIsEditing]);

  const inputClasses = classNames(
    "w-full p-2 outline-none text-hgCream-50 focus:ring-transparent focus:bg-hgBlue-100 focus:bg-opacity-20 active:ring-transparent",
    {
      "bg-hgBlue-50 bg-opacity-10": isEditing,
      "bg-hgBlue-500 bg-opacity-0": !isEditing,
    }
  );
  const buttonClasses = "px-2 py-0 h-full w-full";

  const handleEdit = () => {
    if (!addMode) {
      editCropTypeApi.mutate({
        cropTypeRef: cropType._id,
        body: {
          name: cropTypeName,
        },
      });
    } else {
      addCropTypeApi.mutate({
        body: {
          name: cropTypeName,
        },
      });
    }
  };

  const handleDelete = () => {
    deleteCropTypeApi.mutate({
      cropTypeRef: cropType._id,
    });
  };

  return (
    <div
      className={classNames("p-2 rounded shadow bg-hgBlue-500", {
        "border border-hgBlue-200 my-4": isEditing,
      })}
    >
      <div className="grid grid-cols-[15rem_1fr_1fr] gap-x-2">
        <div className="w-[15rem] bg-hgCream-50 select-none bg-inherit">
          <input
            ref={inputRef}
            className={inputClasses}
            value={cropTypeName}
            onChange={(e) => setCropTypeName(e.target.value)}
            onKeyDown={(e) => {
              if (e?.key === "Enter") handleEdit();
            }}
            disabled={!isEditing}
            autoFocus={addMode}
          />
        </div>
        {!isEditing && (
          <div className="bg-hgBlue-50">
            <Button
              outline
              className={buttonClasses}
              onClick={() => {
                setIsEditing(cropType?._id);
                if (inputRef?.current) {
                  inputRef.current.disabled = false;
                  inputRef.current.focus();
                  inputRef.current.select();
                }
              }}
            >
              Edit
            </Button>
          </div>
        )}
        {isEditing && (
          <div className="bg-hgBlue-50" onClick={handleEdit}>
            <Button primary outline className={buttonClasses}>
              Save
            </Button>
          </div>
        )}
        {!isEditing && (
          <div className="bg-hgBlue-50">
            <Button
              danger
              outline
              className={buttonClasses}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        )}
        {isEditing && (
          <div className="bg-hgBlue-50">
            <Button
              outline
              danger
              className={buttonClasses}
              onClick={() => {
                setIsEditing(null);
                setCropTypeName(cropType?.name);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CropTypeItem;
