import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useGetAllUsers({ params }) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    queryKey: ["users"],
    queryFn: async () =>
      request(
        {
          req: {
            url: "/users",
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
  });

  return queryApi;
}

function useGetOneUser({ ref, params }) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    queryKey: ["user"],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/users/${ref}`,
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
  });

  return queryApi;
}

function useCreateBusinessLink() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ ref, body }) =>
      request(
        {
          req: {
            url: `/users/${ref}/create-business-link`,
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["users"]);
    },
  });

  return queryApi;
}

function useDisconnectBusinessLink() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ ref }) =>
      request(
        {
          req: {
            url: `/users/${ref}/disconnect-business-link`,
            method: "POST",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["users"]);
    },
  });

  return queryApi;
}

export {
  useGetAllUsers,
  useGetOneUser,
  useCreateBusinessLink,
  useDisconnectBusinessLink,
};
