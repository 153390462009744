import { useQuery } from "@tanstack/react-query";
import request from "../apiClient";

const prodToken =
  "dtoken_hEDzcyiWMr1Ff8OSELg7aUkmxQAxWRhIWJrUpFj3eTzterlK3VdWJY1eyhADhPZyucYiN50QJ4dVx9qNaBpOpGbt0qHat1oePZ6cQoe3BjI3W8iCHXumErWM3SmxM228eG6JxkyD733xm4k6Bj-gTLGTcDDj9mszjz8K75TNioY-CNtX2JzsrPEQF8OPRkyXYDO3QhZwfT4Fdm4zynYuNzKIIu0dkTKW";
const devToken =
  "dtoken_hEDzcyiWMr1Ff8OSELg7aUkmxQAxWRhIWJrUpFj3eTzterlK3VdWJY1eyhADhPZykrWyUqrMM9cE0H0LewTj8X4qNoO6ZFhv0c5O4NBUPk9s9Sc-5BDfHJPHxrq0AfRqeAzBRy8C06NoLZw4Srsc6_a-5FtVFPyVfrhIwcZ1qs6q0ENEcvergPdYxsQCjh8n55A4k_6-r-Y";

const dotUkToken =
  "dtoken_hEDzcyiWMr1Ff8OSELg7aUkmxQAxWRhIWJrUpFj3eTzterlK3VdWJY1eyhADhPZyucYiN50QJ4dVx9qNaBpOpF8_KfBR8gFIJ1CEFsW5CgiY2LrW03qbldHnquS4wmQ0pJUcYYATLRRbCeNLdmE-k-y5F3vMVEOuSN3B1RmXeQTwekueani0vteKE7gsgFEGTvjmhrJINY8";

const dotCoDotUkToken =
  "dtoken_hEDzcyiWMr1Ff8OSELg7aUkmxQAxWRhIWJrUpFj3eTzterlK3VdWJY1eyhADhPZyucYiN50QJ4dVx9qNaBpOpLEiZ6DpkVGSjuF7YQ6uNY4DKzMxArf-oxWPlvr6VY8vMzK_R3oI2BK9kxRlt9mtuAXmUCpbOjcByJwMMhPJCiiKpaL_kW7gOfNxR-5fwRB2WUkDab0A-mE";

let token = prodToken;
const origin = window.location.origin;
if (origin.startsWith("http://localhost")) token = devToken;
if (origin.includes("heterogen.uk")) token = dotUkToken;
if (origin.includes("heterogen.co.uk")) token = dotCoDotUkToken;

function useGetAddress({ enabled, postcode }) {
  const queryApi = useQuery({
    staleTime: Infinity,
    enabled,
    queryKey: ["getAddress", postcode],
    queryFn: async () =>
      request({
        req: {
          url: `https://api.getAddress.io/find/${postcode}`,
          method: "GET",
          params: {
            "api-key": token,
            expand: true,
            format: true,
            sort: true,
          },
        },
        transformData: (res) => res.data,
      }),
  });

  return queryApi;
}

export default useGetAddress;
