import { useUpdateProduct } from "../../api";
import ProductItem from "./ProductItem";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function ActiveProducts({ populations, activeProducts, isGrower }) {
  const editProductApi = useUpdateProduct();

  const navigateTo = useNavigate();
  useEffect(() => {
    if (editProductApi?.data?.product?.reference) {
      const ref = editProductApi.data.product.reference;
      navigateTo(`/dashboard/products/${ref}`);
    }
  }, [editProductApi?.data?.product?.reference, navigateTo]);
  if (!activeProducts || !populations) return;

  const handleArchiveProduct = (ref) => {
    editProductApi.mutate({
      productRef: ref,
      body: {
        isArchived: true,
      },
    });
  };

  const renderedItems = activeProducts.map((product) => {
    return (
      <ProductItem
        key={product._id.toString()}
        product={product}
        population={populations[product.population.toString()]}
        isGrower={isGrower}
        handleArchiveProduct={handleArchiveProduct}
        loading={editProductApi.isLoading}
      />
    );
  });

  return <div className="flex flex-col gap-12 w-full">{renderedItems}</div>;
}
export default ActiveProducts;
