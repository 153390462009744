import compareDates from "../../utils/compareDates";

export default function dateValidator({
  value,
  max,
  min,
  label,
  maxLabel,
  minLabel,
}) {
  const dateValue = new Date(value);

  if (!(dateValue instanceof Date)) return `${label} in invalid`;
  if (!Number.isFinite(dateValue.getTime())) return `${label} in invalid`;

  if (compareDates(dateValue, max) === 1) {
    const maxString = new Date(max).toLocaleDateString("en-gb", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return `${label} cannot be later than ${
      maxLabel ? `${maxLabel} (${maxString})` : maxString
    }`;
  }

  if (compareDates(dateValue, min) === -1) {
    const minString = new Date(min).toLocaleDateString("en-gb", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return `${label} cannot predate ${
      minLabel ? `${minLabel} (${minString})` : minString
    }`;
  }

  return true;
}
