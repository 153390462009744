import ModalWindow from "../Components/ModalWindow";
import SignInForm from "../Forms/SignInForm";
import Heading from "../Components/Heading";
import CloseButton from "../Components/CloseButton";

function SignInModal({ onClose }) {
  return (
    <ModalWindow options={{ blur: true, darkMode: true, pos: { top: 20 } }}>
      <div className="relative bg-hgBlue-500 p-8 rounded-lg border border-hgBlue-900 overflow-y-auto">
        <Heading secondary className="text-hgCream-50 text-center mb-4">
          Sign-In
        </Heading>
        <SignInForm onClose={onClose} />
        <CloseButton
          className="absolute top-[5px] right-[5px] bg-hgCream-50 rounded-full p-1 cursor-pointer hover:bg-hgCream-200"
          onClick={onClose}
        />
      </div>
    </ModalWindow>
  );
}
export default SignInModal;
