import { useParams } from "react-router-dom";
import { useGetSeedLot, useGetCurrentUser } from "../../../api";
import Page from "../../../Containers/Page";
import getApiStatusPage from "../../APIStatusPage";
import ViewSeedLot from "../../../Sections/ViewSeedLot";

function SeedLotPage() {
  const { seedLotRef } = useParams();
  const getSeedLotApi = useGetSeedLot({
    seedLotRef,
    params: {
      fields:
        "reference,population,parentLot,parentProduct,isInitialLot,date,quantityKg,isArchived,issuedBy,receivedBy",
      "populate[receivedBy]": "tradingName,business_Reference",
      "populate[issuedBy]": "tradingName,business_Reference",
      "populate[population]": "name,reference",
      "populate[parentLot]": "reference",
      "populate[parentProduct]":
        "reference,DON,ZON,bunt,germinationRate,gluten,hagberg,moistureContent,protein,septoria,specificWeight,thousandGrainWeightGrams",
      includeLineageData: true,
    },
  });
  const useGetCurrentUserApi = useGetCurrentUser();

  const seedLot = getSeedLotApi?.data?.seedLot;
  const canMakeActive =
    useGetCurrentUserApi?.data?.user?.linkedBusiness ===
    seedLot?.receivedBy?._id;
  const lineageData = getSeedLotApi?.data?.lineageData;

  const statusPage = getApiStatusPage({
    apis: [
      {
        api: getSeedLotApi,
        type: "Seed Lot",
        dataRequired: true,
      },
    ],
  });
  if (statusPage) return statusPage;

  return (
    <Page title={`Seed Lot - ${seedLotRef}`}>
      <ViewSeedLot
        seedLot={seedLot}
        lineageData={lineageData}
        getSeedLotLink={(ref) => `/dashboard/seed-lots/${ref}`}
        getBusinessLink={(ref) => `/business-view?ref=${ref}`}
        getPopulationLink={(ref) => `/populations/${ref}`}
        canMakeActive={canMakeActive}
      />
    </Page>
  );
}

export default SeedLotPage;
