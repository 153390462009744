import Button from "../../Components/Button";
import FlexCentered from "../../Containers/FlexCentered";

function Pagination({ api, setQuery }) {
  const currentPage = api?.data?.page;
  const maxPages = api?.data?.maxPages;

  if (maxPages < 2) return;

  return (
    <FlexCentered row>
      <div className="grid grid-cols-2 gap-x-2">
        <Button
          primary
          outline
          disabled={currentPage === 1}
          onClick={() => setQuery.page(currentPage - 1)}
        >
          Previous
        </Button>
        <Button
          primary
          outline
          disabled={currentPage === maxPages}
          onClick={() => setQuery.page(currentPage + 1)}
        >
          Next
        </Button>
      </div>
    </FlexCentered>
  );
}
export default Pagination;
