import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SeedLotItem from "./SeedLotItem";
import { useEditSeedLot } from "../../api";

function ActiveSeedLots({ populations, activeSeedLots }) {
  const editSeedLotApi = useEditSeedLot();

  const navigateTo = useNavigate();
  useEffect(() => {
    if (editSeedLotApi?.data?.seedLot?.reference) {
      const ref = editSeedLotApi.data.seedLot.reference;
      navigateTo(`/dashboard/seed-lots/${ref}`);
    }
  });

  if (!activeSeedLots || !populations) return;

  const handleArchive = (ref) => {
    editSeedLotApi.mutate({
      seedLotRef: ref,
      body: {
        isArchived: true,
      },
    });
  };

  const renderedItems = activeSeedLots.map((seedLot) => {
    return (
      <SeedLotItem
        key={seedLot._id.toString()}
        seedLot={seedLot}
        population={populations[seedLot.population.toString()]}
        handleArchive={handleArchive}
        loading={editSeedLotApi.isLoading}
      />
    );
  });

  return <div className="flex flex-col gap-4 w-full">{renderedItems}</div>;
}
export default ActiveSeedLots;
