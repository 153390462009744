import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useAddPopulation() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: "/populations",
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: (res) => {
      queryClient.invalidateQueries(["populations"]);
    },
  });

  return queryApi;
}

function useGetAllPopulations({
  params,
  refetchOnWindowFocus = true,
  enabled = true,
}) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    enabled,
    queryKey: ["populations"],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/populations`,
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useGetPopulation({
  populationRef,
  params,
  refetchOnWindowFocus = true,
  enabled = true,
}) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    enabled,
    queryKey: ["population", `population/${populationRef}`],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/populations/${populationRef}`,
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useGetFamilyTree({
  populationRef,
  refetchOnWindowFocus = true,
  enabled = true,
}) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    enabled,
    queryKey: ["populations"],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/populations/${populationRef}/family-tree`,
            method: "GET",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useUpdatePopulation() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body, populationRef }) =>
      request(
        {
          req: {
            url: `/populations/${populationRef}`,
            method: "PATCH",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: (res) => {
      queryClient.invalidateQueries(["population"]);
      queryClient.invalidateQueries(["populations"]);
    },
  });

  return queryApi;
}

function useDeletePopulation() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ populationRef }) =>
      request(
        {
          req: {
            url: `/populations/${populationRef}`,
            method: "DELETE",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: (res) => {
      queryClient.invalidateQueries(["population"]);
      queryClient.invalidateQueries(["populations"]);
    },
  });

  return queryApi;
}

function useAddCropType() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: "/populations/crop-types",
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: (res) => {
      queryClient.invalidateQueries(["cropTypes"]);
    },
  });

  return queryApi;
}

function useGetCropTypes({ refetchOnWindowFocus = true }) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    queryKey: ["cropTypes"],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/populations/crop-types`,
            method: "GET",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useEditCropType() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ cropTypeRef, body }) =>
      request(
        {
          req: {
            url: `/populations/crop-types/${cropTypeRef}`,
            method: "PATCH",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: (res) => {
      queryClient.invalidateQueries(["cropTypes"]);
    },
  });

  return queryApi;
}

function useDeleteCropType() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ cropTypeRef }) =>
      request(
        {
          req: {
            url: `/populations/crop-types/${cropTypeRef}`,
            method: "DELETE",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: (res) => {
      queryClient.invalidateQueries(["cropTypes"]);
    },
  });

  return queryApi;
}

export {
  useAddPopulation,
  useAddCropType,
  useGetCropTypes,
  useGetAllPopulations,
  useGetFamilyTree,
  useGetPopulation,
  useUpdatePopulation,
  useDeletePopulation,
  useEditCropType,
  useDeleteCropType,
};
