import { useState, useEffect } from "react";
import Dropdown from "../../../../Components/Dropdown";
import Label from "../../../../Components/Label";

function Controls({ query, setQuery, populations, businesses, isReceived }) {
  const [year, setYear] = useState(null);
  const [business, setBusiness] = useState(null);
  const [isArchived, setIsArchived] = useState(null);
  const [hasProduct, setHasProduct] = useState(null);
  const [population, setPopulation] = useState(null);

  const yearOptions = [];
  for (let i = new Date().getFullYear(); i >= 2010; i--) {
    yearOptions.push({
      label: `${i}`,
      id: i,
      value1: new Date(i, 0, 1),
      value2: new Date(i, 11, 31),
    });
  }

  const booleanOptions = [
    {
      label: "Yes",
      id: "Yes",
      value: true,
    },
    {
      label: "No",
      id: "No",
      value: false,
    },
  ];

  useEffect(() => {
    const field = isReceived
      ? "issuedBy/business_Reference"
      : "receivedBy/business_Reference";

    if (query[field] && query[field] !== business?.business_Reference) {
      setBusiness(
        businesses.find(
          (business) => business.business_Reference === query[field]
        )
      );
    } else if (!query[field] && business) {
      setBusiness(null);
    }
  }, [business, business?.business_Reference, businesses, isReceived, query]);

  useEffect(() => {
    if (
      query.isArchived !== undefined &&
      (!isArchived || isArchived.value !== query.isArchived)
    ) {
      if (query.isArchived) {
        setIsArchived({ label: "Yes", id: "Yes", value: true });
      } else
        setIsArchived({
          label: "No",
          id: "No",
          value: false,
        });
    } else if (query.isArchived === undefined && isArchived)
      setIsArchived(null);
  }, [isArchived, query.isArchived]);

  useEffect(() => {
    const field = "childProduct[exists]";
    if (
      query[field] !== undefined &&
      (!hasProduct || hasProduct.value !== (query[field] === true))
    ) {
      if (query[field] === true) {
        setHasProduct({ label: "Yes", id: "Yes", value: true });
      } else
        setHasProduct({
          label: "No",
          id: "No",
          value: false,
        });
    } else if (query[field] === undefined && hasProduct) setHasProduct(null);
  }, [hasProduct, query]);

  const thirdPartyField = isReceived
    ? "issuedBy/business_Reference"
    : "receivedBy/business_Reference";

  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="col-span-2 grid grid-cols-[auto_1fr] gap-x-2 items-center p-2 border-b-[1px] border-hgBlue-50 ">
        <Label htmlFor="population" className="w-full text-center">
          Population
        </Label>
        <Dropdown
          id="population"
          className="w-full"
          placeholder="Any"
          value={population}
          onChange={setPopulation}
          config={{
            data: populations,
            getLabel: (population) => population.name,
          }}
        />
      </div>
      <div className="flex flex-col items-stretch">
        <Label htmlFor="year" className="w-full text-center font-normal">
          Year
        </Label>
        <Dropdown
          id="year"
          value={year}
          placeholder="Any"
          onChange={(val) => {
            setYear(val);
            if (val) {
              setQuery.filter(
                {
                  fieldName: "date",
                  label: "date",
                  type: "String",
                },
                "Between",
                val.value1,
                val.value2
              );
            } else
              setQuery.unsetFilter({
                fieldName: "date",
                operator: "bet",
              });
          }}
          className="w-full"
          config={{
            data: yearOptions,
          }}
        />
      </div>
      <div className="flex flex-col items-stretch">
        <Label htmlFor="business" className="w-full text-center font-normal">
          {isReceived ? "From" : "To"}
        </Label>
        <Dropdown
          id="business"
          value={business}
          placeholder="Any"
          onChange={(val) => {
            if (val) {
              setQuery.filter(
                {
                  fieldName: thirdPartyField,
                  label: thirdPartyField,
                  type: "String",
                },
                "Equals",
                val.business_Reference
              );
            } else
              setQuery.unsetFilter({
                fieldName: thirdPartyField,
                operator: "eq",
              });
          }}
          className="w-full"
          config={{
            data: businesses,
            getLabel: (business) => business.tradingName,
          }}
        />
      </div>
      {isReceived && (
        <>
          <div className="flex flex-col items-stretch">
            <Label
              htmlFor="isArchived"
              className="w-full text-center font-normal"
            >
              Archived
            </Label>
            <Dropdown
              id="isArchived"
              placeholder="Any"
              className="w-full"
              value={isArchived}
              onChange={(val) => {
                if (val) {
                  setQuery.filter(
                    {
                      fieldName: "isArchived",
                      label: "isArchived",
                      type: "String",
                    },
                    "Equals",
                    val.value
                  );
                } else
                  setQuery.unsetFilter({
                    fieldName: "isArchived",
                    operator: "eq",
                  });
              }}
              config={{
                data: booleanOptions,
              }}
            />
          </div>
          <div className="flex flex-col items-stretch">
            <Label
              htmlFor="hasProduct"
              className="w-full text-center font-normal"
            >
              Has Product
            </Label>
            <Dropdown
              id="hasProduct"
              placeholder="Any"
              className="w-full"
              value={hasProduct}
              onChange={(val) => {
                if (val) {
                  setQuery.filter(
                    {
                      fieldName: "childProduct",
                      label: "childProduct",
                      type: "String",
                    },
                    val.value ? "Exists" : "Does not exist"
                  );
                } else {
                  setQuery.unsetFilter({
                    fieldName: "childProduct",
                    operator: "ex",
                  });
                  setQuery.unsetFilter({
                    fieldName: "childProduct",
                    operator: "nex",
                  });
                }
              }}
              config={{
                data: booleanOptions,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Controls;
