import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../apiClient";

function useIssueSeedLot() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ body }) =>
      request(
        {
          req: {
            url: "/seed-lots",
            method: "POST",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["seedLots"]);
      queryClient.invalidateQueries(["product"]);
    },
  });

  return queryApi;
}

function useEditSeedLot() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ seedLotRef, body }) =>
      request(
        {
          req: {
            url: `/seed-lots/${seedLotRef}`,
            method: "PATCH",
            data: body,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["seedLots"]);
      queryClient.invalidateQueries(["seedLot"]);
      queryClient.invalidateQueries(["products"]);
      queryClient.invalidateQueries(["product"]);
    },
  });

  return queryApi;
}

function useDeleteSeedLot() {
  const queryClient = new useQueryClient();
  const queryApi = useMutation({
    mutationFn: async ({ seedLotRef }) =>
      request(
        {
          req: {
            url: `/seed-lots/${seedLotRef}`,
            method: "DELETE",
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["seedLots"]);
      queryClient.invalidateQueries(["products"]);
      queryClient.invalidateQueries(["product"]);
    },
  });

  return queryApi;
}

function useGetSeedLot({
  seedLotRef,
  params,
  refetchOnWindowFocus = true,
  retry = 3,
}) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    enabled: !!seedLotRef,
    retry,
    queryKey: ["seedLot", `seedLot${seedLotRef}`],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/seed-lots/${seedLotRef}`,
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

function useGetAllSeedLots({
  params,
  refetchOnWindowFocus = true,
  enabled = true,
}) {
  const queryClient = new useQueryClient();
  const queryApi = useQuery({
    enabled: enabled,
    queryKey: ["seedLots"],
    queryFn: async () =>
      request(
        {
          req: {
            url: `/seed-lots/`,
            method: "GET",
            params,
          },
          transformData: (res) => res?.data?.data,
        },
        queryClient
      ),
    refetchOnWindowFocus,
  });

  return queryApi;
}

export {
  useIssueSeedLot,
  useGetSeedLot,
  useGetAllSeedLots,
  useEditSeedLot,
  useDeleteSeedLot,
};
