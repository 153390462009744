import classNames from "classnames";
function Label({ className, children, ...props }) {
  const classes = classNames(
    "tracking-wide",
    {
      "font-semibold": !className?.includes("font-"),
      "cursor-pointer": !className?.includes("cursor-"),
    },

    className
  );
  return (
    <label className={classes} {...props}>
      {children}
    </label>
  );
}

export default Label;
