/**
 * Returns true if the mouse is within the
 * bounds of a given element
 *
 * @param {*} e Mouse move event
 * @param {*} el Element to check
 */
function mouseIntersectsEl(e, el) {
  if (!el) return false;
  const elDimensions = el.getBoundingClientRect();
  return (
    e.clientX >= elDimensions.left &&
    e.clientX <= elDimensions.left + elDimensions.width &&
    e.clientY >= elDimensions.top &&
    e.clientY <= elDimensions.top + elDimensions.height
  );
}
export default mouseIntersectsEl;
