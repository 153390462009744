import fieldIcon from "../../../assets/icons/fieldIcon.png";
import fieldDeemphasizedIcon from "../../../assets/icons/fieldIconDeemphasized.png";
import fieldAddIcon from "../../../assets/icons/fieldAdd.png";
import fieldDeleteIcon from "../../../assets/icons/fieldDelete.png";
import growerIcon from "../../../assets/icons/growerIcon.png";
import merchantIcon from "../../../assets/icons/merchantIcon.png";
import fieldEditIcon from "../../../assets/icons/fieldEdit.png";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

function addMarkersToMap(map, markers, markersToEdit, onUpdateActiveMarker) {
  map.on("load", () => {
    map.loadImage(growerIcon, (error, image) => {
      if (error) throw error;
      //Add image to map
      map.addImage("GrowerIcon", image);
    });

    map.loadImage(merchantIcon, (error, image) => {
      if (error) throw error;
      //Add image to map
      map.addImage("MerchantIcon", image);
    });

    map.loadImage(fieldIcon, (error, image) => {
      if (error) throw error;
      //Add image to map
      map.addImage("FieldIcon", image);
    });
    map.loadImage(fieldDeemphasizedIcon, (error, image) => {
      if (error) throw error;
      //Add image to map
      map.addImage("FieldDeemphasizedIcon", image);
    });
    map.loadImage(fieldAddIcon, (error, image) => {
      if (error) throw error;
      //Add image to map
      map.addImage("FieldAddIcon", image);
    });
    map.loadImage(fieldEditIcon, (error, image) => {
      if (error) throw error;
      //Add image to map
      map.addImage("FieldEditIcon", image);
    });
    map.loadImage(fieldDeleteIcon, (error, image) => {
      if (error) throw error;
      //Add image to map
      map.addImage("FieldDeleteIcon", image);
    });
  });

  map.on("style.load", () => {
    map.addSource("markers", {
      type: "geojson",
      data: markers,
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });
    map.addSource("markersToEdit", {
      type: "geojson",
      data: markersToEdit,
    });

    //1.Add clustor circle layer
    map.addLayer({
      id: "clusters",
      type: "circle",
      source: "markers",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#3d405b",
          5,
          "#313349",
          10,
          "#252637",
        ],
        "circle-radius": ["step", ["get", "point_count"], 20, 5, 30, 10, 40],
      },
    });

    //2. Add cluster count layer
    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "markers",
      filter: ["has", "point_count"],
      layout: {
        "text-field": ["get", "point_count_abbreviated"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 14,
      },
      paint: {
        "text-color": "#ffffff",
      },
    });

    //3. Add unclustered marker icon

    map.addLayer({
      id: "unclustered-point",
      type: "symbol",
      source: "markers",
      filter: ["!", ["has", "point_count"]],
      layout: {
        "icon-image": ["get", "icon"], // reference the image
        "icon-size": 1,
      },
      paint: {
        "icon-opacity": ["get", "visibility"],
      },
    });

    map.addLayer({
      id: "editableMarkers",
      type: "symbol",
      source: "markersToEdit",
      layout: {
        "icon-image": ["get", "icon"], // reference the image
        "icon-size": 1,
      },
      paint: {
        "icon-opacity": 0,
      },
    });

    //Focus on cluster on click
    map.on("click", "clusters", (e) => {
      e.clickOnLayer = true;
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      });
      const clusterId = features[0].properties.cluster_id;
      map
        .getSource("markers")
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });

    const handleIconClick = (e) => {
      e.clickOnLayer = true;
      if (e.features[0].properties.disablePopUps) return;
      if (e.features[0].properties.isEditing) return;

      if (onUpdateActiveMarker && !e.features[0].properties.isActive) {
        onUpdateActiveMarker(e.features[0].properties.id);
      }

      map.easeTo({
        center: e.features[0].geometry.coordinates,
      });

      const coordinates = e.features[0].geometry.coordinates.slice();
      const title = e.features[0].properties.title;
      const description = e.features[0].properties.description;
      const link = e.features[0].properties.link;

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      const renderedTitle = link
        ? `<strong><a href="${link}">${title}</a></strong>`
        : `<strong>${title}</strong>`;

      const popup = new mapboxgl.Popup({
        closeButton: true,
        maxWidth: "auto",
      })
        .setLngLat(coordinates)
        .setHTML(
          `${renderedTitle}
            <p>${description}<p>
            `
        );

      popup.addTo(map);
      map.on("movestart", popup.remove);
      map.on("mousedown", popup.remove);
      // map.on("mousemove", popup.remove);
      map.on("clearMarkers", popup.remove);
    };

    map.on("click", "unclustered-point", handleIconClick);
    map.on("click", "editableMarkers", handleIconClick);

    //Set cursor on hover
    map.on("mousemove", "clusters", () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "clusters", () => {
      map.getCanvas().style.cursor = "grab";
    });

    map.on("mousemove", "unclustered-point", () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "unclustered-point", () => {
      map.getCanvas().style.cursor = "grab";
    });
    map.on("click", (e) => {
      if (e.clickOnLayer) return;
      if (!onUpdateActiveMarker) return;
      onUpdateActiveMarker(null);
    });
  });
}

export default addMarkersToMap;
