import { useState, useEffect, useRef } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import {
  useGetAllBusinesses,
  useGetCurrentUser,
  useIssueSeedLot,
  useEditSeedLot,
} from "../api";
import { useToastContext } from "../providers/toastContext";
import getControlBodyDictionary from "../utils/getControlBodyDictionary";
import Heading from "../Components/Heading";
import Label from "../Components/Label";
import Input from "../Components/Input";
import Dropdown from "../Components/Dropdown";
import DateInput from "../Components/DateInput";
import FlexCentered from "../Containers/FlexCentered";
import Loading from "../Components/Loading";
import seedLotSchema from "./schemas/seedLotSchema";
import P from "../Components/P";
import classNames from "classnames";
import Button from "../Components/Button";
import RenderedErrors from "../Components/RenderedErrors";

function AddEditSeedLotForBusinessForm({
  product,
  seedLot,
  massBalanceFigures,
  setNewLotQty,
  onClose,
}) {
  const formRef = useRef(null);

  const getBusinessesApi = useGetAllBusinesses({
    params: {
      fields: "tradingName",
      completeList: true,
    },
    refetchOnWindowFocus: false,
  });

  const getCurrentUserApi = useGetCurrentUser({ refetchOnWindowFocus: false });
  const currentUser = getCurrentUserApi?.data?.user;
  const businesses = getBusinessesApi?.data?.businesses;
  const isGrower = getCurrentUserApi?.data?.linkedBusinessType === "Grower";

  const issueSeedLotApi = useIssueSeedLot();
  const editSeedLotApi = useEditSeedLot();

  const { createSuccessToast } = useToastContext();

  useEffect(() => {
    if (issueSeedLotApi.data) {
      createSuccessToast("New seed lot created");
      onClose();
    }
  });

  useEffect(() => {
    if (editSeedLotApi.data) {
      createSuccessToast("Success! Seed lot updated");
      onClose();
    }
  });

  //CENTER FORM ON MOUNT
  useEffect(() => {
    if (!formRef?.current) return;
    const windowHeight = window.innerHeight;
    const { height, top } = formRef.current.getBoundingClientRect();
    const formTop = window.scrollY + top;
    const scrollTo = formTop - (windowHeight - height) / 2;
    window.scrollTo({
      top: scrollTo,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const controlBodies = getControlBodyDictionary({ array: true });

  let defaultOrganic = { label: "Not specified", id: "Not specified" };
  if (seedLot?.isOrganic === true)
    defaultOrganic = { label: "Organic", id: "organic" };
  if (seedLot?.isOrganic === false)
    defaultOrganic = { label: "Not Organic", id: "notOrganic" };
  let defaultControlBody;
  if (seedLot?.controlBody)
    defaultControlBody = controlBodies.find(
      (body) => body.code === seedLot.controlBody
    );
  const form = useForm({
    values: seedLot
      ? {
          ...seedLot,
          isOrganic: defaultOrganic,
          controlBody: defaultControlBody,
        }
      : undefined,
  });
  const { register } = form;
  const errors = form.formState.errors;

  const schema = seedLotSchema({
    product,
    massBalanceFigures,
    startingAmount: seedLot?.quantityKg,
  });

  register("receivedBy", schema.receivedBy);
  register("date", schema.date);
  const quantityKgProps = register("quantityKg", schema.quantityKg);
  register("isOrganic", schema.isOrganic);
  register("controlBody", schema.controlBody);

  const [isFarmSaved, setIsFarmSaved] = useState(false);

  let content;
  const receivedBy = useWatch({
    control: form.control,
    name: "receivedBy",
  });
  const isOrganic = useWatch({
    control: form.control,
    name: "isOrganic",
  });

  const quantity = useWatch({
    control: form.control,
    name: "quantityKg",
  });

  useEffect(() => {
    setNewLotQty(
      Number.isFinite(Number(quantity))
        ? +quantity - (seedLot?.quantityKg || 0)
        : -(seedLot?.quantityKg || 0)
    );
  }, [quantity, seedLot?.quantityKg, setNewLotQty]);

  const onSubmit = (data) => {
    const body = {
      parentLot: product?.seedLot?._id,
      parentProduct: product?._id,
      receivedBy: data?.receivedBy?._id,
      date: data?.date,
      quantityKg: data?.quantityKg,
      isOrganic: isGrower
        ? data?.isOrganic?.label !== "Not specified"
          ? data?.isOrganic?.label === "Organic"
            ? true
            : false
          : undefined
        : undefined,
      controlBody: isGrower
        ? data?.isOrganic?.label === "Organic"
          ? data?.controlBody?.code
          : undefined
        : undefined,
    };
    if (!seedLot) {
      issueSeedLotApi.mutate({
        body,
      });
    } else {
      editSeedLotApi.mutate({
        seedLotRef: seedLot._id,
        body,
      });
    }
  };

  useEffect(() => {
    if (seedLot) return;
    if (isFarmSaved) {
      if (
        !receivedBy ||
        receivedBy._id.toString() !== currentUser?.linkedBusiness?.toString()
      ) {
        const userBusiness = businesses.find(
          (business) =>
            business._id.toString() === currentUser?.linkedBusiness?.toString()
        );
        if (!userBusiness) return;
        form.setValue("receivedBy", userBusiness);
      }
    } else if (
      receivedBy &&
      receivedBy._id.toString() === currentUser?.linkedBusiness?.toString()
    ) {
      form.setValue("receivedBy", null);
    }
  }, [
    businesses,
    currentUser?.linkedBusiness,
    form,
    isFarmSaved,
    receivedBy,
    seedLot,
  ]);

  if (!getBusinessesApi.isFetched || !getCurrentUserApi.isFetched)
    content = (
      <FlexCentered col className="text-bgCream-50 px-8 py-4">
        <Loading />
      </FlexCentered>
    );
  else {
    content = (
      <form
        className="lg:grid lg:grid-cols-2 lg:gap-x-2"
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate
      >
        <div className="col-span-2">
          <Label
            htmlFor="receivedBy"
            className="mb-2 text-hgCream-50"
            title="Select the business this seed lot is being issued to"
          >
            Issued to
          </Label>
          <div className="flex flex-row bg-blue-200 mb-4 mt-2 ">
            <Controller
              control={form.control}
              name="receivedBy"
              render={({ field: { onChange, onBlur, value } }) => (
                <Dropdown
                  id="receivedBy"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className="w-full py-[0.75rem]"
                  config={{
                    data: businesses.filter((business) => {
                      return (
                        business._id.toString() !==
                        currentUser?.linkedBusiness?.toString()
                      );
                    }),
                    getLabel: (el) => el?.tradingName,
                    options: {
                      filter: true,
                    },
                  }}
                  title="Select the business this seed lot is being issued to"
                  error={errors.receivedBy}
                  rules={schema.receivedBy}
                  disabled={isFarmSaved || seedLot}
                />
              )}
            />
            {!seedLot && isGrower && (
              <FlexCentered
                col
                className={classNames("px-2 cursor-pointer select-none", {
                  "bg-gray-100 text-hgBlue-700": !isFarmSaved,
                  "bg-hgGreen-700 text-hgCream-50": isFarmSaved,
                })}
                onClick={() => {
                  setIsFarmSaved((curr) => !curr);
                }}
              >
                <P className="text-center font-semibold whitespace-nowrap">
                  Farm Saved
                </P>
              </FlexCentered>
            )}
          </div>
        </div>
        <div>
          <Label
            htmlFor="date"
            className="mb-2 text-hgCream-50"
            title="On which date was the initial seed lot issued?"
          >
            Date
          </Label>
          <Controller
            control={form.control}
            name="date"
            render={({ field: { onChange, onBlur, value } }) => (
              <DateInput
                id="date"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                min={new Date(2010, 0, 1)}
                max={new Date()}
                className=" w-full mb-4 mt-2"
                title="On which date was the initial seed lot issued?"
                error={errors.date}
                rules={schema.date}
              />
            )}
          />
        </div>
        <div>
          <Label htmlFor="quantityKg" className="mb-2 text-hgCream-50">
            Quantity (kg)
          </Label>
          <Input
            type="text"
            id="quantityKg"
            className="w-full mb-4 mt-2 py-[0.75rem]"
            {...quantityKgProps}
            ref={null}
            innerRef={quantityKgProps.ref}
            error={errors.quantityKg}
          />
        </div>
        {isGrower && (
          <>
            <div>
              <Label
                htmlFor="isOrganic"
                className="mb-2 text-hgCream-50"
                title="Is the seed lot organic"
              >
                Organic
              </Label>

              <Controller
                control={form.control}
                name="isOrganic"
                defaultValue={{ label: "Not specified", id: "Not specified" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Dropdown
                    id="isOrganic"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    className="w-full py-[0.75rem]  mb-4 mt-2"
                    config={{
                      data: [
                        { label: "Not specified", id: "Not specified" },
                        { label: "Organic", id: "organic" },
                        { label: "Not Organic", id: "notOrganic" },
                      ],
                      options: {
                        disallowEmptyValues: true,
                      },
                    }}
                    title="Is the seed lot organic"
                    error={errors.isOrganic}
                    rules={schema.isOrganic}
                  />
                )}
              />
            </div>
            {isOrganic?.id === "organic" && (
              <div>
                <Label
                  htmlFor="isOrganic"
                  className="mb-2 text-hgCream-50"
                  title="Specify the control body"
                >
                  Control Body
                </Label>

                <Controller
                  control={form.control}
                  name="controlBody"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropdown
                      id="controlBody"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      className="w-full  py-[0.75rem]  mb-4 mt-2"
                      config={{
                        data: controlBodies,
                        getKey: (el) => el.code,
                        options: {
                          disallowEmptyValues: true,
                        },
                      }}
                      title="Specify the control body"
                      error={errors.controlBody}
                      rules={schema.controlBody}
                    />
                  )}
                />
              </div>
            )}
          </>
        )}
        <FlexCentered col className="col-span-2 pb-4 mt-2">
          {seedLot && (
            <div className="max-w-full bg-hgYellow-500 px-2 py-2 mb-4 select-none">
              <P>
                NB: If this seed lot has been used by the recipient to generate
                a product and/or issue child lots, a mass balance error may be
                received.
              </P>
            </div>
          )}
          <Button
            primary={!seedLot}
            secondary={!!seedLot}
            outline={!!seedLot}
            className="bg-hgBlue-50"
          >
            {seedLot ? "Save" : "Issue"}
          </Button>
          <RenderedErrors
            errors={errors || []}
            apiError={issueSeedLotApi.error || editSeedLotApi.error || ""}
            className="bg-hgCream-50 max-w-[20rem]"
          />
        </FlexCentered>
      </form>
    );
  }

  return (
    <div
      ref={formRef}
      className={classNames("relative rounded px-[4vw] md:px-[4rem] mt-12", {
        "bg-hgBlue-500": !seedLot,
        "bg-hgGreen-1000": seedLot,
      })}
    >
      <div
        className="bg-hgCream-50 w-fit h-fit rounded-full p-1 absolute right-[5px] top-[5px] cursor-pointer hover:scale-105"
        onClick={onClose}
      >
        <AiOutlineClose className=" text-xl text-hgRed-700" />
      </div>
      <Heading
        tertiary
        className="text-hgCream-50 font-light text-xl text-center pt-2 mb-4"
      >
        {seedLot ? `Edit: ${seedLot.reference}` : "Issue Seed Lot"}
      </Heading>
      {content}
    </div>
  );
}

export default AddEditSeedLotForBusinessForm;
